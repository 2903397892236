﻿.trades-container {
    .container-loader {
        position: static;
        flex: 1 1 auto;
    }

    .data-list {
        height: 100%;

        &-container {
            @media all and (-ms-high-contrast:none) {
                min-width: 1480px;
            }
        }

        &-content {
            flex: 1 1 auto;
            overflow: auto;

            &:after {
                display: none;
            }
        }

        &-row {
            flex: none;
        }

        &-cell {
            &.cell-settl-status {
                padding-left: 20px;

                .status {
                    align-items: center;
                }

                .allocation {
                    margin-left: -20px;
                    margin-right: 4px;
                }
            }

            &.cell-shortcode {
                .email {
                    max-width: 210px;
                    width: auto;
                    display: inline-block;
                    vertical-align: bottom;
                }

                .pseudo-link {
                    width: auto;
                }

                .tooltip-wrapper {
                    margin-left: 6px;
                }
            }

            &.cell-reporter {
                .container-loader {
                    width: 16px;
                    min-width: 16px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }

    .infinite-scroll-container {
        .scroll-loader {
            width: 100vw;
        }
    }
}
