﻿.access-tokens {
    .data-list-striped {
        .data-list-header {
            padding-right: 41px;
        }
    }

    .data-list:not(.data-list-freeze) .data-list-container {
        min-width: 1280px;
    }

    .actions-cntrls {
        &-item {
            margin-left: 16px;

            .icon-svg {
                margin-right: 0;
            }

            &:first-child {
                margin-left: 0;
            }
        }
    }
}
