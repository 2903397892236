.sub-header-new-issue {
    position: relative;

    .state-new {
        margin-right: 8px;
    }

    .right-controls {
        display: flex;
        align-items: center;
        line-height: 16px;

        .btn-link,
        .select-alert-wrap {
            margin-left: 24px;
        }
    }

    .header-bwic-bloomberg {
        margin-right: 0;

        .bloomberg-wrapper {
            margin-right: 24px;
        }
    }
}

.container-new-issue {
    position: relative;

    .tabs-content {
        position: relative;

        & > .empty-placeholder {
            width: auto;
        }
    }

    .data-item-general-information {
        .data-item-sub {
            width: 100%;
        }
    }

    .data-item-row.today {
        .data-item-col:not(.data-item-col-title) {
            color: $secondary-1;
        }
    }

    .analytics-info {
        .status-message {
            border-left: none;
            border-radius: 0;
            justify-content: center;
        }
    }

    .data-item {
        position: relative;
        z-index: 0;

        .transaction-type-label {
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
            margin-right: 8px;
        }
    }

    .transaction-type-status-row {
        .label-status-type {
            margin-right: 16px;
        }

        .difference-highlight {
            margin: 2px 8px 2px -8px;

            .transaction-type-label {
                margin-right: 0;
            }
        }
    }

    .data-item-row {

        .increase-value,
        .decrease-value {
            margin-left: 16px;
        }
    }

    .data-list-striped {
        flex-grow: 0;
    }

    .downloaded-documents-type {
        display: flex;
        width: 100%;

        .agenda {
            margin-left: 32px;
            flex-grow: 1;

            &-item {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 202px;

                .downloaded-documents-label {
                    margin-right: auto;
                }
            }
        }
    }

    .analytics {
        margin-bottom: 40px;

        &-heading {
            display: flex;
            align-items: center;
            padding-bottom: 8px;
            margin-bottom: 16px;
            border-bottom: 1px solid $border-color;

            h2 {
                margin-bottom: 0;
                margin-right: 24px;
            }
        }

        .js-plotly-plot {
            width: calc(100% + 28px);
            height: 270px;
            margin-left: -20px;
        }

        .agenda {
            margin-top: -26px;
        }

        .data-list-content {
            overflow: auto;
        }

        .full-name-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .blocked-feature-placeholder {
            margin-top: 12px;
        }
    }

    .agenda {
        display: flex;

        &-vertical {
            flex-direction: column;

            .agenda-item {
                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
        }

        .agenda-item {
            position: relative;
            padding-left: 20px;
            margin-right: 16px;
            font-size: 12px;
            line-height: 1.15;

            &:before {
                position: absolute;
                content: '';
                width: 12px;
                height: 12px;
                border-radius: 2px;
                top: 0;
                left: 0;
            }

            &.deal-documents {
                &:before {
                    background-color: $yellow-1;
                }
            }

            &.intex-file {
                &:before {
                    background-color: $primary-10;
                }
            }

            &.manager-presentation {
                &:before {
                    background-color: $green-9;
                }
            }

            &.target-portfolio {
                &:before {
                    background-color: $blue-5;
                }
            }

            &.iois {
                &:before {
                    background-color: $grey-8;
                }
            }

            &.overview {
                &:before {
                    background-color: $blue-4;
                }
            }

            &.structure {
                &:before {
                    background-color: $blue-11;
                }
            }

            &.comparative-metrics {
                &:before {
                    background-color: $blue-13;
                }
            }

            &.target-portfolio2 {
                &:before {
                    background-color: $primary-13;
                }
            }

            &.documents {
                &:before {
                    background-color: $grey-2;
                }
            }

            &.disclosure {
                &:before {
                    background-color: $purple-2;
                }
            }
        }
    }

    .data-list-syndicate-contacts {
        .data-list-cell-index {
            flex-basis: 16px;
            max-width: 16px;
            min-width: 16px;
            padding-right: 0;
            text-align: right;
        }

        .data-list-content {
            overflow: auto;
        }
    }

    .component-tabs-deal-documents {
        .control-search-wrapper {
            margin-bottom: 20px;
        }

        .mr-left {
            margin-left: 20px;
        }
    }

    .data-item-cap-structure {
        position: static;
        z-index: auto;
    }

    .data-list-striped-cap-structure {
        &.data-list-striped {
            &.data-list-freeze {
                &.right-sticky-col {
                    &:before {
                        content: none;
                    }
                }

                .data-list-header {
                    padding-right: 0;
                    overflow-y: hidden;
                }

                .data-column-sticky-right-content {
                    padding-right: 24px;
                }

                .data-list-content {
                    .data-list-columns {
                        margin-bottom: 0;
                        min-height: 1px;
                    }
                }
            }

            .data-list-row {
                &.data-list-striped-total {
                    margin-bottom: 16px;

                    .total-sticky-col {
                        .data-list-striped-row {
                            padding-left: 24px;
                        }
                    }

                    .data-list-striped-row {
                        padding: 0 8px;
                    }
                }
            }
        }

        .data-list-content {
            overflow-y: hidden;
        }

        .data-column-sticky-middle {
            -ms-overflow-style: none;
            /* Internet Explorer 10+ */
            scrollbar-width: none;
            /* Firefox */

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }

    .transaction-view-history {
        margin-bottom: 12px;

        .data-list-content:after {
            display: none;
        }

        & + .restricted-placeholder {
            padding: 0;
        }
    }

    .file-name-wrap {
        display: flex;
        align-items: center;
    }

    .btn-download-file {
        margin-right: 8px;

        &.removed {
            color: $grey-1;
            margin-right: 16px;
            display: flex;
            align-items: center;

            .icon-svg {
                margin-right: 12px;
            }
        }
    }
}
