.accordion {
    .accordion-row {
        position: relative;
        border-top: 1px solid transparent;
        border-bottom: 1px solid $border-color;
        margin-top: -1px;

        &:hover {
            .collapsable-item-header {
                background-color: $row-hover;
                background-clip: padding-box;
            }
        }

        &.item-open {
            border-color: $border-color;

            &:hover {
                .collapsable-item-header {
                    background: none;
                }
            }
        }

        .collapsable-item-header {
            min-height: 32px;
            padding: 5px 32px 5px 30px;
            color: $grey-4;
            font-size: 12px;

            .kpt-platform-wrapper {
                flex: none;

                .kpt-platform {
                    top: 0;
                }
            }

            .icon-expand {
                flex: none;
                margin-left: -17px;
                margin-right: 7px;
            }
        }
    }

    .accordion-cnt {
        border-top: 1px solid $border-color;
    }
}

.collapsable-item {
    position: relative;
    width: 100%;

    .item-row {
        padding: 3px 20px 3px 95px;
        line-height: 24px;

        .control-label {
            margin-top: -5px;
        }
    }
}

.collapsable-item-header {
    @extend .flex-row;
    padding: 4px 32px 4px 35px;
    height: 40px;
    cursor: pointer;

    &:hover {
        background: $row-hover;
    }

    .limit-blocker {
        display: inline-block;
    }

    .kpt-platform-wrapper {
        width: 24px;
        display: inline-block;
    }

    .kpt-platform {
        display: inline-block;

        .icon-svg-on-platform {
            margin-right: 8px;
        }
    }

    .no-users {
        font-family: $font-roboto-regular;
        margin-left: 12px;
    }

    .bids-counter {
        color: $grey-4;
        font-size: 12px;
    }
}
