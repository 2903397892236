﻿h1, h2, h3, h4, h5, h6, p {
    margin-top: 0;
    margin-bottom: 10px;
    font-weight: normal;
}

h1 {
    font: 500 18px $font-roboto-medium;

    &.normal {
        font-family: $font-roboto-regular;
    }
}

h2 {
    font-size: 16px;
    font-family: $font-roboto-medium;
}

h3 {
    font-size: 14px;
    font-family: $font-roboto-medium;

    &.title {
        font-family: $font-roboto-bold;
    }
}

h4 {
    font-size: 14px;
    font-family: $font-roboto-medium;
}

h5 {
    font-size: 12px;
    font-family: $font-roboto-medium;
}

h6 {
    font-size: 12px;
    font-family: $font-roboto-medium;
}

address {
    font-style: normal;
}

a {
    color: $link-color;
    font-family: $font-roboto-regular;
    text-decoration: none;
    outline: none;
    cursor: pointer;

    &:hover {
        color: $link-hover;
        text-decoration: underline;
    }

    &:focus,
    &:active {
        color: $link-focus;
    }

    &.disabled {
        color: $link-disabled;
        pointer-events: none;
    }
}

.pseudo-link {
    color: $text-color;
    font: 12px/15px $font-family-base;
    text-transform: none;
    text-decoration: none;
    background: none;
    background-color: transparent;
    border: none;
    border-bottom: 1px dashed $text-color;
    border-radius: 0;
    padding: 0;
    display: inline;
    cursor: pointer;

    &:hover {
        color: $link-hover;
        text-decoration: none;
        border-color: $link-hover;
    }

    &:focus,
    &:active,
    &.is-active {
        color: $link-focus;
        text-decoration: none;
        border-color: $link-focus;
    }

    &.disabled {
        color: $link-disabled;
        border-color: $link-disabled;
        user-select: none;
        cursor: default;
    }
}

.icon-svg-docs {
    color: $purple-5; 
}

.limit-blocker {
    .pseudo-link {
        pointer-events: none;
        color: $grey-1;
        border-color: $grey-1;
    }

    .icon-svg-docs {
        color: inherit;
    }
}

.link-filter {
    color: $link-color;
    cursor: pointer;

    &:hover {
        color: $link-hover;
        border-color: $link-hover;
        text-decoration: none;
    }
}

ul,
ol {
    padding: 0;
    margin: 0;
}

ul {
    list-style: none;
}

.list-bullet {
    li {
        position: relative;
        padding-left: 12px;
        line-height: 1.2;

        &:before {
            content: ' ';
            position: absolute;
            top: 6px;
            left: 0;
            width: 4px;
            height: 4px;
            border-radius: 50%;
            background-color: $green-7;
        }

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    &.grey {
        li {
            &:before {
                background-color: $grey-4;
            }
        }
    }

    &.text-sm {
        li {
            &:before {
                top: 4px;
            }
        }
    }
}

ol {
    line-height: 1.4;
    padding-left: .94rem;
}

.list-alfa {
    margin: 0;
    list-style: lower-alpha;
}

.list-roman {
    margin: 0;
    list-style: lower-roman;
}

.contacts-cards {
    li {
        padding: 8px 16px 3px;
        border-top: 1px solid $grey-5;
        font: 12px/15px $font-roboto-regular;

        &:last-child {
            border-bottom: 1px solid $grey-5;
        }

        span {
            display: block;
            margin-bottom: 5px;
        }

        .link-filter {
            color: inherit;

            &:hover {
                color: $link-hover;
            }
        }

        .icon-svg-primary-contact {
            margin-top: -8px;
        }
    }
}

.support-info-list {
    li {
        padding: 0 0 16px;
        display: inline-flex;
        align-items: center;
        width: 100%;

        .icon-svg {
            margin-right: 8px;
        }
    }
}

b,
strong {
    font-weight: normal;
    font-family: $font-roboto-bold;
}


/* Fonts classes */

.text-regular {
    font-family: $font-roboto-regular !important;
}

.text-light {
    font-family: $font-roboto-light !important;
}

.text-medium {
    font-family: $font-roboto-medium !important;
}

.text-bold {
    font-family: $font-roboto-bold !important;
}

.text-black {
    font-family: $font-roboto-black !important;
}

.text-italic {
    font-style: italic;
}

/* end Fonts classes */


/* Fonts sizes and alignment */

.text-xl {
    font-size: 18px;
}

.text-lg {
    font-size: 16px;
}

.text-sm {
    font-size: 12px;
}

.align-middle {
    vertical-align: middle;
}

.align-top {
    vertical-align: top;
}

.align-bottom {
    vertical-align: bottom;
}

.text-left {
    text-align: left !important;
}

.text-right {
    text-align: right !important;
}

.text-center {
    text-align: center !important;
}

.text-capitalize {
    text-transform: capitalize !important;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-underline {
    text-decoration: underline;
}

.text-transform-none {
    text-transform: none !important;
}

.text-ellipsis {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.word-break {
    width: 100%;
    overflow: hidden;
    word-break: break-all;
}

/* end Fonts sizes and alignment */


/* Other text helper classes */

.first-letter {
    display: block;

    &:first-letter {
        text-transform: uppercase;
    }
}

.text-nowrap {
    white-space: nowrap;
}

/* end other text helper classes */


/* Fonts colors */

.text-red {
    color: $secondary-1 !important;
}

.text-warm-grey {
    color: $grey-8 !important;
}

.text-light-grey {
    color: $grey-1 !important;
}

.text-teal-blue {
    color: $primary-3;
}

/* end Fonts colors */

.vertical-divider {
    width: 1px;
    height: 12px;
    background: $grey-2;

    &-light {
        background: $border-color;
    }
}

.markdown-viewer {
    margin-bottom: -10px;
    ul {
        list-style: disc;
        padding-left: 20px;

        li {
            padding-left: 2px;
        }
    }

    ol {
        padding-left: 17px;

        li {
            padding-left: 5px;
        }
    }
}