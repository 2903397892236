.toggle {
    min-height: 16px;
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    line-height: 16px;
    vertical-align: middle;
    cursor: pointer;

    &.disabled {
        pointer-events: none;

        .toggle-text {
            color: $control-checkbox-radio-text-disabled;
        }
    }

    .btn-link {
        vertical-align: baseline;
    }

    input[type="checkbox"] {
        position: absolute;
        z-index: -1;
        opacity: 0;
    }

    input[type="checkbox"]:not(:checked) + .toggle-icon,
    input[type="checkbox"]:checked + .toggle-icon {
        &:after {
            position: absolute;
            top: 0;
            left: 0;
            display: block;
            width: 16px;
            height: 16px;
            content: "";
            background-repeat: no-repeat;
            border-radius: 50%;
            @extend .transition;
            transition: transform .15s ease-in-out,-webkit-transform .15s ease-in-out;
        }
    }

    input[type="checkbox"]:not(:checked) + .toggle-icon {
        &:after {
            background-color: $white-1;
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1C1.3 1.3 5.125 5.125 7 7M1 7L7 1' stroke='%23C57F5F' stroke-width='1.5'/%3E%3C/svg%3E%0A");
            background-position: 3px;
            border: solid 1px $brown-1;
            box-shadow: inset 0px 0px 0px 0.5px $brown-1, 0px 0px 0px 2px $white-1;
        }

        &:hover,
        &:focus,
        &:active {
            &:after {
                background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1C1.3 1.3 5.125 5.125 7 7M1 7L7 1' stroke='%23AC6F02' stroke-width='1.5'/%3E%3C/svg%3E%0A");
                box-shadow: inset 0px 0px 0px 0.5px $brown-2, 0px 0px 0px 2px $white-1;
                border-color: $brown-2;
            }
        }
    }

    input[type="checkbox"]:checked + .toggle-icon {
        &:after {
            background-color: $green-2;
            background-image: url("data:image/svg+xml,%3Csvg width='10' height='9' viewBox='0 0 10 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 4L4 7L9 1' stroke='white' stroke-width='1.5'/%3E%3C/svg%3E%0A");
            background-position: 2px 3px;
            border: solid 1px $green-2;
            box-shadow: inset 0px 0px 0px 0.5px $green-2, 0px 0px 0px 2px $white-1;
            transform: translateX(11px);
        }

        &:hover,
        &:focus,
        &:active {
            &:after {
                background-color: $green-1;
                border-color: $green-1;
                box-shadow: inset 0px 0px 0px 0.5px $green-1, 0px 0px 0px 2px $white-1;
            }
        }
    }

    input[type="checkbox"]:disabled + .toggle-icon {
        pointer-events: none;

        &:before {
            pointer-events: none;
            background: $control-disabled;
        }
    }

    input[type="checkbox"]:not(:checked):disabled + .toggle-icon {
        &:after {
            background-image: url("data:image/svg+xml,%3Csvg width='8' height='8' viewBox='0 0 8 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1C1.3 1.3 5.125 5.125 7 7M1 7L7 1' stroke='%23BDBDBD' stroke-width='1.5'/%3E%3C/svg%3E ");
            box-shadow: inset 0px 0px 0px 0.5px $control-disabled, 0px 0px 0px 2px $white-1;
            border-color: $control-disabled;
        }
    }

    input[type="checkbox"]:checked:disabled + .toggle-icon {
        &:after {
            background-color: $link-disabled;
            border-color: $link-disabled;
            box-shadow: inset 0px 0px 0px 0.5px $link-disabled, 0px 0px 0px 2px $white-1;
        }
    }

    &-icon {
        display: inline-block;
        position: relative;
        vertical-align: top;
        line-height: 16px;
        min-height: 16px;
        padding-left: 28px;
        outline: none;
        @extend .transition;

        &:before {
            position: absolute;
            top: 5px;
            left: 0;
            display: block;
            width: 27px;
            height: 7px;
            border-radius: 11px;
            pointer-events: all;
            content: "";
            background-color: $grey-2;
        }
    }

    &-text {
        margin-left: 4px;
        display: inline-flex;
    }
}
