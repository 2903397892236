.animated-value-update,
.animated-value-update-error {
    position: relative;
    display: inline-block;
    overflow: hidden;
    margin: 0 2px;
    border-radius: 2px;

    .animated-value-update-bg {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &.animated-value-update-default-bg {
            background: $yellow-2;
        }
    }

    .animated-value-update-content {
        position: relative;
        padding: 0 8px;
        font: 10px/16px $font-roboto-medium;
    }
}