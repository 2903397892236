.auto-feedback-settings {
    .status-message {
        padding: 12px;
        font-size: inherit;
        line-height: 1.2;
    }

    &-options {
        position: relative;
        margin-right: -16px;
        margin-left: -16px;
        padding: 16px 16px 4px;
        border-bottom: 1px solid $border-color;

        &.toggle-disabled {
            .toggle {
                opacity: 0.5;
            }
        }

        .popover {
            width: 290px;
            margin-top: -4px;
            margin-left: 16px;
            padding: 8px 15px;

            .popover-body {
                padding-top: 0;
            }
        }
    }
}
