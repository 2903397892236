.checkbox {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    height: 16px;
    font: 12px/16px $font-roboto-regular;
    vertical-align: middle;
    cursor: pointer;

    &.disabled {
        pointer-events: none;

        label {
            color: $control-checkbox-radio-text-disabled;
        }
    }

    label {
        height: auto;
        min-height: 16px;
        line-height: 16px;
        display: inline-block;
        vertical-align: middle;
    }

    input[type="checkbox"]:not(:checked),
    input[type="checkbox"]:checked {
        position: fixed; //absolute
        left: -9999px;
    }

    input[type="checkbox"]:not(:checked) + label,
    input[type="checkbox"]:checked + label {
        position: relative;
        cursor: pointer;
        padding-left: 16px;

        &.checkbox-lbl-text {
            padding-left: 20px;
        }
    }

    input[type="checkbox"]:not(:checked) + label:before,
    input[type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: solid 1px $control-border;
        background-color: $control-bg;
        box-sizing: border-box;
        @extend .transition;
    }

    input[type="checkbox"]:not(:checked) + label:before,
    input[type="checkbox"]:checked + label:before {
    }

    input[type="checkbox"]:not(:checked) + label:hover:before,
    input[type="checkbox"]:checked + label:hover:before {
        border: solid 1px $button-bg;
        background-color: $control-hover-bg;
    }

    input[type="checkbox"]:not(:checked) + label:active:before {
        border: solid 1px $control-radio-check-focus;
        background-color: $control-hover-bg;
    }

    input[type="checkbox"]:not(:checked):disabled + label:before,
    input[type="checkbox"]:checked:disabled + label:before {
        border-color: $control-border;
        background-color: $control-disabled-bg;
    }

    input[type="checkbox"]:disabled + label {
        pointer-events: none;
    }

    input[type="checkbox"]:not(:checked) + label:after,
    input[type="checkbox"]:checked + label:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 16px;
        width: 16px;
        background-color: $button-bg;
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M3.17794 6.31171L0.807277 3.82401L0 4.66518L3.17794 8L10 0.841163L9.19841 0L3.17794 6.31171Z' fill='white'/%3E%3C/svg%3E%0A");
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-size: initial;
        border-radius: 2px;
        border: solid 1px $button-bg;
        transition: all .2s;
        box-sizing: border-box;
    }

    input[type="checkbox"]:checked + label:hover:after {
        background-color: $control-hover;
        border-color: $control-hover;
    }

    input[type="checkbox"]:checked + label:active:after {
        background-color: $control-radio-check-focus;
        border-color: $control-radio-check-focus;
    }

    input[type="checkbox"]:checked:disabled + label:after {
        background-color: $control-disabled;
        border-color: $control-disabled;
    }

    input[type="checkbox"]:not(:checked) + label:after {
        opacity: 0;
        transform: scale(0);
    }

    input[type="checkbox"]:checked + label:after {
        opacity: 1;
        transform: scale(1);
    }

    input[type="checkbox"]:not(:checked).is-invalid + label:before {
        border-color: $color-error;
    }

    & + .form-error {
        top: 17px;
    }

    &.checkbox-indeterminate {
        input[type="checkbox"]:not(:checked) + label:after,
        input[type="checkbox"]:checked + label:after {
            top: 7px;
            left: 5px;
            width: 6px;
            height: 2px;
            border: 0;
            background-color: $control-checkbox-radio-border-checked;
            background-image: none;
            opacity: 1;
            transform: none;
        }

        input[type="checkbox"]:not(:checked) + label:before,
        input[type="checkbox"]:checked + label:before {
            border-color: $control-checkbox-radio-border-checked;
            background-color: $control-checkbox-radio-bg;
        }

        &:hover {
            input[type="checkbox"] + label:before {
                border-color: $control-checkbox-radio-border-hover;
                background-color: $control-checkbox-radio-bg-hover;
            }

            input[type="checkbox"] + label:after {
                background-color: $control-hover;
            }
        }

        &:active {
            input[type="checkbox"] + label:before {
                border-color: $control-radio-check-focus;
                background-color: $control-checkbox-radio-bg-focus;
            }

            input[type="checkbox"] + label:after {
                background-color: $control-radio-check-focus;
            }
        }
    }
}
