﻿.data-list-striped {
    flex: 1 1 auto;
    min-height: 1px;
    font-size: 12px;
    overflow-x: auto;
    overflow-y: hidden;

    @media all and (-ms-high-contrast:none) {
        overflow-x: scroll;
    }

    .data-list-container {
        position: relative;
        min-height: 57px;
    }

    .data-list-row {
        &.data-list-striped-total {
            counter-increment: none;
            background-color: $grey-3;
            border-bottom: none;
            overflow: hidden;

            .data-list-cell {
                color: $text-color;
                font-family: $font-roboto-bold;
                text-transform: uppercase !important;
                padding-top: 6px;
                padding-bottom: 6px;

                &:first-child {
                    font-family: $font-roboto-bold;

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

.data-list-options-column {
    .btn-sm {
        font-size: 11px;
        text-align: center;
        width: 90px;
        border-radius: $app-brd-radius;
        padding: 0;

        &:disabled {
            pointer-events: all;
        }
    }
}
