﻿*,
*::before,
*::after {
    box-sizing: border-box;
}

html, body {
    line-height: 1.4;
    height: 100%;
    width: 100%;
}

html {
    font-family: sans-serif;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba($grey-4, 0);
}

body {
    color: $text-color;
    font-size: 14px;
    font-family: $font-family-base;
    font-weight: normal;
    text-align: left;
    background-color: $body-bg;
    margin: 0;
    padding: 0;
    position: relative;
}

.wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;
}

.container-flex {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    min-height: 1px;
}

.container-overflow {
    overflow: auto;
}

.container {
    position: relative;
}

main,
.container {
    @extend .container-flex;
}

hr {
    box-sizing: content-box;
    height: 0;
    border: none;
    border-top: 1px solid $border-color;
    overflow: visible;
}

svg {
    overflow: hidden;
    vertical-align: middle;
}

.flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.flex-row-full-size {
    flex: 1 1 auto;
    align-items: stretch;
    min-height: 1px;

    & > .empty-placeholder {
        width: 100%;
        align-self: flex-start;
    }
}

.flex-item {
    flex: auto;
}

.flex-item-equal {
    flex: 1 1 50%;
}

.flex-none {
    flex: none;
}

.flex-item-right {
    margin-left: auto;
    text-align: right;
}

.flex-align-end {
    align-self: flex-end;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-direction-row {
    flex-direction: row;
}

.flex-direction-column {
    flex-direction: column;
}

.align-items-flex-start {
    align-items: flex-start;
}

.align-items-flex-end {
    align-items: flex-end;
}

.justify-content-end {
    justify-content: flex-end;
}

.justify-content-space-between {
    justify-content: space-between;
}

.transition {
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.pointer-events-none {
    pointer-events: none;
}

.pointer {
    cursor: pointer;
}

.display-inline {
    display: inline-block;
    vertical-align: middle;
}

.display-inline-flex {
    display: inline-flex;
}

.display-flex {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    min-height: 1px;
    min-width: 100%;
}

.visibility-none {
    visibility: hidden;
}

.display-none {
    display: none !important;
}

.position-relative {
    position: relative;
}

.position-absolute {
    position: absolute;
}

.vertical-align-top {
    vertical-align: top;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-auto {
    overflow: auto;
}

.overflow-visible {
    overflow: visible !important;
}

.margin-t-0 {
    margin-top: 0 !important;
}

.margin-r-0 {
    margin-right: 0 !important;
}

.margin-b-0 {
    margin-bottom: 0 !important;
}

.margin-l-0 {
    margin-left: 0 !important;
}

.margin-l-a {
    margin-left: auto !important;
}

.margin-r-8 {
    margin-right: 8px !important;
}

.margin-r-a {
    margin-right: auto !important;
}

.margin-l-8 {
    margin-left: 8px !important;
}

.margin-b-8 {
    margin-bottom: 8px !important;
}

.padding-r-0 {
    padding-right: 0 !important;
}

.padding-l-0 {
    padding-left: 0 !important;
}

.width-64 {
    width: 64px !important;
}

.width-100 {
    width: 100% !important;
}

.width-a {
    width: auto !important;
}


/* Layout for Sign In and Sign Up pages */

.sign-page {
    overflow: auto;
    background: linear-gradient(147deg, $orange-2, $orange-3) no-repeat 0 0;
    background-size: 50% 100%;

    &.container-flex {
        flex-direction: row;
    }


    .navbar {
        border-width: 0;
        background: none;
    }

    .navbar-item-anonymous-state {
        .navbar-support:after {
            display: none;
        }
    }

    &-form {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;

        .container-flex {
            align-items: center;
            min-height: unset;
        }
    }

    @media only screen and (max-width: 1400px) {
        & {
            background-size: 630px 100%;
        }
    }

    @media only screen and (max-width: 1279px) {
        & {
            background: none;
        }

        .navbar {
            border-width: 1px;
        }
    }
}

/* end Layout for Sign In and Sign Up pages */
