﻿.dashboard-container {
    .filters-area {
        .btn,
        .icon-svg-help {
            margin-right: 8px;
        }

        .icon-svg-help {
            margin-top: -7px;
        }
    }
}


.dashboard {
    padding: 0 24px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    background: $grey-7;
    transition: background-color .45s ease-in-out;

    .empty-placeholder {
        p {
            width: 100%;
        }
    }

    &.filtered {
        background: rgba($grey-4, 0.3);

        h1 {
            user-select: none;
        }

        .dashboard-cnt-box {
            &:after {
                width: 100%;
                height: 100%;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                border-radius: 6px;
                background: rgba($grey-4, 0.3);
                z-index: 3;
            }

            &.filtered-widget {
                &:after {
                    display: none;
                }
            }
        }
    }

    & > h1 {
        margin: 20px 0 0;
        flex: none;
    }

    &-row {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        flex: none;
        margin-left: -16px;

        &:last-child {
            padding-bottom: 72px;
        }

        & + h1 {
            margin-top: 72px;
        }

        .dashboard-cnt-box {
            position: relative;
            border-radius: $app-brd-radius;
            box-shadow: $app-shadow;
            background: $white-1;
            min-height: 351px;
            flex-grow: 0;
            flex-shrink: 1;
            min-width: calc(33.3% - 16px);
            max-width: calc(33.3% - 16px);
            flex-basis: calc(33.3% - 16px);
            padding: 12px 12px 0;
            margin: 16px 0 0 16px;
            display: flex;
            flex-direction: column;

            &.expanded-widget {
                z-index: 10;
            }

            &.with-border {
                border: 1px solid $border-color;
                box-shadow: none;
            }

            &.half-size {
                min-width: calc(50% - 16px);
                max-width: calc(50% - 16px);
                flex-basis: calc(50% - 16px);
            }

            &.double-size {
                min-width: calc(66.6% - 16px);
                max-width: calc(66.6% - 16px);
                flex-basis: calc(66.6% - 16px);
            }

            &.full-size {
                min-width: calc(100% - 16px);
                max-width: calc(100% - 16px);
                flex-basis: calc(100% - 16px);
            }

            &.height-size01 {
                min-height: 430px;
            }

            .tabs {
                margin: 0 -12px;
            }

            .heading {
                line-height: 16px;

                > h3,
                > div h3 {
                    line-height: 16px;
                    margin: 0 10px 12px 0;
                    display: inline-block;

                    .date {
                        font: 14px/16px $font-roboto-regular;
                        margin-left: 8px;
                        color: $grey-4;
                    }
                }

                > .btn,
                > .btn-link,
                .flex-item-right .btn {
                    height: 16px;
                }

                &.flex-row {
                    align-items: flex-start;
                }

                .custom-drop-down .drop-down-btn > span {
                    margin-right: 0;
                }
            }

            &.k-news {
                min-height: 480px;

                &.k-news-auto {
                    min-height: auto;
                }

                .graph-box {
                    position: relative;

                    &:before {
                        position: absolute;
                        content: '';
                        z-index: 1;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 10px;
                        background-color: $white-1;
                    }
                }

                .heading {
                    display: flex;
                }

                .slick-slide {
                    border-bottom: 1px solid $border-color;
                }

                .slick-arrow {
                    display: none !important;
                }

                .news-item {
                    padding-top: 16px;
                    padding-bottom: 16px;
                    margin-bottom: 0;
                    height: 112px;
                }

                .news-controls {
                    min-height: 24px;
                }

                .news-item-title {
                    margin-bottom: 8px;
                    white-space: nowrap;

                    .btn-link {
                        font-size: 12px;
                        line-height: 1.3;
                        max-width: 100%;
                        
                        .tooltip-wrapper {
                            max-width: 100%;
                            display: inline-flex;
                            white-space: nowrap;
                        }
                    }
                }

                .news-item-additional {
                    white-space: nowrap;

                    &-item {
                        padding-right: 10px;
                        margin-right: 10px;

                        .loader-box {
                            background: none;
                        }
                    }
                }
            }

            &.im-chart {
                .heading {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 12px;

                    & > h3 {
                        margin-bottom: 0;

                        span {
                            font-family: $font-roboto-regular;
                            margin-left: 8px;
                        }
                    }

                    .filter-section {
                        &:last-child {
                            margin-right: 0;
                        }

                        .custom-drop-down-term,
                        .custom-drop-down-period {

                            .drop-down-list-item span {
                                width: auto;
                                color: $grey-8;

                                &:first-child {
                                    width: auto;
                                    margin-right: 16px;
                                    color: $grey-4;
                                }

                                &:last-child {
                                    font-family: $font-roboto-regular;
                                }
                            }
                        }

                        .custom-drop-down-term {
                            .drop-down-list {
                                width: 136px;
                            }
                        }

                        .custom-drop-down-period {
                            .drop-down-list {
                                width: 90px;
                            }
                        }
                    }
                }

                .graph-box {
                    display: flex;
                    flex-direction: column;
                }

                .description {
                    padding: 8px 0 12px;
                    margin-top: auto;
                }

                .agenda {
                    display: flex;
                    align-items: center;
                    padding-bottom: 12px;

                    &-item {
                        position: relative;
                        padding-left: 20px;
                        margin-right: 16px;

                        &:before {
                            position: absolute;
                            content: '';
                            width: 12px;
                            height: 12px;
                            border-radius: 2px;
                            top: 0;
                            left: 0;
                        }
                    }

                    .high-yield,
                    .middle-market,
                    .deals-with-esg-language,
                    .us-bsl-new-issue,
                    .eu-compliant,
                    .us-bsl,
                    .short-dates,
                    .long-dates,
                    .percent-of-eu-compliant-deals,
                    .common-median,
                    .trend-mean {
                        position: relative;
                        padding-left: 20px;
                        margin-right: 16px;

                        &:before {
                            position: absolute;
                            content: '';
                            width: 12px;
                            height: 12px;
                            border-radius: 2px;
                            top: 0;
                            left: 0;
                        }
                    }

                    .reset {
                        &:before {
                            background-color: $primary-13;
                        }
                    }

                    .refinancing {
                        &:before {
                            background-color: $blue-11;
                        }
                    }

                    .new-issue {
                        &:before {
                            background-color: $blue-4;
                        }
                    }

                    .high-yield {
                        &:before {
                            background-color: $green-6;
                        }
                    }

                    .middle-market {
                        &:before {
                            background-color: $purple-1;
                        }
                    }

                    .deals-with-esg-language {
                        &:before {
                            background-color: $primary-12;
                        }
                    }

                    .us-bsl-new-issue {
                        &:before {
                            background-color: $blue-4;
                        }
                    }

                    .eu-compliant {
                        &:before {
                            background-color: $green-9;
                        }
                    }

                    .us-bsl {
                        &:before {
                            background-color: $blue-10;
                        }
                    }

                    .short-dates {
                        &:before {
                            background-color: $green-9;
                        }
                    }

                    .long-dates {
                        &:before {
                            background-color: $blue-11;
                        }
                    }

                    .percent-of-issuance-with-esg,
                    .percent-of-eu-compliant-deals,
                    .common-median,
                    .trend-mean {
                        position: relative;
                        padding-left: 24px;

                        &:before {
                            position: absolute;
                            content: '';
                            width: 16px;
                            height: 2px;
                            border-radius: 2px;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    .percent-of-issuance-with-esg {
                        &:before {
                            background-color: $green-7;
                        }
                    }

                    .percent-of-eu-compliant-deals {
                        &:before {
                            background-color: $purple-2;
                        }
                    }

                    .common-median {
                        &:before {
                            background-color: $secondary-1;
                        }
                    }

                    .trend-mean {
                        &:before {
                            background-color: $green-2;
                        }
                    }

                    .outlier {
                        position: relative;
                        padding-left: 14px;

                        &:before {
                            position: absolute;
                            content: '';
                            width: 6px;
                            height: 6px;
                            border-radius: 50%;
                            left: 0;
                            top: 50%;
                            transform: translateY(-50%);
                            background-color: $blue-4;
                        }
                    }
                }

                .js-plotly-plot {
                    height: 350px;
                }
            }

            &.arrangers-chart {
                min-height: 318px;

                .agenda {
                    padding: 8px 0 0;
                }

                .js-plotly-plot {
                    width: 100%;
                    height: 216px;
                }
            }

            &.trustee-chart {
                min-height: 318px;

                .js-plotly-plot {
                    width: 100%;
                    height: 238px;
                }
            }

            &.issuance-spread-chart {
                .graph-box {
                    .draglayer.cursor-crosshair {
                        cursor: pointer !important;

                        * {
                            cursor: pointer !important;
                        }
                    }
                }

                .description {
                    padding-top: 0;
                }

                @media (max-width: 1366px) {
                    .filter-section {
                        margin-right: 8px;
                    }
                }
            }

            &.top-issuers,
            &.top-arrangers,
            &.debut-deals,
            &.static-deals {
                min-height: 456px;

                .heading {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    margin-bottom: 8px;
                    line-height: 24px;

                    & > h3 {
                        margin-bottom: 0;
                    }

                    .loader-box .text-loader {
                        line-height: 24px;
                    }
                }

                .graph-box {
                    display: flex;
                    flex-direction: column;

                    & > .icon-svg {
                        position: absolute;
                        top: calc(50% + 20px);
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }

                .data-list-content {
                    .btn-link {
                        display: inline;
                        text-align: left;
                    }
                }

                .data-list {
                    margin-bottom: 0;
                }

                .control-box {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    width: 100%;
                    margin-top: 4px;
                }

                .control-box-right {
                    display: flex;
                    align-items: center;
                }

                .control-item {
                    margin-right: 8px;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                .pagination {
                    position: static;
                    margin: 0 -12px;
                    width: auto;
                    border-top: none;
                    margin-top: auto;
                }

                .pagination-cntrls {
                    padding-right: 0;
                }

                .description {
                    padding: 8px 0 12px;
                }

                .data-list-cell:last-child {
                    padding-right: 0;
                }
            }

            &.debut-deals,
            &.static-deals {
                .year-dropdown {
                    width: 146px;
                }

                .year-item {
                    width: 50%;
                }
            }

            &.static-deals {
                .data-list-cell:last-child {
                    @include flex-basis(45px);
                }
            }

            &.debut-deals {
                .data-list-cell:last-child {
                    @include flex-basis(90px);
                }
            }

            &.new-issue-chart-box {
                .js-plotly-plot {
                    height: 319px;
                }
            }

            &.bars {
                .js-plotly-plot {
                    height: 281px;
                }

                .agenda {
                    margin: 0 0 16px;

                    .item {
                        margin-right: 12px;
                        vertical-align: top;

                        &:last-child {
                            margin-right: 0;
                        }

                        span {
                            display: inline-block;
                            width: 12px;
                            height: 12px;
                            margin-right: 6px;
                            border-radius: 2px;
                            vertical-align: top;
                        }
                    }
                }
            }

            &.heatmap {
                .js-plotly-plot {
                    height: 255px;
                }
            }
            /* start BWICs Volume & Price graphs section */
            &.price-level {
                .js-plotly-plot {
                    height: 275px;
                }
            }

            &.daily-stats {
                .data-list {
                    margin-bottom: 0;
                }

                .data-list-header {
                    align-items: flex-start;

                    .data-list-cell {
                        padding-top: 4px;
                        padding-bottom: 3px;
                    }
                }

                .data-list-row {
                    &:last-child {
                        font-family: $font-roboto-bold;
                        text-transform: uppercase;
                    }
                }

                .data-list-content {
                    margin-bottom: 5px;

                    &:after {
                        display: none;
                    }
                }

                .data-list-cell {
                    white-space: normal;
                    padding: 0;
                    flex: 1 1 auto;
                    line-height: 16px;

                    .display-inline {
                        vertical-align: baseline;
                    }

                    .sup {
                        font-size: 8px;
                        line-height: 12px;
                        position: relative;
                        top: -5px;
                    }

                    &.data-rating {
                        min-width: 30px;
                        flex-basis: 30px;
                    }

                    &.data-total {
                        min-width: 50px;
                        flex-basis: 50px;
                    }

                    &.data-count {
                        min-width: 45px;
                        flex-basis: 45px;
                    }

                    &.data-dnt {
                        min-width: 37px;
                        flex-basis: 37px;
                    }

                    &.data-color {
                        min-width: 45px;
                        flex-basis: 45px;
                    }

                    &.data-volume {
                        min-width: 45px;
                        flex-basis: 45px;
                    }

                    &.data-avg-color {
                        min-width: 40px;
                        flex-basis: 40px;
                    }

                    &.data-px-talk {
                        min-width: 45px;
                        flex-basis: 45px;
                    }
                }

                .footnote {
                    padding: 0;

                    sup {
                        font-size: 8px;
                        margin-left: 12px;

                        &:first-child {
                            margin: 0;
                        }
                    }

                    p {
                        margin-bottom: 5px;
                    }
                }

                .description {
                    .time {
                        margin-left: 16px;
                    }

                    p, .info {
                        line-height: 16px;
                        margin-top: 5px;

                        .icon-svg-info {
                            position: relative;
                            margin: -2px 0 0 8px;
                            color: $grey-4;
                            cursor: pointer;
                        }
                    }
                }

                .accordion {
                    margin-top: 8px;
                    padding-bottom: 12px;

                    .accordion-row {
                        display: inline-flex;
                        border: none;

                        &:hover .collapsable-item-header {
                            background-color: transparent;
                        }
    
                        .collapsable-item-header {
                            padding: 0;
                            height: auto;
                            min-height: auto;
                            font-family: $font-roboto-medium;
    
                            &:hover {
                                color: $button-hover;
                            }
    
                            &:focus {
                                color: $button-focus;
                            }
    
                            .icon-svg {
                                transform: rotate(90deg);
                            }
                        }
    
                        &.item-open {
                            .icon-svg {
                                transform: rotate(-90deg);
                                margin-top: -1px;
                            }
                        }
                    }

                    .accordion-cnt {
                        border: none;
                    }
                }
            }

            &.biggest-movers {
                .emptyBwics {
                    height: 400px;
                }

                .data-list {
                    margin-bottom: 17px;
                    height: 260px;

                    &-cell {
                        flex: 1 1 auto;
                        min-width: 45px;
                        flex-basis: 45px;
                    }

                    &-ticker {
                        min-width: 105px;
                        flex-basis: 105px;
                    }

                    &-price {
                        min-width: 70px;
                        flex-basis: 70px;
                        width: auto;
                    }

                    &-abs-diff {
                        min-width: 45px;
                        flex-basis: 45px;
                    }

                    &-diff {
                        min-width: 35px;
                        flex-basis: 35px;
                    }

                    &-days-diff {
                        min-width: 40px;
                        flex-basis: 40px;
                    }
                }

                .description {
                    padding-top: 7px;
                }

                .pagination {
                    bottom: 141px;
                    padding: 2px 12px;
                }
            }

            &.most-traded-tickers {
                .data-list {
                    margin-bottom: 40px;
                    height: 217px;

                    &-cell {
                        flex: 1 1 auto;
                    }

                    &-ticker {
                        @include flex-basis(120px);
                    }

                    &-rating {
                        @include flex-basis(70px);
                    }

                    &-trades {
                        min-width: 70px;
                        flex-basis: 70px;
                    }

                    &-trades-vol {
                        min-width: 105px;
                        flex-basis: 105px;
                    }

                    &-avg-size {
                        min-width: 95px;
                        flex-basis: 95px;
                    }

                    &-avg-px {
                        min-width: 79px;
                        flex-basis: 79px;
                    }

                    &-avg-color {
                        min-width: 55px;
                        flex-basis: 55px;
                    }
                }

                .pagination {
                    bottom: 63px;
                }
            }

            &.hy-ig {
                .js-plotly-plot {
                    height: 210px;
                }

                .agenda {
                    margin: 0 0 12px;

                    .ig,
                    .hy {
                        position: relative;
                        padding: 0 20px 0 24px;

                        &:before {
                            position: absolute;
                            left: 0;
                            top: 1px;
                            width: 16px;
                            height: 12px;
                            content: '';
                            background: $purple-1;
                        }
                    }

                    .hy {
                        &:before {
                            background: $blue-12;
                        }
                    }
                }
            }

            &.dtc-selling-buying {
                .js-plotly-plot {
                    height: 210px;
                }

                .agenda {
                    margin: 0 0 12px;

                    .dealer-net-flow {
                        position: relative;
                        padding: 0 20px 0 44px;

                        &:after,
                        &:before {
                            position: absolute;
                            left: 0;
                            top: 1px;
                            width: 16px;
                            height: 12px;
                            content: '';
                            background: $red-1;
                        }

                        &:before {
                            left: 20px;
                            background: $blue-10;
                        }
                    }

                    .cumulative-position {
                        padding: 0 0 0 30px;
                        position: relative;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 6px;
                            left: 0;
                            height: 0;
                            width: 22px;
                            border-bottom: 2px dashed $grey-5;
                        }
                    }
                }
            }

            &.percent-of-trace {
                .js-plotly-plot {
                    height: 210px;
                }

                .agenda {
                    margin: 0 0 12px;

                    .ig,
                    .hy {
                        position: relative;
                        width: 28px;
                        padding: 0 16px 0 36px;
                        margin-right: 16px;

                        &:before,
                        &:after {
                            position: absolute;
                            content: '';
                            top: 50%;
                        }

                        &:before {
                            left: 0;
                            width: 28px;
                            height: 4px;
                            background: $blue-12;
                            margin-top: -2px;
                        }

                        &:after {
                            margin-top: -4px;
                            width: 8px;
                            height: 8px;
                            left: 10px;
                            transform: rotate(-45deg);
                            background: $blue-3;
                        }
                    }

                    .ig {
                        &:before {
                            background: $purple-1;
                        }

                        &:after {
                            background: $secondary-1;
                        }
                    }
                }
            }
            /* end BWICs Volume & Price graphs section */
            /* start Market Health Indicators section */
            &.k-indicator {
                .js-plotly-plot {
                    height: 235px;
                }

                .description {
                    padding-top: 5px;
                }
            }

            &.aaa-spread {
                .js-plotly-plot {
                    height: 235px;
                }

                .agenda {
                    margin: 0 0 8px;

                    .avg-color,
                    .avg-price,
                    .range {
                        padding: 0 16px 0 24px;
                        position: relative;

                        &:before,
                        &:after,
                        span {
                            position: absolute;
                            left: 0;
                            top: 6px;
                            width: 16px;
                            height: 2px;
                            content: '';
                            border-radius: 2px;
                            background: $green-2;
                        }
                    }

                    .avg-price {
                        &:before {
                            background: $secondary-1;
                        }

                        &:after {
                            display: none;
                        }
                    }

                    .range {
                        padding-left: 8px;

                        &:before,
                        &:after,
                        span {
                            top: 2px;
                            width: 2px;
                            height: 2px;
                            background: $blue-4;
                            border-radius: 0;
                        }

                        &:after {
                            top: 6px;
                        }

                        span {
                            top: 10px;
                        }
                    }
                }
            }

            &.dnt-rate {
                .js-plotly-plot {
                    height: 235px;
                }

                .footnote {
                    padding-top: 0;
                }
            }

            &.color-rates {
                .js-plotly-plot {
                    height: 235px;
                }
            }

            &.same-day {
                .js-plotly-plot {
                    height: 235px;
                }
            }

            &.px-talk-deviation {
                .js-plotly-plot {
                    height: 210px;
                }

                .agenda {
                    margin: 0 0 12px;

                    .item {
                        span {
                            margin: 0 8px 0 20px;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            display: inline-block;
                        }

                        &:first-child span {
                            margin-left: 0;
                        }
                    }
                }
            }
            /* end Market Health Indicators section */
            /* start My Portfolio section */
            &.portfolio-alerts {
                .graph-box {
                    .container-loader {
                        top: 73px;
                        bottom: 32px;

                        &:last-child {
                            bottom: 0;
                        }
                    }
                }

                .data-list-content {
                    min-height: 245px;
                }

                .data-list {
                    margin-bottom: 0;
                }

                .data-list-date {
                    display: flex;
                    align-items: center;
                    width: 78px;
                    padding-left: 18px;

                    .same-day-wrapper {
                        margin-left: -18px;
                        margin-right: 2px;
                        line-height: 1;
                    }
                }

                .data-list-bwic-status {
                    width: 140px;
                }

                .data-list-ccy {
                    width: 40px;
                }

                .data-list-status {
                    width: 32px;
                }

                .data-list-updated {
                    width: 95px;
                }

                .data-list-ticker {
                    width: 110px;
                }

                .data-list-ticker-bwics {
                    flex: 1 1 90px;
                    min-width: 90px;
                    max-width: none;
                    overflow: hidden;
                }

                .data-list-size {
                    width: 75px;
                }

                .restricted-placeholder {
                    top: 72px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }

            &.upcoming-bwics {
                .skeleton {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 12px;
                }

                .graph-box {
                    .empty-placeholder {
                        min-height: 260px;
                        height: 260px;
                    }
                }

                .data-list-content {
                    min-height: 245px;
                }

                .data-list {
                    margin-bottom: 0;
                }

                .data-list-rating {
                    width: 50px;
                }

                .data-list-size {
                    width: 60px;
                }

                .data-list-isin {
                    width: 120px;
                }

                .data-list-ticker {
                    width: 134px;
                }

                .data-list-same-day {
                    min-width: 24px;
                    padding-right: 3px;
                }

                .data-list-parsed {
                    width: 60px;
                    padding-right: 0;

                    .state-parsed {
                        color: $grey-1;
                        font-size: 11px;
                        font-family: $font-roboto-medium;
                        margin-right: 0;
                    }
                }

                .restricted-placeholder {
                    top: 72px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }

            &.color-dispersion {
                .js-plotly-plot {
                    height: 175px;
                }

                .chart-legend {
                    margin-bottom: 5px;

                    .chart-item-px {
                        margin-left: 0;
                    }
                }

                .empty-placeholder {
                    margin-top: -8px;
                }
            }
            /* end My Portfolio section */
            .editable {
                display: none;
            }

            .data-list {
                margin: 0 -12px 39px;
                width: auto;
                z-index: 1;
                overflow-x: auto;

                &-date {
                    width: 90px;
                }

                .hidden {
                    display: none;
                }

                .p-r-0 {
                    padding-right: 0;
                }

                @media only screen and (max-width: 1500px) {
                    &-isin {
                        width: 110px;
                    }

                    &-ticker {
                        width: 110px;
                    }
                }
            }

            .data-list-container {
                min-width: 0;
            }

            .data-list-content {
                overflow-y: hidden;

                &:after {
                    display: none;
                }
            }

            .data-list-header {
                padding-left: 12px;
                padding-right: 12px;
            }

            .data-list-row {
                padding-left: 12px;
                padding-right: 12px;
            }

            .pagination {
                border-radius: 0 0 6px 6px;
                background: $white-1;
                border-top: 1px solid $grey-6;
                position: absolute;
                bottom: 0;
                width: 100%;
                left: 0;
                z-index: 3;
                padding: 4px 12px;
            }

            .emptyBwics {
                display: flex;
                flex-direction: column;
                justify-content: center;
                flex-grow: 1;
            }

            .securities-search {
                width: 100%;
                max-width: 550px;
                margin: 0 0 8px;

                .search-lookup {
                    max-height: 270px;
                }
            }

            .data-list {
                &-row {
                    &:nth-child(n+4) {
                        .popover {
                            top: auto;
                            bottom: 100%;
                        }
                    }
                }
            }

            .graph-details {
                display: none;
            }

            .restricted-placeholder {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                border-radius: $app-brd-radius;
            }
        }

        .graph-box {
            width: 100%;
            flex-grow: 1;
            display: flex;
            flex-direction: column;

            .empty-placeholder {
                border: 0;
                background: none;
                display: flex;
                height: 100%;
                align-items: center;
                padding: 0;
                margin: 0;
            }

            .js-plotly-plot {
                width: 100%;
            }
        }

        .agenda {
            font: 12px/14px $font-roboto-regular;
        }

        .description {
            color: $grey-9;
            font: 12px/18px $font-roboto-regular;
            padding-bottom: 11px;

            p {
                margin-top: 5px;
                margin-bottom: 0;
            }

            sup {
                font-size: 8px;
                margin-left: 12px;

                &:first-child {
                    margin: 0;
                }
            }

            .btn {
                height: 18px;
            }

            .icon-svg {
                &.is-open {
                    transform: rotate(180deg);
                }
            }
        }
    }
}

.dnt-points {
    font: 12px/14px $font-roboto-medium;
    margin: 0 0 8px;

    span {
        margin-right: 14px;

        em {
            text-indent: -9999px;
            width: 10px;
            height: 10px;
            display: inline-block;
            margin: 2px 8px -1px 0;
            border-radius: 50%;
            overflow: hidden;
        }
    }
}


.graph-details {
    font: 14px/24px $font-roboto-regular;
    min-height: 48px;
    margin: 0 0 0 -12px;
    white-space: normal;
    color: $grey-4;

    &-item {
        white-space: nowrap;
        display: inline-block;
        margin-left: 12px;
    }
}

.blured-foreground {
    backdrop-filter: blur(3px);
    background-color: rgba($grey-4, 0.5);
    position: absolute;
    z-index: 5;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 3px;
}
