.chip {
    padding: 2px 4px;
    flex: none;
    font: 12px/20px $font-roboto-medium;

    &:first-child {
        padding-left: 0;
    }

    .chip-content {
        display: flex;
        flex-direction: row;
        background: $primary-8;
        padding: 0 8px;
        border-radius: $app-brd-radius;
        cursor: pointer;

        &:hover {
            background: $primary-3;
            color: $white-1;
        }

        &:active,
        &.selected {
            color: $white-1;
            background: $primary-1;

            .chip-button:hover {
                opacity: .3;
            }
        }

        .chip-text {
            padding: 0 10px 0 0;
        }

        .chip-button {
            color: inherit;
            border: 0;
            background: none;
            cursor: pointer;
            padding: 0;
            display: inline-flex;
            align-items: center;

            &:hover {
                opacity: .5;
            }
        }
    }
}

.chip-list {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    max-width: 398px;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
