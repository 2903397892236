﻿.sub-header-pipeline {
    flex: 0 0 auto;

    &.sub-header-head {
        .sub-header-row {
            min-height: 71px;
        }
    }

    &.sub-header-arranger-pipeline {
        .filter-item:nth-last-child(2) {
            margin-right: 225px;
        }

        .row-filters-btns {
            margin-left: -230px;
        }

        .control-filter-equity {
            .control-filter-content-select {
                width: 169px;
            }
        }

        &.sub-header-summary {
            .summary-element {
                margin-right: 8px;

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .control-group-slide {
        width: 181px;
        margin-right: 10px;
    }

    .filter-actions-box {
        display: flex;
        align-items: center;
    }

    .control-search-wrapper.control-search-groups .search-lookup-transactions {
        width: 660px;

        table {
            line-height: 1.4;
            table-layout: auto;

            .classes-row {
                vertical-align: top;
            }

            .classes-name {
                white-space: nowrap;

                & + .classes-info {
                    margin-top: 6px;
                }
            }

            .classes-info {
                font-size: 10px;
                color: $grey-8;
                white-space: nowrap;
            }

            th,
            td {
                padding: 6px 8px;
                width: auto;

                &:first-child {
                    padding-left: 12px;
                    min-width: 80px;
                    max-width: 80px;
                }

                &:nth-child(2) {
                    .classes-wrap {
                        width: 180px;
                    }

                    .result-row-wrap {
                        width: 210px;
                        white-space: nowrap;
                    }
                }

                &:nth-child(3) {
                    .classes-wrap {
                        width: 155px;
                    }

                    .result-row-name {
                        width: 306px;
                        white-space: nowrap;
                    }
                }

                &:nth-child(4) {
                    .classes-wrap {
                        width: 155px;
                    }
                }
            }

            .show-more-results {
                text-align: center;
                cursor: default;
                position: relative;

                .btn-link {
                    font-size: 14px;
                }

                span {
                    text-transform: none;
                }
            }
        }
    }

    .control-search-wrapper-arranger-pipeline {
        .search-lookup table td.col-main {
            text-transform: none;
        }
    }

    .row-align-filters {
        padding-bottom: 0;

        .vertical-divider {
            margin-right: 7px;
            margin-bottom: 7px;
            align-self: center;
            flex: none;

            &:not(:first-child) {
                margin-left: 7px;
            }

            &:after {
                content: none;
            }
        }

        .save-manage-filters {
            padding: 0;

            .saved-filters-wrap {
                display: flex;
                align-items: center;
            }

            .saved-filters-label {
                margin-right: 8px;
                font-size: 12px;
                line-height: 24px;
            }

            .custom-drop-down-filter {
                width: 160px;
                margin-right: 1px;

                .drop-down-btn {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    padding-right: 20px;

                    span {
                        display: inline;
                    }
                }

                .drop-down-list {
                    width: 374px;
                    max-height: calc(100vh - 220px);
                    overflow: auto;
                    border: 1px solid $border-color;
                    top: calc(100% + 5px);
                    border-radius: 0;
                    left: auto;
                    right: 0;

                    .flex-item-right {
                        margin-left: auto;
                        padding-left: 20px;
                    }

                    .btn-link {

                        .icon {
                            margin-right: 0;
                        }
                    }
                }

                .flex-row {
                    padding: 0;
                    white-space: nowrap;
                }

                .filter-name {
                    width: auto;
                    color: $text-color;

                    .toggle {
                        vertical-align: bottom;
                    }
                }

                .default-filter-label {
                    color: $grey-8;
                }
            }

            .vertical-divider {
                margin-bottom: 0;
            }

            .delete-btn {
                line-height: 0;
            }

            .modal {
                .drop-down-btn {
                    padding-right: 16px;

                    span {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }

                .drop-down-list {
                    width: 100%;
                    max-height: 225px;
                    overflow: auto;
                }

                .drop-down-list-item {
                    .item-wrapper {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
            }
        }
    }

    .sub-header-summary {
        display: flex;
        background-color: $green-10;
        position: relative;
        min-height: 32px;
        line-height: 14px;

        &-loading {
            justify-content: center;
        }

        .summary-box {
            display: flex;
            align-items: center;
        }

        .summary-group-name {
            margin-right: 8px;
        }

        .summary-element {
            margin-right: 12px;
            display: flex;
            align-items: center;

            &:last-of-type {
                margin-right: 0;
            }
        }

        .summary-label {
            color: $grey-9;
            margin-right: 4px;

            &.disabled {
                color: $grey-1;
            }
        }

        .summary-value {
            font-family: $font-roboto-medium;

            &.disabled {
                color: $grey-1;
            }
        }

        .vertical-divider {
            margin: 0 12px;
            align-self: center;
            flex: none;

            &:after {
                content: none;
            }
        }

        .restricted-placeholder {
            display: flex;
            justify-content: center;
            width: 100%;
            margin-top: -5px;
            margin-bottom: -5px;
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    .row-filters {
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        margin-right: 8px;

        &.collapsed {
            max-width: calc(100% - 340px);
            padding-right: 290px;
        }

        .btn-link {
            & + .vertical-divider {
                margin: 0 8px 8px;
            }
        }
    }

    .row-filters-btns {
        flex: none;
        display: flex;
        flex-direction: row;
        margin-left: -340px;

        &.collapsed {
            margin-left: 0;
            margin-bottom: 8px;
            position: absolute;
            top: 0;
            left: calc(100% - 290px);

            .vertical-divider {
                display: none;
            }

            .filter-item-control .btn-link + .vertical-divider {
                display: block;
            }
        }

        .hidden-filters-box {
            margin-right: 8px;
        }
    }

    .control-filter-select-btn .selected-data {
        max-width: 65px;
    }

    .btn-clear {
        height: 24px;
        margin-left: 12px;
    }

    .control-search-groups.control-search-wrapper {
        width: 480px;

        .chip-list {
            max-width: 315px;
        }
    }

    .filter-item {
        margin-bottom: 8px;

        &-control {
            display: flex;

            .btn-clear {
                margin-left: 8px;
            }

            .btn-link {
                white-space: nowrap;

                &.secondary {
                    line-height: 24px;
                    
                    &:hover {
                        text-decoration: none;
                    }
                }
            }

            .btn-link + .vertical-divider {
                margin-bottom: 0;
            }
        }

        &:nth-last-child(2) {
            margin-right: 340px;
        }
    }

    .filter-select-and-search {
        .control-search-wrapper.flex-none {
            margin-right: 0;
        }
    }
}
