﻿.rules {
    .column {
        width: 100%;
        margin-top: 31px;

        &:first-child {
            margin-top: 0;
        }

        .rules-section {
            & + .rules-section {
                margin-top: 31px;
            }

            .info-tooltip {
                margin-left: 8px;
                line-height: 16px;

                .icon-svg {
                    vertical-align: top;
                }
            }

            .icon-svg-same-day {
                margin-top: -1px;
                margin-right: 8px;
            }

            .rules-label {
                flex-grow: 1;
            }

            .rules-value {
                flex: none;
                flex-basis: 212px;
                font-family: $font-roboto-bold;
            }

            &-title {
                display: flex;
                align-items: center;
                margin-bottom: 20px;

                h4 {
                    margin-bottom: 0;
                    border-bottom: 0;
                    line-height: 20px;
                }

                .info-tooltip {
                    margin-left: 8px;
                    line-height: 1;
                }
            }

            &-content {
                .flex-row {
                    margin-bottom: 18px;
                    align-items: flex-start;
                    width: 100%;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .rules-stage {
                    &:not(:first-child) {
                        margin-top: 24px;
                    }

                }

                h5 {
                    margin-bottom: 16px;
                }
            }

            &-empty {
                color: $grey-8;
            }
        }
    }

    .restricted-placeholder {
        .required-feature {
            width: 312px;
        }
    }
}
