﻿.user-avatar {
    font-size: 34px;
    width: 1em;
    height: 1em;
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex: none;
    border-radius: 50%;
    text-align: center;
    text-transform: uppercase;
    vertical-align: middle;
    background: $grey-5;

    img {
        display: block;
        width: 100%;
    }

    span {
        font: 0.3em/1 $font-roboto-bold;
        cursor: default;
    }

    &-section {
        position: relative;

        .btn {
            position: absolute;
            right: 0;
            bottom: 0;
            padding: 10px 9px;
            border: 0;
            width: 32px;
            height: 32px;
            border-radius: 50%;

            .icon {
                vertical-align: top;
            }
        }
    }
}

.photo-uploader {
    display: flex;
    flex-direction: column;
    min-height: 432px;

    &-content {
        display: flex;
        flex-grow: 1;
        height: 368px;

        &.photo-uploading {
            opacity: 0.6;
        }

        .btn-main {
            min-width: 261px;
        }
    }

    &-footer {
        display: flex;
        flex-direction: column;
        min-height: 64px;
        padding: 16px 24px;

        .btn-ghost {
            &:first-child {
                margin-left: -12px;
                border-color: transparent;

                &:hover {
                    background: none;
                    text-decoration: underline;
                }
            }
        }

        .btn-main {
            margin-left: 16px;
        }

        .zoom-slider {
            font-size: 12px;
            width: 100%;
            max-width: 320px;

            .rc-slider {
                margin-left: 16px;
                height: 12px;
            }

            .rc-slider-rail {
                height: 2px;
                background-color: $grey-9;
            }

            .rc-slider-track {
                height: 2px;
                border-radius: 0;
                background-color: $primary-3;
            }

            .rc-slider-step {
                height: 2px;
            }

            .rc-slider-handle {
                width: 16px;
                height: 16px;
                margin-top: -7px;
                border: 0;
                background-color: $primary-3;
            }
        }

        .status-message {
            align-self: center;
            max-width: 350px;
            margin-bottom: 12px;
        }

        .loader-box {
            flex-direction: row;
            background: none;
            margin: -4px 0;

            .text-loader {
                font-size: 16px;
                font-family: $font-roboto-medium;
            }

            .preloader {
                width: 40px;
                height: 40px;
                margin-right: 11px;
            }
        }
    }

    &-empty {
        display: flex;
        width: 100%;
        height: 100%;

        .drag-zone {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1 1 auto;
            width: 100%;
            padding: 21px;
            border: 3px solid transparent;
            text-align: center;
            transition: border 200ms linear, background 200ms linear;

            .drag-enter & {
                border-color: $primary-3;
                background-color: $primary-6;
            }

            p {
                color: $grey-1;
                font-size: 20px;
                margin-bottom: 22px;
            }

            .icon-svg {
                margin-bottom: 24px;
                vertical-align: top;
            }

            .or-separator {
                position: relative;
                z-index: 1;
                min-width: 261px;
                margin-bottom: 24px;

                &:before,
                &:after {
                    content: ' ';
                    position: absolute;
                    top: 50%;
                    width: 120px;
                    z-index: -1;
                    height: 1px;
                    background-color: $grey-5;
                }

                &:before {
                    left: 0;
                }

                &:after {
                    right: 0;
                }

                span {
                    padding-right: 5px;
                    padding-left: 5px;
                    background-color: $white-1;

                    .drag-enter & {
                        background-color: $grey-7;
                    }
                }
            }
        }
    }

    .status-message {
        position: absolute;
        top: 64px;
        left: 24px;
        max-width: 652px;
    }

    .cropper-view-box {
        outline: none;
    }

    &-image {
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 100%;
        background-color: $grey-4;

        img {
            display: block;
            max-width: 100%;
            max-height: 345px;
            flex: none;
        }

        .note-text {
            color: $white-1;
            font-size: 12px;
            font-family: $font-roboto-medium;
            background: rgba($grey-4, .6);
            width: 138px;
            text-align: center;
            position: absolute;
            bottom: 44px;
            left: 50%;
            transform: translateX(-50%);
            border-radius: 1px;
        }

        .cropper-crop-box {
            span {
                border-radius: 50%;
                border: 2px solid $white-1;
            }
        }

        .image-cropper {
            max-width: 230px;
        }

        .current-image {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }

        .cropper-modal {
            background: $grey-4;
            opacity: .6;
        }
    }
}
