﻿.manage-dealers {
    margin-bottom: 32px;
    
    .radios-section {
        padding-bottom: 16px;

        .radio-button {
            margin-top: 0;
            margin-bottom: 8px;
        }
    }

    &-list {
        min-height: 1px;
        height: 100%;
    }

    &-column {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        margin-right: 32px;

        &:last-child {
            margin-right: 0;
        }
    }

    &-column-title {
        display: flex;
        flex: none;
        margin-bottom: 16px;
        padding-bottom: 8px;
        border-bottom: 1px solid $grid-borders;

        h2 {
            margin-bottom: 0;
        }
    }
    
    &-column-subtitle {
        margin-bottom: 0;
        padding: 4px 16px;
        background-color: $grey-3;

        &.text-warm-grey {
            padding-top: 8px;
            padding-bottom: 8px;
            background-color: $grey-7;
        }
    }

    &-column-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;

        li {
            display: flex;
            align-items: center;
            padding: 8px 0;
            flex: none;

            .manage-dealers-edit-mode & {
                padding: 4px 16px;
                border-bottom: 1px solid $grid-borders;

                &:hover {
                    background-color: $row-hover;
                }
            }
        }
    }
}
