.restricted-placeholder {
    font-size: 14px;

    .btn-link {
        font-size: inherit;
        line-height: inherit;
        vertical-align: top;
    }

    p {
        margin-bottom: 0;
        line-height: 20px;
    }

    &-inline {
        .required-feature {
            display: flex;
            align-items: center;

            .icon-svg {
                margin-right: 8px;
                min-width: 24px;
            }
        }

        &:not(.empty-placeholder) {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &.empty-placeholder {
            .required-feature {
                justify-content: center;

                .icon-svg {
                    margin: 0 8px 0 0;
                }
            }
        }
    }

    &-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;

        .required-feature {
            width: 262px;
            padding: 16px;
            border-radius: $app-brd-radius;

            .icon-svg {
                margin-top: 16px;
                margin-bottom: 24px;
                transform: scale(2);
            }
        }

        &:not(.restricted-placeholder-bg) {
            border: 1px solid $grey-5;
            background-color: $grey-3;
        }
    }

    &-bg {
        background-position: 0 0;
        background-repeat: no-repeat;
        background-size: cover;

        .required-feature {
            background-color: $white-1;
        }
    }

    &-bwiclog {
        height: 324px;
        background-image: url("../../images/restricted/bwiclog.svg");
    }

    &-px-talk {
        height: 174px;
        background-image: url("../../images/restricted/px-talk.svg");
    }

    &-price-level {
        background-image: url("../../images/restricted/widget-price-level.svg");
    }

    &-daily-trades-analytics {
        background-image: url("../../images/restricted/widget-daily-trades-analytics.svg");
    }

    &-biggest-movers {
        background-image: url("../../images/restricted/widget-biggest-movers.svg");
    }

    &-most-traded-bonds {
        background-image: url("../../images/restricted/widget-most-traded-bonds.svg");
    }

    &-trace-volume {
        background-image: url("../../images/restricted/widget-trace-volume.svg");
    }

    &-trace-bd-net-flow {
        background-image: url("../../images/restricted/widget-trace-bd-net-flow.svg");
    }

    &-bwic-percent-of-trace {
        background-image: url("../../images/restricted/widget-bwic-percent-of-trace.svg");
    }

    &-aaa-px-talk-color-level {
        background-image: url("../../images/restricted/widget-aaa-px-talk-color-level.svg");
    }

    &-dnt-percent-demand {
        background-image: url("../../images/restricted/widget-dnt-percent-demand.svg");
    }

    &-color-percent-in-bwics {
        background-image: url("../../images/restricted/widget-color-percent-in-bwics.svg");
    }

    &-same-day-bwics {
        background-image: url("../../images/restricted/widget-same-day-bwics.svg");
    }

    &-px-talk-deviation-from-color {
        background-image: url("../../images/restricted/widget-px-talk-deviation-from-color.svg");
    }

    &-issuance-spread {
        background-image: url("../../images/restricted/widget-issuance-spread.svg");
    }

    &-top-arrangers {
        background-image: url("../../images/restricted/widget-top-arrangers.svg");
    }

    &-esg-bsl-new-issue {
        background-image: url("../../images/restricted/widget-esg-bsl-new-issue.svg");
    }

    &-top-trustees {
        background-image: url("../../images/restricted/widget-top-trustees.svg");
    }

    &-eu-compliant-bsl {
        background-image: url("../../images/restricted/widget-eu-compliant-bsl.svg");
    }

    &-static-deals {
        background-image: url("../../images/restricted/widget-static-deals.svg");
    }

    &-short-long-dated-deals {
        background-image: url("../../images/restricted/widget-short-long-dated-deals.svg");
    }

    &-debut-manager {
        background-image: url("../../images/restricted/widget-debut-manager.svg");
    }

    &-us-bsl-new-issue-was {
        background-image: url("../../images/restricted/widget-us-bsl-new-issue-was.svg");
    }

    &-us-bsl-new-issue-warf {
        background-image: url("../../images/restricted/widget-us-bsl-new-issue-warf.svg");
    }

    &-us-bsl-new-issue-ds {
        background-image: url("../../images/restricted/widget-us-bsl-new-issue-ds.svg");
    }

    &-security-lookup-price-history {
        background-image: url("../../images/restricted/widget-security-lookup-price-history.svg");
    }

    &-clients-activity {
        background-image: url("../../images/restricted/widget-clients-activity.svg");
        height: 318px;
        margin-bottom: 32px;
    }

    &-downloaded-presentations {
        background-image: url("../../images/restricted/widget-downloaded-presentations.png");
        height: 318px;
    }

    &-was {
        background-image: url("../../images/restricted/widget-was.svg");
        height: 280px;
    }

    &-wal {
        background-image: url("../../images/restricted/widget-wal.svg");
        height: 280px;
    }

    &-warf {
        background-image: url("../../images/restricted/widget-warf.svg");
        height: 280px;
    }

    &-ds {
        background-image: url("../../images/restricted/widget-ds.svg");
        height: 280px;
    }
}