﻿.manage-emails {
    > .empty-placeholder {
        width: auto;
    }

    .sub-header {
        .control-filter-email-type {
            .control-filter-content-select {
                width: 350px;
            }
        }

        .multiselect {
            width: 350px;

            &.email-status {
                width: 150px;
            }

            &-list {
                overflow: auto;
                max-height: 300px;
            }
        }
    }

    .data-list {
        .component-collapsible-list-item-content {
            .data-list-row {
                padding-left: 48px;

                .data-list-cell:first-child {
                    padding-left: 8px;
                }
            }
        }
    }
}
