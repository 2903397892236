﻿.bidding-cnt {
    .flex-row-full-size {
        .container-flex {
            min-width: 0;

            &-bid-all {
                width: 100%;
            }
        }
    }

    .data-list {
        min-width: 855px;

        .data-column-sticky-left {
            &.header-sticky-col,
            .data-list-row {
                padding-right: 0;
            }
        }
    }

    .bid-all-btn-wrapper {
        flex: none;
        text-align: right;

        .btn {
            width: 120px;

            &:last-child {
                margin-right: 0;
            }
        }

        .limit-blocker > .btn {
            margin-right: 0;
        }
    }

    .modal-delete,
    .modal-confirmation {
        .btn-main {
            width: auto;
        }
    }
}

.bidding-timer {
    display: flex;
    align-items: center;

    &-value {
        margin-left: 4px;

        .time-passed {
            color: $green-9;
        }

        .less-then-five-mins {
            color: $secondary-1;
        }
    }
}

.bid-level-delta {
    font-family: $font-roboto-bold;
    text-align: left;
    display: inline-block;
    width: 70px;
    padding-left: 4px;
    white-space: nowrap;
    
    .text-ellipsis {
        display: inline-block;
        vertical-align: middle;
    }

    &-positive {
        color: $green-1;
    }

    &-negative {
        color: $secondary-1;
    }

    &-tooltip {
        position: absolute;
        top: 1px;
        right: 100%;
        background: $grey-4;
        color: $white-1;
        border-radius: 4px;
        font: 12px/24px $font-roboto-medium;
        text-align: center;
        max-width: 70px;
        padding: 0 5px;
        margin-right: 6px;

        .text-ellipsis {
            display: block;
            white-space: nowrap;
        }

        &:after {
            content: "";
            position: absolute;
            width: 0;
            height: 0;
            border-width: 5px;
            border-style: solid;
            border-color: transparent transparent transparent $grey-4;
            top: 7px;
            left: 100%;
        }
    }

    &-tooltip-right {
        right: auto;
        left: 100%;
        margin-left: 6px;

        &:after {
            left: auto;
            right: 100%;
            border-color: transparent $grey-4 transparent transparent;
        }
    }
}

.confirm-bids-popup {
    .modal-content {
        width: 1010px;
    }

    .status-message {
        margin-bottom: 16px;
    }

    .data-list {
        width: auto;

        &-header {
            .data-list-submit-status {
                padding-left: 25px;
            }
        }

        &-content {
            overflow: auto;
            max-height: 256px;

            &:after {
                display: none;
            }
        }

        &-cell {
            &:first-child {
                padding-left: 0;
            }

            &:last-child {
                padding-right: 0;
            }
        }

        &-isin {
            width: 104px;
        }

        &-ticker {
            width: 155px;
            white-space: nowrap;
        }

        &-size {
            width: 85px;
        }

        &-bid {
            display: flex;
            justify-content: flex-end;
            padding-right: 70px;
            white-space: nowrap;
            width: 145px;
            text-align: right;

            .bid-level-delta {
                margin-right: -70px;
                align-self: center;
                line-height: 14px;
                display: inline-flex;
            }
        }

        &-axed,
        &-final {
            width: 30px;
            text-align: center;
        }

        &-submit-status {
            .status {
                margin-right: 0;
            }

            .submitting-bid {
                padding-left: 20px;
            }

            .save-message,
            .error-message {
                margin-left: 12px;
                width: 200px;
            }
        }
    }

    .status-message {
        & + .status-message {
            margin-top: 12px;
        }
    }
}

.confirm-buyer-bids-popup {
    .modal-content {
        width: 1173px;
    }

    .status-message {
        margin-bottom: 16px;
    }

    .data-list {
        width: auto;

        @media all and (-ms-high-contrast:none) {
            overflow: hidden;
        }
    }

    .data-list-container,
    .data-list-content {
        display: block;
        overflow: visible;
    }

    .data-list-header {
        .data-list-submit-status {
            padding-left: 40px;
        }
    }

    .data-list-row {
        line-height: 16px;

        &.security-traded {
            border-bottom-color: $border-color;
        }
    }

    .status-message {
        & + .status-message {
            margin-top: 12px;
        }
    }
}

.rejected-trades-popup {
    .modal-content {
        width: 100%;
        max-width: 1226px;
    }

    .status-message {
        margin-bottom: 16px;
    }

    .data-list {
        width: auto;

        &-content {
            overflow: auto;
            max-height: 256px;

            &:after {
                display: none;
            }
        }
    }
}

.expand-all-participants {
    width: 0;
    height: 0;

    .btn-link {
        position: relative;
        top: -34px;
        z-index: 25;
        margin: 0 0 0 12px;
    }
}

.bidding-participants {
    position: relative;
    flex: 1 1 auto;
    align-self: stretch;
    overflow: auto;

    &-content {
        width: 100%;
        height: 100%;
        overflow: auto;

        .data-list {
            &-header {
                .data-list-options {
                    font-size: 0;
                }
            }
        }
    }
}

.trade-modal {
    display: flex;
    flex-direction: column;
    padding: 55px 30px;

    & > .modal-content {
        display: flex;
        flex-direction: column;
        top: auto;
        width: 100%;
        max-width: 1140px;
        min-height: 341px;
        transform: translateY(0);

        & > .modal-header {
            padding-left: 50px;
        }

        & > .modal-body {
            display: flex;
            flex: 1 1 auto;
            padding: 0;
            overflow: auto;
        }

        .data-list {
            min-width: 1120px;
            background: none;

            .drop-down-list {
                .section {
                    max-height: 160px;
                }
            }
        }

        .data-list-content {
            width: 100%;
            overflow-y: auto;
            height: 340px;
        }

        .data-list-row {
            height: auto;
            min-height: 33px;

            &.active {
                min-height: 33px;
            }

            @for $i from 2 through 7 {
                &:nth-child(#{$i}) {
                    .rdp-overlay {
                        top: -(16px * $i);
                    }
                }
            }

            &:nth-last-child(-n+7):not(:nth-child(-n+7)) {
                .data-list-cell {
                    .drop-down-list {
                        top: auto;
                        bottom: calc(100% + 1px);
                    }
                }
            }
        }

        .data-list-cell {
            &.cell-counterparty {
                .drop-down-list {
                    width: 140px;
                }
            }
        }

        .no-checkbox-label {
            margin-left: 20px;
        }
    }

    .btn-affirm-all {
        min-width: 120px;
    }

    &.trade-modal-trading {
        .checkbox {
            label {
                color: inherit;
            }
        }

        .data-list-price {
            overflow: visible;

            .custom-drop-down {
                padding-right: 32px;
            }

            .drop-down-list {
                width: 94px;
            }
        }
    }

    &.trade-modal-affirm {
        & > .modal-content {
            & > .modal-header {
                padding-left: 30px;
            }

            .data-list-header,
            .data-list-row {
                padding-left: 30px;
            }
        }

        .data-list-container {
            min-width: 0;
        }

        .data-list-status {
            position: static;
        }

        &.trade-modal-affirm-direct {
            & > .modal-content {
                max-width: 1270px;

                .data-list {
                    min-width: 0;
                }

                .data-list-container {
                    min-width: 1220px;
                }

                .data-list-settle {
                    width: 90px;
                }

                .data-list-price {
                    width: 70px;
                }

                .data-list-commission {
                    width: 80px;
                }
            }
        }
    }
}

.conflict-bids-list {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 75px;
    margin-bottom: 10px;

    .flex-row {
        margin: 2px -5px 5px;
        flex-wrap: wrap;
        line-height: 1.2;

        div {
            padding: 0 5px;
        }
    }
}

.color-distribution {
    @extend .container-flex;

    &-title {
        margin: 0 32px;
        padding: 8px 0;
        font-weight: normal;
        border-bottom: 1px solid $grey-3;
        line-height: 32px;
        flex: none;

        &.color-distribution-title-main {
            margin: 0;
            padding: 8px 32px;
            font-family: $font-roboto-bold;
            background: $grey-3;
        }
    }

    .empty-placeholder {
        width: auto;
        align-self: auto;
    }

    &-content {
        display: flex;
        flex-direction: row;
        flex: 1 1 auto;
        min-height: 1px;

        .data-list-container {
            min-width: 100%;
        }
    }

    &-public {
        display: flex;
        flex-direction: column;
        flex: 0 0 340px;
        min-height: 1px;

        .public-color-options {
            overflow: auto;
            padding: 20px 32px;
            flex: auto;

            &-switcher {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 15px;

                .control-group-item {
                    padding-right: 15px;
                    padding-left: 15px;
                }
            }

            &-text {
                margin-bottom: 20px;
            }

            &-form {
                padding: 24px 28px;
                background: $grey-7;
            }

            &-form-row {
                margin-bottom: 34px;

                b {
                    display: block;
                    margin-bottom: 14px;
                }

                label {
                    display: block;
                    margin-bottom: 2px;
                }
            }
        }
    }

    &-preview {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        border-left: 1px solid $border-color;

        .color-distribution-title {
            margin: 0 34px;
            border: 0;
        }
    }
}

.ob-explanation-modal {
    .modal-body {
        padding: 0;
    }

    .modal-content {
        width: 800px;
    }

    .modal-footer {
        justify-content: space-between;
    }

    .ob-explanation-content {
        color: $grey-4;
    }

    .ob-explanation-section {
        align-items: flex-start;
        padding: 8px 12px;

        &:first-child {
            margin-bottom: 8px;
            padding-top: 24px;
            padding-bottom: 24px;
            background-color: $blue-7;
        }

        & > p {
            margin-bottom: 0;
            margin-left: 12px;
        }
    }

    .ob-explanation-column {
        padding-right: 12px;
        padding-left: 12px;
        width: 100%;

        img {
            display: inline-block;
            width: 364px;
            vertical-align: top;
        }

        .title {
            margin-bottom: 16px;
        }

        p {
            margin-bottom: 0;
        }
    }
}

.btn-history {
    position: relative;
    overflow: visible;
    max-height: 20px;

    &.short {
        .icon-svg {
            margin-right: 0;
        }
    }

    .badge {
        position: absolute;
        top: -8px;
        left: 7px;
    }
}

.btn-auto-feedback {
    .auto-feedback-status {
        display: inline-block;
        text-align: left;
        color: $grey-8;
        text-transform: uppercase;

        &.auto-feedback-on {
            color: $green-9;
        }
    }

    &.btn-link-active,
    &[disabled] {
        .auto-feedback-status {
            color: inherit;
        }
    }
}

.limit-blocker {
    .btn-auto-feedback {
        .auto-feedback-status {
            color: $grey-1;

            &.auto-feedback-on {
                color: $grey-1;
            }
        }
    }
}

