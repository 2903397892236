.collapsable-panel {
    flex: none;
    margin-bottom: 24px;
}

.collapsable-panel-title {
    border-bottom: 1px solid $border-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 9px 0 8px;
    cursor: pointer;

    .icon-svg-corner-arrow {
        margin-right: 8px;
        transform: rotate(0deg);
        transform-origin: center;

        &.is-open {
            transform: rotate(-90deg);
        }
    }

    h2 {
        margin-bottom: 0;
    }

    .secondary-text {
        color: $grey-1;
        font-family: $font-family-base;
        margin-left: 8px;
    }
}

.collapsable-panel-click-area {
    display: flex;
    align-items: center;
    flex: auto;
}
