﻿.container-manage-bwics {
    .container-bwic-group-scroll {
        overflow: auto;
    }

    .container-bwic-group-view {
        flex-direction: column;
        min-width: 1280px;

        .data-table-header {
            padding-right: 41px;

            .data-table-item-collapse {
                margin-right: 16px;
            }
        }

        .data-table-content {
            overflow-y: scroll;
        }
    }

    .data-item-collapsable-header {
        min-height: 32px;

        .amr-label {
            margin-left: 24px;
            @include flex-basis(60px);
            text-align: left;
            line-height: 16px;

            .loader-box {
                justify-content: flex-start;
            }
        }

        .btn-main,
        .btn-link {
            margin-left: 10px;
        }

        .btn-edit {
            margin-right: 0;
        }

        a {
            margin-left: 10px;
            margin-right: 10px;
        }

        .state-parsed {
            width: auto;
        }

        .bwic-unpublished {
            @include flex-basis(80px);
        }
    }

    .icon-expand {
        margin-right: 19px;
        margin-left: 3px;
    }

    .data-item-collapsable-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        position: relative;

        &.data-item-empty-bwic {
            font-size: 12px;
            padding: 22px 0 22px 32px;
        }
    }

    .data-table-item-row {
        padding-left: 24px;
        padding-right: 10px;
        @extend .transition;
    }

    .data-item-collapsable-content-rows {
        width: 100%;
    }

    .data-table-item-check {
        margin-right: 16px;
        line-height: 1;

        .checkbox {
            vertical-align: top;
        }
    }

    .data-table-item-color {
        width: 176px;
    }

    .data-table-item-px {
        width: 85px;
    }

    .form-control-table {
        margin: 8px 0 8px 12px;
        flex: auto;
        min-height: 110px;
        height: auto;
    }

    .empty-placeholder {
        width: auto;
    }
}


@media only screen and (max-width: 1470px) {
    .container-manage-bwics {
        .data-item-collapsable-header {
            white-space: nowrap;

            .state-parsed {
                @include flex-basis(50px);
            }

            .amr-label {
                @include flex-basis(60px);
            }

            .btn-main {
                margin-left: 10px;
            }

            a {
                margin-left: 0;
            }

            .btn-link {
                margin-right: 0;
                margin-left: 5px;
            }

            .btn {
                padding: 0;
                width: 70px;
            }
        }
    }
}


.container-manage-bwic {
    .content-manage-bwic {
        max-width: 1004px;
        width: 100%;
        margin: 0 auto;
        padding-top: 16px;
    }

    .collapsable-panel {
        h2 {
            line-height: 1.375;
        }
    }

    .container-rules-area {
        padding-top: 16px;

        & > .form-row-inline {
            justify-content: flex-start;

            &:last-child {
                margin-bottom: 0;
            }

            &.two-stage-hard-cut-off {
                & > .form-item {
                    &.hard-cut-off-est {
                        width: 186px;
                    }
                }
            }

            & > .form-item {
                flex: none;
                width: 150px;

                &:not(:last-child) {
                    margin-right: 24px;
                }

                &.bwic-time,
                &.bids-good-for {
                    .form-row {
                        flex-wrap: nowrap;
                    }

                    .form-error {
                        top: 100%;
                        left: 0;
                    }
                }

                &.bwic-time {
                    width: 348px;

                    .form-item {
                        margin-right: 24px;
                    }
                }

                &.bids-good-for {
                    width: 176px;

                    .form-item {
                        margin-right: 16px;
                    }
                }

                &.days-to-settle {
                    width: 90px;
                }

                &.hard-cut-off {
                    width: 92px;
                }

                &.bidding-process,
                &.hard-cut-off-est,
                &.stage-2-participants {
                    width: 140px;
                }

                &.time-zone {
                    width: 194px;
                }

                &.notes {
                    width: 396px;

                    .form-control {
                        padding-top: 5px;
                        padding-bottom: 5px;
                        height: 32px;
                    }
                }

                .form-label {
                    margin-bottom: 4px;
                    line-height: 16px;
                }


            }
        }

        .rdp-input {
            width: 100%;
        }

        .control-time-picker {
            width: 100%;
            height: 32px;

            .rc-time-picker-input {
                font-size: 14px;
            }
        }
    }
    
    .securities-title {
        margin-top: 8px;
        margin-bottom: 16px;
        border-bottom: 1px solid $border-color;
        line-height: 40px;
    }

    .grid-drop-section {
        min-height: 433px;
    }

    .grid-table-wrapper {
        .cell-ticker {
            @include flex-basis(150px);
        }

        .cell-currency {
            @include flex-basis(80px);
        }

        .cell-color {
            @include flex-basis(166px);
        }
    }
}