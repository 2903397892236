﻿/* Grid common styles for component */

.grid-drop-section {
    position: relative;

    .container-loader {
        height: calc(100% - 75px);
        width: 100%;
        background-color: rgba($grid-action, 0.04);
        border: solid 2px $grid-action;
    }

    &.highlight {
        position: relative;

        &:before {
            display: block;
            height: calc(100% - 75px);
            width: 100%;
            background-color: rgba($grid-action, 0.04);
            border: solid 2px $grid-action;
            position: absolute;
            top: 0;
            left: 0;
            z-index: 10;
            pointer-events: none;
            content: '';
        }

        .block-prompt-wrapper {
            .block-prompt-upload {
                color: $white-1;
                background-color: $grid-action;
                border-color: $grid-action;

                .content-block-prompt {
                    display: flex;
                    align-items: center;
                }

                p {
                    margin-bottom: 0;
                }

                span {
                    color: $white-1;
                }

                .title {
                    margin-bottom: 0;

                    .btn-link {
                        color: $white-1;
                    }
                }
            }
        }

        .grid-table-wrapper {
            pointer-events: none;
        }
    }
}

.grid-table-wrapper {
    overflow-x: auto;
    overflow-y: hidden;

    @media all and (-ms-high-contrast:none) {
        overflow-x: scroll;
    }
}

.block-prompt-wrapper {
    padding: 16px 24px 8px;
    display: flex;
    justify-content: flex-end;
    flex: none;
    border-top: 1px solid $grid-borders;
    position: relative;

    .content-block-prompt {
        width: 100%;
        position: relative;
        padding-left: 36px;
        min-height: 32px;
    }

    .block-prompt-upload {
        background-color: $white-1;
        border-radius: 28px;
        border: solid 1px $control-border;
        line-height: 16px;
        height: 54px;
        padding: 10px 32px;
        display: flex;
        align-items: center;
        max-width: 390px;

        .title {
            font-family: $font-roboto-medium;
            margin-bottom: 0;

            .btn-link {
                font-size: 14px;
                vertical-align: baseline;
            }
        }

        span {
            font: 12px $font-family-base;
            color: $grey-8;
        }

        .icon-svg-upload-doc {
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
        }

        .uploading-progress {
            padding-left: 0;
            display: inline-flex;
            flex-direction: row;
            vertical-align: middle;
            width: 90%;
        }

        .uploading-progress-name {
            color: $text-color;
            font: 14px/32px $font-roboto-medium;
            white-space: nowrap;
        }
    }
}

.grid {
    overflow: hidden;
    font-size: 12px;
    border-spacing: 0;
    border-collapse: separate;

    &:focus {
        outline: none;
    }

    thead {
        background-color: $grid-header-bg;
        display: block;

        tr {
            display: block;
        }
    }

    tbody {
        overflow-x: hidden;
        overflow-y: scroll;
        display: block;
        width: 100%;

        tr {
            &:nth-last-child(-n+5) {
                .cell-lookup {
                    top: auto;
                    bottom: 100%;
                }
            }

            &:last-child {
                .action-wrap {
                    .btn-action-down {
                        display: none;
                    }

                    .action-box {
                        &:before,
                        .action-bottom {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    tfoot {
        background-color: $grid-footer-bg;
        display: block;

        tr {
            display: block;
        }

        td {
            padding: 0 8px;
            border-top: 0;
            border-left: 0;

            &.cell-no-data {
                background: none;
            }
        }
    }

    th {
        font-weight: normal;
        text-align: left;
        height: 24px;
        padding: 0;
        border: none;

        .cell-title-wrapper {
            padding: 4px 8px 2px;
            border-bottom: solid 2px $grid-header-bg;
            line-height: 16px;

            .icon-cell-sort {
                vertical-align: top;
            }
        }

        &:nth-child(3) {
            border-left: solid 1px $grid-borders;
        }

        &.highlight {
            .cell-title-wrapper {
                border-bottom-color: $grid-borders-focus;
                position: relative;

                &:before,
                &:after {
                    position: absolute;
                    bottom: -2px;
                    width: 1px;
                    height: 0;
                    border-bottom: solid 2px $grid-borders-focus;
                    display: block;
                    content: '';
                }

                &:before {
                    left: 0;
                }

                &:after {
                    right: -1px;
                }
            }
        }
    }

    td {
        height: 32px;
        padding: 0;
        border: solid 1px $grid-borders;
        border-bottom: none;
        border-right: none;
        text-transform: uppercase;

        .action-wrap {
            min-height: 32px;
        }

        &.cell-no-data {
            background-color: $grid-cell-inactive;

            &:not(:last-child) {
                border-left: none;
            }
        }

        &.cell-number {
            text-align: right;
            line-height: 32px;

            .cell-item {
                padding-right: 5px;
                border-right: solid 2px transparent;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                -o-user-select: none;
                user-select: none;
                width: 100%;
            }
        }

        &.has-focus {
            .cell-item {
                box-shadow: 0 0 0 1px $grid-borders-focus;

                &.error {
                    padding-top: 0;
                    background: $white-1;
                    box-shadow: 0 0 0 1px $color-error;

                    .cell-item-text,
                    .cell-item-control,
                    select {
                        color: initial;
                    }

                    .form-select:focus {
                        box-shadow: none;
                    }
                }
            }
        }

        &.readonly {
            .cell-item {
                background: $grid-cell-inactive;
            }
        }

        .cell-item {
            &.highlight {
                border-right: solid 2px $grid-borders-focus;

                &:before,
                &:after {
                    position: absolute;
                    right: -2px;
                    width: 0;
                    height: 1px;
                    border-right: solid 2px $grid-borders-focus;
                    content: '';
                    display: block;
                }

                &:before {
                    top: -1px;
                }

                &:after {
                    bottom: -1px;
                }
            }
        }
    }

    th,
    td {
        vertical-align: middle;
        @extend .transition;

        &.cell-no-data {
            &:first-child {
                width: 80px;
                min-width: 80px;
                max-width: 80px;
            }

            &:last-child {
                width: 100%;
            }
        }

        &.cell-number {
            width: 32px;
            min-width: 32px;
            max-width: 32px;

            &.cell-action {
                width: 48px;
                min-width: 48px;
                max-width: 48px;
            }
        }

        .action-wrap {
            height: 100%;
            width: 64px;
            position: relative;
            display: flex;
            align-items: center;

            &.cell-action {
                width: calc(100% + 48px);
            }

            .state-new,
            .state-updated {
                margin-left: 6px;
            }

            .btn-danger,
            .icon-warning,
            .icon-svg-flag {
                margin: 0 8px;

                .icon {
                    margin: 0;
                }
            }

            .icon-svg {
                margin-right: 0;
            }

            .tooltip-wrapper {
                width: 16px;
                height: 16px;
                display: inline-block;
                margin-left: 10px;
            }

            .flagged {
                color: $blue-1;
            }
        }

        .action-move {
            width: 48px;
            position: absolute;
            left: 33px;
            top: 7px;
            display: none;

            &-col {
                width: 50%;
                text-align: center;

                .btn-link {
                    vertical-align: top;
                }

                .btn-action-down {
                    .icon-svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }

        .action-box {
            height: 100%;
            position: absolute;
            left: 82px;
            right: 0;
            top: 0;
            z-index: 1;

            &:before,
            &:after {
                position: absolute;
                content: '';
                width: 3px;
                height: 3px;
                border-radius: 50%;
                background-color: $grey-2;
                bottom: -2px;
                z-index: 1;
                pointer-events: none;
            }

            &:before {
                right: 30px;
            }

            &:after {
                right: 47px;
                display: none;
            }
        }

        .action-top {
            position: absolute;
            top: 0;
            left: 0;
            height: 50%;
            width: 100%;
            z-index: 2;

            .btn-action-add {
                position: absolute;
                z-index: 2;
                top: -9px;
                right: 25px;
                display: none;

                &:hover {
                    &:after {
                        content: '';
                        position: absolute;
                        width: 200vw;
                        border-top: 1px solid $primary-2;
                        left: 15px;
                    }
                }
            }

            &:hover {
                .btn-action-add {
                    display: flex;
                }
            }

            .tooltip-wrapper {
                margin-top: -4px;
            }
        }

        .action-bottom {
            position: absolute;
            top: 50%;
            left: 0;
            height: 50%;
            width: 100%;
            z-index: 2;

            .btn-action-add {
                position: absolute;
                z-index: 2;
                bottom: -8px;
                right: 25px;
                display: none;

                &:hover {
                    &:after {
                        content: '';
                        position: absolute;
                        width: 200vw;
                        border-top: 1px solid $primary-2;
                        left: 15px;
                    }
                }
            }

            &:hover {
                .btn-action-add {
                    display: flex;
                }
            }

            .tooltip-wrapper {
                margin-top: 8px;
            }
        }

        .btn-action-add {
            padding: 0;
            height: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: $white-1;
        }
    }

    tr {
        width: 100%;
        max-width: 100%;

        &:hover {
            td,
            .cell-item.error {
                background-color: $row-hover;
                background-clip: padding-box;

                .form-error:after {
                    background-color: $row-hover;
                }
            }

            .btn-danger {
                display: block;
            }

            .action-move {
                display: flex;
            }

            .cell-label,
            .cell-icon-warning,
            .state-new,
            .state-updated,
            .flagged {
                display: none;
            }

            td.readonly .cell-item {
                background-color: $row-hover;
            }
        }

        &.selected {
            td,
            .cell-item.error {
                background-color: $row-active;
            }
        }

        .btn-danger {
            line-height: 14px;
            display: none;
        }

        &:first-child {
            .action-wrap {
                .action-top {
                    display: none;
                }

                .btn-action-up {
                    display: none;
                }
            }
        }

        &:last-child {
            td {
                border-bottom: 1px solid $grid-borders;
            }
        }
    }

    .form-error {
        top: calc(100% + 1px);
        margin-left: -1px;
        min-width: 100%;
        z-index: 1;

        &:first-letter {
            text-transform: capitalize;
        }
    }
    /* Grid for documents from 4 step New Bwic */
    &.grid-files {
        th {
            padding: 0 8px;

            &:first-child {
                padding-left: 24px;
            }

            &:nth-child(3) {
                border-left: none;
            }
        }

        td {
            height: 100%;
            vertical-align: top;

            &:first-child {
                padding: 6px 8px 0 24px;
            }

            .file-description {
                width: 65%;
                max-width: 65%;
                min-width: 65%;
            }
        }

        tr {
            &.selected-deal {
                td {
                    background-color: $row-active;
                    background-clip: padding-box;
                }
            }

            &:hover {
                .btn-danger {
                    display: none;
                }
            }
        }
    }
    /* end Grid for documents from 4 step New Bwic */
}

.cell-item-control {
    color: inherit;
    font-size: 12px;
    font-family: inherit;
    text-align: inherit;
    border: none;
    padding-bottom: 0;
    height: 100%;
    width: 100%;
}

input.cell-item-control {
    padding: 0 8px;
}

select.cell-item-control {
    padding: 0 32px 0 8px;
    border-radius: 0;
    -moz-appearance: menulist-text; //none;
    -webkit-appearance: menulist-text; //none;
    appearance: menulist-text; //none;
}

.cell-item {
    width: 100%;
    height: inherit;
    text-align: inherit;
    position: relative;

    form#noValidateForm {
        margin: 0;
        padding: 0;
        height: 100%;
    }
    &.error {
        box-shadow: 0px 0px 0px 1px $control-error-brd;
    }

    .cell-item-text {
        text-align: inherit;
    }
}

.cell-item-text {
    display: block;
    width: 100%;
    padding: 8px 8px 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
        text-transform: none;
    }
}

.cell-lookup {
    background: $white-1;
    border: 1px solid $border-color;
    box-shadow: $app-shadow;
    padding: 4px 0;
    max-height: 300px;
    min-height: 32px;
    width: 320px;
    position: absolute;
    top: 32px;
    left: -1px;
    z-index: 2;
    overflow: auto;
    will-change: border, box-shadow;
    -webkit-transform: translate3d(0,0,0);

    li {
        padding: 4px 10px;
        display: flex;

        span {
            color: $text-color;
            font-family: $font-family-base;
            margin-left: auto;
        }

        &:hover, &.active {
            cursor: pointer;
            background-color: $tertiary-3;
        }
    }

    .container-loader {
        height: 100%;
        width: 100%;
        background-color: $white-1;
        border: none;
    }
}

.cell-item-file {
    padding: 0 8px;
    cursor: pointer;
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    vertical-align: top;
    min-width: 239px;
    height: 40px;
    background-clip: padding-box;
    z-index: 1;
    @extend .transition;

    .icon {
        margin-right: 8px;
    }

    &:hover {
        background-color: $yellow-6;

        .btn-update,
        .btn-danger {
            display: block !important;
        }
    }

    .btn-update,
    .btn-danger {
        position: absolute;
        display: none;
    }

    .btn-danger {
        right: 5px;
        top: 11px;
    }

    .btn-update {
        right: 30px;
        top: 11px;
    }

    .file-description {
        font-family: $font-roboto-medium;
        text-transform: none;
        flex: auto;
        line-height: 20px;
        white-space: nowrap;
    }

    .progress-circle {
        position: absolute;
        top: 50%;
        right: 8px;
        margin-top: -8px;
    }
}

.grid-date-cell div {
    height: 32px;
    padding: 8px 8px 7px;
}

.grid-date-cell-tooltip {
    background-color: $white-1;
    margin-top: -4px;
    margin-left: -1px;
    padding: 24px;
    border: 1px solid $border-color;
    width: 260px;

    &.bottom-placement {
        margin-top: auto;
    }
}