﻿// Roboto font family

@font-face {
    font-family: 'Roboto';
    src: url('../../fonts/Roboto-Regular.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'), url('../../fonts/Roboto-Regular.ttf');
}

@font-face {
    font-family: 'Roboto Light';
    src: url('../../fonts/Roboto-Light.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'), url('../../fonts/Roboto-Light.ttf');
}

@font-face {
    font-family: 'Roboto Medium';
    src: url('../../fonts/Roboto-Medium.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'), url('../../fonts/Roboto-Medium.ttf');
}

@font-face {
    font-family: 'Roboto Bold';
    src: url('../../fonts/Roboto-Bold.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'), url('../../fonts/Roboto-Bold.ttf');
}

@font-face {
    font-family: 'Roboto Black';
    src: url('../../fonts/Roboto-Black.eot'); /* IE9 Compat Modes */
    src: url('../../fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'), url('../../fonts/Roboto-Black.ttf');
}
