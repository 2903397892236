﻿.component-tree {
    color: $text-color;
    font-size: 14px;
    font-family: $font-roboto-regular;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;
    overflow: auto;
    width: 360px;
}

.component-tree-item {
    border-bottom: 1px solid $border-color;

    .icon-expand {
        position: absolute;
        top: 50%;
        left: 32px;
        transform: translateY(-50%);
        transition: .4s;
        pointer-events: none;
    }

    &.active {
        .component-tree-item-content {
            background-color: $tree-item-active-bg;
        }
    }
}

.component-tree-item-content,
.component-subtree-item-content {
    position: relative;
    cursor: pointer;
}

.component-tree-item-content {
    line-height: 2.7;
    padding-left: 55px;
    display: block;
    width: 100%;

    &:hover {
        background-color: $tree-item-active-bg;
    }
}

.component-tree-item-title {
    color: $text-color;
    font-size: 15px;
    font-family: $font-roboto-medium;
    display: block;
    width: 284px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.component-subtree-item {
    &.active {
        .component-subtree-item-title {
            color: $primary-3;
            font-family: $font-roboto-medium;
        }

        .component-subtree-item-content {
            background-color: $tree-item-active-bg;
        }
    }
}

.component-subtree-item-content {
    display: flex;
    flex-direction: row;
    padding: 7px 24px 6px 52px;

    &:hover {
        background-color: $tree-item-active-bg;
    }

    .label-status {
        position: absolute;
        top: 4px;
        right: 24px;
    }
}

.component-subtree-item-title {
    color: $text-color;
    font-size: 14px;
    font-family: $font-roboto-regular;
    flex: 1 0 auto;
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
