﻿.container-step-submit {
    position: relative;
    flex-direction: row;

    .flex-item-sidebar h2,
    .template-title {
        padding: 14px 24px;
        line-height: 22px;
        text-transform: none;
        background: $white-1;
        margin: 0;
    }

    .flex-item-sidebar {
        h2 {
            border-bottom: 1px solid $border-color;
        }

        .status-message {
            margin: 16px 24px;
            width: auto;
        }
    }

    .flex-item-template {
        background: $grey-2;

        .template-wrapper {
            padding-top: 40px;
            padding-bottom: 90px;
            overflow: auto;
        }

        .template-cnt {
            max-width: 700px;
            width: 100%;
            margin: 0 auto;
            background: $white-1;
            border-radius: $app-brd-radius;
            box-shadow: $app-shadow;
            padding: 60px 60px 0;

            .icon-logo-bwic {
                margin-bottom: 30px;
            }

            h2 {
                margin: 0 0 8px;

                &.trading-protocol {
                    margin-bottom: 16px;
                }
            }

            .table-submit-securities {
                width: 100%;
                margin: 24px 0;

                thead {
                    th {
                        font-size: 12px;
                        font-weight: normal;
                        text-align: left;
                        padding: 0 10px 3px;
                        border-bottom: solid 1px $blue-1;
                        min-width: auto;
                        max-width: none;
                    }
                }

                tbody {
                    th {
                        font-family: $font-roboto-medium;
                        line-height: 30px;
                        border-bottom: solid 1px $blue-1;
                    }

                    td {
                        color: $grey-4;
                        padding: 5px 10px;
                        border-bottom: solid 1px $row-divider-color;
                        min-width: auto;
                        max-width: none;
                        text-transform: uppercase;
                        font-size: 12px;

                        &:first-child {
                            border-left: none;
                        }

                        &:last-child {
                            border-right: none;
                        }
                    }
                }

                .cell-size {
                    font-family: $font-family-base;
                    text-align: right;
                    width: 7.5rem;
                }

                .cell-currency,
                .cell-rating {
                    width: 3.2rem;
                }

                .cell-asset {
                    width: 65px;
                }

                .cell-ticker {
                    width: 125px;
                }

                .cell-isinCusip {
                    font-family: $font-family-base;
                    width: 110px;
                }
            }

            .regards {
                margin: 24px 0;

                strong {
                    display: block;
                    margin-bottom: 8px;
                }
            }

            .disclaimer {
                background: $grey-7;
                font-size: 10px;
                color: $grey-8;
                margin: 0 -60px;
                padding: 30px 60px;
            }

            .email-disclaimer {
                margin-bottom: 24px;
                padding: 16px 20px;
                background-color: $grey-7;

                h4, p {
                    margin-bottom: 8px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                table {
                    table-layout: unset;
                    background: none;
                    margin-top: 18px;

                    th {
                        overflow: hidden;
                        width: 235px;
                        max-width: 235px;
                        padding-right: 25px;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        vertical-align: top;
                        text-align: left;
                    }

                    td {
                        vertical-align: top;
                        width: auto;

                        .email {
                            color: $primary-3;
                            display: inline;
                            word-break: break-word;
                        }
                    }
                }
            }
        }

        .template-rules-list {
            li {
                padding-top: 8px;

                &:first-child {
                    padding-top: 0;
                }

                ul {
                    li, li:first-child {
                        padding: 4px 0 0;
                    }
                }

                ul.bullets {
                    margin: 0 0 0 8px;

                    li {
                        position: relative;
                        padding: 4px 0 0 12px;

                        &:before {
                            content: '\2022';
                            position: absolute;
                            top: -1px;
                            left: 0;
                            font-size: 22px;
                        }
                    }
                }
            }
        }
    }

    .margin-none {
        margin-bottom: 4px;
    }

    .space-top {
        margin-top: 24px;
    }

    .space-bottom {
        margin-bottom: 24px;
    }
}

.container-submit-participants {
    overflow: auto;

    .participants-cnt {
        padding: 17px 24px;
        border-bottom: 1px solid $border-color;

        &:last-child {
            border-bottom: 0;
        }

        .participants-list {
            font: 12px/16px $font-roboto-regular;

            .submit-participants-distribution {
                position: relative;
                margin-bottom: 4px;
                padding-left: 28px;

                .tooltip-wrapper {
                    position: absolute;
                    top: 0;
                    left: 0;

                    .icon-svg {
                        vertical-align: top;
                    }
                }
            }

            .submit-participants-company {
                word-break: break-all;
                margin-bottom: 4px;
                padding-left: 28px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

.container-submit {
    padding: 24px;
    overflow: auto;

    &-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid $grey-5;
        background: $grey-3;
        border-radius: $app-brd-radius;
        padding: 32px;
        text-align: center;

        h2 {
            margin: 0 0 16px;
        }

        p {
            line-height: 20px;
            margin-bottom: 16px;
        }

        .controls {
            .btn {
                width: 150px;
                margin: 0 8px;
            }
        }
    }
}
