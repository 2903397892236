.sidebar-nav-clo-managers {
    .sidebar-nav-header .control-search-wrapper .form-control-search-btn {
        margin-left: 14px;
    }

    .sidebar-nav-list-item {
        padding-top: 6px;
        padding-bottom: 6px;
        display: block;

        &.sidebar-nav-list-item-extended {
            padding-right: 12px;

            .flex-row:first-child {
                margin-bottom: 4px;
            }

            .title {
                max-width: 300px;
                width: auto;
                overflow: hidden;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .title-with-label {
                max-width: 275px;
            }

            .bwic-state-my {
                margin-left: 8px;
            }
        }

        .btn-link-favorite {
            color: rgba($primary-4, .5);

            .icon-svg {
                margin-right: 0;
            }
        }
    }
}

.container-clo-managers {
    .sub-header {
        h1 {
            line-height: 1.35;
        }

        .action-item {
            line-height: 1;

            .loader-box {
                background-color: transparent;
                margin-left: 24px;
            }
        }

        .vertical-divider,
        .btn {
            margin-left: 8px;
        }

        .btn-link {
            white-space: nowrap;
        }

        .btn-link-manager-presentation {
            margin-left: 24px;
        }

        .btn-ghost:not(:last-child) {
            margin-right: 8px;
        }

        .bwic-state-my {
            margin-right: 0;
        }

        .btn-schedule-meeting {
            &.btn-ghost {
                margin-right: 0;
            }

            .icon-svg {
                margin-right: 5px;
                margin-left: 0;
            }
        }

        .limit-blocker {
            margin-left: 24px;
            line-height: 16px;

            .btn-link-manager-presentation {
                margin-left: 0;
            }
        }

        .sidebar-controls-item-label,
        .sidebar-controls-item {
            margin-right: 8px;
        }

        .vertical-divider + .limit-blocker {
            margin-left: 0;
        }
    }

    .tabs-data {
        padding-top: 16px;
    }

    .tabs-data-deals {
        padding-bottom: 0;
    }

    .loader-box {
        flex: 1 1 auto;
        width: auto;
        background-color: $primary-6;
    }

    .right-panel {
        border-top: 1px solid $border-color;
        margin-right: -24px;
    }

    .data-item-clo-managers-deals {
        @extend .container-flex;

        .data-list-cell-collapse-action {
            @include flex-basis(32px);
        }

        .data-item-content {
            @extend .container-flex;
            @extend .flex-row;
            position: static;
        }

        .data-list-freeze {
            .data-list-striped {
                flex-grow: 0;
            }

            .data-list-container {
                flex-grow: 0;
            }

            .data-column-sticky-middle {
                .component-collapsible-list-item-content {
                    .data-list-header,
                    .data-list-row {
                        padding-left: 88px;

                        .flex-row {
                            padding-right: 0;
                            padding-left: 0;
                        }
                    }

                    .container-loader {
                        align-items: flex-start;
                        padding-left: 300px;
                        border-bottom: 1px solid $row-divider-color;
                    }
                }
            }

            .data-column-sticky-right {
                .icon-svg-go-to-details {
                    margin-right: 0;
                }

                .action-empty-placeholder {
                    margin-right: 5px;
                }

                .empty-placeholder.text-view-row {
                    padding: 0;
                }
            }

            .component-collapsible-list-item-wrap {
                .data-list-header {
                    padding-left: 16px;
                    overflow: hidden;
                }
            }

            .empty-placeholder {
                &.text-view-row {
                    padding-left: 96px;
                    border-bottom: 1px solid $row-divider-color;
                    height: 32px;

                    .icon-svg {
                        margin: 0;
                    }
                }
            }

            .right-panel-header {
                border-top: 1px solid $border-color;
            }

            .sticky-column-preloader {
                height: 32px;
                border-bottom: 1px solid $row-divider-color;
                background-color: $primary-6;
            }
        }

        .data-list-content {
            .data-list-cell-download-deal-documents {
                & > div {
                    width: 48px;
                }
    
                .btn-link {
                    .icon-svg {
                        margin-right: 4px;
                    }
                }
    
                .loader-box {
                    justify-content: flex-start;
                    background-color: transparent;
                }
            }
        }
    }

    .documents-section {
        .empty-placeholder {
            padding-left: 24px;
            padding-right: 24px;
        }
    }

    .clo-managers-charts {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;

        .dashboard-row:last-child {
            padding-bottom: 8px;
        }
    }

    .document-item {
        margin-bottom: 8px;

        .loader-box {
            color: $grey-4;
            padding: 4px 0;
            background: none;
        }
    }

    .show-more-documents {
        .icon-svg {
            transform: rotate(90deg);

            &.is-open {
                transform: rotate(270deg);
            }
        }
    }

    .clo-manager-investment-team-table {
        margin-bottom: 12px;

        .status {
            display: inline-block;
            padding-left: 0;
            width: 8px;
        }

        .data-list-content:after {
            display: none;
        }

        & + .restricted-placeholder {
            padding: 0;
        }
    }

    .analytics {
        &-heading {
            display: flex;
            align-items: center;
            padding-bottom: 8px;
            margin-bottom: 16px;
            border-bottom: 1px solid $border-color;

            h2 {
                margin-bottom: 0;
                margin-right: 24px;
            }

            &-downloaded-presentations {
                h2 {
                    margin-right: 8px;
                }

                .tooltip-wrapper {
                    margin-right: 16px;
                }
            }
        }

        .agenda {
            margin-top: -26px;
        }

        .data-list-content {
            overflow: auto;
        }

        .full-name-ellipsis {
            overflow: hidden;
            text-overflow: ellipsis;
        }

        .blocked-feature-placeholder {
            margin-top: 12px;
        }
    }

    .component-tabs-analytics {
        .analytics {
            margin-bottom: 40px;

            .js-plotly-plot {
                width: calc(100% + 28px) !important;
                height: 270px;
                margin-left: -20px;
            }
        }

        .analytics-legend {
            padding: 16px 12px 16px 16px;
            text-align: left;
            justify-content: flex-start;

            .required-feature {
                justify-content: flex-start;
            }

            h3 {
                margin-bottom: 0;
            }
        }

        .agenda {
            display: flex;

            .agenda-item {
                position: relative;
                padding-left: 20px;
                margin-right: 16px;
                font-size: 12px;
                line-height: 1.15;

                &:before {
                    position: absolute;
                    content: '';
                    width: 12px;
                    height: 12px;
                    border-radius: 2px;
                    top: 0;
                    left: 0;
                }

                &.overview-tab,
                &.contacts-events-tab {
                    &:before {
                        background-color: $blue-4;
                    }
                }

                &.deal-tab,
                &.primary-tab {
                    &:before {
                        background-color: $blue-11;
                    }
                }

                &.clo-team-tab,
                &.secondary-tab {
                    &:before {
                        background-color: $primary-13;
                    }
                }

                &.additional-information-tab {
                    &:before {
                        background-color: $grey-2;
                    }
                }
            }
        }
    }

    .content-row {
        display: flex;
        flex-grow: 1;
        margin: 0 -16px;
    }

    .content-col {
        width: 50%;
        padding: 0 16px;

        &-checkbox {
            .data-item-col {
                display: flex;
                align-items: center;
                min-height: 32px;

                label {
                    font-size: 14px;
                }
            }
        }
    }

    .component-tabs-clo-edit {
        .data-item-company-profile {
            position: relative;

            .btn-link {
                position: absolute;
                right: 0;
                top: 3px;
            }
        }

        .data-item-row {
            margin-bottom: 24px;

            .DayPickerInput {
                width: 100%;
            }

            .component-file-upload + .component-file-upload-list {
                border-top: none;
            }
        }

        .formal-policy-checkbox {
            margin-bottom: 0;

            .data-item-row {
                margin-bottom: 0;
            }
        }

        .data-item-row-full-height {
            .data-item-col-title {
                padding-top: 6px;
            }
        }

        .markdown-editor-head .form-control {
            padding-top: 0;
            padding-right: 8px;
        }

        .section-investment-team {
            position: relative;

            .status-message {
                width: auto;
                position: absolute;
                top: -3px;
                right: 0;
            }

            .grid-table-wrapper {
                &:after {
                    content: " ";
                    min-height: 10px;
                    visibility: hidden;
                }
            }

            .grid {
                /*AMR-8924 CLO Managers:: CLO Team:: Hide the 'Yrs at firm' and 'Yrs experience' fields in the table*/
                min-width: 1490px;

                td {
                    text-transform: none;
                }
            }
        }

        .institution-type-field {
            .component-dropdown-list {
                min-width: 250px;
            }
        }

        .country-field {
            .component-dropdown-list {
                min-width: 350px;
            }
        }
    }

    .data-item-statistics {
        position: relative;

        .data-item-content {
            margin-top: 16px;
        }

        .company-description {
            margin: 16px 0;
        }

        .data-item-title {
            margin-bottom: 0;
        }

        .company-profile-actions {
            position: absolute;
            right: 0;
            top: 2px;

            .btn-link {
                margin-left: 24px;
            }
        }
    }

    .statistics-row {
        display: flex;
        justify-content: space-between;
        padding-bottom: 16px;
        margin-bottom: 32px;
        border-bottom: 1px solid $border-color;
    }

    .statistics-item {
        border-left: 1px solid $border-color;
        padding: 0 10px;
        font-size: 12px;

        &:first-child {
            padding-left: 0;
            border-left: none;
        }
    }

    .statistics-info {
        display: flex;
        font-size: 14px;
        margin-top: 4px;

        b {
            margin-right: 4px;
        }

        span, a, .btn-link {
            margin-left: 4px;
        }
    }

    .statistics-charts {
        display: flex;
        flex-wrap: wrap;
        gap: 32px 160px;
    }

    .statistics-chart-item {
        width: 172px;

        .agenda {
            margin-top: 16px;

            .agenda-item {
                display: flex;
                justify-content: space-between;
                position: relative;
                padding-left: 20px;
                margin-bottom: 8px;
                font-size: 12px;
                line-height: 1.15;

                &:before {
                    position: absolute;
                    content: '';
                    width: 12px;
                    height: 12px;
                    border-radius: 2px;
                    top: 0;
                    left: 0;
                }

                &.usd,
                &.esg,
                &.eu-compliant,
                &.static,
                &.yes {
                    &:before {
                        background-color: $blue-6;
                    }
                }

                &.eur,
                &.non-esg,
                &.non-eu-compliant,
                &.non-static,
                &.no {
                    &:before {
                        background-color: $blue-5;
                    }
                }

                .btn-link {
                    margin-left: 8px;
                }

                .agenda-item-box {
                    display: flex;
                    align-items: center;
                }

                .agenda-item-placeholder {
                    padding-right: 28px;
                }
            }
        }
    }

    .statistics-chart-head {
        display: flex;
        margin-bottom: 16px;

        h3 {
            margin-bottom: 0;
            margin-right: 8px;
        }
    }

    .statistics-chart-box {
        .js-plotly-plot .plotly svg {
            overflow: visible;
        }
    }

    .loan-analysts-with {
        display: block;
        line-height: 1.4;
        margin-bottom: 12px;
    }

    .data-item-row-col-full {
        width: 100%;
    }

    .profile-view-history-table,
    .transaction-view-history-table {
        margin-bottom: 12px;

        .data-list-content {
            max-height: 320px;

            &:after {
                display: none;
            }
        }

        & + .restricted-placeholder {
            padding: 0;
        }
    }

    .description-with-video-box {
        display: flex;
        flex-wrap: wrap;

        & > p {
            max-width: 855px;
            margin-right: 48px;
            margin-bottom: 32px;
        }
    }

    .video-wrap {
        display: flex;
        margin-left: -16px;
        margin-bottom: 32px;
    }

    .video-item {
        padding-left: 16px;
        min-width: 341px;
        min-height: 209px;

        .restricted-placeholder {
            padding: 8px;
            height: 180px;
        }
    }

    .empty-placeholder-col-full {
        margin-left: -240px;
        width: calc(100% + 240px);
    }
}

.downloaded-presentations-tooltip {
    .tooltip-inner {
        p + p {
            margin-top: 14px;
        }
    }
}
