﻿.bwic-details {
    &-header {
        margin-bottom: 20px;

        .flex-row {
            min-height: 22px;
            margin-bottom: 12px;
        }

        .bwic-status {
            margin-right: 16px;
        }

        .state-parsed {
            color: $grey-1;
            font-size: 11px;
            font-family: $font-roboto-medium;
            margin-right: 16px;
        }

        .btn-sm,
        .limit-blocker {
            margin-left: auto;
        }
    }
}
