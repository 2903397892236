﻿.notification {
    &s {
        position: fixed;
        bottom: 50px;
        right: 0;
        z-index: 100;
        width: 452px;
        padding: 0 20px;
    }

    & {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 12px 40px 6px 16px;
        border: solid 1px $grey-5;
        border-radius: $app-brd-radius;
        background-color: $white-1;
        box-shadow: 0 6px 10px 0 rgba($grey-4, .2);
    }

    &-icon {
        flex: none;
        width: 24px;
        margin-right: 8px;
        text-align: center;
    }

    &-content {
        flex: 1 1 auto;

        .notification-message {
            word-break: break-word;
        }
    }

    &-subject {
        word-break: break-word;
        margin-bottom: 4px;
        line-height: 24px;
        font-family: $font-roboto-medium;

        @media all and (-ms-high-contrast:none) {
            word-break: break-all;
        }
    }

    &-error &-subject {
        color: $color-error;
    }

    &-message {
        margin-bottom: 6px;
    }

    &-date {
        font-size: 11px;
        opacity: 0.7;
    }

    &-close {
        position: absolute;
        top: 8px;
        right: 8px;
    }
}
