﻿.pipeline-list-deals {
    &.data-list-iois {
        .data-column-sticky-middle {
            .component-collapsible-list-item-content {
                .data-list-header {
                    padding-left: 130px;
                }

                .data-list-row {
                    .data-list-cell-collapse-action {
                        margin-left: 8px;
                    }

                    & + .component-collapsible-list-item-content {
                        .data-list-header,
                        .data-list-row {
                            padding-left: 130px;
                        }
                    }
                }
            }
        }

        .component-collapsible-list {
            .component-collapsible-list:last-child {
                .component-collapsible-list-item:not(.expanded) {
                    .component-collapsible-list-item-header {
                        border-bottom-color: transparent;
                    }
                }
            }

            &.expand {
                border-top: .5px solid $primary-1;
                border-bottom: .5px solid $primary-1;

                .component-collapsible-list-item.expanded {
                    & > .component-collapsible-list-item-header:not(.pending-trade) {
                        background-color: $primary-15;
                    }
                }

                .component-collapsible-list-item-wrap {
                    .component-collapsible-list.expand {
                        border-top: none;
                        border-bottom: none;
                    }

                    .component-collapsible-list-item.expanded {
                        & > .component-collapsible-list-item-header:not(.pending-trade) {
                            background-color: $primary-14;
                        }
                    }

                    .collapsed-content-level-2 {
                        background-color: $primary-6;

                        &:before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 120px;
                            height: 32px;
                            border-bottom: 1px solid $primary-6;
                            content: '';
                            pointer-events: none;
                        }
                    }
                }

                .component-collapsible-list-item-content {
                    .component-collapsible-list-item-wrap {
                        &:last-child {
                            border-bottom: none;
                        }

                        .component-collapsible-list {
                            &:last-child {
                                .component-collapsible-list-item-content {
                                    .component-collapsible-list-item-wrap {
                                        border-bottom: none;
                                    }
                                }
                            }

                            .component-collapsible-list-item-content {
                                .component-collapsible-list-item-wrap {
                                    border-bottom: .5px solid $border-color;
                                }
                            }
                        }
                    }
                }

                + .component-collapsible-list.expand {
                    border-top: none;
                }
            }
        }
    }
}

.pipeline-list-class {
    &.data-list-iois {
        .component-collapsible-list-item-wrap {
            .data-list-header {
                padding-left: 16px;
                overflow: hidden;
            }
        }

        .component-collapsible-list.expand {
            border-top: .5px solid $primary-1;
            border-bottom: .5px solid $primary-1;

            .component-collapsible-list-item.expanded {
                & > .component-collapsible-list-item-header:not(.pending-trade) {
                    background-color: $primary-14;
                }
            }

            .collapsed-content-level-2 {
                background-color: $primary-6;
            }

            + .component-collapsible-list.expand {
                border-top: none;
            }
        }

        .component-collapsible-list-item-content.expanded {
            .component-collapsible-list-item-wrap {
                border-bottom: none;
            }
        }

        .data-column-sticky-left {
            .collapsed-content-level-2 {
                border-bottom: none;
            }
        }
    }
}
