﻿.container-bwics {
    position: relative;

    .flex-row-full-size {
        align-items: initial;
    }

    .empty-placeholder {
        align-self: flex-start;
    }
}

.select-lookup-items {
    position: relative;
    z-index: 3;
}

.container-bwic-group-view {
    position: relative;
    @extend .container-flex;
    flex-direction: row;
    min-width: 0;
    overflow: auto;

    @media all and (-ms-high-contrast:none) {
        overflow-x: scroll;
        overflow-y: hidden;
    }

    .data-list {
        width: auto;
        min-width: 1280px;
        z-index: 0;

        &.data-list-my-bwics-only {
            min-width: 1340px;
            z-index: auto;

            &.data-list-my-bwics-bd {
                min-width: 1390px;

                .data-table-item-my-bid {
                    width: 102px;
                    padding-right: 42px;

                    .justify-content-end {
                        margin-right: -42px;
                    }

                    .icons-wrapper {
                        margin-left: 8px;
                    }
                }
            }

            .options-list {
                .context-menu-dropdown {
                    right: 1px;
                    left: auto;
                }
            }

            .data-table-item-buttons {
                display: flex;
                justify-content: flex-end;
                min-width: 65px;
            }
        }
    }

    .data-table-header {
        font-size: 12px;
        background-color: $table-header-bg;
        border-bottom: 1px solid $row-divider-color;
        width: 100%;
        padding-right: 24px;
        padding-left: 24px;
        flex: none;
        display: flex;
        flex-direction: row;
        align-items: center;

        .data-table-header-item {
            flex: none;
            margin-right: 1rem;
            padding-top: 3px;
            padding-bottom: 2px;

            &:last-child {
                margin-right: 0;
                padding-left: 0;
            }
        }

        .data-table-item-collapse {
            margin-right: 16px;

            .btn-link {
                margin-top: 2px;
                vertical-align: top;
            }

            .icon-svg {
                margin: 0;
            }
        }

        .tooltip-wrapper {
            .icon-svg {
                margin-left: 4px;
            }
        }
    }

    .data-table-content {
        overflow-x: hidden;
        overflow-y: auto;

        .data-table-item {
            &:last-child {
                margin-bottom: 44px;
            }
        }
    }
}

.data-table-header-item {
    margin-right: 5px;

    &:last-child {
        margin-right: 0;
    }
}

.data-table-item-favorite {
    width: 14px;
}

.data-table-item-ticker {
    @include flex-basis(122px);
}

.data-table-item-same-day {
    @include flex-basis(20px);
}

.data-table-item-inventory {
    width: 80px;
    max-width: 80px;
}

.data-table-item-isin {
    width: 7rem;
}

.data-table-item-asset {
    width: 3rem;
}

.data-table-item-ccy,
.data-table-item-rating {
    width: 4rem;
    max-width: 45px;
}

.data-table-item-factor {
    text-align: center;
    width: 2.8rem;
}

.data-table-item-size {
    width: 4rem;
    max-width: 95px;
    @extend .text-ellipsis;
    text-align: right;
}

.data-table-item-px {
    width: 5.8rem;
    padding-right: 10px;
}

.data-table-item-color {
    width: 6.5rem;
    margin-right: 5%;
}

.data-table-item-color-seller {
    width: 150px;
    min-width: 150px;

    .popover {
        white-space: nowrap;
    }

    .flex-row {
        width: 100%;
    }

    .level-bids {
        width: 100%;
    }

    .context-menu-data {
        position: relative;
    }
}
/*
.data-table-item-docs {
    width: 38px;
    text-align: center;

    .btn-link {
        font-size: 13px;
        font-family: $font-roboto-regular;

        .icon {
            margin-right: 0;
        }
    }
}
*/
.data-table-item-my-bid {
    position: relative;
    text-align: right;
    width: 142px;
    padding-right: 62px;

    .animated-value-update {
        margin-right: -12px;

        .animated-value-update-content {
            z-index: auto;
        }
    }

    .btn-timeline {
        position: absolute;
        top: 0;
        right: 40px;
        height: auto;

        .icon-svg {
            margin-right: 0;
        }
    }

    .justify-content-end {
        margin-right: -62px;

        .icons-wrapper {
            display: flex;
            width: 34px;
            margin-left: 28px;

            .icon-svg-final {
                margin-left: auto;
            }
        }
    }
}

.data-table-item-feedback {
    flex: 1 1 auto;
    min-width: 80px;

    .text-ellipsis {display: block;}

    .status {
        flex: none;
    }
}

.data-table-item-buttons {
    .position-relative {
        .container-loader {
            top: 0;
            left: auto;
            right: 0;
            bottom: 0;
            background: none;
            flex-direction: row;

            .preloader {
                width: 18px;
                height: 18px;
                margin-right: 8px;
            }
        }
    }
}

.data-table-item {
    position: relative;
    flex-shrink: 0;
    border-bottom: 1px solid $row-divider-color;
}

.data-item-collapsable-header,
.component-collapsible-list-item-header {
    font-size: 12px;
    padding: 4px 24px;
    cursor: pointer;
    @extend .flex-row;

    &:hover {
        background-color: $row-hover;
    }

    &.expand {
        padding-bottom: 3px;
        border-bottom: 1px solid $row-divider-color;
        background-color: $row-active;
    }

    .icon-svg-icon-expand {
        margin-right: 0;

        &.disabled {
            pointer-events: none;
            opacity: .5;
        }
    }

    .icon-wrap,
    .kpt-platform {
        line-height: 1;
        display: block;

        .icon-svg {
            vertical-align: top;
        }
    }

    & > .bwic-status {
        @include flex-basis(140px);
    }

    .bwic-date {
        font-family: $font-roboto-bold;
        @include flex-basis(182px);
    }

    .same-day {
        @include flex-basis(24px);
    }

    .bwic-title {
        @include flex-basis(172px);
        white-space: nowrap;
    }

    .bwic-rules-link {
        @include flex-basis(135px);
        line-height: 1;

        .btn-rules {
            vertical-align: top;
        }
    }

    .bwic-unpublished {
        color: $secondary-1;
        font-family: $font-roboto-medium;
        @include flex-basis(100px);
    }

    .bwic-created {
        @include flex-basis(200px);
    }

    .bwic-updated {
        font-family: $font-roboto-medium;
        @include flex-basis(180px);
    }

    .options-list {
        margin-top: -5px;
        margin-bottom: -5px;
    }

    .state-parsed {
        font-size: 10px;
        text-align: center;
        width: 90px;
        border-radius: $app-brd-radius;
        padding: 0;
    }

    .icon-time {
        margin-right: 10px;
        vertical-align: text-bottom;
    }

    .state-parsed {
        color: $grey-1;
        font-family: $font-roboto-medium;
        margin-right: 5px;
    }
}

.data-item-collapsable-content {
    background-color: $white-1;
}

.data-table-item-row {
    font-size: 12px;
    height: 32px;
    padding: 0 24px 0 54px;
    border-bottom: solid 1px $row-divider-color;

    &:hover {
        background-color: $row-hover;
        cursor: pointer;
    }

    &:last-child {
        border-bottom: 0;
    }

    .flex-none {
        margin-right: 1rem;
        white-space: nowrap;

        &:last-child {
            margin-right: 0;
        }
    }

    .badge {
        margin-left: -20px;
        margin-right: 4px;
    }

    &.highlighted,
    &.active {
        background: $row-highlighted !important;

        &:not(:first-child) {
            margin-top: -1px;
            border-top: 1px solid $row-divider-color;
            height: 33px;
        }
    }

    &.affirmed {
        background: $green-8;
    }

    &.tradedaway {
        background: $grey-7;
    }

    &.pending-trade:not(.hover) {
        background: $row-pending;
    }

    &:hover {
        background-color: $row-hover;
    }

    .popover-md {
        right: 0;
    }
}

.px-talk-history-contact {
    display: flex;
    flex-direction: row;
    flex: 1 1 auto;
    margin: 0 -16px;

    &-name,
    &-email,
    &-phone {
        padding: 7px 16px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &-name {
        width: 32%;
    }

    &-email {
        width: 46%;
    }

    &-phone {
        width: 22%;
    }
}
