.resources {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;

    .container-loader {
        z-index: 12;
    }
    
    &-header {
        position: relative;
        z-index: 1;
        margin-bottom: 32px;
        color: #fff;
        background: url(/images/resourses-header.jpg) 0 0 no-repeat;
        background-size: cover;
    }

    &-header-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 180px;
        max-width: 1120px;
        margin-right: auto;
        margin-left: auto;
    }

    &-header-title {
        font-size: 18px;
        font-family: $font-roboto-medium;

        &:first-line {
            font-size: 56px;
            font-family: $font-roboto-bold;
        }
    }

    &-header-register {
        display: flex;
        flex-direction: column;
        gap: 4px;
        min-width: 255px;

        .btn {
            margin-bottom: 12px;
            width: 100%;
            border-color: $secondary-1;
            background-color: $secondary-1;

            &:hover {
                border-color: $warning-2;
                background-color: $warning-2;
            }
        }

        p {
            &:first-child {

            }
        }
    }

    &-content {
        display: flex;
        justify-content: center;
        gap: 10px;
        flex: 1 1 auto;
        min-height: 1px;
    }

    &-navigation {
        display: flex;
        flex-direction: column;
        gap: 8px;
        flex: none;
        width: 199px;
        padding-left: 24px;
    }

    &-navigation-title {
        margin-bottom: 0;
        padding: 5px 8px;
    }

    &-navigation-list {
        display: flex;
        flex-direction: column;
        gap: 4px;

        li {
            &.active {
                a {
                    color: $grey-4;
                    border-radius: $app-brd-radius;
                    background-color: $grey-3;
                    pointer-events: none;
                }
            }
        }

        a {
            display: block;
            padding: 6px 8px;
            font-size: 14px;
        }
    }

    &-sections {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        gap: 100px;
        min-height: 1px;
        max-width: 1053px;
        overflow: auto;
        padding-right: 24px;
        padding-left: 24px;
        scroll-behavior: smooth;
    }

    &-section {
        width: 100%;
        max-width: 1024px;
        margin: 0 auto;

        &:last-child {
            padding-bottom: 32px;
        }

        .data-item-head {
            margin-bottom: 0;
            padding: 7px 0 8px;
        }

        .data-item-title {
            font-size: 18px;
        }
    }

    &-item {
        scroll-margin-top: 72px;
        padding-top: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $border-color;

        &:not(:first-child) {
        }
    }

    &-item-details {
        flex-grow: 1;
        min-width: 0;
    }

    &-item-name {
        margin-bottom: 8px;
    }

    &-item-desc {
        margin-bottom: 8px;
        line-height: 1.28;

        h2 {
            margin-bottom: 8px;
        }
    }

    &-item-media {
        display: flex;
        flex: none;
        @include flex-basis(384px);
        margin-left: 56px;
        height: 216px;

        .position-relative {
            height: 216px;
        }

        .restricted-placeholder {
            flex-grow: 1;
        }

        iframe {
            border: 0;
        }
    }

    &-item-links {
        .btn {
            height: 24px;
        }

        .vertical-divider {
            height: 24px;
            margin-right: 12px;
            margin-left: 12px;
        }
    }
}
