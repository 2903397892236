﻿.sub-header-agreements {
    padding-bottom: 2px;
}

.default-main {
    .sub-header-agreements,
    .container-agreements {
        min-width: 1280px;
    }
}

.container-agreements {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    padding: 68px 32px 0 32px;
    background-color: $white-1;

    hr {
        width: 100%;
        border-top-color: $border-color;
        margin: 5.6rem 0 5rem;
        flex: none;
    }
}

.content-agreements {
    max-width: 800px;
    width: auto;
    flex: none;

    h1 {
        text-align: center;
        margin-bottom: 2rem;

        & + em {
            display: block;
            font-size: 14px;
            margin-bottom: 2rem;
            margin-top: -1rem;
            text-align: center;
        }
    }

    h2 {
        padding-bottom: 14px;
        border-bottom: solid 1px $border-color;
        margin-bottom: 20px;
    }

    p, table {
        line-height: 1.71;
        text-align: justify;
        margin-bottom: 1.87rem;
    }

    a {
        text-decoration: none;
    }

    address {
        font-style: normal;
        margin-bottom: 1.87rem;
    }

    table {
        td {
            padding: 0 4px;
            border: 1px solid currentColor;
            vertical-align: top;
        }
    }
}

.container-learn-more {
    text-align: center;
    padding-bottom: 6rem;
    flex: none;

    .learn-more {
        color: $text-color;
        margin-bottom: 1.87rem;
    }

    .item-info-blue {
        font-size: 16px;
        line-height: 1.5;
    }

    .learn-more-email {
        font-family: $font-roboto-bold;
        text-decoration: none;
    }

    .learn-more-text {
        margin-right: 1rem;
    }

    .btn-main {
        padding-left: 1rem;
        padding-right: 1rem;

        .icon {
            margin-right: 10px;
        }
    }
}

.item-info-blue {
    background-color: $primary-6;
    border: solid 1px $primary-7;
    border-radius: 6px;
    padding: 6px 6px 5px 17px;
}


@media only screen and (max-width: 720px) {
    .sub-header-agreements {
        padding-top: 6px;
        padding-bottom: 6px;

        .flex-row {
            padding-left: 24px;
            padding-right: 24px;
        }

        h1 {
            margin-bottom: 0;
        }
    }

    .container-agreements {
        padding: 24px 24px 0 24px;

        h1 {
            margin-bottom: 12px;
        }

        h2 {
            padding-bottom: 6px;
            margin-bottom: 12px;
        }

        hr {
            margin-top: 0;
            margin-bottom: 38px;
        }
    }

    .container-learn-more {
        padding-bottom: 40px;

        .learn-more {
            color: $text-color;
            text-align: center;
            margin-bottom: 16px;
        }

        .item-info-blue {
            color: $text-color;
            font-size: 14px;
            line-height: 2;
            background-color: transparent;
            border: none;
            padding: 0;
        }
    }
}

@media only screen and (max-width: 598px) {
    .container-learn-more {
        .item-info-blue {
            line-height: 32px;
        }
    }
}

.content-part-sidebar {
    .container-agreements {
        padding-top: 16px;
        min-width: calc(100% - 360px);

        .learn-more {
            text-align: center;
            padding: 0;
            border: 0;
        }
    }
}
