﻿.badge {
    color: $white-1;
    font: 10px/16px $font-roboto-regular;
    text-align: center;
    display: inline-block;
    min-width: 16px;
    height: 16px;
    padding: 0 4px;
    border-radius: 8px;
    vertical-align: top;
    background-color: $secondary-1;

    &-dot {
        min-width: 8px;
        height: 8px;
    }
}

.alert-badge {
    width: 8px;
    height: 8px;
    background-color: $secondary-1;
    border-radius: 50%;
    overflow: hidden;
    display: inline-block;
}
