﻿.intro-carousel {
    width: 50%;
    color: $white-1;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-cnt {
        padding-top: 20px;

        .slick-slide {
            div {
                outline: none;
            }
        }
    }

    h1 {
        margin: 0 0 24px;
        color: $white-1;
    }

    .img-box {
        background: url('../../images/signPagesCarousel/bg-laptop01.png') no-repeat;
        background-size: 430px 284px;
        width: 430px;
        height: 284px;
        margin: 0 auto;
        padding: 20px 0 0;

        img {
            margin: 0 auto;
            width: 368px;
            height: 230px;
        }
    }

    .text-box {
        padding: 36px 0 65px;
    }

    .text-label {
        margin: -82px auto 30px;
        border-radius: 7px;
        background: $white-1;
        width: 190px;
        font: 24px/52px $font-roboto-bold;
        color: $orange-1;
        position: relative;
    }

    h2 {
        margin: 0 0 16px;
        color: $white-1;
        text-transform: none;
        /* new label
        sup {
            display: inline-block;
            text-align: center;
            border-radius: $app-brd-radius;
            background: $white-1;
            text-transform: uppercase;
            color: $orange-1;
            font: 12px/24px $font-roboto-bold;
            width: 48px;
            margin: 0 10px 0 -48px;
            vertical-align: top;
            position: relative;
            top: -25px;

            &:after {
                content: '';
                position: absolute;
                bottom: -4px;
                right: 0;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 0 7px 6px 0;
                border-color: transparent $white transparent transparent;
            }
        }
         */
    }

    ul {
        line-height: 1.4;
        margin: 0;
    }

    .slick-slider {
        &:hover {
            .slick-arrow {
                visibility: visible;
            }
        }

        .slick-list {
            margin: 0 auto;
            width: 430px;
        }

        .slick-arrow {
            top: 128px;
            transition: none;
            width: 32px;
            height: 32px;
            visibility: hidden;

            &:before {
                display: none;
            }

            &.slick-prev {
                left: 104px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0H32V32H0z'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M5.29 25L16 13.874 26.71 25 30 21.575 16 7 2 21.575z' transform='matrix(0 1 1 0 0 0)'/%3E%3C/g%3E%3C/svg%3E%0A");
            }

            &.slick-next {
                right: 104px;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cpath d='M0 0H32V32H0z'/%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M5.29 25L16 13.874 26.71 25 30 21.575 16 7 2 21.575z' transform='rotate(90 16 16)'/%3E%3C/g%3E%3C/svg%3E%0A");
            }
        }

        .slick-dots {
            bottom: 14px;
            margin-top: 11px;

            li {
                margin: 0 2px;

                button {
                    &:before {
                        background: $white-1;
                        opacity: 0.5;
                    }
                }

                &.slick-active {
                    button {
                        &:before {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1400px) {
        & {
            width: 630px;

            .slick-slider {
                .slick-arrow {
                    &.slick-prev {
                        left: 30px;
                    }

                    &.slick-next {
                        right: 30px;
                    }
                }
            }
        }
    }

    @media only screen and (max-width: 1279px) {
        & {
            display: none;
        }
    }

    @media only screen and (min-width:1500px) and (max-width: 1920px) {

        .img-box {
            background-size: 500px 330px;
            width: 500px;
            height: 330px;

            img {
                width: 428px;
                height: 267px;
            }
        }

        .slick-slider {
            .slick-list {
                width: 500px;
            }

            .slick-arrow {
                top: 149px;

                &.slick-prev {
                    left: 96px;
                }

                &.slick-next {
                    right: 96px;
                }
            }
        }
    }

    @media only screen and (min-width:1921px) and (max-width: 2560px) {
        .img-box {
            background-size: 600px 397px;
            width: 600px;
            height: 397px;

            img {
                width: 514px;
                height: 321px;
            }
        }

        .slick-slider {
            .slick-list {
                width: 600px;
            }

            .slick-arrow {
                top: 185px;
            }
        }
    }
}
