.container-news {
    .sub-header {
        .sub-header-row > .tabs {
            margin: 0;
        }

        .tabs-item {
            margin-right: 16px;

            &-link {
                font-size: 18px;
                padding: 5px 0;

                &:hover {
                    background-color: transparent;
                }
            }
        }
    }

    .tabs-data {
        padding-top: 16px;
    }

    .event-row-loader {
        margin: -24px -24px 0;
        position: relative;
        min-height: 244px;
    }

    .event-row-wrap {
        margin: 0 auto;
        width: 100%;
        max-width: 1280px;
    }

    .event-row {
        display: flex;
        margin: 0 -8px 16px;
        position: relative;
    }

    .event-item {
        min-width: 0;
        flex: 1 1 0;
        padding: 0 8px;
    }

    .event-box {
        border: 1px solid $border-color;
        border-radius: $app-brd-radius;
        overflow: hidden;
        display: block;
        color: $text-color;

        &:hover {
            text-decoration: none;
        }
    }

    .event-image {
        width: 100%;
        height: 140px;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: flex-start;

        &-center {
            align-items: center;
        }

        img {
            width: 100%;
            height: auto;
        }

        .event-image-fallback {
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $grey-3;
            border-radius: $app-brd-radius;

            .icon {
                transform: scale(1.4);
            }
        }

        &-wrap {
            height: 200px;
            
            .event-image-fallback {
                flex-direction: column;

                p {
                    font-size: 16px;
                    font-family: $font-roboto-medium;
                    margin: 16px 0 0;
                }
            }
        }
    }

    .event-content {
        padding: 8px;
        border-top: 1px solid $border-color;
    }

    .event-title {
        display: flex;
        align-items: center;
        margin-bottom: 4px;

        .icon-svg {
            margin-right: 8px;
        }
    }

    .event-info {
        min-width: 20px;
        display: flex;
        margin-bottom: 0;
        white-space: nowrap;
        font-size: 12px;
    }

    .event-name {
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .event-link {
        margin-left: 8px;
        display: flex;
        align-items: center;

        .btn-link {
            text-transform: lowercase;
            display: inline;
            line-height: 1.4;
    
            &:first-letter {
                text-transform: uppercase;
            }
        }
    }

    .tabs-container {
        border-top: 1px solid $border-color;
        border-bottom: 1px solid $border-color;
        background-color: $white-1;
        display: flex;
        margin: 0 -24px;
        flex: none;
        position: sticky;
        top: -18px;
        z-index: 3;
    }

    .tabs-news {
        border-bottom: none;
        width: 1330px;
        max-width: 100%;
        margin: 0 auto;
        padding: 0 24px;
    }

    .news-wrap {
        display: flex;
        width: 1280px;
        max-width: 100%;
        margin: 0 auto -24px;
    }

    .news-col-left {
        width: calc(100% - 416px + 24px);
        border-right: 1px solid $border-color;
        padding-right: 16px;
        padding-top: 16px;
        position: relative;
        margin-left: -24px;
        padding-left: 24px;

        .container-loader {
            figure {
                position: absolute;
                top: calc(50vh - 250px);
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .news-col-right {
        width: 416px;

        &.dashboard {
            padding: 16px 0 0 16px;
            background-color: transparent;

            .daily-stats {
                min-height: 388px;

                .data-list-container {
                    min-width: 530px;

                    .data-list-cell {
                        margin-right: 8px;
                    }
                }
            }
        }

        .dashboard-row {
            margin-left: 0;
            display: block;

            &:last-child {
                padding-bottom: 0;
            }

            .dashboard-cnt-box {
                @include flex-basis(100%);
                margin: 0 0 16px;
                box-shadow: none;
                border: 1px solid $border-color;
                border-radius: $app-brd-radius;
                overflow: hidden;

                &.double-size {
                    @include flex-basis(100%);
                }

                &.im-chart {
                    .js-plotly-plot {
                        width: calc(100% + 20px);
                        margin-left: -20px;
                    }
                }
            }
        }
        

        .data-item-head {
            display: flex;
        }
    }

    .news-controls-edit {
        padding-left: 16px;
    }

    .news-wrapper {
        width: 800px;
        margin: 0 auto;
        padding-top: 8px;

        .breadcrumbs-list {
            margin-bottom: 8px;
        }

        .news-title {
            margin: 16px 0;
        }

        .news-content {
            padding: 32px 0;

            td {
                border: 1px solid $border-color;
            }
        }

        .documents-wrap {
            margin-top: -8px;
            padding-bottom: 24px;
        }

        .document-item {
            margin-bottom: 8px;

            .loader-box {
                padding: 4px 0;
                background: none;
            }
        }

        ul {
            margin-bottom: 24px;
        }
    }

    .news-bottom-controls {
        justify-content: space-between;

        &-item {
            .btn-link {
                margin-bottom: 8px;
            }
        }

        &-item-left {
            .btn-link {
                .icon-svg {
                    transform: rotate(180deg);
                }
            }
        }

        &-item-right {
            .btn-link {
                .icon-svg {
                    margin-right: 0;
                    margin-left: 4px;
                }
            }
        }

        &-description {
            max-width: 350px;
        }
    }

    .sub-header-news-info {
        .sub-header-row {
            padding-top: 24px;
            padding-bottom: 24px;
        }

        .breadcrumbs-list {
            position: static;
        }
    }
}

.news-provider-clo {
    display: flex;
    align-items: center;

    &-img {
        min-width: 24px;
        max-width: 24px;
        height: 24px;
        border-radius: $app-brd-radius;
        background-color: #000F2E;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 8px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    &-text {
        font-size: 12px;
        font-family: $font-roboto-medium;
    }

    .news-provider-london-stock-exchange-group {
        width: 92px;
        height: 24px;
        margin-right: 8px;

        img {
            max-width: 100%;
            max-height: 100%;
        }
    }
}

.news-item {
    padding-bottom: 24px;
    margin-bottom: 24px;
    border-bottom: 1px solid $border-color;

    &:last-child {
        border-bottom: none;
        padding-bottom: 0;
    }

    &-title {
        margin-bottom: 12px;

        .btn-link {
            font-size: 16px;
            line-height: 1.4;

            &.disabled {
                color: $text-color;
            }
        }
    }

    &-description {
        margin-bottom: 8px;

        .news-description {
            margin-right: 4px;
        }
    }

    &-read-more {
        white-space: nowrap;
        font-size: 14px;
    }

    &-time {
        color: $grey-8;
    }

    &-additional-item {
        border-right: 1px solid $border-color;
        padding-right: 12px;
        margin-right: 12px;
        font-size: 12px;
        height: 24px;
        display: flex;
        align-items: center;

        .btn-link {
            color: $link-color;
            font-family:$font-roboto-regular;

            &:hover {
                color: $link-hover;
                text-decoration: underline;
            }
        }

        &:last-child {
            border-right: none;
        }
    }

    .btn-download-attachment {
        padding: 0 4px;
        background-color: $grey-7;
        border: none;
        font-family: $font-roboto-regular;
        font-size: 12px;

        .icon-svg {
            margin-right: 2px;

            &:last-child {
                margin-left: 0;
            }
        }
    }

    .placeholder-read-more {
        margin-top: 8px;
    }
}

.news-controls {
    margin-bottom: 8px;

    &-item {
        margin-right: 8px;

        &-edit {
            margin-right: 16px;
        }

        .news-label {
            color: $secondary-1;
            font-family: $font-roboto-medium;
        }

        .btn-link {
            .icon-svg {
                margin-right: 0;
            }
        }
    }
}