﻿.my-inventory {
    .sub-header {
        position: relative;

        &:not(.sub-header-summary) {
            z-index: 3;
        }

        .flex-item-right {
            &.controls {
                .tooltip-wrapper {
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }

            .btn-link-edit {
                margin-right: 0;
            }
        }
    }

    .sub-header-row {
        &.type01 {
            .modal-select-dealer {
                .control-search-wrapper {
                    width: 100%;
                }
            }
        }
    }

    .grid:not(.grid-files) {
        min-width: 2800px;
    }
}

.inventory-cnt {
    .data-list {
        min-width: 1px;

        .pseudo-link {
            width: auto;
            max-width: 100%;
            white-space: nowrap;
        }

        .header-sticky-col.data-column-sticky-right {
            .data-list-cell-icon {
                @include flex-basis(14px);
            }
        }
    }

    .data-list-content {
        .data-list-cell-note {
            position: relative;
            min-height: 32px;
            line-height: 31px;

            .note-text {
                position: absolute;
                top: 50%;
                right: 0;
                left: 8px;
                transform: translateY(-50%);
            }
        }
    }
}
