.markdown-editor {
    border: 1px solid $grey-2;
    border-radius: 3px;

    &-head {
        border-bottom: 1px solid $grey-5;
        box-shadow: 0 2px 3px rgba($tertiary-1, .25);
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        border-radius: 3px 3px 0 0;
        padding: 6px 12px 6px 24px;
    }

    &-controls {
        display: flex;
        align-items: center;
    }

    &-view {
        padding: 16px;

        ul {
            list-style: disc;
            padding-left: 20px;

            li {
                padding-left: 2px;
            }
        }

        ol {
            padding-left: 17px;

            li {
                padding-left: 5px;
            }
        }
    }

    .form-control {
        border: none;
        line-height: 1.4;
        padding-right: 8px;
    }

    .divider {
        width: 1px;
        height: 16px;
        background-color: $grey-5;
        margin-right: 12px;
    }

    .btn-link {
        width: 24px;
        height: 24px;
        margin-right: 7px;

        .icon {
            margin-right: 0;
        }
    }

    .component-dropdown-ghost {
        margin-right: 22px;

        .component-dropdown-toggle {
            font-family: $font-roboto-regular;
            font-size: 12px;
            padding: 0;
            height: 24px;
        }
    }
}
