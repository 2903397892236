.container-banks {
    .sub-header {
        h1 {
            line-height: 1.35;
        }

        .action-item {
            line-height: 1;
        }

        .vertical-divider,
        .btn {
            margin-left: 8px;
        }

        .btn-link {
            white-space: nowrap;
        }

        .btn-link-manager-presentation {
            margin-left: 24px;
        }

        .btn-ghost:not(:last-child) {
            margin-right: 8px;
        }

        .bwic-state-my {
            margin-right: 0;
        }

        .btn-schedule-meeting {
            &.btn-ghost {
                margin-right: 0;
            }

            .icon-svg {
                margin-right: 5px;
                margin-left: 0;
            }
        }

        .limit-blocker {
            margin-left: 24px;
            line-height: 16px;

            .btn-link-manager-presentation {
                margin-left: 0;
            }
        }

        .sidebar-controls-item-label,
        .sidebar-controls-item {
            margin-right: 8px;
        }

        .vertical-divider + .limit-blocker {
            margin-left: 0;
        }
    }

    .sidebar-controls-item-active-deals {
        color: $secondary-1;
        font-size: 10px;
        font-family: $font-roboto-bold;
        display: flex;

        .tooltip-wrapper {
            display: inline-flex;
            align-items: center;
        }
    }

    .managers-transaction-count {
        .data-item-head {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .loader-box {
                background-color: transparent;
                flex: none;
            }
        }

        .data-item-title {
            margin-right: 24px;
            display: flex;
            align-items: center;

            .filters-area {
                margin-left: 24px;
            }
        }

        .limit-blocker .tooltip-wrapper {
            display: inline-flex;
        }

        .year-selector .btn-link {
            line-height: 24px;
        }
    }

    .arranger-pipeline,
    .deals {
        .data-item-content {
            min-height: 56px;
        }

        .data-item-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .data-list-cell {
            .loader-box {
                background: none;
            }
        }
    }

    .arranger-pipeline,
    .managers-transaction-count {
        .data-list-content {
            max-height: 320px;
        }
    }

    .deals {
        .data-list-content {
            max-height: 337px;
        }
    }

    .content-item-wrap {
        margin-bottom: 24px;
    }

    .section-banks-users {
        .grid {
            min-width: 1492px;

            tbody {
                max-height: 330px;
            }

            td {
                text-transform: none;
            }
        }
    }

    .data-item-bank-event .data-item-head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .btn.btn-sm .icon-svg {
            margin: 0 4px 0 0;
        }
    }

    .events-item {
        padding-bottom: 16px;
        margin-bottom: 16px;
        border-bottom: 1px solid $border-color;

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }

        .label-status-draft {
            margin-bottom: 8px;
        }

        ul {
            list-style: inherit;
            padding-left: .94rem;
        }
    }

    .row-event-controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 16px 0 24px;

        .checkbox {
            margin-right: 24px;
        }
    }

    .add-event-wrap {
        display: flex;
        justify-content: center;
        position: relative;

        &:before,
        &:after {
            position: absolute;
            content: '';
            width: calc(50% - 52px);
            height: 1px;
            background-color: $border-color;
            top: 50%;
            transform: translateY(-50%);
        }

        &:before {
            left: 0;
        }

        &:after {
            right: 0;
        }

        .btn.btn-sm .icon-svg {
            margin-right: 4px;
            margin-left: 0;
        }
    }

    .primary-desk-empty {
        margin-bottom: 24px;
    }

    .restricted-placeholder-inline:not(.empty-placeholder) {
        padding-top: 0;
    }

    .dashboard {
        background-color: transparent;
        padding: 0;
        border: 1px solid $border-color;
        border-radius: $app-brd-radius;

        .dashboard-row:last-child {
            padding-bottom: 0;
        }

        .dashboard-row .dashboard-cnt-box {
            margin-top: 0;
        }
    }

    .data-list-primary-desk {
        margin-bottom: 24px;
    }

    .data-item-events {
        td {
            border: 1px solid $border-color;
        }
    }
}

.sidebar-nav-banks {
    .sidebar-nav-header .control-search-wrapper .form-control-search-btn {
        margin-left: 14px;
    }

    .sidebar-nav-list-item {
        padding-top: 6px;
        padding-bottom: 6px;
        display: block;

        &.sidebar-nav-list-item-extended {
            padding-right: 12px;

            .flex-row:first-child {
                margin-bottom: 4px;
            }

            .title {
                max-width: 300px;
                width: auto;
                overflow: hidden;
                display: block;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .title-with-label {
                max-width: 275px;
            }

            .bwic-state-my {
                margin-left: 8px;
            }
        }
    }
}