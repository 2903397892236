.component-tabs-analytics {
    .data-item-analytics {
        margin-bottom: 32px;

        &.data-item-general-cols {
            .data-item-col:last-child {
                font-family: $font-roboto-medium;
            }

            .data-item-row-col {
                max-width: 100%;
            }

            .data-item-row + .analytics-legend {
                margin-top: 8px;
            }
        }

        .viewed-tabs-item {
            width: 100%;
            margin-bottom: 4px;

            &:last-child {
                margin-bottom: 8px;
            }

            .legend-charts {
                margin-top: 6px;
            }

            .analytics-legend {
                margin-top: 16px;
            }
        }
    }

    .proportion-bar {
        padding: 8px 0;

        &-chart {
            display: flex;
            background-color: $grey-3;
        }

        &-progress {
            height: 4px;
        }
    }

    .percentage-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        line-height: 1.15;
        max-width: 420px;
        margin-bottom: 8px;

        &-label {
            width: 120px;
        }

        &-chart {
            width: 220px;
            height: 16px;
            background-color: $grey-3;
            margin: 0 8px;
        }

        &-progress {
            height: 100%;
            background-color: $blue-10;
            position: relative;

            .tooltip-wrapper {
                position: absolute;
                width: 100%;
                height: 100%;
            }
        }

        &-stat {
            width: 74px;
            text-align: right;
        }
    }

    .analytics-legend {
        padding: 16px 12px 16px 16px;
        text-align: left;
        justify-content: flex-start;
        
        .required-feature {
            justify-content: flex-start;
        }

        h3 {
            margin-bottom: 0;
        }

        .icon-svg {
            min-width: 24px;
        }
    }

    .downloaded-documents-type {
        .analytics-legend {
            margin-top: 6px;
        }
    }

    .restricted-placeholder-general {
        padding-top: 0;
    }
}
