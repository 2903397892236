﻿.container {
    min-width: 1280px;
}

.definition-list {
    font: 14px/16px $font-roboto-regular;
    margin: 0;
    display: flex;
    flex-flow: row wrap;
    width: 100%;

    dt {
        flex-basis: 180px;
        margin: 0 0 15px;
    }

    dd {
        margin: 0 0 15px;
        flex-basis: calc(100% - 180px);
        flex-grow: 1;
    }

    &.style01 {
        dt, dd {
            flex-basis: 100%;
        }

        dt {
            margin: 0;
            font-size: 12px;
        }

        dd {
            margin-bottom: 20px;
        }
    }
}

.name-tag {
    display: inline-block;
    color: $grey-4;
    background: $grey-3;
    border-radius: $app-brd-radius;
    white-space: nowrap;
    padding: 0 8px;
    font: 12px/24px $font-roboto-medium;
    margin-left: 8px;
    vertical-align: middle;
    width: auto;
}

.btn-link-bloomberg {
    .icon-svg {
        margin-right: 0;
    }

    &[disabled] {
        .icon-svg {
            rect {
                fill: $button-disabled;
            }
        }
    }
}

.btn-link-valitana {
    &[disabled] {
        .icon-svg {
            path {
                fill: $button-disabled;
            }
        }
    }
}

/* delete after */
.container-test {
    padding: 2rem;
    flex: auto;
    overflow: auto;

    .form-row-inline {
        align-items: baseline;
    }

    .modal:before {
        position: absolute;
    }

    .modal-content {
        top: 0;
        transform: none;
    }
}


/* end delete after */
