.email-chips-list-wrap {
    border: solid 1px $border-color;
    border-radius: $app-brd-radius;

    &.email-chips-list-with-clear {
        position: relative;
        padding-right: 32px;

        .email-chips-list {
            padding: 12px 15px;
        }

        .email-chips-list-clear {
            position: absolute;
            right: 0;
            top: 0;
            width: 32px;
            height: 100%;
            border-left: 1px solid $border-color;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            padding: 16px 0;
            margin-left: auto;

            .btn-link .icon-svg {
                margin-right: 0;
            }
        }

        .invalid-email {
            position: relative;
            padding-top: 3px;
            padding-bottom: 3px;

            &-hidden {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                visibility: hidden;
                font-size: 12px;
            }

            &-input {
                font-family: $font-family-base;
                font-size: 12px;
                height: 20px;
                border: none;
                border-bottom: 1px dashed $warning-3;
                padding: 0;
            }
        }
    }
}

.email-chips-list {
    width: 100%;
    height: 120px;
    overflow-y: auto;
    padding: 4px 8px;

    & > div {
        float: left;
        height: 26px;
        padding-left: 0;
        padding-right: 8px;
    }

    .email-chips-list-input {
        position: relative;
        max-width: 100%;

        & > div, textarea {
            position: absolute;
            top: 0;
            bottom: 0;
            overflow: hidden;
            max-width: 100%;
            white-space: nowrap;
            resize: none;
        }

        &-bg {
            right: 4px;
            left: 0;
            background: $warning-1;
            border-radius: $app-brd-radius;
        }

        &-field {
            font: 14px/24px $font-roboto-regular;
            border: none;
            background: transparent;
            width: 100%;
            padding: 0;
            z-index: 1;
        }
    }
}
