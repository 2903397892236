﻿.banner {
    background-color: $blue-2;
    padding: 8px 16px;
    min-height: 32px;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 16px;

    .icon-svg-info {
        flex: none;
        color: $blue-1;
        margin-right: 8px;
    }

    .btn-close {
        padding: 0;
        margin-left: 8px;
    }
}

.request-direct-bidding-banner {
    padding: 4px 16px;
    border-bottom: 1px solid $border-color;

    &.suspended {
        background: $warning-5;

        .icon-svg-info {
            color: $warning-3;
        }
    }

    .links {
        display: flex;
        align-items: center;

        a, .btn {
            margin: 0 8px;
        }
    }
}

.trial-period-banner {
    &.last-three {
        background-color: $yellow-4;

        & > .icon {
            color: $yellow-1;
        }
    }

    &.last-day {
        background-color: $warning-5;

        & > .icon-svg {
            color: $secondary-1;
        }
    }

    .btn-link {
        font-size: inherit;
        vertical-align: top;
    }
}


.fixed-banner {
    position: absolute;
    right: 0;
    bottom: 32px;
    left: 0;
    z-index: 22;
    align-items: flex-start;
    padding: 16px 24px;
    box-shadow: 4px 0 7px rgba($grey-4, 0.25);

    &.trial-enabled {
        background-color: $primary-15;
    }

    &.trial-expired {
        background-color: $warning-5;
    }

    &-icon {
        margin-right: 8px;
    }

    &-content {
        flex-grow: 1;

        .normal {
            line-height: 24px;
        }

        p {
            margin: 0;
            line-height: 1.4;
        }
    }

    &-control {
        .btn-link {
            margin-right: 16px;
        }
    }
}