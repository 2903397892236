﻿.settlement-accounts-empty {
    .empty-placeholder {
        width: auto;
        max-width: 100%;
    }
}

.settlement-accounts {
    .form-control-autocomplete {
        width: 100%;
    }

    .form-error {
        top: 100%;
    }

    .custom-drop-down {
        .section {
            padding: 0;
        }

        .drop-down-list {
            width: 100%;

            &-item {
                &:last-child {
                    border-top: 1px solid $border-color;
                }
            }

            .icon-svg-plus {
                margin: -1px 4px 0 0;
            }
        }
    }

    .form-row-inline {
        justify-content: space-between;
        margin: 0;
    }

    .form-label {
        display: inline-block;
        width: auto;
        min-width: 160px;
        color: $grey-8;
        font-size: 14px;
        line-height: 32px;
    }

    .form-control {
        display: inline-block;
    }

    .form-cell {
        margin: 0 0 24px;
        flex: 0 0 calc(50% - 16px);

        &.full {
            flex: 0 0 100%;

            .form-control {
                margin-right: 0;
            }
        }

        .form-control-wrapper,
        .outside-wrapper {
            display: inline-flex;
            width: 100%;

            .form-control.is-invalid {
                padding-right: 32px;
            }
        }

        .custom-drop-down {
            margin: 0;
        }

        .form-control {
            margin-right: 24px;
        }

        .icon-delete {
            right: 0;
            top: 8px;
            cursor: pointer;
        }
    }

    .original-account {
        em {
            color: $placeholder-color;
            font-style: normal;
            padding-left: 8px;
        }
    }

    .sub-header-row {
        h1 {
            line-height: 32px;
        }

        .name-tag {
            margin-left: 8px;
        }

        .form-select {
            width: 380px;
            line-height: 30px;
        }
    }

    &-sidebar {
        overflow: auto;
        flex: 0 0 360px;
        border-right: 1px solid $border-color;
    }

    &-list {
        li {
            line-height: 20px;
            padding: 6px 24px;
            border-bottom: 1px solid $border-color;

            &:not(.control):hover,
            &:not(.control).active {
                cursor: pointer;
                background: $row-hover;
            }

            &:not(.control).active {
                background: $primary-6;
            }
        }

        .control {
            border: 0;
            padding: 16px 24px;
        }
    }

    &-content,
    &-form {
        display: flex;
        flex-direction: column;
        width: 100%;
        min-width: 0;
    }

    &-sub-title {
        border-bottom: 1px solid $border-color;
        padding: 13px 24px;

        h1 {
            margin: 0;
        }

        .controls {
            .limit-blocker {
                display: inline-block;
            }

            .btn {
                margin-left: 8px;
            }

            .btn-link {
                margin-right: 16px;
            }
        }
    }

    &-sections {
        overflow: auto;
        flex-grow: 1;

        &-center {
            width: 855px;
            margin: 0 auto;
            padding: 0 24px;
            box-sizing: content-box;
        }

        &-list {
            min-width: 780px;
        }

        .section {
            padding: 24px 0 0;

            &-title {
                border-bottom: 1px solid $border-color;
                padding: 0 0 8px;
                display: flex;

                h2 {
                    margin-bottom: 0;
                    display: inline-block;
                }

                .btn-danger {
                    margin-left: 16px;
                }

                .download-all {

                    .loader-box,
                    .btn-link {
                        height: 22px;
                        line-height: 22px;
                    }
                }
            }
        }

        .acc-section {
            padding: 16px 0;

            .form-control-wrapper {
                display: inline-flex;
                width: 100%;
            }

            .form-cell {
                margin-top: 24px;
            }
        }

        .custom-field {
            background: $grey-7;
            padding: 16px 24px;
            margin-bottom: 16px;

            .form-item:not(:last-child) {
                margin-right: 16px;
            }

            .custom-field-name {
                width: 25%;
            }

            .custom-field-value {
                width: 65%;
            }

            .btn-danger {
                margin-top: 28px;
            }

            & + .btn-link {
                margin-bottom: 24px;
            }
        }

        .main-fields {
            padding-top: 22px;

            .definition-list {
                width: 300px;
                margin: 0 32px 2px 0;

                &:nth-child(2) {
                    width: 490px;

                    dd {
                        flex-basis: 330px;
                    }
                }

                dt, dd {
                    margin-bottom: 12px;
                    line-height: 20px;
                }

                dt {
                    flex-basis: 160px;
                }

                dd {
                    flex-basis: 140px;
                }
            }
        }

        .custom-fields {
            padding: 16px 0 0;
            border-top: 1px solid $border-color;

            &.no-brd {
                border: 0;
                padding-top: 0;
            }

            .definition-list {
                dt, dd {
                    margin-bottom: 12px;
                    line-height: 20px;
                }

                dt {
                    flex-basis: 332px;
                }
            }
        }

        .attn-section {
            margin: 8px 0 32px;

            h4 {
                margin-top: 8px;
            }

            .data-list-content {
                overflow-y: auto;
            }

            .custom-field-full-name,
            .custom-field-email {
                width: 28%;
            }

            .custom-field-phone {
                width: 10%;
            }
        }

        .address-section {
            .main-fields {
                padding: 0 0 8px;

                .definition-list {
                    width: 850px;

                    dd {
                        flex-basis: 660px;
                    }
                }
            }

            .add-address {
                .form-cell {
                    .form-control {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .section-btn {
        position: relative;
        text-align: center;
        margin: 16px 0 40px;

        &:after {
            content: "";
            position: absolute;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            width: 100%;
            height: 1px;
            background: $border-color;
        }

        .btn-ghost.btn-sm {
            position: relative;
            z-index: 1;
            background: $white-1;
            box-shadow: 0 0 0 16px $white-1;

            .icon-svg {
                margin: 0 4px 0 0;
            }
        }
    }
}

    .template-label {
        background: $yellow-5;
        border-radius: $app-brd-radius;
        font: 12px/16px $font-roboto-medium;
        padding: 4px 8px;
        color: $brown-1;

        &.active-template {
            background: $primary-8;
            color: $grey-4;
        }
    }

.allocation-templates {
    display: flex;
    flex-direction: column;
    min-height: 1px;
    padding: 8px 16px 12px;

    .template-title {
        margin-bottom: 14px;
    }

    .template-type {
        flex-direction: row;
        margin-bottom: 6px;

        .radio {
            margin-right: 16px;
        }
    }

    .trade-allocation {
        position: relative;
        display: flex;
        min-height: 1px;
        margin-right: -16px;
        margin-left: -16px;
        border-top: 1px solid $border-color;

        .grid-table-wrapper {
            .grid {
                min-width: 405px;

                tbody {
                    height: 661px;
                    overflow-y: auto;
                }

                .cell-settlementAccountId,
                .cell-rating {
                    text-align: left;
                }

                .cell-percent {
                    .form-error {
                        @extend .form-error-right;
                        margin-right: -1px;
                    }
                }

                tfoot {
                    .cell-no-data {
                        &:first-child {
                            display: none;
                        }

                        &.cell-total {
                            @include flex-basis(64px);
                            padding-right: 10px;
                        }
                    }
                }
            }
        }

        &.trade-allocation-rating {
            .grid-table-wrapper {
                .grid {
                    tbody {
                        height: 298px;
                    }
                }
            }
        }

        .status-message {
            position: absolute;
            right: 16px;
            bottom: 50px;
            left: 16px;
            width: auto;
        }
    }

    .trade-allocation-buttons {
        padding-top: 12px;

        .btn {
            margin-left: 8px;
        }
    }
}

    .templates-list {
        overflow: auto;
        flex-grow: 1;

        &-item {
            padding: 14px 16px 7px;
            border-bottom: 1px solid $border-color;

            &.templates-list-item-active {
                background-color: $primary-6;
            }
        }

        &-item-header {
            margin-bottom: 4px;

            h4 {
                margin-bottom: 0;
            }

            .context-menu {
                margin-left: 8px;
                line-height: 1;
            }
        }

        &-item-type {
            margin-bottom: 8px;
        }

        &-item-row {
            padding-top: 10px;
            padding-bottom: 10px;
            line-height: 1;

            .text-ellipsis {
                margin-right: 16px;
                white-space: nowrap;
            }
        }
    }
