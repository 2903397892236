﻿.bid-list {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 1px;
    
    &-content {
        .bid-list-row {
            &:first-child {
                .bid-list-divider {
                    border-top: 1px solid $border-color;
                }
            }
        }
    }

    &-columns-name,
    &-row {
        min-width: 0;
        padding: 4px 16px;
    }

    &-columns-name {
        flex: none;
        background-color: $table-header-bg;

        .bid-list-cell {
            padding: 0;

            .icon {
                vertical-align: top;
            }
        }
    }

    &-controls {
        flex: none;
        padding: 7px 16px;
        border-bottom: 1px solid $border-color;

        h3 {
            margin-bottom: 0;
            line-height: 1.2;
        }
    }

    &-toggle {
        position: relative;
        margin-left: auto;

        .popover {
            font-size: 14px;
            right: 0;
            left: auto;
            width: 310px;
            margin-top: 4px;
            padding-top: 10px;
            padding-bottom: 20px;
        }
    }

    &-content {
        flex: 1 1 auto;
        overflow: auto;
        background-color: $white-1;

        .bid-list-rank {
            align-self: start;
            padding-top: 5px;
            padding-bottom: 5px;
        }
    }

    &-row {
        flex: none;
        border-bottom: 1px solid $border-color;

        .bid-list-feedback {
            padding: 0;

            .options-list {
                display: inline-block;
            }
        }

        &.active {
            margin-top: -1px;
            border: 1px solid $blue-1;
            background-color: $row-active;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    &-cell {
        flex: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.2;
    }

    &-rank {
        width: 42px;
    }

    &-dealer {
        overflow: hidden;
        width: 53px;
    }

    &-divider {
        font-family: $font-roboto-medium;
        flex-grow: 1;
        margin: -4px -16px;
        padding: 4px 16px;
        background-color: $grey-7;
        line-height: 1.4;
    }

    &-wrap {
        flex-grow: 1;

        .flex-row {
            padding-top: 4px;
            padding-bottom: 4px;
        }
    }

    &-bid {
        overflow: hidden;
        width: 55px;
        text-align: right;
    }

    &-axed,
    &-final {
        width: 16px;

        .icon {
            vertical-align: top;
        }
    }

    &-axed {
        margin-left: 35px;
    }

    &-final {
        margin-left: 8px;
    }

    &-bid-on-behalf,
    &-bid-themself {
        width: 32px;
        text-align: center;
    }

    &-feedback {
        width: 16px;

        .context-menu-content {
            padding: 0;

            .icon-svg-chat {
                vertical-align: top;
            }
        }

        .context-menu-dropdown {
            margin-top: 2px;

            &.show-above {
                top: 100%;
                bottom: auto;
            }
        }
    }
}
