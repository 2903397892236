﻿.kp-tooltip {
    color: $white-1;
    font: 12px/16px $font-family-base;
    max-width: 285px;
    width: max-content;
    z-index: 1070;
    display: inline-block;

    a {
        color: $primary-9;
    }

    &-hidden {
        display: none;
    }

    &-arrow,
    &-arrow-inner {
        display: none;
    }

    &.break-word .rc-tooltip-inner {
        word-break: break-word;
        -ms-word-break: break-all;
    }

    .tooltip-inner {
        padding: 6px;
        background-color: $grey-4;
        border-radius: $app-brd-radius;
        text-align: left;
        word-break: break-word;
        -ms-word-break: break-all;

        p {
            margin: 0;

            &:first-child:not(:last-child) {
                font-family: $font-roboto-medium;
            }

            & + p {
                margin-top: 4px;
            }
        }
    }
}

.tooltip {
    position: relative;
    display: inline-block;
    z-index: 1000;
}

.tooltip-wrapper {
    display: inline;

    .btn[disabled] {
        pointer-events: none;

        @media all and (-ms-high-contrast:none) {
            pointer-events: all;
        }
    }
}

/* custom content tooltips */
.rating-tooltip {
    max-width: 482px;
}

.px-talk-tooltip {
    max-width: 367px;

    h5 {
        margin: 0 3px 6px;
    }

    .rating-table {
        width: calc(100% - 3px);
        margin: 0 0 0 3px;

        tr {
            line-height: 14px;

            th, td {
                padding: 0 16px 2px 0;
                font-family: $font-roboto-regular;
                &:last-child {
                    padding-right: 0;
                }
            }

            th {
                white-space: nowrap;
                border-bottom: 0;

                &:first-child {
                    width: 116px;
                }
            }

            td {
                border: 0;
            }

            &:nth-child(odd) {
                th {
                    border-top: 1px solid $grey-8;
                    padding-top: 3px;
                }
            }
        }
    }
}

.linkedin-link-tooltip {
    overflow: hidden;
    max-width: 545px;
    max-height: 325px;
}

.rating-table {
    background: none;
    margin: 10px;
    width: auto;

    th {
        font-family: $font-roboto-medium;
        padding: 0 0 5px;
    }

    th,
    td {
        padding-right: 24px;
        border-bottom: 1px solid $grey-8;

        &:last-child {
            padding-right: 0;
        }
    }

    th {
        text-align: left;
    }

    td {
        white-space: nowrap;
        vertical-align: top;
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.blotter-tooltip-overlay {
    hr {
        border-color: $grey-8;
    }
}
