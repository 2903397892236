.data-item {
    margin-bottom: 32px;
    flex: none;

    &:last-child {
        margin-bottom: 0;
    }
}

.data-item-sub {
    margin-top: 20px;
}

.data-row {
    display: flex;
    flex-direction: row;
    flex: none;

    .data-item {
        flex: 1 1 50%;

        &:first-child {
            margin-right: 5%;
        }
    }
}

.data-item-row-col {
    flex: 1 1 50%;
    max-width: 50%;
    padding-right: 2rem;

    &-full {
        flex: 1 1 100%;
        max-width: 100%;
        padding-right: 0;
    }

    .data-item-row {
        flex: 0 0 100%;
        width: 100%;
    }

    &:last-child {
        padding-right: 0;
    }
}

.data-item-content {
    position: relative;
}

.data-item-head {
    padding-bottom: 10px;
    border-bottom: 1px solid $border-color;
    margin-bottom: 16px;

    &-without-title {
        padding-bottom: 0;
        margin-bottom: 0;
        border-bottom: none;
    }
}

.data-item-title {
    margin-bottom: 0;

    .tooltip-wrapper {
        margin-left: 4px;
    }
}

.data-item-group,
.data-item-row {
    display: flex;
    flex-direction: row;
}

.data-item-group {
    margin-bottom: 20px;
}

.data-item-row {
    margin-bottom: 8px;
    align-items: center;

    &.data-item-row-full-height {
        align-items: flex-start;

        .data-item-text {
            white-space: pre-wrap;
        }

        .data-item-col-title {
            align-self: flex-start;
            line-height: 1.4;
            min-height: 24px;
        }
    }

    &.data-item-row-title-sm {
        .data-item-col {
            width: calc(100% - 120px);
        }

        .data-item-col-title {
            flex: 0 0 120px;
        }

        .data-item-text {
            padding-left: 0;
        }
    }

    &.data-item-row-title-md {
        .data-item-col {
            width: calc(100% - 160px);
        }

        .data-item-col-title {
            flex: 0 0 160px;
        }

        .data-item-text {
            padding-left: 0;
        }
    }

    &.data-item-row-title-lg {
        .data-item-col {
            width: calc(100% - 280px);
        }

        .data-item-col-title {
            flex: 0 0 280px;
        }

        .data-item-text {
            padding-left: 0;
        }
    }

    .restricted-placeholder-inline:not(.empty-placeholder) {
        padding: 0;
    }

    .limit-blocker {
        display: inline-flex;
    }

    .text-ellipsis {
        white-space: nowrap;

        .data-item-col {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.data-item-row-half {
    flex: 0 0 50%;
}

.row-items-center {
    align-items: center;
}

.data-item-col {
    flex: 0 1 100%;
    width: calc(100% - 240px);
    padding-right: 10px;
    position: relative;

    &:last-child {
        padding-right: 0;
    }
}

.data-item-col-title {
    color: $grey-8;
    flex: 0 0 240px;
    width: 240px;
    align-self: center;

    .title-info-icon {
        color: $grey-4;
        cursor: pointer;
        margin: -2px 0 0 8px;
    }
}

.data-item-capitalize {
    text-transform: capitalize;
}

.data-item-text {
    line-height: 1.4;
    padding-left: .683rem
}

.data-item-block {
    background-color: $grey-7;
    width: 100%;
    padding: 20px 20px 8px;
    margin-bottom: 20px;
}

.data-item-empty {
    color: $grey-1;
    border-radius: 6px;
    background-color: $grey-7;
    padding: 9px 14px 8px;
}

.data-item-block-columns {
    display: flex;
    flex-direction: row;
    padding-top: 8px;
    padding-bottom: 13px;

    .data-item-row {
        flex-direction: column;
        align-items: stretch;
        flex: 0 0 36%;
        min-width: 36%;
        max-width: 36%;
        height: auto;
        margin-right: 10px;
        margin-bottom: 0;

        .data-item-col {
            width: auto;
        }

        &:last-child {
            min-width: 25%;
            max-width: 25%;
            margin-right: 0;
        }

        .data-item-text {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            padding-left: 0;
        }
    }

    .data-item-col-title {
        font-size: 12px;
        line-height: 33px;
        padding-right: 0;
        flex: none;
        align-self: flex-start;
    }

    .data-item-col {
        flex: none;
    }
}

.data-item-column-cnt {
    .data-item-row {
        flex-direction: column;
        height: auto;
        margin-bottom: 20px;
        align-items: stretch;
    }

    .data-item-col {
        flex: none;
        width: 100%;
    }

    .data-item-col-title {
        flex: none;
        flex-basis: auto;
        align-self: flex-start;
        line-height: 16px;
        padding-bottom: 7px;
    }
}

.table-output-data {
    font-size: 12px;
    line-height: 1.65;
    width: 100%;
    max-width: 100%;
    border-collapse: collapse;

    thead {
        height: 24px;

        tr {
            background-color: $grid-header-bg;
        }
    }

    tbody {
        tr {
            height: 32px;
            border-bottom: 1px solid $grid-borders;

            &:first-child {
                td {
                    padding-top: 3px;
                }
            }
        }
    }

    th, td {
        text-align: left;

        &:last-child {
            padding-right: 15px;
        }
    }
}
