.container-deals {
    .sidebar-nav-deals {
        .sidebar-nav-list-item.sidebar-nav-list-item-extended {
            &.expanded {
                background-color:$row-active;
            }

            .sidebar-nav-list-item-content {
                padding: 6px 0;
            }

            .icon-expand {
                margin-top: 3px;
            }

            .title {
                width: 260px;
            }
        }

        .component-subtree-item {
            &:hover {
                text-decoration: none;
            }

            &.active {
                .component-subtree-item-title {
                    color: $grey-4;
                }
            }

            &-content {
                padding-top: 4px;
                padding-bottom: 4px;
            }

            &-title {
                font-size: 12px;
                max-width: 100%;
            }
        }

        .sidebar-nav-header .control-search-wrapper .form-control-search-btn {
            margin-left: 18px;
        }
    }

    .sub-header-deals {
        .sub-header-row {
            & > .flex-row + .flex-row {
                margin-top: 8px;
            }

            .name-tag {
                margin-left: 0;
            }
        }

        h1 {
            margin-right: 8px;
            line-height: 24px;
        }

        .text-sm {
            line-height: 16px;
        }

        .component-dropdown-class {
            margin-left: 8px;

            .component-dropdown-toggle {
                background-color: $grey-7;
                border-radius: $app-brd-radius;
                height: 24px;
                font-size: 16px;
                font-family: $font-roboto-medium;
                padding-left: 8px;

                & > span {
                    padding-right: 6px;
                    border-right: 1px solid $white-1;
                }

                .icon-component-dropdown {
                    margin: 0 8px 0 6px;
                }
            }

            .component-dropdown-list {
                width: 288px;
                overflow: hidden;
    
                .data-list-header,
                .data-list-row {
                    padding-right: 0;
                }
    
                .data-list-row {
                    cursor: pointer;
    
                    &.selected {
                        background-color: $primary-6;
        
                        .data-list-cell:first-child {
                            font-family: $font-roboto-medium;
                        }
                    }
                }
    
                .data-list-content {
                    max-height: 374px;
                    overflow: auto;
    
                    &:after {
                        display: none;
                    }
                }
    
                .closing-type-cell {
                    color: $grey-8;
                }
            }
        }

        .btn {
            margin-left: 8px;
        }

        .btn-danger {
            margin-right: 8px;
        }
    }

    .content-part-sidebar {
        position: relative;
    }

    .btn-link-bloomberg {
        .icon-svg {
            margin-right: 4px;
        }
    }

    .data-item-row-wrap {
        display: flex;
        margin: 0 -12px 32px;
    }

    .content-col {
        width: 33.33%;
        padding: 0 12px;
    }

    .data-item-general-cols {
        margin-bottom: 24px;
    }

    .data-item-title {
        font-size: 14px;
    }

    .data-item-row.data-item-row-full-height:not(.data-item-row-details) .data-item-text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .data-item-row-details {
        .markdown-viewer {
            margin-bottom: 8px;
        }
    }

    .data-item-row {
        font-size: 12px;
        margin-bottom: 4px;

        &.data-item-row-title-md .data-item-col-title {
            flex: 0 0 148px;
        }

        &:nth-child(odd) {
            background-color: $grey-11;
        }

        .data-item-col {
            padding-top: 4px;
        }
    }

    .data-item-general-cols .data-item-content {
        margin: 0 -12px;
    }

    .data-item-row-col {
        padding: 0 12px;
    }

    .data-list-content {
        overflow: auto;

        .data-column-sticky-left .data-list-row.inactive {
            color: $grey-8;
        }
    }

    .tabs-data-all-classes-edit {
        .grid {
            min-width: 5125px;
    
            td {
                text-transform: none;
            }
        }
    }

    .tabs-data-amr-info-edit {
        .grid {
            min-width: 1465px;
    
            td {
                text-transform: none;
            }
        }
    }

    .tabs-data-deal-detail-edit,
    .tabs-data-amr-info-edit {
        .data-item-row {
            min-height: 24px;
            margin-bottom: 8px;

            &:nth-child(odd) {
                background-color: transparent;
            }

            .rdp-input {
                width: 100%;
            }

            &-checkbox {
                .data-item-col-title {
                    width: 100%;

                    .checkbox input[type="checkbox"]:not(:checked) + label.checkbox-lbl-text,
                    .checkbox input[type="checkbox"]:checked + label.checkbox-lbl-text {
                        padding-left: 0;

                        &:before,
                        &:after {
                            left: 148px;
                        }
                    }
                }
            }

            .data-item-col {
                padding-top: 0;
            }

            .form-control {
                height: 24px;
                font-size: 12px;
                line-height: 24px;
            }

            .component-dropdown-toggle {
                font-size: 12px;
            }
        }

        .markdown-editor textarea.form-control {
            height: 204px;
        }

        .grid-table-wrapper {
            .grid {
                tbody {
                    padding-bottom: 16px;
                }
            }
        }
    }

    .tabs-data-overview {
        .data-item-row-col-left {
            flex: 1 1 35%;
            max-width: 35%;
        }

        .data-item-row-col-right {
            flex: 1 1 65%;
            max-width: 65%;

            .data-item,
            .data-item-row-wrap {
                margin-bottom: 8px;
            }
        }
    }

    .security-information {
        flex-direction: row;

        &-chart {
            width: 50%;
            border: 1px solid $border-color;
            border-radius: $app-brd-radius;
            padding: 0 8px 16px 8px;
            margin: 0 12px 0 0;

            .chart-legend {
                margin-top: 8px;
            }

            .checkbox {
                margin-right: 20px;
            }
        }

        &-history {
            width: 50%;
            margin-left: 12px;

            & > .flex-row:first-child {
                margin: 0;
                padding-top: 0;
                border: none;
            }
        }

        &-table {
            margin: 0;

            .data-list-striped {
                max-height: 408px;
            }

            .data-list-header {
                position: sticky;
                top: 0;
                z-index: 2;
            }
        }
    }

    .data-list-deals {
        margin-top: -16px;

        .data-list-header .data-list-cell-action {
            padding-right: 22px;
        }

        .middle-sticky-content .component-collapsible-list-item-content {
            .data-list-row,
            .data-list-header {
                padding-left: 48px;
            }
        }

        .data-list-container {
            width: 100%;
        }

        & > .data-list-container > .data-list-content {
            overflow: scroll;
        }

        .data-list-content:after {
            display: none;
        }

        &.data-list-freeze.data-list-striped.data-list .data-list-content .data-list-columns {
            margin-bottom: 0;
            min-height: 100%;
        }

        .component-collapsible-list-item-content .data-list-header {
            overflow-y: hidden;
        }

        .data-list-cell-auto {
            width: 1060px;
        }
    }

    .show-historical-classes-checkbox {
        margin-bottom: 12px;
        font-family: $font-roboto-medium;
    }

    .document-group-item {
        .data-item-empty {
            font-size: 12px;
            line-height: 1.15;
            color: $grey-8;
            padding: 14px 24px;
        }

        .component-file-upload-list .data-list-header .data-list-cell:first-child {
            padding-left: 0;
        }

        .component-file-upload-list {
            .data-list-row,
            .data-list-header {
                padding-left: 24px;
            }

            .data-list:not(.data-list-freeze),
            .data-list-content {
                overflow: visible;
            }
        }

        .component-file-upload-placeholder {
            height: 80px;
        }

        .component-file-upload + .component-file-upload-list {
            border-top: none;
        }
    }

    .tabs-data-all-classes {
        padding-bottom: 0;
        
        .data-list-striped {
            flex-grow: 0;
        }
    }

    .tabs-data-documents-edit {
        .data-list-cell:not(:first-child) {
            overflow: visible;
        }

        .data-item-row {
            margin-bottom: 0;

            &:nth-child(odd) {
                background-color: transparent;
            }

            .data-item-col {
                padding-top: 0;
            }

            .data-item-col-title {
                .checkbox {
                    margin-top: 2px;
                }
            }
        }

        .data-list-cell {
            .data-item-row {
                margin-bottom: 0;
            }
        }

        .component-dropdown-toggle {
            font-size: 12px;
        }

        .rdp-input .rdp-overlay {
            left: auto;
            right: 0;
        }

        .documents-section {
            padding: 0;
        }

        .document-group-item-title {
            margin-bottom: 12px;
        }

        .component-file-upload-placeholder {
            margin-bottom: 12px;
        }

        .component-file-upload + .component-file-upload-list {
            padding-top: 0;
        }

        .data-item-documents {
            & > .data-item-row:not(.data-item-row-full-height) {
                margin-left: 24px;
                margin-bottom: 16px;
            }
        }

        .link-download-docs {
            margin-left: 24px;
        }
    }

    .amr-info-header {
        .breadcrumbs-list {
            padding: 11px 24px 0;
        }
    }

    .tabs-data-amr-info {
        .data-item-row-status {
            .data-item-col + .data-item-col {
                padding-top: 0;
            }
        }
    
        .data-list-cell-amr-classes-actions {
            .btn-link:first-child {
                margin-right: 12px;
            }
        }

        .data-column-sticky-left {
            z-index: 1;
        }

        .data-list-amr-classes .data-list-content {
            overflow-y: scroll;
        }

        .data-item-row-wrap {
            margin-bottom: 0;
        }

        .amr-info-settlement-instructions {
            .data-item-head {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }
        }

        .available-excluded-dealers-row {
            font-size: 12px;
            line-height: 1.16;
            padding: 5px 0;

            &:nth-child(odd) {
                background-color: $grey-11;
            }

            &-empty {
                background-color: $grey-7;
                color: $grey-8;
                padding: 5px 24px;
            }
        }

        .contacts-title {
            margin-top: -8px;
            
            & + .data-item-row-wrap {
                margin-bottom: 24px;
            }
        }
    }

    .tabs-data-documents {
        .document-group-item-title {
            position: sticky;
            z-index: 2;
            top: -25px;
            background-color: $white-1;
            padding: 8px 0;
            margin-top: -8px;
            margin-bottom: 16px;
        }

        .data-item-row:nth-child(odd) {
            background-color: transparent;
        }

        .data-item-documents {
            & > .data-item-row {
                margin-left: 24px;
                margin-bottom: 16px;
            }
        }
    }

    .drop-down-list .control-search-wrapper .form-control {
        height: 30px;
    }
}
