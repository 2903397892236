﻿.message-history {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    justify-content: flex-start;
    min-height: 1px;
    overflow: auto;
    padding: 16px;

    @media all and (-ms-high-contrast:none) {
        display: block;
    }

    &-placeholder {
        display: flex;
        flex-direction: column;
        width: 100%;
        flex: 1 1 auto;
        justify-content: center;
        align-items: center;

        p {
            color: $grey-8;
            font-size: 20px;
            text-align: center;
            max-width: 220px;
            margin-top: 30px;
            margin-bottom: 0;
        }
    }

    &-date {
        font: 12px/16px $font-roboto-medium;
        color: $grey-8;
        text-align: center;
        margin: 0 0 6px;
    }

    .unread-notifications {
        text-align: center;
        color: $primary-3;
        font: 12px/14px $font-roboto-regular;
        position: relative;
        margin: 0 0 12px;

        &:before {
            content: "";
            position: absolute;
            width: 100%;
            height: 1px;
            background: $primary-3;
            opacity: .6;
            top: 50%;
            left: 0;
        }

        span {
            background: $white-1;
            padding: 0 20px;
            position: relative;
        }
    }



    .history-message {
        margin: 0 0 16px;
    }

    .message-name {
        font-size: 12px;
        margin-bottom: 6px;
    }

    .avatar {
        display: inline-block;
        background: $tertiary-3;
        color: $grey-4;
        border-radius: $app-brd-radius;
        padding: 0 8px;
        margin: 0 0 0 5px;
        font: 12px/20px $font-roboto-medium;
    }

    .message-box {
        line-height: 20px;
        text-align: left;
        position: relative;
        display: inline-block;
    }

    .unread-message {
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: $secondary-1;
        position: relative;
        top: -20px;
        margin: 0 6px 0 -10px;
    }

    .message-content {
        font-size: 12px;
        line-height: 16px;

        span {
            display: inline-block;
        }

        .at,
        .icon-svg {
            position: relative;
            top: -1px;
        }

        .level {
            font-family: $font-roboto-medium;

            &:not(:last-child) {
                margin-right: 4px;
            }
        }

        .pass {
            color: $primary-3;
            font-family: $font-roboto-medium;
        }

        .icon {
            margin-left: 3px;
        }

        .icon-svg-bid-on-behalf,
        .icon-svg-blast-message {
            margin-left: 0;
            margin-right: 8px;
        }

        .bid-level-delta {
            font: 10px/16px $font-roboto-medium;
            width: auto;
            margin-top: -1px;
            padding: 0 4px;
            vertical-align: top;
            border-radius: 10px;

            &-positive {
                background-color: $green-5;
            }

            &-negative {
                background-color: rgba($secondary-1, 0.2);
            }
        }

        label {
            font-family: $font-roboto-medium;
            display: inline-block;
            margin-right: 5px;
            padding: 4px 8px;
            color: $blue-1;
            border-radius: $app-brd-radius;
            text-transform: uppercase;
            background: $tertiary-3;
            line-height: 1.15;
        }
    }

    .message-time {
        font: 10px $font-roboto-regular;
        position: absolute;
        bottom: 6px;
        right: 6px;
        margin-left: 8px;
    }

    .bwic-common-message {
        color: $grey-8;
        line-height: 16px;
        margin: 0 0 16px;

        .message-box {
            text-align: center;
            display: flex;
            padding: 0;
            flex-direction: column-reverse;
        }

        .message-time,
        .message-content {
            display: block;
            position: static;
        }

        .message-time {
            font: 12px $font-roboto-regular;
            margin: 0 0 6px;
            line-height: 16px;
        }

        .message-content {
            font: italic 12px/16px $font-roboto-regular;

            *:last-child {
                margin-right: 0;
            }
        }
    }

    .my-message {
        text-align: right;

        .message-wrapper {
            display: inline-block;
            text-align: left;
        }

        .message-box {
            text-align: left;
            padding: 6px 6px 24px;
            border-radius: 8px 8px 0 8px;
            background: $grey-3;
            color: $grey-4;
        }

        .message-name {
            margin-left: 8px;
        }

        .message-content {
            display: block;
        }
    }

    .response-message {
        .message-box {
            background: $tertiary-2;
            border-radius: 0 8px 8px 8px;
            padding: 6px 6px 24px;
            color: $white-1;

            .pass {
                color: $white-1;
            }
        }

        .message-section {
            display: flex;
            flex-direction: row;
            align-items: flex-end;

            .avatar {
                flex-basis: 44px;
                flex-grow: 0;
                flex-shrink: 0;
            }
        }

        .message-content {
            display: block;
        }
    }

    .restricted-placeholder-box {
        .required-feature {
            width: auto;
        }
    }
}
