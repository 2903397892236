﻿@keyframes newvalueanimation {
    0% {
        background-color: $orange-2;
    }
    50% {
        background-color: $orange-2;
    }
    100% {
        background-color: transparent;
    }
}

@keyframes newbidrowanimation {
    0% {
        background-color: $yellow-2;
    }
    50% {
        background-color: $yellow-2;
    }
    100% {
        background-color: $yellow-4;
    }
}

@keyframes warning-animation {
    0% {
        background-color: $secondary-2;
    }
    100% {
        background-color: $secondary-3;
    }
}

@keyframes input-border-animation {
    0% {
        border-color: $blue-1;
    }
    100% {
        border-color: $border-color;
    }
}