.popover-esg-comments,
.popover-eu-compliance,
.popover-last-updated {
    .popover-header {
        .tooltip-wrapper {
            min-width: 0;

            .name-tag {
                width: 100%;
            }
        }

        .name-tag {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .markdown-viewer {
        margin: 0;
    }
}

.popover-esg-comments,
.popover-eu-compliance {
    .popover-body {
        max-height: 190px;
        overflow: auto;
    }
}

.popover-transaction-views {
    .popover {
        width: 540px;
        padding-bottom: 16px;
        min-height: 346px;
        display: flex;
        flex-direction: column;
    }

    .popover-header {
        .name-tag {
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .popover-body {
        max-height: 500px;
        overflow: auto;

        & + .btn {
            align-self: flex-end;
            margin-top: auto;
        }

        .list-bullet li {
            line-height: 1.4;

            &:not(:first-child) {
                margin-top: 8px;
            }

            &:before {
                background-color: $grey-4;
            }
        }
    }

    .tabs-content {
        padding-top: 16px;
        padding-bottom: 8px;
    }

    .download-document-item {
        &:not(:last-child) {
            margin-bottom: 2px;
        }

        .tooltip-wrapper {
            display: inline-flex;
            max-width: 100%;
        }
    }

    .tabs-viewed-item {
        display: flex;

        &:not(:last-child) {
            margin-bottom: 2px;
        }
    }

    .tabs-viewed-label {
        font-family: $font-roboto-medium;
        width: 155px;
    }

    .empty-placeholder {
        margin: 0;
    }

    .data-item-row-email .data-item-text {
        @extend .text-ellipsis;
        white-space: nowrap;
    }

    .docs-downloaded-box {
        max-height: 175px;
        overflow: auto;
    }
}

.popover-last-updated {
    .popover {
        min-height: 96px;
    }

    .last-updated-scroll-content {
        max-height: 150px;
        overflow: auto;
        margin-top: 8px;
    }

    .loader-box {
        justify-content: flex-start;
    }

    .deal-updates-date {
        color: $grey-8;
    }

    .transaction-history-link {
        margin-top: 16px;
    }

    .updates-list {
        li {
            line-height: 1.4;

            &:not(:first-child) {
                margin-top: 0;
            }

            &:before {
                background-color: $grey-4;
                top: 7px;
            }
        }
    }
}
