﻿.dealers-list-panel {
    margin: -8px -16px 0;

    &-search {
        position: relative;
        padding: 8px 12px 8px 16px;
        border-bottom: 1px solid $border-color;
        box-sizing: content-box;

        .control-search-wrapper {
            .form-control-search-btn {
                height: 22px;

                .icon-search {
                    margin-top: 3px;
                }
            }

            .form-control-search {
                font-size: 12px;
                height: 22px;
            }

            .btn-close {
                padding-top: 4px;
                padding-bottom: 4px;
            }
        }
    }

    &-with-bids {
        width: 100%;
        padding: 8px 32px 7px 48px;
        border-bottom: 1px solid $border-color;

        &:hover {
            background: $row-hover;
        }

        .row-text {
            flex-grow: 1;
            margin-bottom: 0;
            line-height: 16px;
        }

        .tooltip-wrapper {
            line-height: 1;
        }
    }

    &-section {
        border-bottom: 1px solid $border-color;

        &.section-is-open {
            border-bottom-color: $border-color;

            .collapsable-item-header:not(:hover) {
                background-color: $primary-6;
            }
        }

        .dealer-checkbox-row {
            position: relative;
            display: flex;
            align-items: center;
            padding: 6px 12px 6px 48px;
            box-sizing: content-box;
            border-top: 1px solid $border-color;

            &:hover {
                background: $row-hover;
            }

            .tooltip-wrapper {
                flex-grow: 1;
                min-width: 0;
                height: 18px;
            }

            .checkbox {
                max-width: 100%;
                padding-right: 8px;

                input[type="checkbox"]:not(:checked) + label,
                input[type="checkbox"]:checked + label {
                    &.checkbox-lbl-text {
                        padding-left: 24px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            .bids-counter {
                color: $grey-4;
                width: 52px;
                flex: none;
                white-space: nowrap;
            }

            .favorite-action-button {
                flex: none;
                width: 16px;
                margin-right: 12px;

                .svg-icon {
                    vertical-align: top;
                }
            }
        }
    }

    &-group {
        .collapsable-item-header {
            display: flex;
            align-items: center;
            padding: 0;
            height: 32px;

            .flex-row {
                flex-grow: 1;
                padding: 6px 16px;
            }

            .row-text {
                margin-bottom: 0;
                margin-right: 4px;
            }

            .icon-svg-icon-expand {
                margin-right: 16px;
            }
        }

        .collapsable-item-content {
            .empty-placeholder {
                border-top: 1px solid $border-color;
            }
        }
    }

    .collapsable-item-header,
    .dealers-list-panel-with-bids {
        .checkbox {
            margin-right: 8px;
        }
    }
}
