﻿.sidebar-panel {
    background-color: $white-1;
    border: solid 1px $border-color;
    width: 460px;
    padding: 42px 0 0;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 50;

    &:before {
        position: fixed;
        width: calc(100% - 460px);
        height: 100%;
        top: 0;
        left: 0;
        content: "";
        z-index: 49;
        opacity: .3;
        background: $grey-4;
    }

    &.sidebar-panel-color,
    &.sidebar-panel-pxTalk {
        width: 534px;

        &:before {
            width: calc(100% - 534px);
        }

        .form-error {
            top: 100%;
            margin-left: -1px;
            min-width: auto;
        }
    }

    h2 {
        line-height: 22px;
        margin-bottom: 0;
    }

    h3 {
        margin-bottom: 5px;
    }

    h4 {
        color: $grey-8;
        font-family: $font-family-base;
    }

    form {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        min-height: 1px;
    }
}

.sidebar-panel-header {
    @extend .flex-row;
    flex: none;
    padding: 0 60px;
    margin-bottom: 25px;

    h2 {
        text-transform: none;
    }

    .btn-close {
        margin-right: -8px;
    }
}

.sidebar-panel-content {
    padding: 0 60px;
}

.sidebar-panel-footer {
    background-color: $grey-7;
    padding: 20px 60px;
    flex: none;
    margin-top: auto;
    display: flex;
    flex-direction: row;

    .btn {
        width: auto;
        margin-left: 10px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.sidebar-panel-color {
    .sub-text {
        font-size: 12px;
        position: absolute;
        left: .62rem;
        bottom: -28px;
        width: 186px;
    }

    .bold {
        color: $grey-4;
        font-family: $font-roboto-medium;
        font-size: 14px;
    }

    .form-row-inline {
        align-items: flex-end;
    }

    .form-item {
        position: relative;
        flex: none;
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }
    }

    .form-label {
        text-transform: none;
    }

    .form-color-wrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -12px;

        .form-item {
            margin: 10px 12px 36px;
            width: calc(50% - 24px);

            &.flex-row {
                margin-right: 8px;
                margin-left: 8px;
                width: calc(50% - 16px);

                .control-group {
                    width: 100%;
                    margin: 0 4px;
                }
            }

            &.form-item-color {
                margin-top: -12px;
            }
        }
    }
}

.sidebar-panel-pxTalk {
    .sidebar-panel-header {
        padding: 0 40px 0 32px;
    }

    .sidebar-panel-footer {
        padding: 20px 40px 20px 32px;
        justify-content: flex-end;
    }

    .sidebar-panel-content {
        padding: 0;
    }

    .form-row-add {
        margin: 0 0 16px;
        background-color: $grey-7;
        padding: 8px 60px 8px 32px;
        justify-content: flex-start;

        .form-control-wrapper {
            max-width: 218px;
        }

        .btn-main {
            width: 72px;
            padding: 0;
        }
    }

    .form-control-autocomplete {
        width: 100%;
        margin-right: 8px;
        flex: none;
    }

    .table-pxTalks {
        .form-control-wrapper {
            width: 72px;
        }

        .form-error {
            top: 100%;
        }

        .flex-none {
            margin-right: 8px;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .table-pxTalks-cell-company {
        width: 156px;
    }

    .table-pxTalks-cell-pxTalk {
        width: 72px;
    }

    .table-pxTalks-cell-normalized {
        width: 85px;
    }

    .table-pxTalks-cell-type {
        width: 92px;
    }

    .table-pxTalks-header {
        font-size: 12px;
        font-family: $font-roboto-bold;
        padding: 0 40px 0 32px;
    }

    .table-pxTalks-body {
        height: calc(100vh - 282px);
        overflow: auto;

        .flex-row-pxTalks {
            padding: 4px 40px 4px 32px;
            position: relative;
            @extend .transition;

            &:hover {
                background-color: $row-hover;

                .btn-danger {
                    visibility: visible;
                }
            }
        }

        .table-pxTalks-cell-company {
            text-transform: none;
        }

        .btn-danger {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            visibility: hidden;
        }
    }
}
