﻿.connection-toast {
    position: fixed;
    top: 44px;
    left: 50%;
    z-index: 100;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 710px;
    padding: 9px 20px;
    color: $white-1;
    text-align: center;
    border-radius: $app-brd-radius;
    box-shadow: $app-shadow;
    transform: translateX(-50%);

    @media only screen and (max-width: 730px) {
        left: 10px;
        right: 10px;
        width: auto;
        transform: none;
    }

    &-icon {
        flex: none;
        margin-right: 8px;
        line-height: 1;

        .icon {
            vertical-align: top;
        }
    }

    &-message {
        font: 12px/14px $font-roboto-medium;

        span {
            text-decoration: underline;
            cursor: pointer;

            &:hover {
                text-decoration: none;
            }
        }
    }

    &-connecting {
        color: $grey-4;
        background-color: $yellow-1;
    }

    &-connected {
        background-color: $green-9;
    }

    &-disconnected {
        background-color: $warning-1;
    }
}
