.component-file-upload {
    position: relative;

    & + .component-file-upload-list {
        border-top: 1px solid $border-color;
        padding-top: 5px;
    }
}

.component-file-upload-placeholder {
    font-family: $font-roboto-medium;
    text-align: center;
    height: 70px;
    width: 100%;
    background-color: $primary-6;
    border: 1px dashed $primary-3;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 18px;

    .file-upload-main-text {
        display: flex;
        align-items: center;
    }

    .icon-svg {
        margin-right: 12px;
    }

    .btn-link {
        line-height: 1.4;
    }

    .footnote {
        font: 12px/18px $font-roboto-regular;
        color: $grey-9;
        display: block;
    }
}

.component-file-upload-list {
    &.data-list:not(.data-list-freeze) {
        .data-list-container {
            min-width: unset;
        }
    }

    .data-list-container {
        min-height: 30px;
    }

    .data-list-content {
        overflow-y: hidden;
    }

    .data-list-header {
        color: $grey-8;
        background-color: transparent;
        border-bottom: none;
        padding-left: 14px;
        padding-right: 20px;
        margin-bottom: 6px;

        .data-list-cell {
            background-color: transparent;

            &:first-child {
                padding-left: 36px;
            }
        }
    }

    .data-list-row {
        font-size: 12px;
        background-color: $grey-7;
        border-radius: 4px;
        border-bottom: none;
        margin-bottom: 6px;
        padding: 0 20px 0 14px;
        min-height: 42px;
        align-items: center;
        cursor: pointer;

        &:not(.disabled):hover {
            background-color: $row-active;
        }

        &.disabled {
            cursor: default;

            .data-list-cell:nth-child(3),
            .btn-download-file {
                color: $grey-1;
                cursor: default;
            }
        }
    }

    .data-list-cell {
        &:last-child {
            padding-right: 0;
        }
    }

    .icon-svg-basket {
        margin-right: 11px;
        margin-left: 5px;
        vertical-align: text-bottom;
    }
}

.progress-bar-container {
    background-color: $grey-3;
    width: 120px;
    height: 20px;
}

.progress-bar-content {
    border-radius: $app-brd-radius;
    box-shadow: 0 2px 5px rgba($grey-4, 0.25) inset;
    background-color: $primary-3;
    height: 100%;
}

.type01 {
    .component-file-upload-placeholder {
        width: 400px;
        background: $primary-6;
        height: 60px;
        border: 1px dashed $primary-3;
        border-radius: 30px;
        font: 14px/18px $font-roboto-regular;

        .btn-link {
            font-size: 14px;
        }
    }
}

.file-download-row {
    .data-item-col {
        overflow: hidden;
    }
}

.btn-download-file {
    font-family: $font-roboto-regular;
    text-align: left;
    display: inline-block;
    width: auto;
    max-width: 100%;

    .icon {
        margin-right: 8px;
    }

    &.secondary {
        font-size: 14px;
    }

    &:hover {
        text-decoration: none;
    }
}
