@mixin card-bullet {
    content: ' ';
    overflow: hidden;
    line-height: 1;
    display: block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: currentColor;
}


.subscriptions {
    .tabs {
        padding-left: 24px;
    }

    .tabs-data {
        flex: none;
        overflow: visible;
        height: auto;
    }

    .empty-placeholder {
        width: auto;
    }
}

.btn-add-payment {
    font: 14px/18px $font-roboto-medium;
    width: 300px;
    height: 170px;
    text-transform: capitalize;
    border-style: dashed;
    border-radius: 10px;
    background-color: $primary-6;
    cursor: pointer;

    .icon {
        margin-top: -4px;
        margin-right: 10px;
        vertical-align: top;
    }

    &[disabled] {
        color: $primary-3;
        border-color: $primary-4;
        opacity: 0.5;
    }
}

.payment-cards {
    align-items: flex-start;
    width: 100%;
    max-width: 836px;

    p {
        margin-bottom: 25px;

        & + .payment-cards-item {
            margin-right: 300px;
        }
    }
    
    &-list {
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        margin-right: -330px;

        & + .payment-cards-new {
            padding-top: 44px;
        }
    }

    &-item {
        align-items: flex-start;
        margin-bottom: 40px;

        .radio-button-section {
            display: flex;
            align-items: flex-start;
            cursor: pointer;

            & + .btn-danger {
                flex: none;
                margin-top: 20px;
                margin-left: 10px;
            }
        }

        .radio {
            position: static;
            flex: none;
            margin-top: 30px;
            padding-right: 10px;
        }
    }
}

.payment-card {
    color: $white-1;
    font-size: 12px;
    overflow: hidden;
    width: 300px;
    height: 170px;
    padding: 30px;
    position: relative;
    z-index: 1;
    border-radius: 10px;
    background-color: $blue-11;

    &:before {
        content: ' ';
        width: 300px;
        height: 300px;
        position: absolute;
        right: 100px;
        bottom: 75px;
        z-index: -1;
        border-radius: 50%;
        background: radial-gradient(at -2000px 0, rgba($white-1, 0.45) 0%, rgba($white-1, 0) 100%);
    }

    &-header {
        display: flex;
        align-items: flex-start;
        height: 22px;
        line-height: 1.2;

        span {
            font-size: 14px;
            font-family: $font-roboto-medium;
            text-transform: capitalize;
            white-space: nowrap;
            margin-right: 16px;
        }

        small {
            font-size: 10px;
            line-height: 1;
            padding-top: 4px;
            margin-left: auto;
        }
    }

    &-number {
        font-size: 14px;
        line-height: 1.1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 14px 0 20px;

        ul {
            display: flex;
            margin: 0 13px 0 0;
            padding: 0;
            list-style: none;

            &:before,
            &:after,
            li:before,
            li:after {
                @include card-bullet;
                margin-left: 4px;
            }

            li {
                display: flex;
            }
        }

        &.payment-card-number-amex {
            div {
                display: flex;
                align-items: center;

                &:before {
                    @include card-bullet;
                    margin-right: 4px;
                }
            }
        }
    }

    &-name {
        max-width: 170px;
        margin-bottom: 4px;
        white-space: nowrap;
    }

    &-logo {
        position: absolute;
        bottom: 50%;
        right: 0;
        transform: translateY(50%);
    }
}

.billing-info {
    flex-direction: row;
    margin-top: -14px;

    &-section {

        &:first-child {
            max-width: 510px;
            margin-right: 40px;
        }
    }

    &-header {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        padding-bottom: 12px;
        border-bottom: 1px solid $border-color;

        h2 {
            margin-bottom: 0;
        }

        .btn-link-edit {
            margin-left: 10px;
        }
    }

    &-content {
        .profile-list {
            dt {
                flex-basis: 200px;
            }

            dd {
                width: calc(100% - 200px);
                white-space: nowrap;

                .text-field {
                    @extend .text-ellipsis;
                }
            }
        }
    }
}

.subscription-plans {
    .data-list-content {
        overflow-y: auto;
    }

    .data-list-header,
    .data-list-row {
        padding-right: 0;

        .data-list-cell {
            padding-top: 7px;
            padding-bottom: 7px;

            &.selected {
                background-color: $primary-15;
            }

            &.data-list-cell-xs-flexible {
                min-width: 100px;
            }
        }
    }

    .data-list-header {
        max-height: none;
        flex-basis: auto;

        .title {
            margin-bottom: 6px;
            line-height: 20px;
        }
        
        .till-date {
            height: 14px;
            line-height: 14px;
            margin-bottom: 12px;
        }

        .btn {
            vertical-align: top;
        }
    }

    .data-list-row {
        .data-list-cell {
            min-height: 31px;
            line-height: 16px;

            .pseudo-link,
            .icon-svg {
                vertical-align: top;
            }
        }
    }
}

.subscription-overview {
    &-section {
        width: 428px;
        height: 250px;
        margin: 0 17px 18px 0;
        padding: 24px;
        border: 1px solid $border-color;
        border-radius: $app-brd-radius;

        &:last-child {
            margin-right: 0;
        }

        h1 {
            margin-bottom: 24px;
            line-height: 26px;
        }

        p {
            margin-bottom: 24px;
        }

        .payment-cards-item {
            margin-top: 13px;
            margin-bottom: 27px;
        }

        .definition-list {
            padding: 10px 0 0;

            dt {
                font-size: 14px;
                margin-bottom: 10px;
            }
        }

        .empty {
            padding: 20px 0 0;
            font-size: 16px;
        }

        .add-link,
        .add-link-inactive {
            margin-top: 20px;
            display: inline-block;

            .icon-svg {
                margin-right: 10px;
            }
        }

        .add-link-inactive {
            color: $primary-3;
            opacity: 0.5;
            cursor: default;
        }

        .position-relative {
            .popover {
                margin-top: 2px;
                padding-top: 8px;
                padding-bottom: 13px;
                width: 390px;
            }
        }
    }
}

.subscription-changes {
    flex-direction: column;
    min-width: 1280px;
    padding: 0;

    &-title {
        flex: none;
        width: 100%;
        padding: 17px 32px;
        border-bottom: 1px solid $border-color;
        background: $grey-3;

        h2 {
            margin: 0;
            text-align: center;
        }
    }

    &-scroll {
        overflow-x: hidden;
        overflow-y: auto;
        padding-right: 32px;
        padding-left: 32px;
    }

    &-content {
        align-self: center;
        flex: none;
        width: 100%;
        max-width: 880px;
        padding-top: 37px;
        padding-bottom: 37px;

        .changes-preview {
            margin-bottom: 40px;
        }

        .flex-row {
            .btn {
                margin-right: 20px;

                .icon-svg-button-arrow {
                    margin-top: -2px;
                }
            }
        }

        .text-warm-grey {
            & + .flex-row {
                margin-top: 20px;
            }
        }
    }
}

.changes-preview {
    align-items: flex-start;

    &-main {
        width: 400px;
        padding-top: 18px;
    }

    &-section {
        p {
            margin-bottom: 0;
        }

        &:not(:first-child) {
            margin-top: 40px;

            h2 {
                margin-bottom: 20px;
                padding-bottom: 20px;
                border-bottom: 1px solid $border-color;
            }
        }
    }

    &-card {
        align-items: center;
        margin-bottom: 30px;

        .icon {
            color: $primary-3;
            flex: none;
            margin-right: 16px;
        }

        .payment-card-number {
            justify-content: flex-start;
            padding: 0;
        }
    }

    &-plan {
        width: 400px;
        overflow: hidden;
        margin-left: auto;
        border-radius: $app-brd-radius;
        box-shadow: 0 1px 6px 0 rgba($grey-4, 0.16);
        background: $white-1;

        .flex-row {
            padding: 20px 20px;
            align-items: flex-start;

            &:not(:last-child) {
                position: relative;

                &:before {
                    content: ' ';
                    position: absolute;
                    top: -1px;
                    right: 20px;
                    left: 20px;
                    border-top: 1px dashed $border-color;
                }
            }

            p {
                margin-bottom: 0;

                & + p {
                    margin-top: 6px;
                }
            }

            &:first-child {
                flex-direction: column;
                align-items: flex-start;
                position: relative;
                padding-top: 18px;
                padding-bottom: 18px;

                h2 {
                    margin-bottom: 2px;
                }

                span {
                    font-family: $font-roboto-bold;
                    position: absolute;
                    top: 19px;
                    right: 20px;
                    padding: 10px 15px;
                    border: 1px solid $border-color;
                    border-radius: $app-brd-radius;
                }
            }

            &:last-child {
                color: $white-1;
                margin-left: auto;
                background: $grey-4;

                div {
                    &:last-child {
                        font-family: $font-roboto-bold;
                    }
                }
            }
        }
    }
}

.downgrade-preview {
    align-items: flex-start;
    padding-bottom: 45px;

    h2 {
        margin-bottom: 0;
    }

    p {
        &:first-child {
            margin-top: 8px;
            margin-bottom: 28px;
        }
    }

    .downgrade-features {
        column-count: 2;
        column-gap: 40px;
        margin-top: 38px;
        margin-bottom: 38px;
        padding: 30px 37px 20px 39px;
        border: 1px solid $border-color;

        &-section {
            break-inside: avoid;

            &:not(:first-child) {
                margin-top: 26px;
            }

            h3 {
                text-transform: capitalize;
            }
        }
    }

    .flex-row {
        margin-top: 34px;
        margin-bottom: 34px;

        .btn {
            &:first-child {
                margin-right: 10px;
            }
        }
    }
}

.billing-history {
    .container-filter-bwics {
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
        margin: -4px 0 8px;
    }

    &-info {
        margin: 0 -24px;
        min-height: 100px;

        .container-loader {
            background: none;
        }
    }

    .data-list {
        &-header {
            padding-right: 32px;
        }

        &-cell {
            flex: 1 1 auto;
        }

        &-source {
            min-width: 50px;
            flex-basis: 50px;
        }

        &-date {
            min-width: 150px;
            flex-basis: 150px;
        }

        &-amount {
            min-width: 50px;
            flex-basis: 50px;
        }

        &-status {
            min-width: 50px;
            flex-basis: 50px;
        }

        &-details {
            min-width: 150px;
            flex-basis: 150px;
        }

        &-billing-details {
            min-width: 50px;
            flex-basis: 50px;
        }

        &-content {
            overflow: auto;
        }
    }

    .no-payment {
        color: $grey-8;
        border-bottom: 1px solid $border-color;
        padding: 8px 32px 20px;
    }
}
