﻿.progress-circle {
    display: inline-block;
    flex: none;
    width: 16px;
    height: 16px;

    svg {
        transform: rotate(-90deg);

        circle {
            width: 100%;
            height: 100%;
            fill: none;
            stroke: $grey-6;
            stroke-width: 2px;
            stroke-linecap: round;

            &:last-of-type {
                stroke-dasharray: 43;
                stroke-dashoffset: calc(43 - (43 * var(--percent)) / 100);
                stroke: $blue-1;
            }
        }
    }
}
