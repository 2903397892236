.security-information {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;

    h4 {
        margin-bottom: 0;
        line-height: 32px;
    }

    &-main {
        margin-bottom: 16px;

        .flex-item {
            display: flex;
            flex-direction: column;
            flex: none;

            &:not(:first-child) {
                margin-left: 16px;
            }

            &.security-isin {
                width: 95px;
            }

            &.security-ticker {
                width: 125px;
            }

            &.security-asset {
                width: 80px;
                text-align: right;
            }

            &.security-currency {
                flex: 1;
            }
        }
    }

    &-links {
        margin-bottom: 16px;
        border-bottom: 1px solid $border-color;

        .flex-row {
            flex-wrap: wrap;
            margin-left: -24px;
        }

        .flex-item {
            flex: none;
            margin-left: 24px;
            margin-bottom: 16px;

            .btn-link-bloomberg {
                .icon-svg {
                    margin-right: 4px;
                }
            }
        }
    }

    &-definition {
        flex: none;

        &:not(:first-child) {
            margin-bottom: 24px;
        }

        .definition-list {
            color: $grey-4;
            font-size: inherit;

            dt, dd {
                margin-top: 6px;
                margin-bottom: 10px;
            }

            dt {
                span {
                    vertical-align: middle;
                }

                .icon-svg-info {
                    margin-left: 8px;
                }
                
                &:nth-last-child(2) {
                    margin-bottom: 0;
                }
            }

            dd {
                font-family: $font-roboto-bold;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &-chart {
        margin-top: -8px;
        margin-bottom: 8px;
    }

    &-graph {
        .graph-details {
            font-size: inherit;
            line-height: inherit;
        }

        .svg-container {
            z-index: 1;
        }
    }

    &-empty {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }

    &-history {
        & > .flex-row:first-child {
            margin-right: -16px;
            margin-left: -16px;
            padding: 8px 16px 0;
            border-top: 1px solid $border-color;
        }

        .cell-bwic-view-link {
            .btn-link:disabled {
                color: inherit;
                font-family: $font-roboto-regular;
            }
        }
    }

    &-table {
        margin-right: -16px;
        margin-left: -16px;

        .data-list-striped {
            .data-list-header,
            .data-list-row {
                padding-left: 16px;
                padding-right: 16px;
            }
        }
    }

    .container-loader {
        background: none;
        top: 180px;
        padding-top: 82px;
        justify-content: flex-start;
    }

    .js-plotly-plot {
        width: 100%;
        height: 300px;
    }
}
