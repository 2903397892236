﻿.empty-placeholder {
    justify-content: center;
    padding: 31px;
    margin: 16px 24px;
    text-align: center;
    border: 1px solid $border-color;
    border-radius: $app-brd-radius;
    background-color: $grey-3;
    width: 100%;

    &.text-view {
        background: none;
        border: 0;
        color: $empty-placeholder-color;
    }

    &.text-view-row {
        border: 0;
        color: $empty-placeholder-color;
        text-align: left;
        justify-content: flex-start;
        margin: 0;
        padding: 6px 24px;
        background-color: $white-1;
    }

    &.empty-widget {
        @extend .container-flex;
        color: $grey-8;
        margin: 0;
        height: 100%;
        justify-content: center;
        border: 0;
        background: none;
    }

    &.empty-placeholder-chart {
        width: 262px;
        position: absolute;
        z-index: 6;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        padding: 16px;
        line-height: 1.4;

        .icon-svg {
            transform: scale(2);
            margin: 16px 0 24px;
        }

        .btn-link {
            line-height: 1.4;
            vertical-align: top;
            display: inline;
        }

        p {
            margin: 0;
        }
    }

    &.empty-placeholder-without-margin {
        margin: 0;
    }

    h2 {
        margin-bottom: 16px;
    }

    p {
        line-height: 20px;
        margin-bottom: 16px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .icon-svg {
        margin: 0 auto 8px;
    }

    .tooltip-wrapper,
    .tooltip-wrapper > div {
        display: inline-block;
        width: auto;
    }

    .placeholder-text {
        .btn-link {
            vertical-align: top;
            line-height: inherit;
        }
    }
}
