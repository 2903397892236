﻿$base-radius: 8px;

.container-step-process {
    position: relative;
    display: flex;
    flex-direction: row;
}

.flex-item-sidebar-cnt,
.flex-item-template-cnt {
    overflow: auto;
}

.flex-item-sidebar {
    h2 {
        margin: 0;
        text-transform: capitalize;
    }

    &-title {
        padding:14px 24px;
        line-height: 22px;

        .status-message {
            margin: 8px 0 2px;
        }
    }
}

/* List Templates */

.template-list {
    border-top: solid 1px $border-color;
    margin-bottom: 2.5rem;
}

.template-list-item {
    font-size: 12px;
    border-bottom: solid 1px $border-color;
    padding: 16px 24px;
    position: relative;
    min-height: 80px;
    cursor: pointer;

    &:hover {
        background-color: $row-active;

        .icon-delete {
            display: block;
        }
    }

    &.selected {
        background-color: $row-active;
    }

    .animated-value-update {
        border-radius: $base-radius;
        background: $blue-2;
        vertical-align: middle;

        &.animated-value-update-default-bg {
            border-radius: $base-radius;
        }
    }

    .animated-value-update-error {
        background: $warning-5;
        color: $warning-3;
    }
}

.template-list-item-title {
    font: 14px/20px $font-roboto-medium;
    padding-right: 1.5rem;
    margin-bottom: 8px;
    word-break: break-word;

    .icon-svg {
        margin: -2px 0 0 8px;
    }
}

.template-list-item-text {
    line-height: 18px;

    h5 {
        margin: 8px 0 0;
    }

    .option-row {
        &.first-animation-option {
            .animated-value-update:first-child {
                margin-left: -2px;
            }
        }
    }
}

/* end List Templates */


/* Template Content */

.flex-item-template {

    .radio-text,
    .checkbox label {
        font-size: 14px;
    }

    .bwic-date-time {
        background: $grey-7;
        border-bottom: 1px solid $border-color;
        padding: 0 0 0 24px;

        &-list {
            li {
                display: inline-block;
                margin-right: 48px;
                font: 12px/32px $font-roboto-medium;

                &:last-child {
                    margin-right: 0;
                }

                label {
                    color: $grey-9;
                    display: inline-block;
                }

                .icon-svg {
                    margin-top: -2px;
                }
            }
        }
    }

    &-cnt {
        padding: 25px 24px 24px;

        h2 {
            border-bottom: 1px solid $border-color;
            padding-bottom: 8px;
            margin-bottom: 16px;
        }
    }

    .flex-row-item {
        padding: 11px 24px;
        align-items: center;
        border-bottom: 1px solid $border-color;

        &.flex-item-template-title {
            flex: none;
            border: 0;
            padding: 12px 24px;
            max-height: 48px;

            h2 {
                padding-bottom: 0;
                margin-bottom: 0;
                border: 0;
            }

            .modify {
                line-height: 16px;

                .notify {
                    vertical-align: bottom;
                }

                .btn-link {
                    margin-left: 16px;

                    .icon-svg {
                        margin-top: 1px;
                    }
                }
            }
        }
    }

    .icon-svg-info {
        margin: -1px 0 1px 4px;
    }
}

.section-row {
    align-items: flex-start;
    padding: 0 0 24px;

    &-title {
        @include flex-basis(316px);

        h2, h4 {
            margin: 0;
        }
    }

    &-cnt {
        flex-grow: 1;

        .form-label {
            .text-red {
                margin-left: 4px;
                position: relative;
                right: -4px;
            }
        }
    }

    .options {
        @include flex-basis(316px);
        margin-bottom: 8px;

        .options-row {
            margin-bottom: 16px;
        }
    }

    .notes {
        margin-bottom: 8px;

        .form-label {
            line-height: 14px;
            margin-bottom: 5px;
        }

        textarea {
            width: 437px;
            height: 56px;
            padding: 6px 8px;
        }
    }
}

.stages {
    h2:not(:first-child) {
        margin-top: 8px;
    }

    .form-label,
    .form-control,
    .form-control-wrapper,
    .info-label {
        display: inline-block;
    }

    .form-label {
        margin-right: 8px;
        font-size: 14px;
        line-height: 32px;
    }

    .range {
        width: 90px;
        text-align: right;
    }

    .info-label {
        margin-left: 12px;
        color: $empty-placeholder-color;
    }

    .radio-group {
        flex-direction: row;
        margin: 0;
        min-height: 32px;
        align-items: center;

        .radio {
            margin: 0 16px 0 0;
        }
    }

    .form-select {
        flex: none;
        width: 140px;
        max-width: none;

        & + .form-label {
            margin-left: 24px;
            margin-bottom: 0;
        }

        & + .status-message {
            margin-left: 16px;
        }
    }
}

