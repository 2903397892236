﻿.data-list {
    &.data-list-freeze {
        .data-list-header {
            padding-right: 17px;
        }
    }

    &-header,
    &-row {
        align-items: center;
        flex: none;
        padding-left: 24px;
        padding-right: 24px;
        font-size: 12px;
    }

    &-header {
        flex: none;
        flex-basis: 24px;
        min-height: 24px;
        max-height: 24px;
        border-bottom: 1px solid $row-divider-color;
        background-color: $table-header-bg;
        position: relative;

        .data-list-cell {
            padding-top: 4px;
            padding-bottom: 3px;
            background-color: $table-header-bg;
            line-height: 16px;

            &.sort-column {
                cursor: pointer;

                .icon-svg-corner-arrow {
                    color: $grey-2;
                    margin-left: 1px;
                }

                &.sorted {
                    .icon-svg-corner-arrow {
                        color: $grey-4;
                        transform: scale(-1);
                    }

                    &.desc {
                        .icon-svg-corner-arrow {
                            transform: none;
                        }
                    }
                }
            }

            .icon-svg {
                margin-left: 4px;
                vertical-align: top;
            }

            &.cell-bob {
                min-height: 0;

                .position-relative {
                    display: flex;
                    flex-direction: row;
                }

                .tooltip-wrapper .icon-svg {
                    margin-left: 0;
                }

                .column-header-icons {
                    padding-left: 0;
                    padding-right: 0;

                    li {
                        margin-right: 4px;

                        .icon-svg-favorite-fill {
                            color: $grey-8;
                        }
                    }
                }

                .remove-button {
                    flex: none;
                    height: 16px;
                    margin-left: auto;
                    margin-right: 4px;

                    button {
                        color: $grey-8;
                        height: 16px;
                        padding: 0 4px;
                        border: 0;
                        vertical-align: top;
                        background: none;
                        cursor: pointer;

                        &:hover {
                            color: $text-color;
                        }

                        .icon-svg {
                            vertical-align: top;
                        }
                    }
                }
            }

            &.cell-flexible-text {
                min-height: 16px;
                line-height: 16px;
            }
        }

        .data-list-controls {
            padding: 0 17px 0 8px;
            background-color: $table-header-bg;
        }

        .btn {
            height: 24px;
        }
    }

    &-row {
        height: 32px;
        border-bottom: 1px solid $row-divider-color;
        background-color: $white-1;
        min-height: 1px;
        min-width: 1px;

        &.pending-agreement {
            background-color: $yellow-4;
        }

        &.pending,
        &.affirmed,
        &.tradedaway,
        &.inactive {
            .data-list-controls {
                background: none;
            }

            .form-control-changed {
                &:before {
                    display: none;
                }
            }

            .data-list-cell.cell-bid-value {
                .btn-link:not(.btn-timeline) {
                    display: none;
                }
            }
        }

        &.affirmed {
            &:not(.hover) {
                background: $green-8;
            }
        }

        &.tradedaway,
        &.inactive {
            &:not(.hover) {
                background: $grey-7;
            }
        }

        &.affirmed,
        &.tradedaway,
        &.inactive {
            .data-list-cell.cell-open-bidding-feedback {
                .animated-value-update,
                .animated-value-update-bg {
                    background: none;
                }

                .animated-value-update-content {
                    padding: 0;
                }
            }
        }

        .data-list-docs {
            .btn-link {
                cursor: pointer;

                .icon {
                    margin-right: 0;
                }
            }
        }

        &.active {
            position: relative;
            height: 33px;
            margin-top: -1px;
            border-top: 1px solid $row-divider-color;
            background-color: $yellow-6 !important;
        }

        &.pending-trade {
            background: $row-pending;
        }

        &:hover,
        &.hover {
            &,
            &.highlight,
            &.pending,
            &.affirmed,
            &.tradedaway,
            &.active,
            &.inactive,
            &.pending-agreement {
                background-color: $row-hover;
            }
        }

        &.sold-status { //using in all tables
            background: $grey-7;
        }

        &.inactive {
            .form-control {
                opacity: 0.6;
            }

            textarea.form-control {
                opacity: 1;
            }
        }

        .data-list-color-label {
            display: flex;
            flex-direction: row;
            align-items: center;

            .btn-link {
                margin-left: auto;
            }
        }

        &:nth-last-child(-n+10):not(:nth-child(-n+15)) {
            .quick-feedback-menu {
                .context-menu-dropdown {
                    top: auto;
                    bottom: 100%;
                }
            }
        }

        &:hover,
        &.hover {
            background-color: $row-hover;
        }

        &.highlighted {
            background-color: $row-pending !important;
        }
        
        &.row-grouping {
            font-family: $font-roboto-bold;
            background-color: $primary-6;
            cursor: default;
        }
    }
}


.data-list-row-animation {
    background-color: $yellow-4;
    animation-duration: 12s;
    animation-name: newbidrowanimation;
}

.data-list-row-animated {
    background-color: $yellow-4;
}

.data-list-row-colored {
    background-color: $grey-7;
}
