.viewed-tabs-tooltip {
    position: relative;
    top: -5px;

    &:before {
        position: absolute;
        content: "";
        border-top: 4px solid transparent;
        border-right: 5px solid $grey-4;
        border-bottom: 4px solid transparent;
        top: 50%;
        transform: translateY(-50%);
        left: -5px;
    }
}

.proportion-bar-tooltip {
    position: relative;
    width: 200px;
    top: -7px;

    &:before {
        position: absolute;
        content: "";
        border-left: 4px solid transparent;
        border-bottom: 5px solid $grey-4;
        border-right: 4px solid transparent;
        left: 50%;
        transform: translateX(-50%);
        top: -5px;
    }

    &.top-placement {
        top: auto;
        bottom: -7px;

        &:before {
            border-left: 4px solid transparent;
            border-top: 5px solid $grey-4;
            border-bottom: none;
            border-right: 4px solid transparent;
            top: auto;
            bottom: -5px;
        }
    }

    .proportion-bar-row {
        display: flex;
        align-items: center;
        line-height: 1.15;

        &:not(:last-child) {
            margin-bottom: 4px;
        }
    }

    .proportion-bar-color {
        width: 10px;
        height: 10px;
        margin-right: 4px;
    }

    .proportion-bar-label {
        font-size: 12px;
    }
}