﻿.manage-user {

    .sub-header {
        h1 {
            line-height: 32px;
        }
    }

    &-form {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex: 1 1 auto;
        position: relative;

        &-cnt {
            width: 1004px;
            margin: 32px auto;
        }

        h2 {
            border-bottom: 1px solid $border-color;
            margin-bottom: 16px;
            padding: 0 0 8px;
        }

        .box {
            padding-bottom: 18px;
            width: 100%;
        }

        .columns {
            margin: 0 -8px;
            align-items: flex-start;

            .box {
                width: 50%;
                padding: 0 8px;

                .form-row {
                    .form-item {
                        @media all and (-ms-high-contrast:none) {
                            width: 100%;
                        }
                    }
                }

                .form-row-desk {
                    display: flex;

                    .form-item-desk {
                        flex: 0 0 325px;

                        & + .form-item-primary-contact {
                            padding-top: 30px;
                        }
                    }

                    .form-item-primary-contact {
                        white-space: nowrap;
                    }
                }
            }
        }

        .form-row {
            justify-content: flex-start;
            margin-left: -8px;
            margin-right: -8px;

            .form-item {
                margin-right: 0;
                flex: 0 0 33.3%;
                padding: 0 8px;

                @media all and (-ms-high-contrast:none) {
                    flex: none;
                    width: 33.3%;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        .subscription-info {
            background: $grey-7;
            padding: 16px;
            margin-top: -17px;

            p {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}


