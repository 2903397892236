﻿/* Stepper */

.control-stepper {
    width: 92px;
    height: 32px;
    padding: 4px;
    border-radius: $app-brd-radius;
    border: solid 1px $control-border;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    &.is-focus {
        border-color: $control-focus;
        box-shadow: 0 0 0 1px $control-focus;
    }

    &.disabled,
    &:disabled {
        border-color: $control-disabled;
        background-color: $control-disabled-bg;

        .btn-stepper {
            color: $placeholder-color;
            background-color: $control-disabled-bg;
            pointer-events: none;
        }

        .form-control-stepper {
            color: $placeholder-color;
            background-color: $control-disabled-bg;
        }
    }

    &.is-invalid {
        border-color: $color-error;
        box-shadow: 0 0 0 1px rgba($secondary-1, 0.4);

        .form-control-stepper {
            &:focus {
                background-color: $bg-error;
                border: none;
            }
        }

        &.is-focus {
            border-color: $color-error;
            box-shadow: 0 0 0 1px $color-error;
        }

        .btn-stepper {
            color: $placeholder-color;
            pointer-events: none;
        }
    }

    .btn-stepper {
        color: inherit;
        border: none;
        background-color: $white-1;
        width: 24px;
        height: 24px;
        padding: 4px;
        border-radius: $app-brd-radius;
        cursor: pointer;
        @extend .display-inline;
        @extend .transition;

        .icon-svg {
            vertical-align: top;
        }

        &:hover {
            color: $button-hover;
            background-color: $button-ghost-hover;
        }

        &:active,
        &:focus {
            color: $button-focus;
            background-color: $primary-8;
        }

        &.disabled,
        &:disabled {
            color: $placeholder-color;
            background-color: $control-disabled-bg;
            pointer-events: none;
            cursor: auto;
        }
    }

    .form-control-stepper {
        @extend .form-control;
        @extend .display-inline;
        font-size: 14px;
        width: 31px;
        height: 24px;
        flex: auto;
        margin-right: 2px;
        margin-left: 2px;
        text-align: center;
        border-radius: 0;
        border: none;
        color: $grey-4;

        &.disabled {
            color: $control-disabled;
            background-color: $control-disabled-bg;
        }

        &:focus {
            border-top: none;
            border-bottom: none;
            box-shadow: none;
        }
    }

    &-sm {
        width: 88px;
        padding: 3px;
        height: 24px;

        .btn-stepper {
            width: 24px;
            height: 18px;
            padding: 1px 4px;
        }

        .form-control-stepper {
            height: 22px;
            margin-left: 1px;
            margin-right: 1px;
            font-size: 12px;
        }
    }
}

/* End stepper */


/* Controls Groups */

.control-group {
    @extend .flex-row;
    height: 32px;
}

.control-group-item {
    font-family: $font-roboto-medium;
    border-radius: 3px;
    position: relative;
    height: 32px;
    cursor: pointer;
    @extend .transition;
}

.control-group-type01 {
    .control-group-item {
        color: $primary-3;
        font-size: 14px;
        background: $white-1;
        border: 1px solid $grey-2;
        padding: 6px 16px;
        margin-right: 8px;

        &:last-child {
            margin-right: 0;
        }

        &:hover,
        &.selected {
            color: $primary-2;
            background: $primary-6;
            border-color: $primary-2;
        }

        &:focus,
        &:active {
            color: $primary-1;
            background: $primary-6;
            border-color: $primary-1;
        }

        &:disabled {
            color: $grey-1;
            background: $grey-3;
            border-color: $grey-2;
        }
    }
}


/* Radio Buttons Square */

.control-radio-square {
    display: inline-block;
    height: 80px;
    position: relative;

    &:last-child {
        label {
            margin-right: 0;
        }
    }

    label {
        height: auto;
        min-height: 80px;
        line-height: 5.4;
        display: inline-block;
        margin-right: 20px;
    }

    input[type="radio"]:not(:checked),
    input[type="radio"]:checked {
        position: absolute;
        left: -9999px;
    }

    input[type="radio"]:not(:checked) + label,
    input[type="radio"]:checked + label {
        color: $primary-3;
        font-family: $font-roboto-medium;
        border-radius: 3px;
        border: solid 1px $control-border;
        background-color: $control-bg;
        width: 145px;
        text-align: center;
        position: relative;
        cursor: pointer;
        @extend .transition;
    }

    input[type="radio"]:not(:checked) + label:hover,
    input[type="radio"]:checked + label:hover,
    input[type="radio"]:checked + label {
        color: $primary-2;
        background-color: $primary-6;
        border-color: $primary-2;
    }

    input[type="radio"]:not(:checked) + label:active,
    input[type="radio"]:checked + label:active {
        color: $primary-1;
        background-color: $primary-6;
        border-color: $primary-1;
    }

    &.disabled {
        input[type="radio"]:not(:checked) + label {
            color: $grey-1;
            background-color: $grey-3;
            border-color: $grey-2;
            pointer-events: none;
        }
    }
}

/* Radio Buttons Square */


/* Control Group Slide */

.control-group-slide {
    position: relative;
    z-index: 1;
    align-self: flex-end;
    padding: 0;
    border: solid 1px $primary-4;
    border-radius: $app-brd-radius;

    &:hover {
        border-color: $control-hover;
    }

    &:active {
        border-color: $control-radio-check-focus;
    }

    &:disabled,
    &.disabled,
    &:disabled:hover,
    &.disabled:hover {
        border-color: $control-disabled;
        pointer-events: none;

        .control-group-item {
            color: $link-disabled;

            &.selected {
                color: $white-1;

                &:after {
                    background: $control-disabled;
                }
            }
        }
    }

    .control-group-item {
        color: $primary-3;
        font-size: 12px;
        position: static;
        border: 0;
        border-radius: 0;
        background: none;
        text-align: center;
        line-height: 28px;
        height: 28px;
        padding: 0;
        width: 50%;
        text-align: center;

        &:hover {
            color: $control-hover;
            background: none;
        }

        &:focus,
        &:active {
            color: $control-radio-check-focus;
        }

        &.selected {
            color: $white-1;

            &:focus,
            &:active {
                color: $white-1;
                background-color: transparent;
                border-color: transparent;
            }
        }

        &:first-child {
            &:after {
                content: ' ';
                position: absolute;
                top: 1px;
                bottom: 1px;
                left: calc(50% - 1px);
                z-index: -1;
                width: 50%;
                border-radius: $app-brd-radius;
                background-color: $primary-3;
                transition: all .20s ease-in-out;
            }

            &.selected {
                &:after {
                    left: 1px;
                }
            }
        }
    }
}

/* end Control Group Slide */

/* End Groups */
