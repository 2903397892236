﻿.container-new-bwic {
    .sub-header-row .status-message {
        margin-left: 0;
    }
    .dealers-table {
        .data-table-item-row {
            padding-left: 78px;
        }
    }
}
