﻿.container-main {
    @extend .container-flex;
    align-items: center;
    overflow: auto;
    width: 100%;
    padding: 20px 0;
}

.content-account {
    background-color: $white-1;
    min-width: 460px;
    max-width: 460px;
    flex: 0 0 auto;
    border-radius: 2px;
    box-shadow: $app-shadow;
    padding: 3.75rem 5rem;
    margin: auto;

    &.content-account-send,
    &.content-account-error {
        height: auto;
        max-width: 700px;
    }

    &.content-account-setup-pass,
    &.content-account-reset-pass {
        h2 {
            margin-bottom: 1.8rem;
        }
    }

    &.content-account-setup-pass,
    &.content-account-setup-pass-done {

        .password-requirements {
            margin: 32px 0;
        }
    }

    &.content-account-setup-pass-done {
        margin-top: 0;
    }

    h2 {
        text-align: center;
        margin-top: 14px;
        margin-bottom: 0;

        &.text-margin {
            margin-bottom: 4px;
        }
    }

    p {
        margin-bottom: 1.8rem;
    }

    .title {
        text-align: center;
        margin-bottom: 24px;

        .title-text,
        .loading-text {
            font: 24px/28px $font-roboto-medium;
            display: none;
        }

        .title-text {
            display: block;
        }
    }

    .form-row {
        margin-bottom: 20px;
        position: relative;
    }

    .authorization-actions {
        text-align: center;
        margin-top: 16px;
    }

    .link-forgot {
        font: 12px/16px $font-roboto-medium;
        position: relative;
        top: -12px;
        margin-bottom: 4px;
        display: inline-block;
    }

    .divider {
        margin-top: 8px;
        margin-bottom: 8px
    }
}

.content-login {
    min-width: 380px;
    max-width: 380px;
    margin: auto;
    background: none;
    box-shadow: none;
    padding: 20px 0;
    height: 549px;

    h2 {
        text-align: left;
        margin: 0;
        line-height: 28px;
    }

    form {
        position: relative;
        margin-bottom: 32px;

        &.is-loading {
            input {
                visibility: hidden;
            }
        }
    }

    .form-row {
        margin-bottom: 18px;
    }

    .container-loader {
        background-color: $white-1;
        bottom: 0;
        left: -1px;
        width: calc(100% + 2px);
    }

    .authorization-actions {
        text-align: left;

        p {
            margin: 30px 0 0;
        }
    }

    .go-back {
        font: 12px/16px $font-roboto-medium;
        text-decoration: none;
        padding: 16px 0 0;
        display: inline-block;

        &:hover {
            text-decoration: underline;
        }

        .icon {
            transform: scaleX(-1);
            margin: -1px 0 0;
        }
    }

    .status-message {
        margin-bottom: 20px;
    }
}

.content-account-wrapper {
    padding: 0 5rem 1.25rem;
    text-align: center;

    p {
        line-height: 1.67;
    }

    .icon {
        margin-bottom: 1.75rem;
    }

    .status-message {
        text-align: left;
        margin-bottom: 6px;
    }

    .form-row {
        text-align: left;
    }
}

.password-requirements {
    border-radius: $app-brd-radius;
    border: solid 1px $border-color;
    background-color: $grey-3;
    padding: 18px 23px 24px;
    color: $grey-4;

    p {
        font: 14px/18px $font-roboto-bold;
        margin: 0 0 20px;
    }

    ul {
        li {
            line-height: 18px;
            padding: 0 0 0 28px;
            position: relative;

            div:before {
                content: '';
                position: absolute;
                left: 0;
                background: $secondary-1;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                top: 5px;
            }

            div.is-validation-passed {
                color: $green-2;

                &:before {
                    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='11' height='8' viewBox='0 0 11 8'%3E%3Cpath fill='%231FCC90' fill-rule='nonzero' d='M9.206.234a.75.75 0 0 1 1.088 1.032l-5.696 6a.75.75 0 0 1-1.088 0L.206 3.786a.75.75 0 1 1 1.088-1.032l2.76 2.907L9.206.234z'/%3E%3C/svg%3E");
                    border-radius: 0;
                    width: 11px;
                    height: 8px;
                    top: 5px;
                }
            }
        }
    }
}

.footer-account {
    text-align: center;
    background-color: $grey-3;
    padding: 1.43rem 0;

    p {
        margin-bottom: 0;
    }

    a {
        text-decoration: none;
    }
}

.content-account-error {
    text-align: center;

    h2 {
        text-align: inherit;
    }

    .icon-svg {
        margin: 0 auto 20px;

        & + p {
            margin-bottom: 6px;
        }
    }

    .reset-pass-text {
        font-size: 16px;

        .btn-link {
            vertical-align: baseline;
            font-size: 16px;
            font-family: $font-family-base;
        }

        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.content-account-send {
    text-align: center;

    h1, .image, p {
        margin-bottom: 32px;
    }

    h1 {
        line-height: 26px;
    }

    p {
        .btn-link {
            vertical-align: baseline;
            font-size: 14px;
            font-family: $font-family-base;
        }
    }

    .support-info-list {
        li {
            justify-content: center;
        }
    }
}

.content-account-setup-pass-done {
    text-align: center;

    .icon-svg {
        margin: 20px auto 28px;
    }

    .status-message {
        margin-top: 20px;
        text-align: left;
    }

    p {
        font-size: 16px;
        margin-bottom: 10px;
    }

    a {
        text-decoration: none;
    }
}

@media only screen and (max-width: 720px) {
    .content-account {
        padding: 0 24px;
        min-width: 375px;
        width: 100%;
        box-shadow: none;

        &.content-account-send {
            padding: 56px 24px;
        }

        &.content-account-setup-pass,
        &.content-account-setup-pass-done {
            .password-requirements {
                margin: 20px 0 10px;
            }
        }

        &.content-account-setup-pass {
            .authorization-actions {
                margin: 20px 0;
            }
        }

        .title {
            margin-bottom: 24px;

            .icon-logo-bwic {
                display: none;
            }
        }

        .is-loading {
            .title {
                .title-text {
                    display: none;
                }

                .loading-text {
                    display: inline;
                }
            }
        }

        .form-label {
            margin-bottom: 5px;
        }

        .form-control {
            font-size: 16px;
            height: 40px;
        }

        .content-account-wrapper {
            padding: 0;
        }
    }
   .login-area.flex-row {
        align-items: baseline;
    }

    .content-login {
        padding: 20px;
        height: auto;
    }

    .password-requirements {
        padding: 18px 20px 24px;

        ul {
            li {
                padding: 0 0 0 20px;
            }
        }
    }

    .footer-account {
        background-color: $white-1;
        padding: 0;
    }
}

@media only screen and (min-width:1500px) and (max-width: 1920px) {
    .content-login {
        height: 622px;
    }
}
@media only screen and (min-width:1921px) and (max-width: 2560px) {
    .content-login {
        height: 717px;
    }
}

.divider {
    height: 32px;
    text-align: center;
    font: 12px/32px $font-roboto-regular;
    position: relative;

    &:after {
        content: '';
        width: 100%;
        height: 1px;
        background: $grey-5;
        position: absolute;
        top: 16px;
        left: 0;
    }

    span {
        background: $white-1;
        line-height: 28px;
        padding: 0 10px;
        position: relative;
        z-index: 1;
        display: inline-block;
    }
}
