﻿.bwic-monitor {
    &.data-list-freeze {
        &.data-list-striped {
            &.data-list {
                .data-list-content {
                    .data-list-columns {
                        margin-bottom: 44px;

                        & + .scroll-loader {
                            margin-top: -44px;
                        }
                    }
                }

                .component-collapsible-list-item-header {
                    .bwic-status {
                        .tooltip-wrapper {
                            display: inline-flex;
                        }
                    }

                    .bwic-state-my {
                        line-height: 18px;
                    }
                }

                .options-list {
                    .context-menu-dropdown {
                        top: 24px;
                    }
                }

                .data-list-cell {
                    &.cell-bid-padding {
                        padding-right: 48px;

                        .axed-final-icons {
                            width: 48px;
                            margin-right: -48px;

                            .final-icon-wrap {
                                margin-left: auto;
                            }
                        }
                    }

                    &.cell-right-fix {
                        .status {
                            display: inline-flex;
                        }
                    }
                }
            }
        }
    }

    &.data-list-monitor-bdSeller {
        .data-column-sticky-middle {
            .header-sticky-content {
                min-width: 1510px;
            }
        }
    }

    .btn-timeline {
        margin-right: 0;
    }

    .data-list-view-bwic {
        &.data-list-view-bwic-sell {
            .btn {
                margin-left: 6px;
            }
        }

        .export-bwic-data {
            display: inline-block;
            margin: 0 8px 0 -16px;
            vertical-align: middle;
        }
    }

    .seller-rejected {
        display: inline-block;
        margin-right: 8px;
    }

    .data-list-header .data-list-cell.data-list-cell-eval {
        padding-top: 0;
        padding-bottom: 0;
    }
}

.created-by-cnt {
    .pseudo-link {
        white-space: nowrap;
    }
}
