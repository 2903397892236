﻿/* Placeholders */

::-webkit-input-placeholder {
    color: $placeholder-color;
    font-family: $font-roboto-regular !important;
}

::-moz-placeholder {
    color: $placeholder-color;
    font-family: $font-roboto-regular !important;
    opacity: 1;
}

:-ms-input-placeholder {
    color: $placeholder-color !important;
    font-family: $font-roboto-regular !important;
}

:-moz-placeholder {
    color: $placeholder-color;
    font-family: $font-roboto-regular !important;
}

/* end Placeholders */


/* Form components rows */
.form-row {
    margin-bottom: 24px;
}

.form-row-inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;

    & > .form-item,
    & > .form-control-wrapper,
    & > .form-label,
    & > .form-control {
        flex: auto;
        margin-right: 10px;
    }

    & > .form-item:last-child {
        margin-right: 0;
    }
}
/* Form components rows */


input,
button,
select,
optgroup,
textarea {
    margin: 0;
    outline: none;
}

input[type=time] {
    &::-webkit-clear-button,
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        display: none;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

input.invisible {
    width: 0;
    height: 0;
    border: none;
    background-color: transparent;
    position: absolute;
    margin: 0;
    padding: 0;
}

textarea {
    line-height: 20px;
    overflow: auto;
    resize: none;

    &.form-control {
        padding: 6px 8px;
        font-family: $font-family-base;
        line-height: 20px;
        height: 120px;
    }
}

.form-label {
    color: $form-label;
    font-size: 12px;
    line-height: 14px;
    margin: 0 0 4px;
    display: block;

    &.form-label-lg {
        font-size: 14px;
    }

    &:empty {
        height: 10px;
    }
}

.form-control-wrapper {
    position: relative;
}

.form-control-loader {
    background-color: $grey-7;
    border: 1px solid $border-color;
    border-radius: $app-brd-radius;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;

    .loader-box {
        height: 100%;
    }
}

.form-control {
    color: $grey-4;
    font: 14px/#{$control-line-height} #{$font-family-base};
    background-color: $control-bg;
    border: solid 1px $control-border;
    border-radius: $app-brd-radius;
    height: $control-height;
    padding: 0 $control-padding-x;
    display: block;
    width: 100%;

    &:hover {
        border-color: $control-hover-brd;
    }

    &:focus {
        border-color: $control-focus;
    }

    &:disabled,
    &.disabled,
    .disabled & {
        color: $grey-1;
        -webkit-text-fill-color: $grey-1;
        background-color: $control-disabled-bg;
        border-color: $control-disabled;
        cursor: default;
    }

    .form-control-error &,
    &.is-invalid,
    &.invalidated:invalid {
        border-color: $control-error-brd;

        &:not(textarea) {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.80021 8.7998H7.2002V3.99981H8.80021V8.7998Z' fill='%23FF614D'/%3E%3Cpath d='M7.2002 12H8.80021V10.4H7.2002V12Z' fill='%23FF614D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1.3C11.7003 1.3 14.7 4.29969 14.7 8C14.7 11.7003 11.7003 14.7 8 14.7C4.29969 14.7 1.3 11.7003 1.3 8C1.3 4.29969 4.29969 1.3 8 1.3Z' fill='%23FF614D'/%3E%3C/svg%3E%0A");
            background-position: right 10px top 50%;
            background-repeat: no-repeat;
            padding-right: 30px;
        }
    }

    &-sm {
        height: $control-sm-height;
        line-height: $control-sm-line-height;
        font-size: 12px;
    }
}



input.cell-item-control,
input.form-control {
    line-height: 1;

    &::-ms-clear {
        display: none;
    }
}

.form-error {
    color: $white-1;
    font: 12px $font-family-base;
    background-color: $warning-3;
    border-radius: 0 0 3px 3px;
    white-space: nowrap;
    text-transform: none;
    text-align: left;
    height: 16px;
    padding: 0 8px;
    position: absolute;
    z-index: 10;

    &:before,
    &:after {
        content: "";
        position: absolute;
        top: -3px;
        left: 0;
        width: 3px;
        height: 3px;
        background-color: $warning-3;
    }

    &:after {
        background-color: $white-1;
        border-radius: $app-brd-radius;
        left: 1px;
        top: -5px;
        width: 4px;
        height: 4px;
    }
}

.form-error-right {
    right: 0;
    left: auto;

    &:before {
        left: auto;
        right: 0;
    }

    &:after {
        left: auto;
        right: 1px;
    }
}

.form-error-top {
    bottom: 100%;
    border-radius: 3px 3px 0 0;

    &:before {
        top: 100%;
    }

    &:after {
        top: 100%;
        margin-top: 1px;

        .form-control-changed & {
            background-color: $secondary-1;
        }
    }
}

.form-select {
    color: $grey-4;
    padding-right: 28px;
    max-width: 100%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: $white-1 url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.79629 2.92134C0.875134 2.03439 0.138719 1.33629 0 1.22551L1.06573 0C1.28216 0.172827 2.09949 0.951822 2.97625 1.79602C3.64086 2.43596 4.37176 3.14489 4.97712 3.734L8.82286 0.0487468L10 1.17677L4.96672 6L4.3782 5.42559C3.71313 4.77648 2.6935 3.78523 1.79629 2.92134Z' fill='%234A4A4A'/%3E%3C/svg%3E%0A") no-repeat right 10px top 50%;

    .disabled &,
    &:disabled,
    &.disabled {
        background-image: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.79629 2.92134C0.875134 2.03439 0.138719 1.33629 0 1.22551L1.06573 0C1.28216 0.172827 2.09949 0.951822 2.97625 1.79602C3.64086 2.43596 4.37176 3.14489 4.97712 3.734L8.82286 0.0487468L10 1.17677L4.96672 6L4.3782 5.42559C3.71313 4.77648 2.6935 3.78523 1.79629 2.92134Z' fill='%23BDBDBD'/%3E%3C/svg%3E%0A");
    }

    &.form-control.is-invalid {
        background:
            url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.79629 2.92134C0.875134 2.03439 0.138719 1.33629 0 1.22551L1.06573 0C1.28216 0.172827 2.09949 0.951822 2.97625 1.79602C3.64086 2.43596 4.37176 3.14489 4.97712 3.734L8.82286 0.0487468L10 1.17677L4.96672 6L4.3782 5.42559C3.71313 4.77648 2.6935 3.78523 1.79629 2.92134Z' fill='%234A4A4A'/%3E%3C/svg%3E%0A") no-repeat right 10px top 50%,
            url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.80021 8.7998H7.2002V3.99981H8.80021V8.7998Z' fill='%23FF614D'/%3E%3Cpath d='M7.2002 12H8.80021V10.4H7.2002V12Z' fill='%23FF614D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1.3C11.7003 1.3 14.7 4.29969 14.7 8C14.7 11.7003 11.7003 14.7 8 14.7C4.29969 14.7 1.3 11.7003 1.3 8C1.3 4.29969 4.29969 1.3 8 1.3Z' fill='%23FF614D'/%3E%3C/svg%3E%0A") no-repeat right 27px top 50%;
    }

    &.show {
        border-color: $control-focus;
        background-image:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjZweCIgdmlld0JveD0iMCAwIDEwIDYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoyOyI+PHBhdGggZD0iTTEuNzk2LDMuMDc5Yy0wLjkyMSwwLjg4NyAtMS42NTcsMS41ODUgLTEuNzk2LDEuNjk1bDEuMDY2LDEuMjI2YzAuMjE2LC0wLjE3MyAxLjAzMywtMC45NTIgMS45MSwtMS43OTZjMC42NjUsLTAuNjQgMS4zOTYsLTEuMzQ5IDIuMDAxLC0xLjkzOGwzLjg0NiwzLjY4NWwxLjE3NywtMS4xMjhsLTUuMDMzLC00LjgyM2wtMC41ODksMC41NzRjLTAuNjY1LDAuNjUgLTEuNjg1LDEuNjQxIC0yLjU4MiwyLjUwNVoiIHN0eWxlPSJmaWxsOiM0YTRhNGE7Ii8+PC9zdmc+');
    }

    &::-ms-expand {
        display: none;
    }

    &.form-select-time {
        display: inline-block;
        width: 50px;
        background-position: right .4rem top 50%;
        padding: 0 1.3rem 0 .3rem;
    }

    option {
        color: $grey-4;
        background: $white-1;

        &:disabled {
            background: $white-1;
        }
    }
}

select.form-select {
    &:focus:active {
        @media all and (-ms-high-contrast:none) {
            background: $white-1;
        }
    }
}


/* Search control + search list + search chips */

.control-search-wrapper {
    position: relative;
    border: solid 1px $control-border;
    border-radius: $app-brd-radius;
    display: flex;

    &:not(.disabled):hover {
        border-color: $control-hover-brd;
    }

    &.small-size {
        .form-control-search {
            height: 22px;
            font: 12px/14px $font-family-base;
        }

        .form-control-search-btn {
            height: 22px;

            .icon-search {
                margin-top: 3px;
            }
        }

        .btn-close {
            padding-top: 4px;
            padding-bottom: 4px;
        }

        .chip {
            line-height: 16px;
        }

        &.compact {
            &.disabled {
                pointer-events: none;
            }

            &.compact-expanded {
                width: 340px;
            }

            .form-control-search {
                min-width: 55px;
            }

            .form-control-search-btn {
                width: 22px;
                cursor: pointer;
            }

            .chip-list {
                max-width: 225px;
            }

            .search-lookup table td.col-main {
                width: 60%;
            }

            .search-lookup table td.col-ellipsis {
                width: 55%;
            }
        }
    }

    &.control-search-focus,
    &.control-search-focus:hover {
        border-color: $control-focus;
    }

    &.disabled {
        background-color: $control-disabled-bg;

        .form-control-search-btn {
            background-color: $control-disabled-bg;

            .icon-search {
                opacity: .4;
            }
        }
    }

    &.is-invalid {
        border-color: $control-error-brd;

        &:hover {
            border-color: $control-error-brd;
        }

        .btn-close {
            margin-left: -14px;
        }
    }

    .form-control-search {
        font: 14px/22px $font-family-base;
        background: none;
        box-shadow: none;
        border: 0;
        border-radius: 0;
        outline: none;
        padding-right: 10px;
        padding-left: 0;
        height: 30px;
        min-width: 100px; //FF fix

        &::-ms-clear {
            display: none;
        }

        &::placeholder {
            color: $placeholder-color;
        }

        &:-ms-input-placeholder {
            color: $placeholder-color;
        }
    }

    .form-control-search-btn {
        width: 31px;
        height: 30px;
        background: none;
        text-align: center;
        flex: none;

        .icon-search {
            outline: none;
            vertical-align: baseline;
            margin: 7px 0 0;
        }
    }

    .btn-close {
        margin: -1px;
        flex: none;
    }

    .search-lookup {
        background-color: $white-1;
        box-shadow: $app-shadow;
        border: solid 1px $border-color;
        max-height: 400px;
        overflow: auto;
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        z-index: 5;

        &.search-lookup-hide {
            display: none;
        }

        &.search-lookup-top {
            bottom: 100%;
            top: auto;
        }

        .empty-placeholder {
            @extend .container-flex;
            justify-content: center;
            height: 320px;
            margin: 0;
        }

        .placeholder {
            font-size: 22px;
        }

        table {
            line-height: 32px;

            tr {
                &:hover {
                    cursor: pointer;

                    td {
                        background-color: $primary-6;
                    }
                }

                &.active {
                    td {
                        background-color: $row-active;
                    }
                }
            }

            td {
                color: $grey-8;
                padding: 0 9px;
                width: 25%;

                &.col-main {
                    color: $grey-4;
                    text-transform: uppercase;
                    width: 35%;
                }

                &.col-ellipsis {
                    width: 65%;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }
    }

    &.control-search-groups {
        .search-lookup {
            width: 100%;

            table {
                th, td {
                    text-align: left;
                    text-transform: capitalize;
                    border-bottom: 1px solid $border-color;
                }

                th {
                    font-size: 12px;
                    line-height: 12px;
                    background-color: $table-header-bg;
                    padding: 7px 20px;
                }

                td {
                    padding: 0 20px;
                }

                tr:last-child {
                    td {
                        border-bottom: none;
                    }
                }
            }
        }
    }
}

/* end Search control + search list + search chips */


/* Input with bookmark */

.form-control-changed {
    position: relative;

    &:before,
    &:after {
        content: ' ';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        border-style: solid;
    }

    &:before {
        border-width: 10px 10px 0 0;
        border-color: $primary-3 transparent transparent;
    }

    &:after {
        border-width: 1px 1px 0 0;
        border-color: $white-1 transparent transparent;
    }

    &.form-control-error {
        &:before {
            border-color: $secondary-1 transparent transparent;
        }

        .form-control {
            border-color: $control-error-brd;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.80021 8.7998H7.2002V3.99981H8.80021V8.7998Z' fill='%23FF614D'/%3E%3Cpath d='M7.2002 12H8.80021V10.4H7.2002V12Z' fill='%23FF614D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1.3C11.7003 1.3 14.7 4.29969 14.7 8C14.7 11.7003 11.7003 14.7 8 14.7C4.29969 14.7 1.3 11.7003 1.3 8C1.3 4.29969 4.29969 1.3 8 1.3Z' fill='%23FF614D'/%3E%3C/svg%3E%0A");
            background-position: calc(100% - 7px) 50%;
            background-repeat: no-repeat;
            padding-right: 30px;
        }
    }
}

/* end Input with bookmark */
