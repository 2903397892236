﻿.tfa-container {
    overflow: auto;

    .tfa-verify {
        width: 100%;
        max-width: 380px;
        margin: 100px auto 40px;

        @media only screen and (max-width: 720px) {
            padding: 20px;
        }

        h2 {
            margin-bottom: 20px;
        }

        p {
            line-height: 21px;
            margin-bottom: 20px;
            word-break: break-word;
        }

        .control-radio-square {
            width: 100%;

            input[type="radio"]:not(:checked) + label,
            input[type="radio"]:checked + label {
                width: 100%;
                text-align: left;
                padding: 19px 16px;
            }

            label {
                font: 14px/16px $font-roboto-medium;
                color: $grey-4;

                span {
                    font: 14px/16px $font-roboto-regular;
                    margin-top: 10px;
                    display: inline-block;
                }
            }

            .btn-enable {
                margin: -38px 0 0 15px;
                position: relative;
            }
        }

        .control-label {
            margin: 2px 0 0;

            span {
                top: -2px;
                position: relative;
            }
        }

        .btn-full-width {
            margin-top: 10px;
            margin-bottom: 30px;
        }

        .btn-link {
            white-space: normal;
            display: inline;
        }

        .re-send-section {
            margin-bottom: 30px;

            .timer {
                display: inline-block;

                .icon {
                    display: none;
                }
            }
        }

        .trouble {
            .btn-link {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.timer-area {
    margin: -10px 0 25px;
    height: 32px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .timer {
        &.time-ends-soon {
            color: $secondary-1;
        }

        .icon {
            margin-right: 10px;
        }

        span {
            vertical-align: sub;
            font-size: 16px;
        }
    }
}
