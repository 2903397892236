﻿.profile-users {
    padding-bottom: 32px;

    .sub-title {

        .status {
            display: inline-block;
            margin-left: 24px;
            position: relative;
            top: -3px;
        }
    }

    .data-list {
        &:not(.data-list-freeze) {
            .data-list-container {
                min-width: 872px;
            }
        }

        &-content {
            &:after {
                display: none;
            }

            .data-list-user-role {
                color: $grey-8;
            }
        }

        &-header,
        &-row {
            padding-left: 24px;
            padding-right: 24px;
        }

        &-header {
            padding-right: 41px;
        }

        &-user-role {
            width: 105px;

            .text-ellipsis {
                display: block;
            }
        }

        &-user {
            width: 135px;

            .text-ellipsis {
                display: block;
                max-width: 100%;
                width: auto;
            }

            .popover-header {
                h1 {
                    margin-right: 25px;
                    word-break: break-word;
                }
            }

            .popover-body {
                padding-top: 4px;
            }
        }

        &-status,
        &-job-title {
            width: 125px;
            overflow: hidden;
        }

        &-reg-date {
            width: 82px;
        }

        &-reg-by {
            overflow: hidden;
            flex: 1 1 185px;
            min-width: 185px;
            max-width: none;
        }

        &-email {
            flex: 1 1 150px;
            min-width: 150px;
            max-width: none;
            overflow: hidden;
        }

        &-phone {
            @include flex-basis(110px);
            overflow: hidden;
        }

        &-options {
            width: 53px;

            .context-menu {
                ul {
                    right: 1px;
                    left: auto;
                }
            }
        }
    }

    .user-form {
        margin-top: -8px;

        .form-item {
            display: flex;
        }

        .form-label {
            flex: 0 0 240px;
            color: $grey-8;
            font-size: 14px;
            line-height: 32px;
        }

        .form-row-role-selector {
            margin-top: -6px;
            margin-bottom: 14px;

            .form-control-wrapper {
                margin-bottom: 10px;
            }

            .radio-group {
                margin-bottom: 0;

                .radio {
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .form-control-wrapper {
            flex: 0 0 366px;

            .radio-group {
                .radio {
                    margin-top: 8px;
                    margin-bottom: 8px;
                }

                .checkbox {
                    margin: 0 0 8px 20px;
                }
            }
        }
    }

    .notify-options {
        margin-bottom: 24px;

        &-checkboxes {
            margin-bottom: 4px;

            .checkbox {
                margin-right: 16px;
            }
        }
    }
}
