﻿.data-list-freeze {
    .data-column-sticky-middle {
        .component-collapsible-list-item-content {
            .data-list-row {
                position: relative;
                padding-left: 48px;
            }
        }

        .data-list-header {
            &.collapsed-content-level-1 {
                padding-left: 80px;
            }

            &.collapsed-content-level-2 {
                padding-left: 72px;
            }
        }

        .data-list-row {
            &.collapsible-list-shift {
                .data-list-cell {
                    &:first-child {
                        margin-left: 24px;
                        padding-left: 8px;
                    }
                }
            }

            &.collapsed-content-level-1 {
                padding-left: 80px;
            }

            &.collapsed-content-level-2 {
                padding-left: 72px;
            }
        }
    }

    .data-column-sticky-left {
        & + .data-column-sticky-middle {
            .component-collapsible-list-item-content {
                .data-list-row {
                    padding-left: 16px;
                }
            }
        }
    }

    .component-collapsible-list-item {
        &.expanded:last-child {
            border-bottom: none;
        }
    }

    .data-column-sticky-middle {
        .component-collapsible-list-item {
            .data-list-cell {
                &:first-child {
                    padding-left: 8px;
                }
            }
        }
    }

    .data-column-sticky-right {
        .component-collapsible-list {
            .empty-placeholder {
                height: 32px;
            }
        }
    }
}
