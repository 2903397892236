﻿.status-message {
    border-radius: 4px;
    border-left-width: 3px;
    border-left-style: solid;
    padding: 8px 16px 8px 13px;
    font: 14px/16px $font-roboto-regular;
    width: 100%;
    display: flex;
    flex-direction: row;

    &.info {
        background-color: $blue-2;
        border-left-color: $blue-1;
    }

    &.error {
        background-color: $warning-5;
        border-left-color: $secondary-1;
    }

    &.alert {
        background-color: $yellow-4;
        border-left-color: $brown-1;
    }

    &.success {
        background-color: $green-3;
        border-left-color: $green-9;
    }

    &.icon-only {
        padding: 0;
        border-left: 0;
        background: none;
    }

    .icon {
        margin-right: 8px;
        flex: none;
    }

    &-cnt {
        display: inline-block;
        word-break: break-word;
        line-height: 1.4;
        margin: -2px 0;

        .icon {
            margin: 0;
            vertical-align: top;
        }

        a {
            &:hover {
                text-decoration: underline;
            }
        }

        p:last-child,
        .notification-message {
            margin-bottom: 0;
        }
    }
}
