﻿.notification-panel {
    width: 360px;
    border: 1px solid $grey-5;
    box-shadow: $app-shadow;
    background: $white-1;
    position: absolute;
    top: 100%;
    right: 0;
    font-size: 12px;
    max-height: 0;
    transition: 0.6s ease-in-out;
    visibility: hidden;
    overflow: hidden;

    &.show {
        max-height: 600px;
        visibility: visible;
    }

    .title {
        padding: 0 16px;
        line-height: 46px;
        border-bottom: 1px solid $grey-5;

        h3 {
            margin: 0;
        }

        .btn-link {
            &:hover {
                text-decoration: underline;
            }
        }
    }

    .notification-cnt {
        height: auto;
        max-height: 552px;
        overflow: auto;

        .placeholder {
            padding: 15px 16px;
            margin: 0;
            color: $grey-8;
        }
    }

    .notifications-block {
        border-top: 1px solid $grey-5;

        &:first-child {
            border: 0;
        }

        h6 {
            padding: 16px 16px 8px;
            margin: 0;
        }

        .box {
            border-top: 1px solid $grey-5;
            padding: 16px 16px 16px 39px;
            line-height: 14px;

            &.new {
                background-color: $primary-6;
            }

            .alert-badge {
                margin: 4px 15px 0 -23px;
            }

            .subject {
                flex: 1;
                font-family: $font-roboto-medium;
            }

            .time {
                white-space: nowrap;
                padding-left: 16px;
                align-self: flex-start;
                font-family: $font-roboto-medium;
            }

            p {
                margin: 10px 0 0;
            }
        }
    }
}
