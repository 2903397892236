﻿/* Advanced filters with DropDown */

.control-filter-select {
    display: inline-block;
    position: relative;
    margin-right: 8px;

    .empty-placeholder {
        @extend .container-flex;
        justify-content: center;
        width: 100%;
        height: 198px;
        margin: 0;
        padding: 32px 2px;
    }
}

.control-filter-select-btn {
    color: $text-color;
    font-size: 12px;
    font-family: $font-roboto-medium;
    white-space: nowrap;
    height: 24px;
    padding: 0 8px;
    border-radius: $app-brd-radius;
    background-color: $grey-7;
    border: 1px solid transparent;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    @extend .transition;

    &:hover {
        background-color: $primary-6;
    }

    &.expanded {
        background-color: $white-1;
        border: 1px solid $color-focus;

        &:hover {
            background-color: $primary-6;
        }

        .expand-clear-toggle {
            line-height: 22px;
            border-left-color: $row-divider-color;
        }
    }

    &.applied {
        background-color: $primary-8;
        border: 1px solid $primary-8;
    }

    &.changed {
        border: 1px solid $primary-13;

        .expand-clear-toggle {
            border-color: $primary-13;
        }
    }

    &.error {
        color: $warning-3;
        background-color: $warning-5;
        border: 1px solid transparent;

        &:hover {
            color: $warning-2;
            background-color: $warning-5;
        }

        .expand-clear-toggle {
            border-left-color: $white-1;
        }

        &.expanded {
            color: $warning-2;
            background-color: $warning-5;
            border-color: $warning-2;

            .expand-clear-toggle {
                border-left-color: $warning-2;
            }
        }
    }

    &.disabled {
        border-color: $grey-2;
        color: $button-disabled;
        background-color: $grey-7;
        pointer-events: none;
    }

    .selected-data {
        display: inline-block;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 160px;
    }

    .expand-clear-toggle {
        line-height: 24px;
        border-left: 1px solid $white-1;
        padding-left: 6px;
        margin-left: 6px;
        padding-right: 8px;
        margin-right: -8px;

        .icon-svg-icon-expand {
            transform: rotate(90deg);
        }

        .icon-svg-close {
            margin-top: -2px;
        }
    }
}

.control-filter-select-btn-content {
    display: inline-flex;
    align-items: center;
}

.control-filter-content {
    background-color: $white-1;
    border: 1px solid $row-divider-color;
    box-shadow: $app-shadow;
    padding: 8px 12px 0;
    position: absolute;
    left: 0;
    top: 28px;
    z-index: 4;

    &.control-filter-content-datepicker {
        padding: 10px 0 0;
    }

    .btn-link {
        vertical-align: top;
    }
}

.control-filter-content-select {
    max-height: 453px;
    min-width: 117px;
    overflow: auto;

    &.filter-select-and-search {
        max-height: 450px;
        width: 350px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden; //IE fix
    }

    .control-search-wrapper {
        width: 100%;
        margin-bottom: 12px;
        flex: none;
    }

    .btn-link {
        margin-bottom: 8px;
    }

    ul,
    .radio-group {
        margin: 0 -12px;
    }

    .checkbox,
    .radio {
        height: auto;
        width: 100%;
        padding: 8px 12px;

        &:hover {
            background-color: $primary-6;
        }

        .checkbox-lbl-text {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    ul {
        li {
            line-height: 16px;
            display: flex;
            flex-direction: row;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .control-filter-select-list {
        .checkbox-lbl-text {
            white-space: nowrap;
        }
    }

    .radio-group {
        .radio {
            margin-top: 0;
            margin-bottom: 0;
            white-space: nowrap;
        }
    }

    hr {
        border-top-color: $border-color;
        flex: none;
        width: calc(100% + 24px);
        margin: 8px -12px;
        transform: translateZ(0);
    }

    .control-filter-item-tree {
        display: block;

        & > .checkbox {
            font-family: $font-roboto-medium;
        }

        .control-filter-list-tree {
            width: 100%;
            margin: 0;

            .control-filter-sub-item-tree {
                position: relative;

                .checkbox {
                    padding: 8px 80px 8px 32px;

                    & + span {
                        pointer-events: none;
                        position: absolute;
                        color: $grey-8;
                        font-size: 12px;
                        right: 12px;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
            }
        }
    }
}

.filter-select-and-search {
    ul {
        width: calc(100% + 24px);
        overflow: auto;

        &.control-filter-select-list {
            max-height: 350px;
            flex: auto;
        }

        &.control-filter-select-list-selected {
            max-height: 120px;
            flex: none;
        }
    }

    .btn-link {
        flex: none;
    }
}

.control-filter-settings {
    text-align: left;
    margin-right: 0;

    .control-filter-select-btn {
        font-size: 10px;
        font-family: $font-roboto-bold;
        text-transform: uppercase;
        @extend .btn-ghost;

        &.expanded {
            color: $primary-1;
            background-color: $button-ghost-hover;
            border-color: $primary-1;
        }
    }

    .icon-svg {
        margin-right: 4px;
    }

    .control-filter-content-select.filter-select-and-search {
        width: 232px;
    }

    .control-filter-content-select .control-search-wrapper {
        margin-bottom: 12px;
    }
}

.control-filter-content-range {
    min-height: 133px;
    width: 360px;
    padding-bottom: 30px;
}

.control-filter-range-row {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin: 16px 10px 0;

    .dash {
        font-size: 15px;
        margin: 0 14px 4px;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: button;
    }
}

.control-filter-content-date {
    width: 182px;
    padding-left: 0;
    padding-right: 0;
    display: flex;
    flex-direction: row;

    &.control-filter-content-datepicker,
    &.control-filter-content-year-range {
        width: 696px;
        height: 374px;
        padding: 0;

        &[data-turn-right] {
            .control-filter-range-list {
                order: 2;
            }

            .custom-view-year-range,
            .custom-view-item-body {
                border-right: solid 1px $border-color;
                border-left: none;
            }
        }
    }

    &.control-filter-content-single-date {
        width: 442px;
    }

    &.control-filter-content-datepicker {
        height: auto;

        .custom-view-item-footer {
            border-left: 0;
        }
    }

    .control-filter-range-list {
        flex: 0 0 180px;
        min-width: 180px;
        max-width: 180px;

        .btn-link {
            margin: 8px 12px;
        }
    }

    .control-filter-date-list {
        li {
            font-size: 12px;
            line-height: 1.3335;
            display: block;
            width: 100%;
            padding: 8px 12px;
            cursor: pointer;
            @extend .transition;

            &:hover {
                background-color: $row-active;
            }

            &.selected {
                font-family: $font-roboto-medium;
                background-color: $row-active;
            }
        }
    }
}

.control-filter-content-year-range-input {
    .year-range {
        font-size: 12px;
        color: $grey-8;
    }

    .custom-view-item-body .separator-line {
        margin-bottom: 25px;
    }
}

.control-filter-content-year-range {
    .control-filter-range-row {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin: 0;

        .dash {
            margin-top: 28px;
        }
    }

    .custom-view-year-range {
        flex: 0 0 514px;
        max-width: 514px;
        min-width: 514px;
        padding: 20px;
        border-left: 1px solid $border-color;
    }
}

.control-filter-content-datepicker {
    .custom-view-item {
        padding-left: 0;
        width: 514px;
        position: relative;
        box-shadow: none;
        border: none;
        top: 0;
        left: 0;
        border-left: 1px solid $border-color;
        display: flex;
        flex-direction: column;
    }

    .custom-view-item-body {
        padding-bottom: 24px;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-left: none;
    }

    &:not(.control-filter-content-date) {
        width: 516px;

        .btn-link {
            margin-left: 21px;
        }

        .custom-view-item-body {
            border-left: none;
        }
    }
}

.control-filter-content-color {
    align-items: stretch;
    padding: 0;

    .control-filter-content-select {
        min-width: 180px;
        padding: 8px 12px 0;
    }
    
    .control-filter-content-range {
        width: 390px;
        border-left: 1px solid $border-color;
    }

    .control-filter-range-row {
        margin: 0;
        padding: 24px;

        .form-control {
            max-width: 150px;
            text-align: right;
        }

        .form-error {
            height: auto;
            white-space: wrap;
        }
    }
}

.vertical-separator {
    color: $grey-2;
    margin: 0 8px;
}

.wide-range-date {
    .control-filter-content-date {
        width: 160px;

        .control-filter-range-list {
            @include flex-basis(158px);
        }

        &.control-filter-content-datepicker {
            width: 674px;
        }
    }
}