.eval-lp {
    min-width: 1280px;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    color: $grey-10;

    &-container {
        max-width: 1070px;
        padding: 0 15px;
        margin: 0 auto;
    }

    &-section {
        padding: 80px 0;
    }

    h1 {
        font: 64px/1.2 $font-roboto-bold;
        letter-spacing: 1.92px;
        text-transform: uppercase;
    }

    h2 {
        font: 40px/1.4 $font-roboto-medium;
    }

    h3 {
        font: 24px/1.6 $font-roboto-medium;
    }

    p {
        font-size: 18px;
        line-height: 1.6;
    }

    .eval-head {
        min-height: 597px;
        display: flex;
        align-items: center;
        position: relative;

        &-content {
            h1 {
                margin-bottom: 16px;
            }

            p {
                font-size: 24px;
                margin-bottom: 40px;
            }
        }

        img {
            width: 1348px;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: -1;
        }
    }

    .regtech-pricing-service {
        padding-top: 56px;

        h2 {
            margin-bottom: 16px;
        }

        &-info {
            margin-bottom: 80px;
        }

        &-row {
            display: flex;
            flex-wrap: wrap;
            gap: 32px;
        }

        &-col {
            width: calc(50% - 16px);

            h3 {
                background: linear-gradient(262deg, #6AEAFF -19.85%, #204FFF 75.5%);
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
                margin-bottom: 8px;
            }

            p {
                margin: 0;
            }
        }
    }

    .market-data-input {
        position: relative;
        display: flex;
        align-items: center;

        &:before {
            position: absolute;
            content: '';
            top: 0;
            left: -100vw;
            width: 200vw;
            height: 100%;
            background-color: $grey-7;
            z-index: -1;
        }

        &-image {
            width: 50%;
            display: flex;
            justify-content: center;

            img {
                width: 307px;
            }
        }

        &-content {
            width: 50%;
            display: flex;
            justify-content: center;

            h2, p {
                margin-bottom: 24px;
            }

            ul {
                font: 18px/1.6 $font-roboto-medium;
                margin-left: 12px;

                li {
                    position: relative;
                    margin-bottom: 16px;
                    padding-left: 12px;

                    &:before {
                        position: absolute;
                        content: '';
                        width: 4px;
                        height: 4px;
                        border-radius: 50%;
                        background-color: $grey-10;
                        left: 0;
                        top: 12px;
                    }
                }
            }
        }
    }

    .methodology {
        h2 {
            position: relative;
            margin-bottom: 80px;

            &:before {
                position: absolute;
                content: '';
                width: 744px;
                height: 2px;
                background: linear-gradient(90deg, rgba(#55BFFF, 1) 0%, rgba(#2150FF, 1) 100%);
                right: 0;
                top: 30px;
            }
        }

        &-row {
            display: flex;
            justify-content: flex-end;
            align-items: flex-start;
            gap: 35px;
        }

        &-col {
            width: calc(33% - 27px);
            border-radius: $app-brd-radius;
            padding: 24px;
            border: 1px solid;
            position: relative;
            z-index: 0;

            &:nth-child(1) {
                border-color: #6BEBFF;

                .methodology-index {
                    border: 1px solid #6BEBFF;

                    &:before {
                        background: linear-gradient(205deg, #6AEAFF 33.07%, #204FFF 183.34%);
                    }
                }
            }

            &:nth-child(2) {
                border-color: #4FB1FF;

                .methodology-index {
                    border: 1px dashed #52B7FF;;
                   
                    &:before {
                        background: linear-gradient(206deg, #6AEAFF -3.95%, #204FFF 143.51%);
                    }
                }
            }

            &:nth-child(3) {
                border-color: #6299FF;

                .methodology-index {
                    border: 1px solid #AD81FF;
                    width: 52px;
                    height: 52px;
                    top: -26px;
                    left: -26px;
                    
                    &:before {
                        background: linear-gradient(213deg, #6AEAFF -96.05%, #47A1FF 3.44%, #AD81FF 114.46%);
                    }

                    &:after {
                        position: absolute;
                        content: '';
                        width: 48px;
                        height: 48px;
                        border-radius: 50%;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border: 1px solid #AD81FF;
                    }
                }
            }

            h3 {
                margin-bottom: 16px;
            }

            p {
                margin: 0;
            }
        }

        &-index {
            position: absolute;
            top: -24px;
            left: -24px;
            width: 48px;
            height: 48px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: $white-1;
            z-index: 2;

            &:before {
                position: absolute;
                content: attr(data-number);
                width: 40px;
                height: 40px;
                border-radius: 50%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 18px;
                color: $white-1;
            }
        }
    }

    .faq {
        h2 {
            margin-bottom: 24px;
        }

        .collapsable-item-header {
            font: 18px/1.67 $font-roboto-bold;
            padding: 16px 48px 16px 24px;
            height: auto;
            position: relative;

            &:hover {
                background-color: transparent;
            }

            .icon-svg {
                position: absolute;
                right: 24px;
                top: 50%;
                transform: translateY(-50%) scale(1.5);
            }
        }

        .accordion-cnt {
            padding: 0 24px 16px 24px;
            font-size: 18px;
            line-height: 1.5;
            border-top: none;
        }

        .accordion-row {
            &:first-child {
                border-top: 1px solid $border-color;
            }

            &.item-open {
                .collapsable-item-header {
                    padding-bottom: 8px;
                }
            }
        }
    }

    .free-trial {
        padding-top: 56px;

        &-box {
            border: 2px solid #55BFFF;
            border-radius: 6px;
            padding: 60px;

            h2 {
                text-align: center;
                margin-bottom: 32px;
            }

            p {
                margin: 0;
            }
        }

        &-head {
            padding-bottom: 32px;
            margin-bottom: 32px;
            border-bottom: 1px solid $grey-4;

            p + p {
                margin-top: 32px;
            }
        }
    }
}