﻿.component-collapsible-list-item {
    display: flex;
    flex-direction: column;

    &:hover {
        background-color: $row-hover;
    }

    &.expanded {
        & > .component-collapsible-list-item-header {
            &:not(.pending-trade) {
                background-color: $row-active;
            }
        }
    }
}

.component-collapsible-list-item-header {
    border: 0;

    &:before {
        margin-right: 8px;
    }

    &.pending-trade {
        & + .component-collapsible-list-item-content {
            .data-list-header,
            .data-list-row {
                background: $row-pending;
            }

            .data-list-row {
                &:hover,
                &.hover {
                    background-color: $row-hover;
                }
            }

            .data-list-cell {
                background: none;
            }
        }
    }

    .text-ellipsis {
        width: auto;
        max-width: 100%;
    }
}

.component-collapsible-list-item-content {
    .component-collapsible-list-item-wrap {
        border-bottom: .5px solid $border-color;

        .data-list-row:last-of-type {
            border-bottom: none;
        }
    }

    .data-list-freeze.data-list {
        .data-list-content {
            overflow-y: hidden;

            .data-list-row:last-child {
                border-bottom: none;
            }

            .data-list-columns {
                min-height: 0;
                margin-bottom: 0;
            }
        }

        .fake-horizontal-scroll {
            display: none;
        }
    }

    .container-loader {
        position: static;
        min-height: 32px;
    }
}
