﻿.portfolio {
    .sub-header {
        .portfolio-name-title {
            max-width: 350px;
            display: block;
            white-space: nowrap;
        }
    }

    &-cnt {
        flex-direction: row;

        .empty-placeholder {
            align-self: flex-start;
        }

        .data-list-cell-portfolio-ticker {
            @include flex-basis(180px);

            .checkbox,
            .checkbox-lbl-text {
                width: 100%;
            }
        }

        .data-column-sticky-left {
            .component-collapsible-list-item-header {
                .number-of-selected {
                    margin-left: 8px;
                    vertical-align: bottom;
                    color: $primary-3;
                    font: 12px/16px $font-roboto-regular;
                }
            }

            .component-collapsible-list-item-content {
                .data-list-row {
                    padding-left: 48px;

                    .checkbox {
                        margin-right: 4px;
                    }
                }
            }
        }

        .data-column-sticky-right {
            .portfolio-alert-selector {
                margin-right: 16px;
            }

            .context-menu:not(.alert-menu) {
                .icon-svg {
                    margin-right: 0;
                }
            }
        }

        .today {
            color: $secondary-1;
        }
    }
}

.modal-portfolio-confirmation {
    .modal-content {
        width: 635px;
    }

    .status-message {
        margin-bottom: 20px;
    }

    .bwic-info-list {
        height: 195px;
        overflow: auto;
        padding-right: 15px;
    }

    .bwic-info {
        margin: 0 0 8px;
        padding: 0 0 0 23px;

        .bwic-status {
            width: 110px;
        }

        & > span {
            margin-right: 20px;

            &.bwic-state-my {
                width: 23px;
                margin-right: 0;
                margin-left: -23px;
                vertical-align: top;
            }

            &.due-date {
                width: 165px;
            }
        }
    }
}


.modal-add-to-portfolio {
    .modal-content {
        min-height: 0;
    }
}