﻿.progress-steps {
    li {
        counter-increment: stepNum;
        position: relative;
        display: inline-flex;
        margin-right: 12px;
        align-items: center;
        color: $grey-4;

        &:last-child {
            margin-right: 0;

            &:after {
                display: none;
            }
        }

        &:after {
            width: 16px;
            height: 16px;
            content: '';
            pointer-events: none;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M7.92134 11.2037C7.03439 12.1249 6.33629 12.8613 6.22551 13L5 11.9343C5.17283 11.7178 5.95182 10.9005 6.79602 10.0238C7.43596 9.35914 8.14489 8.62824 8.734 8.02288L5.04875 4.17714L6.17677 3L11 8.03328L10.4256 8.6218C9.77648 9.28687 8.78523 10.3065 7.92134 11.2037Z' fill='%23BDBDBD'/%3E%3C/svg%3E%0A");
            display: inline-block;
            margin: 0 0 0 12px;
        }

        &:before {
            text-align: center;
            width: 32px;
            height: 32px;
            line-height: 32px;
            background-color: $grey-3;
            border-radius: 50%;
            margin-right: 8px;
            display: inline-block;
            content: counter(stepNum);
            font-family: $font-roboto-medium;
        }

        &.is-active {
            font-family: $font-roboto-medium;

            &:before {
                color: $white-1;
                background-color: $primary-3;
            }
        }

        &.passed {
            &:before {
                text-indent: -9999px;
                background-color: $primary-8;
                background-image: url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.23119 9.01923L0.696903 5.26442L0 6.00481L3.53429 9.75962L4.23119 10.5L11.25 3.04327L10.5531 2.25L4.23119 9.01923Z' fill='%230096AA'/%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-position: 55% 50%;
            }

            &.is-active {
                font-family: $font-roboto-regular;
            }
        }

        &.warning {
            &:before {
                background-color: $warning-5;
                content: "!";
                color: $warning-1;
            }
        }
    }
}
