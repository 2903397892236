﻿.breadcrumbs-list {
    margin: 0;
    line-height: 12px;

    li {
        font-size: 12px;
        line-height: 14px;
        display: inline-block;
        padding-right: 16px;
        margin-right: 4px;
        background:
            url("data:image/svg+xml,%3Csvg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.941 8.40278C5.27579 9.09365 4.75222 9.64596 4.66913 9.75L3.75 8.9507C3.87962 8.78838 4.46387 8.17538 5.09702 7.51782C5.57697 7.01935 6.10867 6.47118 6.5505 6.01716L3.78656 3.13286L4.63257 2.25L8.25 6.02496L7.8192 6.46635C7.33236 6.96515 6.58892 7.72988 5.941 8.40278Z' fill='%23BDBDBD'/%3E%3C/svg%3E%0A")
            no-repeat 100% 50%;

        &:last-child {
            margin-right: 0;
            padding-right: 0;
            background: none;
        }

        a {
            &:hover {
                text-decoration: none;
            }
        }
    }
}
