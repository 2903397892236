﻿$pi: 3.14159265358979;
$extRadius: 40;
$intRadius: 20;
$extCircumference: 2 * $pi * $extRadius;
$intCircumference: 2 * $pi * $intRadius;
$rotationTime: 1s;
$morfingTime: 4s;

.container-loader {
    background-color: $primary-6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.container-loader,
.loader-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    figure {
        color: $blue-1;
        margin: 0;

        circle {
            stroke-width: 8px;
            stroke-linecap: round;
        }

        .preloader {
            margin: 0 auto;
            width: 48px;
            height: 48px;
            display: block;
            line-height: 1;
            animation: spin $rotationTime linear infinite;

            &.sm {
                width: 16px;
                height: 16px;
            }
        }
    }

    svg {
        vertical-align: baseline;

        #external-circle {
            stroke-dasharray: $extCircumference, 1;
            stroke-dashoffset: 0;
            animation: edash $morfingTime ease-in-out infinite;

            @supports (-ms-ime-align: auto) {
                animation: none;
            }
        }

        #internal-circle {
            stroke-dasharray: $intCircumference, 1;
            stroke-dashoffset: 0;
            animation: idash $morfingTime ease-in-out infinite;

            @supports (-ms-ime-align: auto) {
                animation: none;
            }
        }
    }

    @keyframes edash {
        0% {
            stroke-dasharray: $extCircumference, 1;
            stroke-dashoffset: 0;
        }

        50% {
            stroke-dasharray: 1, $extCircumference;
            stroke-dashoffset: -$extCircumference + 1;
        }

        100% {
            stroke-dasharray: $extCircumference, 1;
            stroke-dashoffset: -$extCircumference + 1;
        }
    }

    @keyframes idash {
        0% {
            stroke-dasharray: 1, $intCircumference;
            stroke-dashoffset: 0;
        }

        50% {
            stroke-dasharray: $intCircumference, 1;
            stroke-dashoffset: 0;
        }

        100% {
            stroke-dasharray: 1, $intCircumference;
            stroke-dashoffset: -$intCircumference + 1;
        }
    }

    @keyframes spin {
        from {
            transform: rotate(0deg);
        }

        to {
            transform: rotate(360deg);
        }
    }
}

.loader-box {
    flex-direction: row;

    .text-loader {
        font: 12px/1 $font-roboto-medium;
        margin-left: 4px;
    }
}

.scroll-loader {
    padding-top: 24px;
    padding-bottom: 24px;

    .loader-box {
        figure {
            .preloader {
                width: 32px;
                height: 32px;
            }
        }
    }
}
