﻿.chart-legend {
    font-size: 12px;
    line-height: 16px;

    .chart-box {
        margin-right: 16px;
        display: flex;
        align-items: center;

        &.eval-box {
            padding: 0 6px;
            background-color: transparent;

            &:before {
                display: none;
            }
        }
    }

    .checkbox {
        margin-right: 16px;

        input[type="checkbox"] + label.checkbox-lbl-text {
            display: flex;
            align-items: center;
            padding-left: 24px;
        }
    }

    .chart-item {
        margin-right: 4px;

        &-px {
            width: 12px;
            height: 12px;
            background-color: $primary-5;
            border: 2px solid $blue-4;
            margin: 0 4px 0 0;
        }

        &-ktalk {
            width: 6px;
            height: 6px;
            background-color: $secondary-1;
            border-radius: 50%;
        }

        &-color {
            width: 24px;
            height: 2px;
            background: $green-7;
            position: relative;

            &:after {
                content: "";
                width: 6px;
                height: 6px;
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                top: -2px;
                background: $green-7;
                border-radius: 50%;
            }
        }

        &-dnt {
            width: 8px;
            height: 8px;
            background: $grey-10;
            border-radius: 50%;
            margin-right: 8px;
        }
    }

    .avg-px-tooltip {
        margin-left: -8px;}

    .info-tooltip {
        margin: -1px 0 0 -15px;
    }
}
