﻿.sign-up {
    min-width: 375px;

    &-thanks {
        text-align: center;
        width: 100%;
        height: 100%;
        min-height: 320px;
        @extend .container-flex;
        justify-content: center;

        h1,
        .icon-svg {
            margin-bottom: 30px;
        }

        .icon-svg {
            align-self: center;
        }

        p {
            margin-bottom: 0;
        }
    }

    h2 {
        margin: 0 0 20px;
        font: 24px/28px $font-roboto-medium;
    }

    form {
        @extend .container-flex;
    }

    &-cnt {
        margin: auto;
        max-width: 380px;
        padding: 40px 0 0;

        .form-row {
            margin-bottom: 18px;

            &.double-margin {
                margin-bottom: 32px;
            }
        }

        .form-row-inline {
            flex-wrap: nowrap;
        }

        .btn-margin {
            width: 100%;
            margin: 14px 0 0;
        }

        p {
            font: 12px/16px $font-roboto-regular;
            margin: 12px 0 0;
        }

        .control-radio-square {
            width: calc(50% - 10px);
            height: 32px;

            &:last-child {
                margin-left: 20px;
            }

            input[type="radio"]:not(:checked) + label,
            input[type="radio"]:checked + label {
                width: 100%;
            }

            label {
                min-height: 30px;
                line-height: 30px;
            }
        }

        .custom-drop-down {
            z-index: 11;

            &.select-search-field {
                .section:nth-child(2) {
                    height: auto;
                    max-height: 154px;
                }
            }
            .drop-down-list {
                @media only screen and (max-height: $screen-lg-min) {
                    top: auto;
                    bottom: calc(100% + 1px);
                    margin-top: 0;
                    margin-bottom: 4px;
                }
            }
        }

        .divider {
            margin-top: 8px;
            margin-bottom: 8px;
        }
    }

    .container-loader {
        background-color: $white-1;
    }
}

@media only screen and (max-width: 720px) {
    .sign-up {
        &-cnt {
            margin: 30px auto 0;
            padding: 0 20px;

            .form-control {
                font-size: 16px;
                height: 40px;
            }
        }
    }
}
