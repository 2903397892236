﻿.main-table {
    font: 12px/24px $font-roboto-regular;
    border-collapse: separate;
    border-spacing: 0;

    th {
        background: $table-header-bg;
        font-weight: normal;
        line-height: 23px;
        border-bottom: 1px solid $border-color;
        z-index: 5;
        text-align: left;
    }

    th, td {
        padding: 0 10px;

        &:first-child {
            padding-left: 24px;
        }

        &:last-child {
            padding-right: 24px;
        }

        &.padding-left-0 {
            padding-left: 0;
        }
    }

    td {
        padding-top: 4px;
        padding-bottom: 4px;
        border-bottom: 1px solid $row-divider-color;

        .text-ellipsis {
            display: inline-block;
            max-width: 100%;
            vertical-align: middle;
        }
    }

    tr {
        &:hover {
            td {
                background: $row-hover;
            }
        }

        &:first-child {
            th, td {
                border-top: 0;
            }
        }

        &.selected,
        &.highlighted {
            td {
                background: $row-highlighted;
            }
        }
    }
}
