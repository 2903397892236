.container-manage-news {
    .empty-placeholder {
        width: auto;

        .icon-svg {
            margin: 0 4px 0 0;
        }
    }

    .content-part-sidebar {
        position: relative;
    }

    .sidebar-nav-manage-news {
        .sidebar-nav-header .control-search-wrapper {
            padding-left: 12px;
        }

        .sidebar-nav-list-item-draft {
            background-color: $yellow-4;
        }

        .sidebar-nav-list-item-content {
            width: 100%;
            white-space: nowrap;
        }

        .sidebar-nav-list {
            position: relative;

            .tooltip-wrapper {
                display: inline-block;
                max-width: 100%;
                min-width: 1px;
            }

            .title {
                width: 100%;
                margin-right: 8px;
            }

            .flex-row {
                min-height: 24px;
            }

            .flex-item-left {
                &:first-child {
                    margin-bottom: 0;
                }
            }

            .label-status {
                margin-right: 8px;

                &-draft {
                    margin-right: 0;
                    margin-left: 4px;
                }
            }

            .news-label {
                color: $secondary-1;
                margin-right: 8px;
            }

            .news-provider {
                font-family: $font-roboto-medium;
            }
        }

        .add-new-box {
            padding: 16px 24px;
            border-top: 1px solid $border-color;
            margin-top: -1px;
            position: sticky;
            bottom: 0;
            background-color: $white-1;

            .btn {
                width: 100%;
            }
        }
    }

    .jodit_sticky>.jodit-toolbar__box {
        top: -25px;
    }

    .sub-header {
        h1 {
            white-space: nowrap;
            text-overflow: ellipsis;
            max-width: calc(100% - 270px);
            overflow: hidden;
            margin-right: 16px;
        }

        .flex-item-right {
            .btn,
            .vertical-divider {
                margin-left: 8px;
            }

            .btn-danger {
                margin-right: 8px;
            }
        }
    }

    .rdp-input {
        width: 100%;
    }

    .news-info-box {
        width: 960px;
        max-width: 100%;
        margin: 0 auto;

        &-edit {
            .data-item-row {
                margin-bottom: 24px;
            }
        }
    }

    .news-description {
        td {
            border: 1px solid $border-color;
        }

        ul {
            list-style: inherit;
            padding-left: .94rem;
        }
    }

    .news-description-field {
        margin-bottom: 0;
        
        .jodit-wysiwyg {
            min-height: 204px !important;

            table tr td {
                border-color: $border-color;
            }
        }
    }

    .documents-section {
        .component-file-upload-placeholder {
            margin-bottom: 12px;
        }
    
        .component-file-upload-list {
            border-top: none;
            padding-top: 0;
    
            .data-list-header {
                display: none;
            }
        }
    }

    .document-item {
        margin-bottom: 8px;
    }

    .data-item-row {
        .btn-link {
            display: inline-block;
        }
    }
}

.jodit-context.jodit__preview-box table tr td {
    border-color: $border-color;
}

.jodit-dialog__panel,
.jodit-react-container {
    ul {
        list-style: inherit;
        padding-left: .94rem;
    }
}
