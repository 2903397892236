﻿.btn {
    font: 12px/1.25 $font-roboto-bold;
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    white-space: nowrap;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    border-radius: $app-brd-radius;
    padding: 0 $btn-padding-x;
    height: $btn-height;
    line-height: $btn-line-height;
    position: relative;
    user-select: none;
    cursor: pointer;
    @extend .transition;

    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: none;
    }

    &:disabled {
        cursor: default;
    }

    &.btn-no-uppercase {
        text-transform: none;
    }

    .icon,
    .icon-svg {
        margin-right: 4px;

        &-right {
            margin-right: 0;
            margin-left: 4px;
        }
    }

    &.btn-arrow {
        width: 32px;
        min-width: 32px;
        padding: 0;

        .icon-svg {
            margin-right: 0;
            vertical-align: top;
        }

        &.btn-arrow-mirror {
            .icon-svg {
                transform: rotate(180deg);
            }
        }
    }

    &.btn-sm {
        font-size: 10px;
        height: $btn-height-sm;
        line-height: $btn-line-height-sm;
        padding: 0 $btn-sm-padding-x;

        .icon-svg {
            transform: scale(0.875);

            &:first-child {
                margin-right: 3px;
                margin-left: -2px;
            }

            &:last-child {
                margin-right: -2px;
                margin-left: 3px;
            }
        }

        &.btn-arrow {
            min-width: 24px;
            max-width: 24px;
            padding: 0;

            .icon-svg {
                margin-right: 0;
                margin-left: 0;
            }

            &.btn-arrow-mirror {
                .icon-svg {
                    transform: scale(0.875) rotate(180deg);
                }
            }
        }
    }

    &.btn-full-width {
        width: 100%;
    }
}

.btn-main,
a.btn-main {
    color: $white-1;
    background-color: $button-bg;
    border: solid 1px $button-bg;

    &:hover {
        color: $white-1;
        background-color: $button-hover;
        border-color: $button-hover;
    }

    &:focus,
    &:active {
        color: $white-1;
        background-color: $button-focus;
        border-color: $button-focus;
    }

    &[disabled] {
        background-color: $button-disabled-bg;
        border-color: $button-disabled-bg;
    }
}

a.btn-main {
    &[disabled] {
        pointer-events: none;
    }
}

.btn-ghost {
    color: $button-bg;
    background: none;
    border: solid 1px rgba($button-bg, .5);

    &:hover {
        color: $button-ghost-hover-color;
        background-color: $button-ghost-hover;
        border-color: $button-ghost-hover-color;
    }

    &:active,
    &:focus {
        color: $button-ghost-focus;
        background-color: $button-ghost-focus-bg;
        border-color: $button-ghost-focus;
    }

    &[disabled] {
        color: $button-disabled-bg;
        border-color: $button-disabled-bg;
        pointer-events: none;
    }
}

.btn-ghost-secondary {
    background-color: $white-1;
    border: 1px solid $grey-5;
    color: $grey-4;
}

.btn-secondary {
    color: $text-color;
    background-color: $white-1;
    border: none;
    border-radius: 0;
    padding-left: 10px;
    padding-right: 9px;

    &.border-left {
        border-left: 1px solid $border-color;
    }

    &.border-right {
        border-right: 1px solid $border-color;
    }

    &:hover {
        color: $text-color;
    }
}


/* Buttons like links */


/* Link medium with normal case with icon and without icon  */
.btn-link-default {
    color: inherit;
    vertical-align: middle;
    border: none;
    background: none;
    line-height: 16px;
    cursor: pointer;

    &:hover {
        color: $link-hover;
        text-decoration: none;
    }

    &:active,
    &.active {
        color: $link-focus;
    }

    &[disabled],
    &.disabled {
        color: $link-disabled;
        cursor: default;
        pointer-events: none;

        .icon {
            cursor: default;
        }
    }
}

.btn-link {
    @extend .btn-link-default;
    color: $text-color;
    font-size: 12px;
    padding: 0;
    border-radius: 0;
    display: inline-flex;
    align-items: center;
    font-family: $font-roboto-medium;
    text-transform: none;

    &-lg {
        font-size: 14px;
    }

    &:not(.disabled):not(.btn-is-loading).secondary {
        color: $link-color;

        &:hover {
            color: $link-hover;
            text-decoration: underline;
        }

        &:focus,
        &:active {
            color: $link-focus;
        }

        &[disabled] {
            color: $button-disabled;
        }
    }

    &.regular {
        font-family: $font-roboto-regular;
    }

    .icon,
    .icon-svg {
        margin-right: 4px;
    }

    &-no-label {
        .icon,
        .icon-svg {
            margin-right: 0;
        }
    }
}

/* end Link medium with normal case with icon and without icon  */


/* Buttons Danger/Delete */

.btn-link.btn-danger,
.btn-ghost.btn-danger {
    color: $link-danger-color;
    border-color: rgba($link-danger-hover, .5);

    &:hover {
        color: $link-danger-hover;

        .icon-delete {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.66652 0H10.3332V1.14286H5.66652V0Z' fill='%23f75d4a'/%3E%3Cpath d='M5.66652 5.71429H6.83319V12.5714H5.66652V5.71429Z' fill='%23f75d4a'/%3E%3Cpath d='M9.16667 5.71429H10.3333V12.5714H9.16667V5.71429Z' fill='%23f75d4a'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 2.28571V3.42856H2.16667V14.8571C2.16667 15.4883 2.689 16 3.33333 16H12.6667C13.311 16 13.8333 15.4883 13.8333 14.8571V3.42856H15V2.28571H1ZM3.33333 14.8571V3.42856H12.6667V14.8571H3.33333Z' fill='%23f75d4a'/%3E%3C/svg%3E");
        }
    }

    &:focus,
    &:active {
        color: $link-danger-focus;

        .icon-delete {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.66652 0H10.3332V1.14286H5.66652V0Z' fill='%23dd4212'/%3E%3Cpath d='M5.66652 5.71429H6.83319V12.5714H5.66652V5.71429Z' fill='%23f75d4a'/%3E%3Cpath d='M9.16667 5.71429H10.3333V12.5714H9.16667V5.71429Z' fill='%23dd4212'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 2.28571V3.42856H2.16667V14.8571C2.16667 15.4883 2.689 16 3.33333 16H12.6667C13.311 16 13.8333 15.4883 13.8333 14.8571V3.42856H15V2.28571H1ZM3.33333 14.8571V3.42856H12.6667V14.8571H3.33333Z' fill='%23dd4212'/%3E%3C/svg%3E");
        }
    }

    &[disabled],
    &.disabled {
        color: $link-disabled;

        .icon-delete {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.66652 0H10.3332V1.14286H5.66652V0Z' fill='%23bdbdbd'/%3E%3Cpath d='M5.66652 5.71429H6.83319V12.5714H5.66652V5.71429Z' fill='%23bdbdbd'/%3E%3Cpath d='M9.16667 5.71429H10.3333V12.5714H9.16667V5.71429Z' fill='%23bdbdbd'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1 2.28571V3.42856H2.16667V14.8571C2.16667 15.4883 2.689 16 3.33333 16H12.6667C13.311 16 13.8333 15.4883 13.8333 14.8571V3.42856H15V2.28571H1ZM3.33333 14.8571V3.42856H12.6667V14.8571H3.33333Z' fill='%23bdbdbd'/%3E%3C/svg%3E");
        }
    }
}

.btn-ghost.btn-danger {
    border-color: $link-danger-color;

    &:hover,
    &:focus,
    &:active {
        background: $warning-5;
    }

    &:focus,
    &:active {
        border-color: $link-danger-focus;
    }

    &[disabled],
    &.disabled {
        border-color: $link-disabled;
    }
}

.btn-main.btn-danger {
    color: $white-1;
    background: $link-danger-color;
    border-color: $link-danger-color;

    &:hover {
        background: $link-danger-hover;
        border-color: $link-danger-hover;
    }

    &:focus,
    &:active {
        background: $link-danger-focus;
        border-color: $link-danger-focus;
    }

    .icon-delete {
        margin: -1px 5px 0 0;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='15' viewBox='0 0 14 15'%3E%3Cpath fill='%23FFF' fill-rule='nonzero' d='M2.145 3H.75V2h4.188v-.625C4.938.625 5.563 0 6.313 0h1.875c.75 0 1.374.625 1.374 1.375V2h4.188v1h-1.395l-.824 10.594a1.492 1.492 0 0 1-1.469 1.375H4.439c-.75 0-1.407-.625-1.47-1.375L2.146 3zm.942 0l.82 10.531c.03.281.28.5.53.5h5.625c.282 0 .532-.25.532-.5L11.413 3H3.087zm2.725-1h2.875v-.625c0-.281-.218-.5-.5-.5H6.313c-.282 0-.5.219-.5.5V2zm-.906 2.656h.938v7.5h-.938v-7.5zm1.875 0h.938v7.5H6.78v-7.5zm1.875 0h.938v7.5h-.938v-7.5z'/%3E%3C/svg%3E");
    }

    &[disabled] {
        background: $control-disabled;
        border-color: $control-disabled;
        pointer-events: none;
    }
}

/* end Buttons Danger/Delete */


/* Button Clear */

.btn-link.btn-clear {
    color: inherit;

    &:hover {
        color: $link-hover;
    }

    &:focus,
    &:active {
        color: $link-focus;
    }

    &.disabled,
    &[disabled] {
        color: $button-disabled;
    }
}

/* end Button Clear */


/* Button Edit */

.btn-edit {
    width: 16px;
    height: 16px;

    .icon-svg {
        margin-right: 0;
    }
}
/* end Button Edit */

.btn-affirm,
.btn-success {
    color: $green-2;

    &:hover {
        color: $green-7;
    }

    &:focus,
    &:active {
        color: $green-1;
    }

    & + .btn-reject {
        margin-left: 6px;
    }
}

.btn-close {
    @extend .btn-link-default;
    padding: 8px;

    .icon-svg {
        vertical-align: top;
    }
}

.btn-options {
    &.btn-sm {
        .icon-svg-icon-expand {
            transform: scale(0.875) rotate(90deg);
        }

        &.show {
            .icon-svg-icon-expand {
                transform: scale(0.875) rotate(-90deg);
            }
        }

        &.loading {
            .icon-svg-icon-expand {
                opacity: 0;
            }
        }
    }

    .container-loader {
        width: 16px;
        right: 8px;
        left: auto;
        background: $button-ghost-hover;
    }
}

.btn-favorite {
    color: $button-bg;
    display: block;
    padding: 0;
    border: 0;
    background: none;
    outline: none;
    line-height: 1;
    cursor: pointer;

    &:hover {
        color: $button-hover;
    }

    &[disabled] {
        color: $button-disabled-bg;
        opacity: 0.4;
        pointer-events: none;
        cursor: default;
    }
}

.btn-counter {
    @extend .counter;

    &:hover {
        background-color: $grey-5;
    }
}

.btn-is-loading {
    &,
    &:hover,
    &:focus {
        color: #000;
        text-decoration: none;
        cursor: default;
        pointer-events: none;
    }
}