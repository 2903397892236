﻿/* Timepicker */

.control-time-picker {
    width: 92px;
    height: 24px;
    border-radius: $app-brd-radius;
    border: solid 1px $control-border;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    &.is-focus {
        border-color: $blue-1;
        box-shadow: 0px 0px 0px 1px $blue-1;
    }

    &.disabled {
        border-color: $grey-1;
        background-color: $grey-3;

        .form-control {
            color: $grey-1;
            background-color: $grey-3;
            border-color: $grey-1;
        }
    }

    &.is-invalid {
        border-color: $secondary-1;
        box-shadow: 0px 0px 0px 1px rgba($secondary-1, 0.4);

        .rc-time-picker-input {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.80021 8.7998H7.2002V3.99981H8.80021V8.7998Z' fill='%23FF614D'/%3E%3Cpath d='M7.2002 12H8.80021V10.4H7.2002V12Z' fill='%23FF614D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1.3C11.7003 1.3 14.7 4.29969 14.7 8C14.7 11.7003 11.7003 14.7 8 14.7C4.29969 14.7 1.3 11.7003 1.3 8C1.3 4.29969 4.29969 1.3 8 1.3Z' fill='%23FF614D'/%3E%3C/svg%3E%0A");
            background-position: right 10px top 50%;
            background-repeat: no-repeat;
            transition: none;
        }

        .form-control {
            &:focus {
                background-color: $warning-5;
                border: none;
            }
        }

        &.is-focus {
            border-color: $secondary-1;
            box-shadow: 0px 0px 0px 1px $secondary-1;
        }
    }

    .rc-time-picker {
        height: 100%;
    }

    .rc-time-picker-input {
        color: $grey-4;
        font-size: 12px;
        font-family: $font-roboto-regular;
        text-transform: uppercase;
        height: 100%;
        border: none;
        border-radius: 2px;
        padding: 0 8px;
        cursor: pointer;

        &::-webkit-input-placeholder {
            text-transform: capitalize;
        }

        &::-moz-placeholder {
            text-transform: capitalize;
        }

        &:-ms-input-placeholder {
            text-transform: capitalize;
        }
    }

    .rc-time-picker-icon,
    .rc-time-picker-clear {
        display: none;
    }
}

.rc-time-picker-panel {
    width: 135px;

    &.is-invalid {
        .rc-time-picker-panel-input {
            &:focus {
                box-shadow: none;
            }
        }
    }
}

.rc-time-picker-panel-inner {
    border-radius: 2px 2px 0 0;
    box-shadow: none;
    border: none;
    margin-top: 3px;
    background: transparent;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.rc-time-picker-panel-input-wrap {
    border-bottom: none;
    padding: 0;
    height: 22px;

    .rc-time-picker-normal & {
        height: 30px;
    }
}

.rc-time-picker-panel-input {
    color: $grey-4;
    font-size: 12px;
    font-family: $font-roboto-regular;
    text-transform: uppercase;
    min-height: 22px;
    width: 90px;
    padding: 0 8px;
    border-radius: $app-brd-radius;
    border: 0;
    background-color: $white-1;

    &:focus {
        border: none;
        box-shadow: 0 0 0 1px $control-focus;
    }

    &::-webkit-input-placeholder {
        text-transform: capitalize;
    }

    &::-moz-placeholder {
        text-transform: capitalize;
    }

    &:-ms-input-placeholder {
        text-transform: capitalize;
    }

    .rc-time-picker-normal & {
        min-height: 30px;
        font-size: 14px;
    }

    .rc-time-picker-normal-150 & {
        width: 148px;
    }

    .rc-time-picker-normal-140 & {
        width: 138px;
    }

    .rc-time-picker-normal-186 & {
        width: 184px;
    }
}

.rc-time-picker-panel-combobox {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 1px;
    margin-left: -1px;
    box-shadow: $app-shadow;
    border: solid 1px $border-color;
    background-color: $white-1;
}

.rc-time-picker-panel-select {
    font-size: 14px;
    float: none;
    border-color: $border-color;
    width: 45.5px;
    max-height: 365px;
}

.rc-time-picker-panel-select li {
    padding: 0;
    text-align: center;
    text-transform: uppercase;
    height: 30px;
    line-height: 30px;
}

.rc-time-picker-panel-select li:focus {
    outline: none;
}

.rc-time-picker-panel-select li:hover,
li.rc-time-picker-panel-select-option-selected {
    font-weight: normal;
    font-family: $font-roboto-medium;
    background-color: $primary-6;
}

li.rc-time-picker-panel-select-option-disabled,
li.rc-time-picker-panel-select-option-disabled:hover {
    color: $grey-8;
    font-family: $font-roboto-regular;
    background: $grey-3;
}

.rc-time-picker-panel-placement-topLeft {
    .rc-time-picker-panel-inner {
        flex-direction: column-reverse;
        transform: translateY(-3px);
    }
}

/* end Timepicker */


/* Autocomplete */

.form-control-autocomplete {
    background-color: $white-1;
    position: relative;
}

.control-autocomplete-list {
    background-color: $white-1;
    box-shadow: $app-shadow;
    border: solid 1px $border-color;
    padding-bottom: 10px;
    position: absolute;
    top: 32px;
    left: 0;
    width: 100%;
    max-height: 170px;
    overflow: auto;
    z-index: 11;

    li {
        color: $grey-4;
        font-family: $font-roboto-medium;
        background-color: $white-1;
        padding: .5rem .75rem .37rem;
        cursor: pointer;
        text-transform: none;
        @extend .transition;

        &:hover {
            background-color: $primary-6;
        }
    }
}

/* end Autocomplete */

/* Multiselect */

.multiselect {
    @extend .text-ellipsis;
    position: relative;
    white-space: nowrap;
    cursor: pointer;

    .placeholder {
        color: $placeholder-color;
    }

    &-row {
        display: flex;
        padding-right: 8px;

        .text-ellipsis {
            width: auto;
            max-width: 100%;
            padding-right: 4px;
        }
    }

    &-list {
        box-shadow: $app-shadow;
        border: solid 1px $border-color;
        background-color: $white-1;
        position: absolute;
        top: calc(100% + 2px);
        left: 0;
        width: 100%;
        z-index: 11;
        max-height: 325px;
        overflow: auto;

        .reset-select {
            .btn {
                padding: 0 12px;
            }
        }

        .checkbox {
            width: 100%;
            padding: 8px 12px;
            height: auto;

            &:not(:disabled):hover {
                background-color: $primary-6;
            }

            label {
                @extend .text-ellipsis;
                white-space: nowrap;
                display: block;
                font-size: 14px;
            }
        }

        .button-wrapper {
            padding: 16px;
            text-align: right;
            background-color: $grey-7;
        }
    }
}

/* end Multiselect */