﻿.manage-cnt {
    .sub-header {
        .control-group-slide {
            padding: 0;
            width: 190px;
            align-self: center;
            margin-left: 16px;
        }

        .form-select {
            width: 195px;
        }

        .filter-section {
            .empty-placeholder {
                width: 100%;
            }
        }
    }

    .companies-table {
        min-width: auto;
        overflow-y: auto;

        .table-head,
        .manage-list {
            min-width: 1450px;
        }
    }

    .table-head {
        background: $table-header-bg;
        padding-right: 17px;
        flex: none;

        th:last-child {
            padding-right: 24px;
        }
    }

    .manage-list {
        overflow-y: scroll;

        .main-table {
            .text-ellipsis {
                white-space: nowrap;
            }

            tr {
                &.user-request {
                    td {
                        background: $yellow-6;
                    }
                }
            }

            td:last-child {
                padding-right: 24px;
            }

            .data-user {
                .text-ellipsis {
                    width: auto;
                }

                .popover {
                    .popover-body {
                        padding-top: 10px;
                    }

                    h3 {
                        margin: 16px 0 0;
                    }
                }
            }

            .sales-reps {
                .position-relative {
                    &:not(:first-child) {
                        margin-left: -6px;
                    }
                }

                .user-avatar {
                    font-size: 24px;
                    vertical-align: top;
                    box-shadow: 0 0 0 2px $white-1;
                }
            }

            .btn:not(.btn-counter) {
                height: auto;
                text-align: left;
            }

            .affirm-reject {
                margin-right: -6px;

                .btn-reject {
                    margin-left: 12px;
                }
            }

            .status {
                line-height: 20px;

                &.has-blocked-reason {
                    cursor: pointer;

                    & > span {
                        border-bottom: 1px dashed;
                    }
                }
            }

            .loader-box {
                justify-content: flex-end;
            }
        }
    }

    .empty-placeholder {
        width: auto;
    }
}


.quick-feedback-menu {
    .context-menu-content {
        padding: 4px;
        line-height: 1;

        &.disabled {
            color: $grey-1;
            cursor: default;
        }

        &:not(.disabled):hover,
        &.show {
            color: $link-hover;
        }

        &:not(.disabled):focus,
        &:not(.disabled):active {
            color: $link-focus;
        }

        .icon-svg {
            line-height: 1;
            vertical-align: top;
        }
    }

    .context-menu-dropdown {
        padding-top: 8px;
        padding-bottom: 8px;

        @media (max-height: 840px) {
            max-height: 240px;
            overflow-x: auto;
            overflow-y: scroll;
        }

        li {
            font-size: 12px;
            padding: 8px 11px;
            line-height: inherit;

            .icon {
                margin-right: 4px;
            }

            label {
                cursor: inherit;
            }

            &.disabled {
                color: $grey-1;
                background: none;
                cursor: default;
            }

            .limit-blocker {
                color: $grey-1;
            }
        }

        &.show-above {
            top: auto;
            bottom: 100%;
            margin-bottom: -2px;
        }
    }
}

.edit-user-popup {
    .modal-content {
        width: 552px;
        flex: none;

        .modal-body {
            h2 {
                line-height: 20px;
                border-bottom: 1px solid $border-color;
                padding: 0 0 15px;
                margin: 25px 0 20px;

                &:first-child {
                    margin-top: 0;
                }
            }
        }

        .form-row {
            margin-bottom: 15px;
            justify-content: space-between
        }

        .form-row-inline {
            .form-item {
                flex: none;
                max-width: 230px;
                width: 100%;
            }
        }

        .company-row {
            .form-item {
                flex: 1 0 200px;
            }

            .form-select {
                width: 200px;
            }
        }
    }
}

.manage-company {
    overflow: auto;

    &-cnt {
        width: 1150px;
        margin: 0 auto;
        padding: 60px 0 30px;

        .flex-row {
            align-items: baseline;
            justify-content: space-between;
        }

        .col {
            width: 550px;
        }

        h2 {
            border-bottom: 1px solid $border-color;
            padding: 24px 0 14px;
        }

        .form-item {
            margin-bottom: 16px;
        }

        textarea {
            height: 64px;
        }

        .two-col {
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;

            .form-item {
                width: calc(50% - 6px);
                margin-bottom: 0;
            }
        }

        input.is-invalid {
            & + .form-control.multiselect {
                border-color: $secondary-1;
                background: url("data:image/svg+xml,%3Csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M1.79629 2.92134C0.875134 2.03439 0.138719 1.33629 0 1.22551L1.06573 0C1.28216 0.172827 2.09949 0.951822 2.97625 1.79602C3.64086 2.43596 4.37176 3.14489 4.97712 3.734L8.82286 0.0487468L10 1.17677L4.96672 6L4.3782 5.42559C3.71313 4.77648 2.6935 3.78523 1.79629 2.92134Z' fill='%234A4A4A'/%3E%3C/svg%3E%0A") no-repeat right 10px top 50%, url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.80021 8.7998H7.2002V3.99981H8.80021V8.7998Z' fill='%23FF614D'/%3E%3Cpath d='M7.2002 12H8.80021V10.4H7.2002V12Z' fill='%23FF614D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1.3C11.7003 1.3 14.7 4.29969 14.7 8C14.7 11.7003 11.7003 14.7 8 14.7C4.29969 14.7 1.3 11.7003 1.3 8C1.3 4.29969 4.29969 1.3 8 1.3Z' fill='%23FF614D'/%3E%3C/svg%3E%0A") no-repeat right 27px top 50%;
            }
        }

        .multiselect-list {
            max-height: 155px;
            overflow: auto;
        }

        .selected-employees-list {
            padding-left: 12px;

            h5 {
                color: inherit;
                line-height: 1;
            }

            ul {
                li {
                    font-size: 12px;

                    &:not(:first-child) {
                        margin-top: 2px;
                    }
                }
            }
        }

        .clearing-bank {
            font-size: 12px;
            margin: 0;
        }
    }
}
