﻿.address-book {
    .grid {
        min-width: 1324px;
    }

    .main-table {
        width: calc(100% - 17px);
        position: relative;

        &:after {
            position: absolute;
            right: -17px;
            top: 0;
            content: "";
            height: 25px;
            width: 17px;
            background: $table-header-bg;
        }
    }

    .collapsable-item {
        &-content {
            .row-contact {
                padding-left: 90px;

                &.heading {
                    cursor: default;
                }
            }
        }
    }

    .conflicts-area {
        background: $grey-7;
        height: calc(100vh - 174px);
        overflow-y: scroll;

        &-info {
            padding: 10px 32px;
            margin: 0;
            font: 12px/24px $font-roboto-regular;

            .icon {
                width: 32px;
                background-position: 50% 0;
                margin-top: -2px;
            }
        }

        .main-table {
            background: $white-1;
            width: 100%;
            position: static;

            &:after {
                display: none;
            }

            tr {
                td {
                    border-bottom: 1px solid $border-color;
                    padding-top: 4px;
                    padding-bottom: 3px;
                    font: 12px/24px $font-roboto-regular;

                    &:first-child {
                        width: 32px;
                        padding-right: 0;
                    }

                    &:last-child {
                        padding-right: 33px;
                    }

                    .state-new {
                        position: relative;
                        top: -1px;
                    }

                    .text-ellipsis {
                        display: block;
                    }
                }

                &:first-child {
                    td {
                        border-top: 1px solid $border-color;
                    }
                }
            }
        }
    }
}

.dealers-table {
    min-width: 1280px;
    width: auto;

    .data-table-item-row {
        line-height: 16px;
    }

    .data-item-collapsable-header {
        height: 32px;

        .btn-link {
            margin-right: 16px;
        }

        .bd-options {
            .show-limits {
                margin-right: 32px;
            }
        }
    }

    .data-table-header-item {
        padding-left: 0;
        padding-right: 0;
        margin: 0 16px 0 0;
    }

    .data-table-item {
        &:nth-last-child(-n+4):not(:nth-child(-n+4)) {
            .context-menu ul {
                top: auto;
                bottom: 100%;
            }
        }
    }

    .data-list {
        .data-table-content {
            overflow-x: hidden;
            overflow-y: auto;
            height: 100%;
            position: relative;

            &:after {
                content: " ";
                min-height: 44px;
                display: block;
            }
        }

        &-header {
            .data-list-name {
                margin-left: 34px;
                padding-left: 14px;
            }

            .data-list-title {
                padding-left: 24px;
            }

            .data-list-options {
                flex: 1 1 auto;
                padding-right: 17px;
            }
        }

        &-name {
            @include flex-basis(250px);

            .name {
                padding-left: 14px;
                width: auto;
                max-width: 100%;
            }

            .status {
                display: inline-block;
                margin-right: -14px;
            }
        }

        &-title {
            @include flex-basis(200px);

            .icon-svg {
                vertical-align: bottom;
                margin-right: 8px;
            }

            .dash {
                margin-left: 24px;
            }

            .title {
                margin-left: 24px;
                width: 185px;
                display: inline-block;

                .dash {
                    margin-left: 0;
                }
            }
        }

        &-email {
            @include flex-basis(300px);
            line-height: 16px;

            .email {
                width: auto;
                max-width: 100%;
            }

            a {
                &:hover {
                    text-decoration: underline;
                }
            }
        }

        &-phone {
            @include flex-basis(105px);
        }

        .context-menu ul {
            left: auto;
            right: 0;

            .limit-blocker {
                li {
                    pointer-events: none;
                    cursor: default;
                    opacity: .5;
                }
            }
        }
    }

    .favorite-company {
        position: relative;
        margin-right: -16px;
        min-width: 16px;
    }

    .kpt-platform {
        margin-right: -48px;
        margin-left: 24px;
        position: relative;
    }

    .icon-svg-clearing {
        margin-right: -48px;
        margin-left: 24px;
    }

    .dealer-name {
        padding-left: 48px;
    }

    .tooltip-wrapper {
        line-height: 16px;
    }

    .checkbox {
        margin-right: 8px;
    }

    .data-table-item-collapse {
        .btn-link {
            width: 16px;
        }

        .icon-svg-icon-double-arrows {
            margin-top: 1px;
            margin-right: 0;
        }
    }

    .data-item-collapsable-content {
        .data-list-name {
            margin-left: 36px;
        }
    }

    .status-wrapper {
        margin-left: 16px;
        position: relative;

        .popover {
            left: auto;
            right: 0;
        }
    }

    .empty-placeholder {
        width: auto;
    }
}
