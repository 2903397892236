﻿.modal-cover {
    position: fixed;
    z-index: 35;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba($grey-4, .3);

    &+.modal {
        background: none;
    }
}

.modal {
    position: fixed;
    z-index: 35;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    height: 100%;
    text-align: left;
    background: rgba($grey-4, 0.3);

    @media all and (-ms-high-contrast:none) {
        position: -ms-page;
    }

    &.modal-test {
        position: relative;
        width: 540px;
        min-height: 400px;
        height: auto;
        left: auto;
        top: auto;
    }
}

.modal-content {
    position: relative;
    width: 460px;
    margin: auto;
    padding: 0;
    border: none;
    border-radius: $app-brd-radius;
    box-shadow: 0 6px 20px 0 rgba($grey-4, .1);
    background-color: $white-1;
    animation-name: animatetop;
    animation-duration: 0.4s;
}

@keyframes animatetop {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.modal-header {
    padding: 14px 24px;

    h1 {
        display: flex;
        margin-bottom: 0;
        margin-right: 8px;
        text-align: left;
        white-space: nowrap;
        line-height: 28px;
        align-items: center;
    }

    .tooltip-wrapper {
        flex-grow: 1;
        min-width: 0;
        margin-left: 8px;

        .name-tag {
            max-width: 92%;
            margin-left: 0;
        }
    }

    .btn-close {
        position: absolute;
        top: 12px;
        right: 16px;
    }
}

.modal-body {
    padding: 0 24px 16px;

    &.new-template {
        textarea.form-control {
            height: 70px;
        }
    }

    p {
        word-wrap: break-word;
    }
}

.modal-footer {
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    border-bottom-right-radius: 3px;
    border-bottom-left-radius: 3px;

    .btn {
        margin-left: 8px;
    }
}

.modal-full-size {
    .modal-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        width: auto;
        min-height: 1px;
        margin: 71px 21px;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;
        min-height: 1px;
    }
}

.modal-cancelation-reason-text {
    margin-bottom: 12px;
}

.modal-onboarding-support {
    .modal-body {
        padding-top: 16px;
        padding-bottom: 16px;
        text-align: center;
        background-color: $blue-7;

        img {
            width: 302px;
            height: auto;
            vertical-align: top;
        }
    }
}

.modal-clients-invite {
    .status-message {
        margin-bottom: 16px;
    }

    .email-chips-list-input-field {
        min-height: 24px;
    }
}

.modal-feedback {
    position: -ms-device-fixed;

    p {
        margin-bottom: 16px;

        &.info {
            margin: 32px 0 0;
        }
    }

    textarea {
        height: 50px;
        line-height: 20px;
    }
}

.modal-invite-broker {
    .modal-header {
        padding-bottom: 8px;
    }

    .modal-body {
        text-align: left;

        .form-row {
            margin-bottom: 30px;
        }

        textarea {
            border-color: $border-color;
            font: 14px/20px $font-roboto-regular;
            height: 140px;
            resize: none;
            padding: 8px 12px;
            white-space: normal;
        }
    }
}

.modal-dealer-stats {
    display: flex;
    flex-direction: column;
    padding: 36px 30px;

    .modal-header {
        padding-right: 60px;

        .btn-link {
            height: auto;
        }

        .flex-row {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
        }
    }

    .modal-content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        max-width: 1523px;
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        padding: 0;
        min-height: 1px;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .modal-footer {
        flex: none;
    }

    .dealer-stats-groups-name {
        display: flex;
        flex: none;
        width: 100%;
        margin: 0;
        padding: 0;
        list-style: none;

        li {
            font-size: 12px;
            text-align: center;
            flex: none;
            padding: 3px 12px;
            background-color: $grey-7;

            &:first-child {
                width: 453px;

                &+li {
                    width: 491px;
                    border-right: 1px solid $row-divider-color;
                    border-left: 1px solid $row-divider-color;
                }
            }

            &:last-child {
                width: 600px;
            }
        }
    }

    .data-list {
        min-width: 1544px;
        text-align: right;

        .data-list-cell {
            padding: 8px 8px 7px;

            &:first-child {
                padding-left: 0;
                text-align: left;
            }

            .position-relative {
                width: 100%;
            }
        }

        .data-list-header {
            height: auto;
            max-height: none;
            flex-basis: auto;
            z-index: 1;

            .data-list-cell {
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                white-space: normal;
                line-height: 1.2;
                cursor: default;
                position: relative;

                &:nth-last-child(-n+4) {
                    .popover {
                        left: auto;
                        right: -10px;
                    }
                }

                &.data-list-ds-dealer {
                    justify-content: flex-start;

                    .position-relative {
                        width: auto;
                    }
                }

                .icon-svg-corner-arrow {
                    flex: none;
                }
            }
        }

        .data-list-container {
            min-width: 0;
        }

        .data-list-row {
            height: auto;
        }
    }
}

.modal-login-history {
    .modal-body {
        padding: 0;
    }

    .data-list-container {
        min-height: 120px;
        max-height: 217px;
    }

    .data-list-row {
        &:hover {
            cursor: unset;
        }
    }

    .data-list-action-date {
        width: 240px;
    }

    .data-list-action-name {
        overflow: hidden;
    }
}

.add-contact-form {
    margin: 0 -10px 8px 0;

    .form-control {
        margin-right: 5px;

        &.is-invalid {
            background-image: none;
        }
    }

    .buttons {
        width: 60px;
        height: 32px;
        margin-left: 16px;

        .btn-link {
            width: 30px;
        }

        .icon-svg {
            margin-right: 0;
        }

        .container-loader {
            height: 32px;
            background: none;

            .preloader {
                height: 20px;
                width: 20px;
            }
        }
    }
}

.modal-payment-popup-form {
    .modal-content {
        align-self: center;
        flex: none;
        width: auto;
        min-width: 590px;

        .modal-body {
            position: relative;
            min-height: 291px;
            padding-bottom: 30px;
        }

        .modal-footer {
            .icon-svg-button-arrow {
                margin-top: -2px;
            }

            .btn-ghost {
                .icon-svg-button-arrow {
                    margin-right: 6px;
                }
            }

            .btn-main {
                .icon-svg-button-arrow {
                    margin-left: 6px;
                    transform: rotate(180deg);
                }
            }
        }

        .progress-steps {
            margin-top: -6px;
            margin-bottom: 26px;

            &+.warning-message {
                min-height: 50px;
            }
        }

        .form-row {
            margin-bottom: 15px;
        }

        .form-row-inline {
            &:last-child {
                align-items: flex-start;
            }

            .form-item {
                &:not(:last-child) {
                    margin-right: 30px;
                }

                textarea {
                    height: 64px;
                }
            }
        }

        .warning-message {
            color: $grey-8;
            min-height: 75px;
            max-width: 530px;
            padding-top: 6px;
        }

        .changes-preview {
            margin-bottom: 10px;
        }

        p {
            max-width: 880px;
        }

        .manage-payments-link {
            display: none;
        }
    }
}

.modal-change-subscription {
    .modal-content {
        width: 540px;

        h2 {
            line-height: 32px;

            .title {
                margin-right: 10px;
            }
        }

        .flex-item-equal {
            &:last-child {
                margin-left: 15px;
            }
        }

        .rdp-input {
            width: 100%;

            input::-ms-clear {
                display: none;
            }
        }

        .definition-list.style01 dd {
            margin-bottom: 0;
            padding-top: 4px;
            padding-bottom: 4px;
        }

        .form-label {
            margin-top: 10px;
        }
    }
}

.modal-plan-details {
    .modal-content {
        .modal-header {
            display: flex;
            align-items: center;

            h1 {
                margin-right: 0;
                text-align: inherit;
            }
        }

        .modal-body {
            padding-bottom: 24px;

            .list-bullet {
                padding-left: 8px;

                li {
                    margin-top: 0;
                    line-height: 20px;
                }
            }
        }
    }
}

.modal-contact-sales {
    .modal-content {
        width: 590px;

        .modal-body {
            padding-bottom: 30px;
        }

        textarea.form-control {
            height: 64px;
        }

        .text-warm-grey {
            min-height: 40px;
        }
    }
}

.modal-photo-uploader {
    &>.modal-content {
        display: flex;
        flex-direction: column;
        width: 700px;
    }
}

.modal-congrats {
    .modal-header {
        display: none;
    }

    .modal-content {
        width: 460px;
    }

    .modal-body {
        padding-top: 34px;
        text-align: center;

        h1 {
            margin-top: 30px;
            margin-bottom: 0;
        }

        h2 {
            margin-bottom: 0;
        }

        .subscription-plan-features {
            margin-bottom: 0;
            padding: 26px 10px 10px;
            text-align: left;
        }
    }

    .modal-footer {
        .btn {
            width: 100%;

            &:first-child {
                margin-right: 5px;
                margin-left: 0;
            }

            &:last-child {
                margin-left: 5px;
            }
        }
    }
}

.modal-bob-conflicts {
    .modal-content {
        width: 590px;

        p {
            &:last-child {
                margin-bottom: 0;
            }
        }

        .conflict-bids-list {
            max-height: 335px;
        }
    }
}

.modal-commission,
.modal-clearing-bank,
.modal-limits {
    .modal-body {
        padding-bottom: 0;
    }

    .data-list {
        width: auto;
        margin-right: -24px;
        margin-left: -24px;
    }

    .data-list-content {
        overflow: hidden;

        &:after {
            display: none;
        }
    }

    .data-list-rating {
        width: 24%;
        min-width: 100px;
    }

    .data-list-commission {
        min-width: 100px;
    }
}

.modal-clearing-bank {
    .modal-content {
        width: 540px;
    }

    .controls-row {
        margin-bottom: 16px;
    }

    .data-list:not(.data-list-freeze),
    .data-list-content {
        overflow: visible;
    }

    .data-list-content {
        overflow-y: unset;
    }

    .data-list-commission {
        width: 115px;

        .form-control-wrapper {
            display: inline-block;
        }

        .form-control {
            max-width: 110px;
        }

        .btn-link {
            width: 16px;
            margin-right: -20px;
            margin-left: 4px;

            .icon-svg {
                margin-right: 0;
            }
        }
    }
}

.modal-limits {
    .data-daily-trading-limit {
        min-width: 135px !important;
        padding-right: 0 !important;
    }

    .data-list-container {
        min-width: auto !important;
    }
}

.modal-choose-email {
    p {
        font-size: 12px;
        margin: 0 0 24px;
    }

    .form-item {
        margin-bottom: 15px;
    }

    .add-contact-form {
        .form-control {
            text-align: right;
            padding-right: 111px;
        }

        .mask-domain {
            position: absolute;
            top: 1px;
            right: 8px;
            color: $grey-8;
            line-height: 30px;
        }
    }
}

.modal-add-to-portfolio {
    font-family: $font-roboto-regular;
    white-space: normal;

    h1 {
        line-height: 28px;
    }

    .container-loader {
        background: $white-1;
    }

    .modal-content {
        min-height: 249px;
        display: flex;
        flex-direction: column;
    }

    .modal-body {
        padding-bottom: 0;

        &>.form-item {
            margin-bottom: 16px;

            input.form-control {
                height: 32px;
                font-size: 14px;
            }
        }

        .custom-drop-down {
            z-index: 15;

            .drop-down-btn {
                padding-right: 24px;

                span {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
            }
        }

        .drop-down-list {
            width: calc(100% + 2px);
            left: -1px;

            .section {
                overflow: auto;
                max-height: 120px;
            }

            .item-wrapper {
                overflow: visible;
            }

            .btn-create {
                font-family: $font-roboto-regular;
                font-size: inherit;
                color: inherit;
                display: block;
                width: calc(100% + 20px);
                margin: -8px -10px;
                padding: 8px 10px;
                border-top: 1px solid $border-color;
                text-align: left;

                &:hover {
                    background: $primary-6;
                }

                .icon-svg {
                    margin-top: 2px;
                    margin-right: 4px;
                    vertical-align: top;
                }
            }
        }

        .status-message {
            margin-top: 8px;
            margin-bottom: 12px;
        }

        .data-list {
            width: auto;
            min-width: 0;
            margin-right: -24px;
            margin-left: -24px;

            .data-list-container {
                width: 100%;
            }

            .data-list-header,
            .data-list-row {
                padding-left: 24px;
            }

            .data-list-row:last-of-type {
                border-bottom: 1px solid $border-color;
            }
        }

        .data-list-content {
            overflow: hidden;
            padding-bottom: 12px;

            .cell-input {
                overflow: visible;
            }

            &::after {
                display: none;
            }
        }

        .data-list-isin {
            width: 110px;
        }

        .data-list-ticker {
            width: 124px;
        }

        .data-list-rating {
            width: auto;
        }
    }

    .container-loader {
        position: static;
        flex-grow: 1;
        padding-bottom: 32px;
    }

    .modal-footer {
        padding-top: 4px;
    }
}

.introducing-direct-modal {
    .modal-content {
        width: 700px;
    }

    .modal-body {
        padding-bottom: 16px;
        min-height: 334px;

        ul.list-bullet {
            margin-bottom: 32px;
            margin-left: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            li {
                margin-top: 0;
                line-height: 20px;

                &:before {
                    top: 8px;
                    background-color: $grey-4;
                }
            }
        }

        h2 {
            border-bottom: 1px solid $border-color;
            padding-bottom: 8px;
            margin-bottom: 16px;
        }

        p {
            margin-bottom: 32px;
        }
    }

    .modal-footer {
        justify-content: space-between;
        align-items: center;

        .btn {
            text-transform: none;
        }
    }
}

.modal-whats-new {
    .modal-content {
        width: 700px;
    }

    .container-loader,
    .modal-body {
        position: static;
        height: 488px;
    }

    .modal-header {
        h1 {
            display: initial;
        }
    }

    .modal-body {
        overflow: auto;
        padding-top: 24px;
        padding-bottom: 24px;



        h1,
        img,
        p {
            margin-bottom: 24px;
        }

        h2 {
            margin-bottom: 16px;
            line-height: 16px;

            .icon-svg {
                vertical-align: top;
            }
        }

        img {
            max-width: 100%;
        }

        .more {
            margin-top: 5px;
        }

        ul {
            @extend .list-bullet;
            margin-top: 24px;

            li:before {
                background-color: $grey-4;
            }
        }

        .description {
            margin-bottom: 24px;
            white-space: pre-wrap;

            +.more {
                margin-top: -19px;
            }

            ul:last-child {
                margin-bottom: 0;
            }
        }
    }

    .modal-footer {
        justify-content: flex-start;
        align-items: center;
        height: 64px;
    }

    .updates {
        p {
            margin: 0 8px 0 0;
        }

        .icon-svg {
            margin: 0 0 0 16px;
        }
    }
}

.modal-user-agreement {
    z-index: 1001;

    .modal-content {
        width: 700px;
    }

    .modal-body {
        padding: 0 0 0 24px;
    }

    .disclaimer {
        height: 488px;
        overflow: auto;
        font-size: 14px;
        white-space: normal;
    }

    h2,
    p {
        margin: 0 10px 16px 0;
    }

    .row {
        margin: 0 0 20px;
    }
}

.modal-notifications {
    &.bidding-completed-popup {
        .modal-content {
            width: 700px;

            .list-bullet {
                margin-top: 4px;
                padding-left: 8px;

                li {
                    margin-top: 0;
                    line-height: 1.4;
                }
            }
        }
    }

    .status-message {
        overflow: auto;
        max-height: 520px;
    }
}

.modal-flagged {
    .status-message {
        overflow: auto;
        max-height: 404px;
    }

    .list-bullet {
        margin: 8px 0 0 8px;

        li {
            margin: 0;
            line-height: 1.4;

            &:before {
                top: 8px;
                background-color: $grey-4;
            }
        }
    }
}

.modal-select-dealer {
    .modal-body {
        position: relative;
        min-height: 76px;
    }
}

.modal-reject-form {
    form {
        textarea {
            white-space: normal;
        }
    }
}

.modal-confirmation {
    z-index: 36;

    .modal-body {
        padding-bottom: 0;
    }

    .list-bullet {
        margin-top: 8px;
        margin-bottom: 8px;

        li {
            &:not(:first-child) {
                margin-top: 4px;
            }
        }
    }

    .status-message {
        margin-bottom: 16px;
    }

    // Trade date field
    .data-item-row {
        display: block;
        margin-bottom: 16px;

        .data-item-col {
            width: 100%;
        }

        .data-item-col-title {
            font-size: 12px;
            color: inherit;
            display: block;
            margin-bottom: 4px;
        }

        .rdp-input {
            width: 100%;
        }
    }
}

.modal-syndicate-contacts {
    .modal-content {
        width: 767px;
    }

    .modal-header {
        display: flex;
        align-items: center;

        h1 {
            margin-right: 0;
        }
    }

    .modal-body {
        padding: 0;

        .data-list-content {
            overflow: auto;
            max-height: 320px;

            &:after {
                display: none;
            }
        }
    }
}

.modal-save-filters {
    .radio {
        margin-bottom: 12px;
    }

    .save-as-label {
        display: inline-block;
        line-height: 1.15;
        margin-bottom: 5px;
    }

    .row-checkboxes {
        padding-top: 8px;

        .checkbox {
            margin-right: 24px;
        }

        h3 {
            margin-bottom: 12px;
        }
    }

    .controls-wrap-radio {
        display: flex;
    }

    .controls-radio-item {
        margin-right: 24px;
        display: flex;
        align-items: center;

        .radio {
            margin-bottom: 0;
        }

        .info-tooltip {
            margin-left: 4px;
            line-height: 16px;

            .icon-svg {
                vertical-align: top;
            }
        }
    }

    .custom-alert-options {
        .controls-wrap {
            &:not(:first-child) {
                margin-top: 12px;
            }
        }

        .controls-wrap-head {
            display: flex;
            align-items: center;
        }

        .controls-wrap-title {
            min-width: 84px;
            margin-right: 24px;
            margin-bottom: 0;
            font-size: 12px;
            font-family: $font-roboto-regular;
        }
    }

    .modal-footer {
        align-items: center;
        justify-content: flex-start;

        .checkbox {
            margin-right: auto;
        }
    }

    .drop-down-btn {
        padding-right: 20px;

        span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .drop-down-list {
        width: 100%;
        max-height: 225px;
        overflow: auto;
    }
}

.modal-upload-all {
    .modal-body {
        overflow-y: auto;
        max-height: 522px;
        padding-bottom: 16px;
    }

    .bwic-info {
        p {
            margin-bottom: 4px;

            &:last-child {
                margin-top: 16px;
                margin-bottom: 16px;
            }
        }
    }

    .form-row {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .row-files {
        margin-top: 24px;
        max-height: 142px;
        overflow: auto;
    }

    .flex-row-multifiles {
        border-radius: $app-brd-radius;
        background-color: $grey-7;
        height: 42px;
        padding: 0 8px 0 16px;
        margin-bottom: 6px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .file-name {
        margin-left: 10px;
    }
}

.intex-popup {
    .modal-body {
        padding-top: 6px;
        padding-bottom: 6px;
    }

    .data-item {
        position: relative;

        .intex-link {
            position: absolute;
            right: 0;
            top: 0;
            white-space: nowrap;
        }
    }
}

.modal-participants-form {
    .modal-content {
        width: 540px;
    }

    .form-row {
        &:last-child {
            margin-bottom: 0;
        }
    }

    .form-label {
        margin-bottom: 5px;
        line-height: 14px;
    }
}

.modal-trade-allocation {
    .modal-content {
        display: flex;
        flex-direction: column;
        width: 730px;
        height: 95%;
        max-height: 672px;
    }

    .modal-body {
        padding: 0;
    }

    .cell-percent {
        .form-error:first-letter {
            text-transform: unset;
        }
    }

    .cell-amount {
        .form-error {
            margin-right: -1px;
            @extend .form-error-right;
        }
    }
}

.modal-iois {
    .modal-content {
        width: 700px;
        height: 530px;
        display: flex;
        flex-direction: column;

        .tabs-content {
            padding-top: 16px;
        }

        .data-list-content {
            overflow: auto;
            height: 320px;
        }

        .modal-footer {
            margin-top: auto;
        }

        .component-collapsible-list-item-header {
            font-size: 14px;
            font-family: $font-roboto-medium;
        }

        .component-collapsible-list-item-content  {
            .data-list-container {
                min-height: 1px;
            }

            .data-list-content {
                height: auto;
    
                &:after {
                    display: none;
                }

                .data-list-row {
                    padding-left: 48px;

                    .data-list-cell:first-child {
                        padding-left: 8px;
                    }
                }
            }
        }

        address {
            p {
                margin: 0;

                & + p {
                    margin: 4px 0 0;
                }
            }
        }
    }

    .empty-placeholder {
        margin: 0;
    }
}

.modal-email-preferences {
    &.email-preferences-fixed-height {
        .modal-body {
            height: 78vh;
        }
    }

    &.modal-portfolio-alerts {
        .modal-content {
            .modal-body {
                padding-bottom: 24px;
            }

            .controls-wrap:last-child {
                margin-bottom: 0;
            }

            .status-message {
                width: auto;
                display: inline-flex;
                margin-bottom: 16px;
            }
        }

        .notifications-center .tabs {
            margin: 0;
        }

        .tabs-content {
            padding: 16px 0;
            max-height: 70vh;
            overflow: auto;
        }
    }

    .page-content-profile .profile-content {
        display: block;
    }

    .modal-content {
        width: 800px;

        .modal-body {
            padding-bottom: 0;
            max-height: 78vh;
            overflow: auto;
        }

        .notifications-center {
            .data-list {
                &:not(.data-list-saved-filter) {
                    flex: none;
                }

                &.data-list-saved-filter {
                    min-height: 90px;
                }
            }

            .data-list-content {
                &:after {
                    display: none;
                }
            }
        }
    }

    .modal-confirmation {
        .modal-content {
            width: 460px;
        }

        .modal-footer {
            justify-content: flex-end;
        }
    }
}


.modal-upload-bids {
    justify-content: center;

    .modal-content {
        width: 100%;
        max-width: 1238px;
        max-height: 607px;
        margin: 32px auto;
    }

    .modal-header {
        padding-right: 48px;

        .btn-link {
            margin-left: 24px;
        }

        .loader-box {
            display: inline-flex;
            vertical-align: middle;
        }
    }

    .modal-body {
        overflow: hidden;
        padding-bottom: 0;

        .status-message {
            margin-bottom: 16px;
        }

        .grid-drop-section {
            margin-right: -24px;
            margin-left: -24px;
        }

        .grid {
            &.container-flex {
                min-width: min-content;
            }

            .cell-no-data {
                &:first-child {
                    @include flex-basis(32px);
                }
            }

            .highlight {
                .cell-title-wrapper {
                    margin-bottom: -1px;
                    border-bottom-width: 2px;
                    border-bottom-color: $grid-borders-focus;
                }
            }
        }

        .cell-title-wrapper {
            border: 1px solid transparent;

            &.column-removable {
                display: flex;
                justify-content: flex-end;
            }

            &.error {
                border-color: $warning-3;
            }

            .btn-close {
                color: $grey-2;
                margin-left: 4px;
                padding: 0;

                &:hover {
                    color: $text-color;
                }
            }
        }

        .cell-companyCode {
            text-align: right;

            .btn-placeholder {
                margin-right: 0;
                text-align: left;
                color: inherit;
            }

            &:nth-child(n+8) {
                .drop-down-list {
                    right: -1px;
                    left: auto;
                }

                .form-error {
                    @extend .form-error-right;
                    margin-right: -1px;
                }
            }
        }

        .select-bd {
            flex: none;
            width: auto;
            margin: -4px -9px;
            padding-right: 28px;
            border-color: transparent;
            text-align: right;
            background: none;

            &.select-focused {
                margin-top: -6px;
                padding-bottom: 1px;
                border-color: $grid-borders-focus;
                border-top-width: 2px;
                border-radius: 0;
                background-color: $grey-3;
            }

            .drop-down-list {
                width: 460px;
                text-align: left;
                white-space: normal;

                .section {
                    max-height: 280px;
                }
            }

            .drop-down-list-item {
                padding-top: 8px;
                padding-bottom: 8px;
                text-transform: uppercase;
            }

            .select-bd-name {
                margin-right: 24px;
            }
        }
    }
}

.add-dealers {
    .modal-content {
        flex: none;
    }

    .dealers {
        strong {
            &:after {
                content: ', ';
            }

            &:last-child:after {
                content: '.';
            }
        }
    }

    .note {
        margin-top: 8px;
    }

    .checkbox {
        margin-top: 16px;
    }
}

.modal-share-transaction {
    .modal-content {
        width: auto;

        .share-transaction-preview .loader-box {
            width: 721px;
            height: 100%;
        }
    }

    .share-transaction-row {
        display: flex;
    }

    .share-transaction-form {
        width: 426px;
        padding-right: 16px;
        display: flex;
        flex-direction: column;

        .status-message {
            margin-bottom: 8px;
        }

        .email-chips-list-wrap {
            max-height: calc(100vh - 433px);
            height: 347px;
            border-radius: $app-brd-radius $app-brd-radius 0 0;

            .email-chips-list {
                height: 100%;
            }
        }

        .share-transaction-message {
            width: 100%;
            height: 185px;
            border-color: $border-color;
            border-top: none;
            border-radius: 0 0 $app-brd-radius $app-brd-radius;
            padding: 12px;
        }
    }

    .share-transaction-preview {
        height: 580px;
        max-height: calc(100vh - 200px);
        background-color: $grey-2;
        padding: 16px;
        overflow: auto;
    }

    .mail-preview {
        background-color: $white-1;
        padding: 16px 10px;
        width: 700px;

        &-head {
            margin-bottom: 24px;

            h2, p {
                margin-bottom: 8px;
            }
        }

        &-info {
            margin-top: 14px;
            margin-bottom: 24px;
        }

        &-link-placeholder {
            color: $grey-8;
            font-style: italic;
        }

        &-info-text {
            h3, p {
                margin-bottom: 8px;
            }
        }

        &-bottom {
            background-color: $grey-7;
            color: $grey-8;
            padding: 24px 10px;
            font-size: 10px;
            width: 700px;

            a {
                pointer-events: none;
            }
        }
    }

    .data-item-general-cols {
        margin-bottom: 16px;

        .data-item-content {
            margin: 0 -16px;
        }

        .data-item-row-col {
            padding: 0 16px;
        }

        .data-item-row {
            font-size: 12px;
            margin-bottom: 15px;
        }

        .data-item-col {
            padding: 0 8px;
        }

        .data-item-row-header {
            border-bottom: 1px solid $blue-1;
            padding-bottom: 5px;
            margin-bottom: 9px;

            .data-item-col {
                color: $grey-4;
            }
        }

        .label-status {
            font: 12px/16px $font-family-base;
            text-transform: capitalize;
            background-color: transparent;
            padding: 0;
        }
    }

    .data-list-share-transaction {
        margin-bottom: 24px;

        .data-list-header,
        .data-list-row {
            padding-left: 8px;
            padding-right: 4px;
        }

        .data-list-header {
            background-color: $white-1;
            border-bottom: 1px solid $blue-1;

            .data-list-cell {
                background-color: $white-1;
            }
        }

        .data-list-content {
            overflow: auto;

            &:after {
                display: none;
            }
        }

        .data-list-row.data-list-striped-total {
            .data-list-cell-total {
                @include flex-basis(50px);
                text-transform: none !important;
            }

            .data-list-cell-total-value {
                margin-left: 118px;
            }
        }

        .user-email {
            color: $primary-3;
        }
    }
}

.modal-schedule-meeting {
    .mail-preview,
    .mail-preview-bottom {
        width: 600px;
    }

    .share-transaction-preview {
        height: 400px;
    }

    .share-transaction-form .email-chips-list-wrap {
        height: 175px;
    }

    .mail-preview-optional-text {
        margin-bottom: 24px;
        white-space: pre-wrap;
    }

    .data-list-striped .data-list-container {
        min-height: 1px;
    }

    .data-list-header {
        display: none;
    }

    .data-list-content {
        border-top: 1px solid $border-color;
    }
}

.modal-token {
    .form-select {
        margin-bottom: 24px;
    }

    .api-list {
        li {
            margin-top: 16px;
            line-height: 16px;

            &:first-child {
                margin-top: 0;
            }

            .info-tooltip {
                margin-left: 4px;
            }

            ul {
                margin: 8px 0 0 20px;
                li {
                    margin-top: 8px;
                }
            }
        }
    }

    .status-message {
        margin-top: 16px;

        .btn-link {
            margin-left: 16px;

            .icon-svg {
                margin: -2px 0 0;
            }
        }
    }

    .modal-footer {
        justify-content: space-between;
        align-items: center;

        .copy-to {
            cursor: pointer;

            .icon-svg {
                margin-right: 4px;
                margin-top: -4px;
            }
        }
    }
}

.modal-settlement-agent-users {
    .modal-content {
        width: 508px;
    }

    .modal-body {
        padding: 0;

        .data-list-content {
            overflow: auto;
            max-height: 320px;

            &:after {
                display: none;
            }
        }
    }
}

.modal-webinar {
    .modal-content {
        width: 700px;

        h2 {
            margin-bottom: 8px;
        }

        p {
            margin-bottom: 16px;
        }

        img {
            max-height: 330px;
            width: 100%;
        }
    }
}

.modal-portfolio-user-agreement {
    .modal-content {
        width: 700px;
    }

    .modal-body {
        padding: 0 0 0 24px;
    }

    .disclaimer {
        height: 488px;
        overflow: auto;

        p {
            margin: 0 10px 16px 0;
        }

        address {
            margin-bottom: 16px;
        }

        .list-bullet {
            li:before {
                background-color: $grey-4;
            }
        }

        & > .row {
            padding: 16px 0;

            & ~ .row {
                padding: 0 0 16px;
            }
        }
    }
}

.modal-free-im-trial {
    .status-message {
        margin-bottom: 16px;
    }

    .list-bullet {
        margin-bottom: 16px;

        li {
            padding-left: 20px;

            &:not(:first-child) {
                margin-top: 4px;
            }

            &:before {
                left: 8px;
                background-color: $grey-4;
            }
        }

        & + p {
            margin-bottom: 0;
        }
    }
}

.modal-congrats-free-im-trial {
    .modal-content {
        width: 800px;
    }

    .im-trial-image {
        margin: 0 -24px 16px;
        padding: 24px;
        background-color: $blue-7;

        img {
            width: 100%;
        }
    }
}

.modal-limits {
    .total-limits-row {
        padding-top: 6px;
        font-size: 14px;

        .label {
            margin-right: 30px;
        }
    }

    .total-daily-limit {
        padding: 14px 0 0;

        .label {
            width: 150px;
            display: inline-block;
        }
    }
}

.modal-security-usage {
    .modal-content {
        width: 710px;
    }

    .modal-body {
        height: 488px;
    }

    .status-message {
        margin-bottom: 16px;
    }

    .tabs-content {
        padding-top: 16px;
    }

    .data-list-content {
        overflow-y: auto;
    }

    .data-list-striped {
        .data-list-header, .data-list-row {
            padding-left: 16px;
            padding-right: 16px;
        }
    }

    .data-list-bwic-status {
        @include flex-basis(144px);
    }
}

.modal-trading-protocol {
    .modal-body {
        .radio {
            margin-top: 6px;
        }

        .text-warm-grey {
            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.available-excluded-dealers-popup.customize-columns-popup {
    .customize-columns-col:first-child .customize-columns-item,
    .customize-columns-head {
        padding-left: 24px;
    }
}

.edit-settlement-instructions-popup {
    .modal-content {
        width: 800px;
        height: 632px;
        max-height: calc(100vh - 178px);

        .modal-body {
            max-height: calc(100vh - 298px);
            overflow: auto;
            padding-bottom: 0;
        }

        .form-control {
            height: 24px;
            font-size: 12px;
            line-height: 24px;
        }

        .data-item-row {
            margin-bottom: 16px;
        }

        .data-item-col.data-item-col-title {
            padding: 0;
            color: $grey-4;
        }

        .data-item-row:nth-child(odd) {
            background-color: transparent;
        }

        .data-item-general-cols {
            margin-bottom: 0;
        }
    }
}
