.btn-link-intex {
    .icon-svg-intex {
        color: $red-3;
    }

    &[disabled],
    &.disabled {
        .icon-svg-intex {
            color: $grey-1;
        }
    }
}

.im-banner {
    &.last-week-expired {
        background-color: $yellow-4;
    }

    &.expired {
        background-color: $warning-5;
    }

    .btn-link-subscribe {
        margin-right: 4px;
    }

    .btn-link-subscribe-info {
        margin-left: 4px;

        .icon-svg-info {
            color: $grey-4;
            margin-right: 0;
        }
    }
}

.trial-tooltip {
    max-width: 265px;
}

.name-with-link {
    display: flex;
    align-items: center;

    .name-box {
        max-width: calc(100% - 24px);
        width: auto;
    }

    .btn-link {
        margin-right: 0;
        margin-left: 8px;
        min-height: 18px;
        white-space: nowrap;
    }
}

.blocked-feature-placeholder {
    display: flex;
    align-items: center;

    .icon-svg {
        margin: 0 8px 0 0;
    }

    &.legend-charts {
        padding: 16px;
        text-align: left;
        justify-content: flex-start;

        h3 {
            margin-bottom: 0;
        }
    }
}

.email-alert-select {
    position: relative;

    .email-alert-wrap {
        display: flex;
        align-items: center;
    }

    .email-alert-icon {
        margin-right: 4px;
    }

    .email-alert-btn {
        white-space: nowrap;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &.without-alalitics {
            min-width: 60px;
        }

        &.disabled {
            pointer-events: none;
            color: $grey-1;

            .email-alert-label-info {
                background-color: $grey-7;
            }
        }

        &.alert-off {
            &.disabled {
                .email-alert-label-info {
                    background-color: $grey-7;
                }
            }
            
            .email-alert-label-info {
                background-color: $grey-6;
            }
        }
    }

    .email-alert-label-info {
        border-radius: $app-brd-radius;
        background-color: $primary-8;
        padding: 2px 6px;
        font-family: $font-roboto-medium;
        font-size: 10px;
        line-height: 1.2;
    }
}

.history-box {
    .btn-link-history {
        & + .history-version {
            color: $grey-8;
            margin-left: 8px;
            font-size: 12px;
            position: relative;
            top: 1px;
        }
    }
}

.action-row {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: -2px;
}

.action-col {
    @include flex-basis(32px);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    line-height: 16px;
    padding: 2px;

    .icon-svg {
        margin: 0;
    }
}

.action-empty-placeholder {
    color: $grey-1;
    width: 16px;
    text-align: center;
}

.text-row-placeholder {
    display: flex;
    align-items: center;

    &.empty-placeholder {
        padding: 16px 12px 16px 16px;
        text-align: left;
        justify-content: flex-start;

        &.placeholder-center {
            justify-content: center;
            text-align: center;
        }

        .icon-svg {
            margin: 0;
            min-width: 24px;
        }

        .placeholder-text {
            display: flex;
            flex-direction: column;
        }
    }

    h3 {
        margin-bottom: 2px;
    }

    .placeholder-text {
        padding-left: 8px;
        line-height: 16px;

        .btn-link {
            display: inline;
            vertical-align: top;
        }
    }
}

.column-row {
    display: flex;
    margin: 0 -16px;

    &-col {
        width: 50%;
        padding: 0 16px;
    }
}

.data-list-saved-filter .data-list-header {
    .data-list-cell-collapse-action .icon-svg {
        display: none;
    }
}

.data-list-saved-filter {
    .data-list-header {
        .data-list-cell-filter-name {
            margin-left: 24px;
            padding-left: 8px;
        }
    }

    .component-collapsible-list-item-header {
        padding-top: 0;
        padding-bottom: 0;

        .data-list-cell {
            height: 32px;
            padding-top: 8px;
            padding-bottom: 4px;
        }
    }

    .data-list-cell-right-border {
        position: relative;

        &:before {
            position: absolute;
            content: '';
            width: 1px;
            height: 32px;
            background-color: $border-color;
            top: 0;
            right: 0;
        }
    }

    .data-list-header,
    .data-list-row {
        padding-right: 0;
    }
}

.component-dropdown-related-trans {
    .component-dropdown-list {
        width: 232px;
        overflow: hidden;
        min-height: 40px;
    }

    .data-list-row,
    .data-list-header {
        padding-left: 12px;
        padding-right: 12px;
    }

    .data-list-content {
        max-height: 350px;
        overflow: auto;

        &:after {
            display: none;
        }

        .data-list-row {
            cursor: pointer;

            .data-list-cell-xxxs {
                opacity: 0;
            }

            &:hover {
                background-color: $row-active;

                .data-list-cell-xxxs {
                    opacity: 1;
                }
            }
        }
    }
}

.related-trans-btn {
    svg {
        min-width: 16px;
    }

    .icon-component-dropdown {
        margin-right: 0;
        margin-left: 4px;
    }
}