.px-talk-information {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    padding: 16px 16px 22px;

    &-title {
        margin-bottom: 14px;

        h4 {
            margin-bottom: 0;
        }
    }

    &-chart {
        margin-bottom: 25px;

        .graph-details {
            font-size: inherit;
            line-height: inherit;
        }

        .js-plotly-plot {
            width: 100%;
            height: 100px;
        }
    }

    &-history {
        margin-right: -16px;
        margin-left: -16px;

        .px-talk-information-title {
            margin-bottom: 0;
            padding: 0 16px;
            border-bottom: 1px solid $border-color;
            line-height: 24px;
            background-color: $grey-3;

            .icon-svg-icon-double-arrows {
                margin-right: 40px;
            }

            .icon-svg-info {
                margin: -1px 0 0 8px;
            }
        }

        .data-list {
            .data-list-header {
                padding: 0 16px;

                .companies-column {
                    padding-left: 48px;
                }
            }

            &.with-collapsible {
                .companies-column {
                    padding-left: 24px;
                }
            }

            .data-list-row {
                padding: 0 16px;

                &.eval {
                    .companies-column {
                        padding-left: 20px;
                    }

                    .eval-box {
                        min-width: 75px;
                        height: 30px;
                        font-family: $font-roboto-bold;
                        justify-content: center;
        
                        .icon-svg-kopentech {
                            margin-right: 8px;
                            margin-left: 4px;
                        }
                    }
                }

                .companies-column {
                    display: flex;
                    padding-left: 0;

                    .company-name {
                        padding-left: 47px;

                        &.active-company {
                            padding-left: 0;
                        }
                    }
                }
            }

            .contact-list {
                .data-list-row {
                    padding-left: 64px;
                    min-height: 64px;
                }
            }
        }

        .col-companies {
            width: 175px;

            &.company-name {
                width: 232px;
                white-space: nowrap;
            }
        }

        .col-px-talk {
            width: 80px;
        }

        .col-digitized {
            width: 80px;
        }

        .col-k-talk {
            width: 190px;
        }

        .data-list-content {
            overflow-y: auto;
        }

        .kpt-platform-wrapper {
            margin-right: 8px;
        }
    }

    .accordion {
        &-row {
            &:hover {
                border-top-color: $border-color;
            }

            .collapsable-item-header {
                padding-right: 16px;
                padding-left: 16px;
                height: 32px;
            }
        }

        .kpt-platform-wrapper {
            margin-right: -56px;
            margin-left: 16px;

            & + .company-name {
                color: $primary-3;
                font-family: $font-roboto-medium;
            }
        }

        .company-name {
            padding-left: 56px;
        }

        .contacts-cards {
            li {
                padding-right: 30px;
                padding-left: 72px;

                &:hover {
                    background: $row-hover;
                }

                &:first-child {
                    border-top: 0;
                }

                &:last-child {
                    border-bottom: 0;
                }
            }
        }

        .px-talk-history-contact {
            &.heading {
                display: none;
            }
        }

        .collapsable-item-k-talk {
            .collapsable-item-header {
                padding-top: 0;
                padding-bottom: 0;
                padding-left: 46px;

                .eval-box {
                    height: 32px;
                    margin-left: -6px;
                    padding: 0 8px;
                    
                    span {
                        font-family: $font-roboto-medium;
                    }

                    .icon-svg-kopentech {
                        margin-right: 8px;
                    }

                    .icon-svg-info {
                        margin-left: 8px;
                    }
                }
            }
        }

        .text-loader {
            display: block;
            padding: 4px 0 0 72px;
        }
    }

    &-placeholder {
        position: relative;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
}
