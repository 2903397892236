.radio {
    position: relative;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    height: 16px;
    font: 12px/16px $font-family-base;
    cursor: pointer;

    &.disabled {
        color: $control-checkbox-radio-text-disabled;
    }

    &-text {
        margin-left: 4px;
    }

    input[type="radio"]:not(:checked),
    input[type="radio"]:checked {
        position: absolute;
        left: 0;
        z-index: -1;
        opacity: 0;
    }

    input[type="radio"]:not(:checked) + .radio-icon,
    input[type="radio"]:checked + .radio-icon {
        position: relative;
        padding-left: 16px;
    }

    input[type="radio"]:not(:checked) + .radio-icon:before,
    input[type="radio"]:checked + .radio-icon:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border: solid 1px $control-border;
        background-color: $control-bg;
        box-sizing: border-box;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        @extend .transition;
    }

    input[type="radio"]:checked + .radio-icon:before {
        border-color: $button-bg;
        background-color: $control-bg;
    }

    input[type="radio"]:not(:checked):disabled + .radio-icon:before,
    input[type="radio"]:checked:disabled + .radio-icon:before {
        border-width: 1px;
        border-color: $control-disabled;
        background-color: $control-disabled-bg;
    }

    input[type="radio"]:disabled + .radio-icon {
        pointer-events: none;
    }

    input[type="radio"]:not(:checked) + .radio-icon:hover:before {
        border-width: 1px;
        border-color: $control-hover;
        background-color: $control-hover-bg;
    }

    input[type="radio"]:not(:checked) + .radio-icon:active:before {
        border-width: 1px;
        border-color: $control-radio-check-focus;
        background-color: $control-hover-bg;
    }

    input[type="radio"]:not(:checked) + .radio-icon:after,
    input[type="radio"]:checked + .radio-icon:after {
        content: '';
        position: absolute;
        top: 3px;
        left: 3px;
        height: 10px;
        width: 10px;
        background: $button-bg;
        border-radius: 50%;
    }

    input[type="radio"]:checked + .radio-icon:hover:after {
        background-color: $control-hover;
    }

    input[type="radio"]:checked + .radio-icon:hover:before {
        border-color: $control-hover;
    }

    input[type="radio"]:checked + .radio-icon:active:after {
        background-color: $control-radio-check-focus;
    }

    input[type="radio"]:checked + .radio-icon:active:before {
        border-color: $control-radio-check-focus;
    }

    input[type="radio"]:checked:disabled + .radio-icon:after {
        background-color: $control-disabled;
    }

    input[type="radio"]:checked:disabled + .radio-icon:before {
        border-color: $control-disabled;
    }

    input[type="radio"]:not(:checked) + .radio-icon:after {
        opacity: 0;
        transform: scale(0);
    }

    input[type="radio"]:checked + .radio-icon:after {
        opacity: 1;
        transform: scale(1);
    }

    &-icon {
        height: 16px;
    }

    &.radio-indeterminate {
        input[type="radio"]:checked + .radio-icon:after {
            top: 7px;
            left: 5px;
            width: 6px;
            height: 2px;
            background-color: $control-checkbox-radio-border-checked;
            border-radius: 2px;
        }

        input[type="radio"]:checked + .radio-icon:before {
            border-color: $control-checkbox-radio-border-checked;
            background-color: $control-checkbox-radio-bg;
        }

        &:hover {
            input[type="radio"]:checked + .radio-icon:after {
                background-color: $control-hover;
            }

            input[type="radio"]:checked + .radio-icon:before {
                border-color: $control-checkbox-radio-border-hover;
                background-color: $control-checkbox-radio-bg-hover;
            }
        }

        &:active {
            input[type="radio"]:checked + .radio-icon:after {
                background-color: $control-radio-check-focus;
            }

            input[type="radio"]:checked + .radio-icon:before {
                border-color: $control-radio-check-focus;
                background-color: $control-checkbox-radio-bg-focus;
            }
        }

        &.disabled {
            cursor: default;

            input[type="radio"]:checked + .radio-icon {
                &:before {
                    border-color: $control-disabled;
                }

                &:after {
                    background-color: $control-disabled;
                }
            }
        }
    }
}

.radio-group {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
        margin-bottom: 24px;
    }

    .radio {
        margin-top: 8px;
        margin-bottom: 8px;
    }
}

.radio-group-row {
    flex-direction: row;

    .radio {
        &:not(:last-child) {
            margin-right: 16px;
        }
    }
}
