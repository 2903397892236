.data-item-general-cols {
    .data-item-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
}

.data-list-striped-cap-structure {
    .data-list-striped-total {
        .middle-sticky-content {
            min-width: 1990px;
        }
    }
}

.data-item-general-information {
    .data-list-striped-cap-structure {
        .data-list-striped-total {
            .middle-sticky-content {
                min-width: 1535px;
            }
        }
    }
}

.component-tabs-target-portfolio,
.component-tabs-iois {
    .data-item-content {
        @extend .container-flex;
    }
}

.component-tabs-target-portfolio {
    h2 {
        display: none;
    }

    .data-list-container {
        min-width: 4480px;
    }

    .data-item {
        @extend .container-flex;
        margin-bottom: 0;
    }

    .data-item-content {
        margin-top: 12px;
    }

    .block-placeholder .placeholder {
        font-size: 22px;
    }

    .data-list-content:after {
        display: none;
    }
}

.component-tabs-iois {
    .data-item {
        flex: auto;
    }

    .data-item-content {
        padding-top: 10px;
    }

    .data-list-container {
        min-width: 1755px;

        .data-list-row.deleted {

            .data-list-cell {
                color: $grey-1;
                text-decoration: line-through;

                &:not(.data-list-cell-ioi-class) {
                    pointer-events: none;
                }

                .pseudo-link {
                    border-bottom: none;
                    pointer-events: none;
                    color: $grey-1;
                }
            }
        }
    }

    .controls-iois {
        display: flex;
        align-items: center;
    }

    .grid-table-wrapper {
        &:after {
            content: " ";
            min-height: 9px;
            visibility: hidden;
        }
    }

    .grid {
        min-width: 1695px;

        td {
            text-transform: none;
        }

        .cell-comment {
            textarea {
                min-height: 30px;
                position: relative;
                z-index: 2;
                padding-top: 6px;
                padding-left: 8px;
            }
        }
    }

    .flex-item-right {
        .btn,
        .btn-link {
            margin-left: 16px;
        }

        .btn-save-iois {
            margin-left: 8px;
        }
    }

    .data-list-striped {
        margin-top: 10px;

        &:not(.data-list-freeze) {
            @media all and (-ms-high-contrast:none) {
                overflow-x: scroll;
            }
        }
    }
}

.data-item-collateral-quality-tests {
    .data-list-content {
        overflow: auto
    }

    .data-list-cell-xl {
        @include flex-basis(42%);
    }
}

.data-item-collateral-assumptions,
.data-item-sources-and-uses,
.data-item-management-fee,
.data-item-administrative-fee {
    .data-item-col-title {
        flex-basis: 60%;
    }

    .data-item-col {
        &:last-child {
            text-align: right;
        }
    }
}

.component-comparable-metrics {
    .data-item-content > p {
        margin-bottom: 16px;
    }

    .data-list-header,
    .data-list-row {
        padding-left: 8px;
        padding-right: 12px;
    }

    .data-list-content {
        overflow: auto;
    }

    .metrics-chart-row {
        display: flex;
        gap: 14px;
    }

    .metrics-chart-col {
        min-width: calc(25% - 10px);
        max-width: calc(25% - 10px);
        padding: 12px;
        border: 1px solid $border-color;
        border-radius: $app-brd-radius;
        overflow: hidden;

        .restricted-placeholder {
            margin: -15px;

            .required-feature {
                width: 190px;
            }
        }
    }

    .graph-box {
        .js-plotly-plot {
            width: 100%;
            height: 200px;
        }

        .empty-placeholder {
            padding: 0;
            height: 205px;
        }

        .description {
            font-size: 12px;
            color: $grey-9;
            padding-top: 8px;
        }
    }

    .comparative-metrics-info {
        margin-bottom: 25px;
    }

    .restricted-placeholder-box .required-feature {
        width: 100%;
    }
}
