﻿table {
    border-collapse: collapse;
    table-layout: fixed;
    width: 100%;
    max-width: 100%;
    background-color: $grid-bg;
    padding: 0;
    margin: 0;

    th {
        font-weight: normal;
    }
}


/* Sorting icon arrow in the tables header */

.icon-cell-sort {
    color: $grey-2;
    margin-left: 2px;

    &:hover {
        color: $text-color;
    }
}

.cell-sort {
    cursor: pointer;

    &.asc,
    &.desc {
        .icon-cell-sort {
            color: $text-color;
        }
    }

    &.asc {
        .icon-cell-sort {
            transform: rotate(180deg);
        }
    }
}

/* end Sorting icon arrow in the tables header */
