﻿@mixin activeButton {
    color: $white-1;
    font-family: $font-roboto-bold;
    border-color: $primary-3;
    background-color: $primary-3;

    &:hover {
        background-color: $primary-2;
        border-color: $primary-2;
    }

    &:focus {
        background-color: $primary-1;
        border-color: $primary-1;
    }
}

.rdp {
    &-caption {
        position: relative;
        line-height: 32px;

        &_label {
            position: relative;
            z-index: 2;
            display: flex;
            flex-grow: 1;
            justify-content: center;
            text-align: center;
            margin-right: 16px;
            margin-left: 16px;

            .btn-link {
                margin: 0 1px;
                padding: 0 8px;
                font-size: 14px;

                &:hover {
                    background: $primary-8;
                }
            }
        }
    }

    &-nav {
        position: absolute;
        top: 8px;
        right: 0;
        left: 0;
        z-index: 1;
        display: flex;
        justify-content: space-between;

        &_button {
            padding: 0;
            border: 0;
            background: none;
            line-height: 1;

            .icon-svg {
                vertical-align: top;
            }

            &:not(.rdp-nav_button_disabled) {
                &:hover {
                    color: $link-hover;
                }

                &:focus,
                &:active {
                    color: $link-focus;
                }
            }
        }

        &_button_previous {
            .icon-svg {
                transform: rotate(180deg);
            }
        }

        &_button_disabled {
            color: $grey-1;
            cursor: default;
            pointer-events: none;
        }
    }

    &-table {
        max-width: 210px;
        text-align: center;
        margin: 0 auto;
    }

    &-head {
        color: $grey-1;
        font-size: 10px;
        font-family: $font-roboto-bold;
        text-transform: uppercase;
        line-height: 24px;
    }

    &-cell {
        padding: 0;
    }

    &-button {
        background: none;
        vertical-align: top;
        cursor: pointer;
    }

    &-day {
        width: 30px;
        height: 30px;
        border: 1px solid transparent;
        border-radius: $app-brd-radius;

        &:not(.rdp-day_disabled):not(.rdp-day_selected):not(.rdp-day_outside) {
            &:hover {
                background-color: $primary-6;
                border-color: $primary-6;
            }

            &:focus {
                background-color: $primary-8;
                border-color: $primary-8;
            }
        }

        &_today {
            border: solid 1px $color-focus;
            border-radius: $app-brd-radius;
        }

        &_outside,
        &_disabled {
            color: $grey-1;
        }

        &_disabled {
            pointer-events: none;
        }

        &_desirable_range {
            &:not(.rdp-day_range_start):not(.rdp-day_range_end):not(.rdp-day_disabled):not(.rdp-day_selected):not(.rdp-day_outside) {
                border-radius: 0;
                background-color: $primary-6;

                &:hover {
                    border: 1px dashed $grey-1;
                    border-radius: $app-brd-radius;
                }
            }
        }
    }

    &-input {
        position: relative;
        width: 140px;

        &:before {
            @extend .icon;
            @extend .icon-calendar;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 8px;
            content: '';
            pointer-events: none;
        }

        .form-control {
            @extend .form-control;
            padding-right: 28px;
            width: 100%;
            cursor: pointer;
            color: $grey-4;

            &.form-control-sm {
                @extend .form-control-sm;
            }

            &.is-invalid {
                padding-right: 50px;
                background-position: right 27px top 50%;
            }
        }

        .disabled & {
            &:before {
                @extend .icon-calendar-grey;
            }
        }
    }

    &-overlay {
        position: absolute;
        left: 0;
        z-index: 12;
        width: 270px;
        padding: 28px 24px 24px;
        border: solid 1px $border-color;
        box-shadow: $app-shadow;
        background: $white-1;
    }

    &.rdp-single {
        .rdp-day_selected:not(.rdp-day_disabled):not(.rdp-day_outside) {
            @include activeButton;
        }
    }

    &.rdp-custom {
        .rdp-day_selected {
            &:not(.rdp-day_range_start):not(.rdp-day_range_end) {
                border-radius: 0;
            }

            &:not(.rdp-day_disabled):not(.rdp-day_outside) {
                border-color: $primary-8;
                background-color: $primary-8;

                &.rdp-day_range_start,
                &.rdp-day_range_end {
                    @include activeButton;
                }
            }
        }
    }

    &-list {
        display: flex;
        flex-wrap: wrap;

        &_item {
            width: 33.33%;
            text-align: center;

            &.rdp-list_item_current {
                .rdp-list_button {
                    background: $primary-3;
                    color: $white-1;
                    font-family: $font-roboto-bold;
                }
            }
        }

        &_button {
            height: 44px;
            line-height: 44px;
            width: 48px;
            border: 0;
            border-radius: $app-brd-radius;
            cursor: pointer;

            &:hover {
                color: $primary-3;
                background-color: $primary-6;
            }

            &:focus {
                color: $primary-1;
                background-color: $primary-6;
            }
        }

        &_months {
            .rdp-list_item {
                width: calc(100% / 3);
            }
        }

        &_years {
            .rdp-list_item:not(.rdp-list_item_range) {
                width: 25%;
            }

            .rdp-list_item_range {
                width: 50%;

                .rdp-list_button {
                    width: auto;
                }
            }
        }
    }
}

.custom-view-item {
    background-color: $white-1;
    padding: 0 0 0 178px;
    box-shadow: $app-shadow;
    border: solid 1px $border-color;
    width: 699px;
    position: absolute;
    top: -1px;
    left: -1px;
    z-index: 2;

    &-body {
        border-left: solid 1px $border-color;
        padding: 24px 24px 13px;

        .flex-row {
            padding: 0;
        }

        .form-label {
            line-height: 20px;
        }

        .rdp {
            width: 210px;
            margin-top: 24px;

            &:first-child {
                margin-right: 47px;
            }
        }

        .form-control {
            font-family: $font-roboto-medium;
            width: 210px;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .separator-line {
            display: inline-block;
            align-self: flex-end;
            margin: 0 18px 7px;
        }
        
        .custom-view-box {
            display: flex;
            width: 210px;

            .form-item {
                width: 50%;
                position: relative;

                &:first-child {
                    padding-right: 5px;
                }

                &:last-child {
                    padding-left: 5px;
                }

                .form-control {
                    width: 100%;
                    font-family: $font-roboto-regular;

                    &::placeholder {
                        font-size: 13px;
                    }
                }

                .time-picker-outside {
                    position: relative;

                    &.is-open {

                        & > .form-control {
                            opacity: 0;
                        }
                    }
                }

                .time-picker-wrap {
                    border: 1px solid $border-color;
                    box-shadow: $app-shadow;
                    border-radius: $app-brd-radius;
                    position: absolute;
                    z-index: 6;
                    top: 36px;
                    right: 0;
                    background-color: $white-1;
                    width: 136px;
                }

                .rc-time-picker + div {
                    position: static !important;
                }

                .rc-time-picker-panel {
                    position: static !important;
                }

                .rc-time-picker {
                    display: none;
                }

                .rc-time-picker-panel-input-wrap {
                    position: absolute;
                    z-index: 7;
                    top: -102px;
                    right: 0;
                    width: 103px;
                    height: auto;

                    input {
                        @extend .form-control;
                        width: 100%;

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }

                .control-time-picker {
                    flex-direction: column;
                    height: auto;
                    border: none;
                }

                .radio-group {

                    .radio {
                        margin-top: 8px;
                        margin-bottom: 8px;
                        padding: 0 12px;
                    }
                }
            }
        }
    }

    &-footer {
        text-align: right;
        padding: 21px;
        border-left: 1px solid $grey-5;

        .btn {
            margin-left: 8px;
        }
    }
}