﻿.dealers {
    .data-list {
        &-cell {
            flex: 1 1 auto;
        }

        &-dealer-name {
            flex: 0 1 25%;
        }

        &-date {
            text-align: left;
        }

        &-status {
            width: 127px;
            flex: none;
        }
    }

    .dealer-popup {
        .popover-header {
            h1 {
                white-space: normal;
            }
        }

        &.grid-item-sm {
            flex: auto;
        }

        .flex-row {
            .flex-item-auto {
                flex: 0 0 70%;
                white-space: normal;

                &:first-child {
                    flex: 0 0 30%;
                }
            }
        }

        .contacts {
            max-height: 200px;
            overflow: auto;

            .contact-box {
                margin: 0 10px 15px 0;
                white-space: normal;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
