.blast-message-settings {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    min-height: 1px;
    flex-grow: 1;
    overflow: hidden;

    &-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow: auto;

        .status-message {
            width: auto;
            margin: 16px 16px 0;
        }
    }

    &-buttons {
        padding: 16px 24px;
        text-align: right;

        .btn {
            margin-left: 12px;
        }

        .btn-ghost {
            min-width: 90px;
        }

        .btn-main {
            min-width: 77px;
        }
    }


    &-types {
        padding: 24px 16px;

        .blast-message-types-title {
            margin-bottom: 6px;
            padding-left: 28px;
            font-family: $font-roboto-medium;
        }

        .blast-message-types-list {
            .radio {
                display: block;

                &:not(:first-child) {
                    margin-top: 26px;
                }

                &.radio-with-input {
                    display: flex;
                    align-items: flex-start;
                    height: auto;

                    .radio-icon {
                        margin-top: 4px;
                    }

                    .input-wrapper {
                        display: inline-block;
                        width: 60px;
                        margin: 0 4px -1px;
                    }
                }
            }

            .sub-group-radios {
                margin-bottom: 26px;
                padding-left: 20px;

                .radio {
                    margin-top: 8px;
                    font-style: normal;
                }
            }
        }
    }

    &-recipients {
        display: flex;
        flex-direction: column;
        border-top: 1px solid $border-color;

        .checkbox {
            vertical-align: top;

            input[type="checkbox"]:not(:checked) + label,
            input[type="checkbox"]:checked + label {
                display: flex;
                padding-left: 32px;
            }
        }

        .select-all-checkbox {
            font-family: $font-roboto-medium;
            padding: 7px 16px;
            border-bottom: 1px solid $border-color;
        }

        .blast-message-recipients {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            overflow: auto;
            min-height: 50px;
        }

        .recipient-checkbox-row {
            display: flex;
            align-items: center;
            flex: none;
            min-width: 100%;
            line-height: 16px;
            padding: 8px 16px 7px;
            border-bottom: 1px solid $border-color;

            &:hover {
                background: $row-hover;
            }

            .checkbox {
                .tooltip-wrapper {
                    vertical-align: top;
                }

                .icon-wrap {
                    min-width: 16px;
                    margin-right: 8px;

                    .right-panel-blast-message-all-to-all & {
                        display: none;
                    }

                    .icon-svg {
                        vertical-align: top;
                    }

                    & + .company-name {
                        padding-left: 0;
                    }
                }

                .company-name {
                    padding-left: 20px;
                    flex-grow: 1;
                    white-space: nowrap;
                }
            }

            .bids-counter {
                color: $grey-4;
                flex: none;
                min-width: 56px;
                margin-left: auto;
                padding-left: 16px;
                white-space: nowrap;

                & + .favorite-action-button {
                    margin-left: 8px;
                }
            }

            .favorite-action-button {
                flex: none;
                width: 16px;
                margin-left: auto;
            }

            .text-ellipsis {
                max-width: 260px;
                display: inline-block;
                white-space: nowrap;
            }
        }

        .blast-message-empty {
            flex-grow: 1;
        }
    }
}
