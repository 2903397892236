﻿.data-list {
    position: relative;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    flex-grow: 1;
    width: 100%;
    background: $white-1;

    &-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        flex: 1 1 auto;
        min-height: 1px;
    }

    &-content {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        overflow-x: unset;
        overflow-y: scroll;
        min-height: 1px;

        &:after {
            content: ' ';
            min-height: 12px;
            visibility: hidden;
        }
    }

    &-columns {
        display: flex;
        width: 100%;
        flex-grow: 1;
    }

    .context-menu {
        ul {
            right: auto;
            left: 1px;
        }
    }

    &:not(.data-list-freeze) {
        overflow: auto;

        .data-list-container {
            min-width: min-content;
        }
    }

    &.data-list-overflow-auto {
        .data-list-content {
            overflow: auto;
        }
    }

    &.data-list-freeze {
        .middle-sticky-content {
            min-width: min-content;
        }

        .data-list-content {
            display: flex;
            flex-direction: column;

            &:after {
                display: none;
            }

            .data-column-sticky-middle {
                overflow-y: hidden;
                overflow-x: auto;
            }
        }
    }

    &.data-list-bd-bidding {
        .data-list-container {
            .data-list-header {
                padding-right: 41px;
            }

            .cell-bid-actions {
                margin-left: auto;
            }
        }

        &.data-list-mac {
            .data-list-container {
                .data-list-header {
                    padding-right: 39px;
                }
            }
        }
    }

    &.data-list-bd-scheduled,
    &.data-list-bd-finished,
    &.data-list-bd-cancelled {
        &.data-list-striped {
            .data-list-header {
                padding-right: 41px;
            }

            &.data-list-mac {
                .data-list-header {
                    padding-right: 39px;
                }
            }
        }
    }

    &.data-list-bd-finished {
        .data-list-container {
            .data-list-cell.cell-bid-value {
                margin-right: 0;
                padding-right: 48px;

                .justify-content-end {
                    flex-grow: 1;
                    margin-right: -48px;
                }

                .axed-final-icons {
                    flex: none;
                    width: 48px;

                    .final-icon-wrap {
                        margin-left: auto;
                    }
                }

                .input-placeholder-value {
                    min-width: 60px;
                    padding: 0;
                }
            }
        }
    }

    .all-tab & {
        .data-column-sticky-middle {
            .header-sticky-content {
                min-width: 1310px;
            }
        }
    }
}


.data-list-apply-all {
    text-align: right;

    .btn {
        width: 120px;
        padding: 0 0.7rem;
    }
}

.data-list-apply-all-bid {
    padding-right: 58px;
}
