.company-members {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 1px;
    width: 100%;

    h4 {
        margin: 0 16px 12px;

        &:first-child {
            margin-top: 15px;
        }
    }

    &-header {
        display: flex;
        align-items: center;
        flex: none;
        white-space: nowrap;
        padding: 14px 16px;

        .text-medium {
            margin-bottom: 0;
            text-transform: none;
        }

        .text-sm {
            color: $grey-8;
            margin-left: 24px;
        }
    }

    &-content {
        position: relative;
        display: flex;
        flex: 1 1 auto;
        overflow-x: hidden;
        overflow-y: auto;
        border-top: 1px solid $grey-5;

        &.empty {
            border: 0;
        }

        .placeholder {
            flex: none;
            text-align: center;
            font-size: 14px;
            color: $grey-1;
            text-align: center;
            padding: 0 16px;
        }

        .contacts-cards {
            flex-grow: 1;

            li {
                &:first-child {
                    border-top: 0;
                }

                .name {
                    width: 370px;
                }
            }
        }

        .container-loader {
            flex-direction: row;
            align-self: flex-start;
            padding: 0 16px;
            position: static;
            background: none;

            .icon {
                width: 16px;
                height: 16px;
                margin-right: 4px;
            }
        }
    }

    .company-info {
        padding: 0 16px 20px;

        .flex-row {
            flex-wrap: nowrap;
            align-items: flex-start;
            margin-bottom: 6px;

            &:last-child {
                margin-bottom: 0;
            }

            .flex-item-auto {
                font-family: $font-roboto-medium;

                &.website-address {
                    .text-ellipsis {
                        max-width: 255px;
                        white-space: nowrap;
                    }
                }

                &:first-child {
                    flex: 0 0 130px;
                    font-family: $font-roboto-regular;
                }
            }
        }
    }
}
