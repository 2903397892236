﻿/* Icons */

.icons-test {
    .icon {
        margin-bottom: 15px;
    }

    .icon-svg {
        margin-bottom: 10px;
    }

    .icon-submit,
    .icon-btn-arrow {
        background-color: $grey-1;
    }
}

/* Icons */


/* Buttons */
.buttons-uikit {
    .form-row {
        flex-wrap: nowrap;
    }

    .button-kit-item {
        flex: none;
    }
}

/* end Buttons */

/* Statuses */

.statuses-uikit {
    .status,
    .bwic-state-my,
    .bwic-state-ktx,
    .state-new,
    .state-updated,
    .status-affirmed,
    .bwic-state-live {
        width: 140px;
        display: inline-block;
        margin-right: 0;
    }

    .label-status {
        margin-right: 20px;
        margin-bottom: 10px;
    }

    .bwic-status {
        margin-right: 20px;
        margin-bottom: 10px;

        .label-status {
            margin: 0;
        }
    }
}

/* end Statuses */

.container-test {
    .control-group-slide {
        width: 180px;
    }
}
