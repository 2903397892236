.horizontal-slider {
    overflow: hidden;

    &-button {
        position: relative;
        z-index: 1;
        flex: none;
        width: 29px;
        height: 32px;
        justify-content: center;

        &-prev {
            box-shadow: 15px 0 15px $white-1;
            border-right: 1px solid $border-color;

            .icon-svg {
                transform: scaleX(-1);
            }
        }

        &-next {
            box-shadow: -15px 0 15px $white-1;
            border-left: 1px solid $border-color;
        }

        &.disabled {
            box-shadow: none;
        }

        .icon-svg {
            margin-right: 0;
        }
    }

    &-content {
        overflow: hidden;
        flex-grow: 1;
    }
}