﻿header {
    flex: none;

    &.default-header {
        min-width: 1280px;
    }
}

.navbar {
    height: 53px;
    background-color: $white-1;
    border-bottom: solid 1px $border-color;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 23;

    &-no-border {
        border-bottom-color: transparent;
    }

    &-item-right {
        .tooltip-wrapper {
            display: block;
        }
    }
}

.nav-brand {
    margin: 0 8px 0 24px;
}

.navbar-menu {
    @extend .flex-row;
    height: 100%;

    .navbar-link {
        padding: 16px 8px 14px;
    }
}

.navbar-item-anonymous-state {
    margin-right: 20px;

    .info-text {
        margin: 0 30px 0 10px;
        white-space: nowrap;
    }
}

.navbar-item-right {
    flex: none;
    margin-left: auto;
    display: flex;
    align-items: center;
    position: relative;

    .btn-invite-clients {
        margin-right: 8px;
    }

    .navbar-link {
        margin-left: 1px;
    }
}

.navbar-link {
    color: $grey-4;
    font: 12px/20px $font-family-base;
    text-transform: uppercase;
    background: none;
    border: none;
    padding: 16px 10px;
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    border-bottom: solid 2px transparent;
    @extend .transition;

    &:active,
    &:focus {
        color: $grey-4;
    }

    &:hover {
        color: $grey-4;
        background-color: $primary-6;
        text-decoration: none;
    }

    &.active {
        color: $grey-4;
        font-family: $font-roboto-bold;
        border-bottom-color: $color-selected-border;

        &[disabled],
        &.disabled {
            pointer-events: none;
        }
    }

    &.navbar-link-feedback {
        .icon {
            margin: -4px 8px -3px 0;
        }
    }

    .badge,
    .badge-wrapper {
        margin: -11px 0 0 3px;
        border: 0;
    }

    .badge-wrapper {
        line-height: 16px;
        vertical-align: top;

        .badge {
            margin: 0;
        }
    }

    .new-label {
        margin-right: 6px;
    }
}

.limit-blocker .navbar-item.dropdown .navbar-link:after {
    background-image: url("data:image/svg+xml,%3Csvg width=%279px%27 height=%276px%27 viewBox=%270 0 9 6%27 xmlns=%27http://www.w3.org/2000/svg%27%3E%3Cg id=%27BWIC-creation-master%27 stroke=%27none%27 stroke-width=%271%27 fill=%27none%27 fill-rule=%27evenodd%27%3E%3Cg id=%27UI-Elements%27 transform=%27translate%28-100.000000, -2287.000000%29%27 fill=%27%23bdbdbd%27%3E%3Cpolygon id=%27Rectangle%27 transform=%27translate%28104.242641, 2288.242641%29 rotate%28-45.000000%29 translate%28-104.242641, -2288.242641%29 %27 points=%27101.242641 2285.24264 103.042641 2285.24264 103.042641 2289.44264 107.242641 2289.44264 107.242641 2291.24264 101.242641 2291.24264%27%3E%3C/polygon%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}

.limit-blocker .navbar-link,
.navbar-link.disabled {
    color: $button-disabled;
    cursor: default;

    &:hover {
        background: none;
    }
}

.navbar-link-support,
.navbar-link-feedback,
.navbar-link-notifications,
.navbar-link-whats-new {
    position: relative;
    width: 52px;
    border: 0;
    padding: 14px 10px;
}

.navbar-link-notifications {
    width: 50px;

    &.is-show {
        color: $grey-4;
        background-color: $primary-6;
    }
}

.navbar-link-whats-new {
    width: 56px;
}

.navbar-link-notifications,
.navbar-link-whats-new {
    .alert-badge {
        position: absolute;
        top: 10px;
        right: 12px;
    }
}

.navbar-item.dropdown,
.navbar-item-dropdown {
    position: relative;

    .icon-header-menu {
        display: inline-block;
        vertical-align: top;
        margin-left: 0.4em;
    }

    .navbar-link:not(.custom-dropdown-icon) {
        &::after {
            background-repeat: no-repeat;
            content: '';
            pointer-events: none;
            @extend .icon-header-menu;
        }
    }

    &.show,
    &.is-show {
        .navbar-link:after, .icon-header-menu {
            transform: rotate(180deg);
            transform-origin: center;
        }
    }

    &.dropdown-with-badge {
        .navbar-link {
            padding-right: 20px;

            .badge {
                margin-right: -30px;
                margin-left: 15px;
            }

            .alert-badge {
                margin: -25px -25px 0 17px;
                vertical-align: top;
            }

            .new-label {
                margin-right: 6px;
            }
        }

        .navbar-dropdown-menu-item {
            .badge {
                margin-right: -8px;
                margin-left: 8px;
            }
        }
    }
}

.navbar-item-dropdown {
    .navbar-link {
        position: relative;
        padding-right: 33px;
        line-height: 1.4;

        &:after {
            position: absolute;
            right: 14px;
            top: 50%;
            transform: translateY(-50%);
            margin-top: 1px;
        }

        &.navbar-link-profile,
        &.navbar-link-support {
            padding: 9px 24px;
            border: 0;

            &:after {
                display: none;
            }
        }

        &.navbar-link-support {
            padding: 14px 10px;
        }
    }

    &.show,
    &.is-show {
        .navbar-dropdown-menu {
            display: block;
        }

        .navbar-link {
            color: $link-color;
            background-color: $primary-6;

            &:after {
                margin-top: -3px;
            }

            &.navbar-link-support,
            &.navbar-link-profile {
                color: $grey-4;
            }
        }
    }
}

.navbar-profile {
    margin-right: 1px; //STG env fix
}

.navbar-dropdown-menu {
    position: absolute;
    display: none;
    background-color: $white-1;
    border: solid 1px $border-color;
    top: 100%;
    width: 100%;
    box-shadow: $app-shadow;


    &.navbar-dropdown-support {
        width: 320px;
        left: auto;
        right: 0;
    }

    &.navbar-dropdown-profile {
        min-width: 360px;
        right: 0;
        left: auto;
        
        h3 {
            margin-bottom: 0;
            padding-left: 16px;
            border-top: 1px solid $border-color;
            line-height: 32px;

            .icon-svg {
                margin-right: 8px;
                vertical-align: top;
            }
        }

        .navbar-dropdown-menu-item {
            height: 38px;
            padding-left: 40px;
            border-top: 0;

            &:hover {
                color: $grey-4;
            }
        }

        .navbar-dropdown-sign-out {
            display: block;
            height: 32px;
            font-family: $font-roboto-medium;
            border-top: 1px solid $border-color;

            .flex-row {
                justify-content: center;
            }
        }
    }

    .sub-text {
        color: $grey-8;
        font-size: 12px;
        display: block;
    }
}

.navbar-dropdown-menu-item {
    color: $grey-4;
    font: 14px $font-family-base;
    text-transform: capitalize;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 16px;
    background-color: $white-1;
    border: none;
    border-top: solid 1px $grey-5;
    cursor: pointer;
    @extend .transition;

    &:hover {
        color: inherit;
        text-decoration: none;
        background-color: $primary-6;
    }

    .limit-blocker & {
        color: $button-disabled;
        background: none;
        cursor: default;
    }

    .icon {
        flex: none;
        margin-right: 10px;
    }

    .icon-expand {
        pointer-events: none;
    }

    .navbar-dropdown-menu {
        display: block;
        top: -1px;
        left: 100%;
        border-top: 0;
    }
}

.navbar-dropdown-support {
    .navbar-dropdown-menu-item {
        text-transform: initial;
        padding-top: 12px;
        padding-bottom: 12px;
        align-items: flex-start;
        min-height: 64px;
        height: auto;

        a {
            text-decoration: none;
        }

        .toggle {
            margin-left: 3px;
            align-items: flex-start;

            .toggle-text {
                flex-direction: column;
                width: 235px;
                margin-left: 11px;
   
                span:first-child {
                    color: $grey-4;
                }
            }
        }
    }
}

.navbar-dropdown-manage,
.navbar-dropdown-resources {
    border-top: 0;

    .display-inline {
        width: 100%;
    }
}

.navbar-dropdown-resources {
    &.dropdown-menu {
        min-width: 190px;
    }
}

.navbar-dropdown-menu-section {
    padding: 15px 16px 13px;
    border-top: solid 1px $grey-5;

    &:first-child {
        border: 0;
    }

    h4 {
        margin-bottom: 16px;
    }
}

.navbar-dropdown-employees {
    padding: 0;

    h3 {
        line-height: 20px;
        padding: 13px 20px;
        margin: 0;
    }

    .flex-row {
        align-items: flex-start;
    }

    .employees-list {
        .navbar-dropdown-menu-item {
            &:hover,
            &:active,
            &:focus {
                color: $grey-4;
            }
        }
    }

    .employee-icon {
        flex: none;
        min-width: 32px;
        min-height: 24px;
        margin-right: 10px;
        text-align: center;

        .icon-svg {
            vertical-align: middle;
            margin: 0;
        }

        .icon-svg-email {
            margin-top: 2px;
        }
    }

    .employee-avatar {
        flex: none;
        min-width: 32px;
        min-height: 32px;
        margin-right: 10px;

        .user-avatar {
            font-size: 34px;
            vertical-align: top;
        }
    }

    .employee-contacts {
        width: 100%;

        li {
            line-height: 20px;

            &.text-sm {
            }

            &:not(:first-child) {
                margin-top: 4px;
                line-height: 14px;
            }
        }

        a {
            display: block;
            line-height: 16px;
            margin-bottom: 5px;

            &:hover {
                text-decoration: none;
            }
        }
    }
}

.navbar-dropdown-user {
    text-align: center;
    padding: 16px 20px;

    @media all and (-ms-high-contrast:none) {
        overflow: hidden;
    }

    &-avatar {
        .user-avatar-section {
            display: inline-block;

            .btn {
                width: 24px;
                height: 24px;
                padding: 6px 5px;

                .icon-svg {
                    position: absolute;
                    margin: 0;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                }
            }
        }

        .user-avatar {
            font-size: 64px;
        }
    }

    &-name {
        line-height: 20px;
        margin: 8px 0 4px;
    }

    &-email {
        font-size: 12px;
        line-height: 14px;
    }
}

.navbar-dropdown-my {
    max-width: 80px;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    display: none;
    min-width: 180px;
    width: 100%;
    padding: 0 0;
    margin: 0 0 0;
    font-size: 28px;
    color: $grey-4;
    text-align: left;
    list-style: none;
    background-color: $white-1;
    background-clip: padding-box;
    border-radius: 0 0 2px 2px;

    &.show {
        display: block;
    }
}

@media only screen and (max-width: 600px) {
    .navbar {
        padding: 0 20px;
    }
    .navbar-item-right {
        padding-left: 0;
    }

}

@media only screen and (max-width: 720px) {
    .info-text {
        display: none;
    }
}
