﻿.pagination {
    padding: 5px 0;
    font: 12px/24px $font-roboto-regular;

    &-cntrls {
        button {
            justify-content: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            text-align: center;
            color: $grey-4;
            vertical-align: top;

            &.pagination-cntrls-step-right,
            &.pagination-cntrls-step-left {
                width: 12px;
            }

            .icon-svg {
                margin: 0;
                vertical-align: middle;
            }
        }

        &-step-left {
            transform: rotate(-180deg);
        }


        &-item {
            margin: 0 2px;

            &.selected {
                button {
                    color: $primary-3;
                    background: $primary-8;
                    font-family: $font-roboto-medium;

                    &:hover {
                        color: $link-hover;
                        background: $primary-5;
                    }

                    &:active {
                        color: $link-focus;
                        background: $primary-7;
                    }
                }
            }
        }

        .ellipsis {
            color: $grey-4;
            width: 24px;
            text-align: center;
        }
    }

    &-go-to {
        margin-left: 16px;

        &-text {
            font-size: 14px;
        }

        .input-wrapper {
            width: 70px;
            margin: 0 8px;
        }
    }
}
