.component-dropdown {
    display: inline-block;
    vertical-align: middle;
    position: relative;

    &.full-width {
        width: 100%;
    }

    &-toggle {
        font-size: 14px;
        cursor: pointer;
        border-radius: 3px;
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;

        span {
            display: block;
            width: 100%;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        .placeholder {
            color: $placeholder-color;
        }

        input {
            background: none;
            border: 0;
            padding: 0;
            height: 30px;
            width: calc(100% - 9px);
            cursor: pointer;
        }

        input::-ms-clear {
            display: none;
        }
    }

    &-list {
        width: calc(100% + 2px);
        min-width: 0;
        position: absolute;
        top: calc(100% + 3px);
        left: -1px;
        overflow: hidden;
        z-index: 20;
        background-color: $white-1;
        box-shadow: $app-shadow;
        border: solid 1px $border-color;
        max-height: 400px;
        overflow: auto;

        .empty-placeholder {
            margin: 0;
            padding: 16px;
            text-align: left;
        }

        &-item {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            text-align: left;
            position: relative;
            cursor: pointer;
            padding: 6px 12px;
            background-color: $white-1;

            &:hover {
                background-color: $control-hover-bg;
            }

            &:active,
            &:focus {
                background-color: $control-hover-bg;
            }

            &.selected {
                background-color: $control-hover-bg;
                font-family: $font-roboto-medium;
            }

            &.disabled {
                pointer-events: none;
            }
        }
    }
}

.icon-component-dropdown {
    transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    margin-left: auto;
    flex: none;

    &.is-open {
        transform: rotate(180deg);
    }
}

.component-dropdown-light {
    .component-dropdown-toggle {
        color: $text-color;
        font-family: $font-family-base;
        line-height: 1.7;
        height: 32px;
        background-color: $white-1;
        border: solid 1px $grey-2;
        padding: 3px 8px 2px;

        &:hover {
            border-color: $grey-8;
        }

        &.is-disabled {
            color: $placeholder-color;
            background-color: $control-disabled-bg;
            border-color: $control-disabled;

            input {
                color: $placeholder-color;
            }
        }

        &-selected {
            border-color: $color-focus;

            &:hover {
                border-color: $color-focus;
            }
        }

        &.error {
            position: relative;
            border-color: $color-error;

            &:after {
                position: absolute;
                content: '';
                right: 24px;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                width: 16px;
                height: 16px;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0)'%3E%3Cpath d='M7.19979 8.7998H8.7998L8.7998 3.99981L7.19979 3.99981L7.19979 8.7998Z' fill='%23FF614D'/%3E%3Cpath d='M8.7998 12H7.19979V10.4H8.7998V12Z' fill='%23FF614D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0ZM8 1.3C4.29969 1.3 1.3 4.29969 1.3 8C1.3 11.7003 4.29969 14.7 8 14.7C11.7003 14.7 14.7 11.7003 14.7 8C14.7 4.29969 11.7003 1.3 8 1.3Z' fill='%23FF614D'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0'%3E%3Crect width='16' height='16' fill='white' transform='matrix(-1 0 0 1 16 0)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
                pointer-events: none;
            }

            span {
                padding-right: 32px;
            }

            input {
                padding-right: 24px;
            }
        }
    }
}

