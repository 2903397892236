﻿.seller-buyside {
    &-content {
        min-width: 855px;

        &:not(.data-list-freeze) {
            @media all and (-ms-high-contrast:none) {
                overflow-x: scroll;
                overflow-y: hidden;
            }
        }

        .data-list-header {
            padding-right: 49px;
        }

        .data-list-cell {
            &.cell-bid-value {
                overflow: visible;
                margin-right: 0;
                margin-left: 2px;

                .status {
                    flex: none;
                }

                .form-control-wrapper {
                    max-width: 94px;
                    
                    &.commision {
                        margin-left: 40px;
                        max-width: 74px;
                    }
                }

                .bid-attributes {
                    min-width: 56px;
                }

                .checkbox-wrapper {
                    display: flex;
                    margin-left: 4px;
                    margin-right: 4px;

                    &:nth-last-child(2) {
                        margin-left: 8px;
                    }

                    .icon-svg {
                        display: none;
                    }
                }

                .bid-level-delta-tooltip {
                    top: 0;
                }
            }

            &.cell-external-links {
                min-width: 132px;
            }
        }

        .seller-rejected {
            margin-right: 0;
        }
    }
}
