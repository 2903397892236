﻿/* Sticky table */

.data-list-freeze {
    &.data-list-striped {
        overflow: hidden;

        .data-list-container {
            height: auto;
            position: relative;
            overflow: hidden;
            width: 0;
            min-width: 100%;
        }

        .data-list-header {
            padding-right: 15px;
            flex-basis: 24px;
            min-height: 24px;
        }

        .data-column-sticky-right {
            flex: none;
            box-shadow: -9px 0 9px 0 rgba($grey-8,0.48);
            margin-left: auto;

            .data-list-cell-icon-collapsed {
                text-align: right;

                .icon-svg {
                    margin: 0;
                }
            }

            .data-list-row {
                padding-left: 0;
            }

            .data-list-cell {
                &:first-child {
                    padding-left: 8px;
                }
            }

            .data-list-cell {
                &:last-child {
                    padding-right: 0;
                }
            }

            .context-menu {
                display: inline-block;

                ul {
                    left: auto;
                    right: 1px;
                }
            }
        }

        .data-column-sticky-right-content {
            display: flex;
            flex-direction: row;
            flex: none;
            padding: 0 9px 0 0;
        }

        &.data-list {
            .data-list-content {
                display: block;
                flex-direction: row;

                .data-list-columns {
                    margin-bottom: 17px;
                    flex: 1 1 auto;
                    min-height: calc(100% - 17px);
                    min-width: 100%;
                    flex-direction: row;
                    width: auto;
                }

                .data-column-sticky-middle {
                    overflow: auto;
                }
            }
        }

        &.right-sticky-collapsed {
            .data-list-cell-icon-collapsed {
                &.visible-collapse-icon {
                    .icon-svg {
                        transform: rotate(-180deg);
                    }
                }
            }

            .data-column-sticky-right {
                .keep-while-collapsed:not(.visible-collapse-icon) {
                    @include flex-basis(96px);
                    padding-right: 0;
                    padding-left: 0;
                }

                .data-list-cell:not(.keep-while-collapsed) {
                    display: none;
                }
            }
        }

        .scroll-loader {
            margin-top: -17px;
        }
    }

    .data-list-content {
        position: relative;
        height: 100%;
        min-width: 0;
        flex: 1 1 auto;
        overflow-x: hidden;
    }

    .data-list-header {
        overflow: hidden;
        width: 100%;
        position: relative;
    }

    .fake-horizontal-scroll {
        position: absolute;
        width: 10000px;
        bottom: 0;
        overflow-x: auto;
        height: 17px;
        overflow-y: hidden;

        .fake-element {
            height: 17px;
            width: 150%;
        }
    }

    &.data-list.right-sticky-col {
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            width: 15px;
            height: 24px;
            background: $table-header-bg;
            z-index: 18;
        }

        .data-list-header {
            padding-left: 0;
            padding-right: 15px;
            overflow-y: scroll;

            &.collapsed-content-level-1,
            &.collapsed-content-level-2 {
                overflow: hidden;
            }

            .data-column-sticky-left {
                padding-left: 24px;
            }
        }
    }
}

.header-sticky-col {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    height: 100%;
    align-items: center;
    background-color: $table-header-bg;
    position: relative;

    &.data-column-sticky-middle {
        overflow: hidden;
        min-width: 0;
    }

    &.data-column-sticky-right {
        .data-list-cell {
            padding-left: 8px;
        }
    }
}

.header-sticky-content {
    display: flex;
    flex-direction: row;
}

.data-column-sticky {
    position: relative;
    display: flex;
    flex-direction: column;
}

.data-column-sticky-left {
    flex: none;
    box-shadow: 9px 0 9px 0 rgba($grey-8,0.48);
    z-index: 2;

    .data-list-cell:last-child {
        &.text-right {
            padding-right: 8px;
        }
    }

    & + .data-column-sticky-middle {
        .header-sticky-content .flex-row,
        .data-list-row {
            padding-left: 16px;
        }
    }

    .data-list-row {
        padding-right: 0;
    }
}

.data-column-sticky-middle {
    flex: 1 1 auto;
    overflow: auto;
    min-width: 0;
    min-height: 100%;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }

    .header-sticky-content {
        flex: 1 1 auto;
        max-height: 32px;
    }

    .header-sticky-content .flex-row,
    .data-list-row {
        padding-left: 24px;
        padding-right: 24px;
        max-height: 32px;

        &.active {
            max-height: 33px;
        }

        &:not(.flex-none) {
            flex: 1 1 auto;
        }
    }
}

.middle-sticky-content {
    position: relative;
    min-width: 0;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
}

.infinite-scroll-container-sticky {
    flex: 1 1 auto;
    min-width: 100%;
    min-height: 1px;
    display: flex;
    flex-direction: column;
}

.total-sticky-col {
    position: relative;
    display: flex;
    flex-direction: row;
    height: 100%;
}

