﻿.sub-header {
    background-color: $white-1;
    flex: none;

    &-row {
        border-bottom: 1px solid $border-color;
        padding-left: 24px;
        padding-right: 24px;
        /* types */
        &.type01 {
            padding-top: 26px;
            padding-bottom: 12px;
            line-height: 32px;

            .control-search-wrapper {
                width: 580px;
            }

            .template-label {
                margin-left: 8px;
            }
        }

        &.type02 {
            .filter-section {
                .control-search-wrapper:not(.compact) {
                    width: 380px;
                }

                .filter-select-and-search {
                    .control-search-wrapper {
                        width: 100%;
                    }
                }

                .control-trading-protocol {
                    padding-right: 8px;
                    min-height: 24px;

                    .form-label {
                        margin-bottom: 0;
                        margin-right: 8px;
                    }

                    .radio-group {
                        flex-direction: row;

                        .flex-row {
                            &:not(:first-child) {
                                margin-left: 16px;
                            }
                        }
                    }

                    .radio {
                        margin-top: 0;
                        margin-bottom: 0;
                    }

                    .info-tooltip {
                        margin-left: 4px;
                        line-height: 1;

                        .svg-icon {
                            vertical-align: top;
                        }
                    }
                }
            }

            &.filters-row {
                padding-bottom: 0;

                .filters-area {
                    flex-wrap: wrap;
                }

                .filter-section {
                    margin-bottom: 8px;
                    margin-right: 8px;

                    .control-filter-select {
                        margin-right: 0;
                    }
                }
            }

            .sort-dropdown {
                &:not(:last-child) {
                    margin-right: 8px;
                }

                .form-control {
                    width: 120px;
                    margin-left: 8px;
                }

                .drop-down-list {
                    width: 100%;
                }
            }

            .column-customizer-btn {
                display: inline-block;
                padding-top: 4px;
                padding-bottom: 4px;
                line-height: 1;

                .tooltip-wrapper {
                    display: inline-block;
                }
            }
        }

        &.type02,
        &.type03 {
            padding-top: 8px;
            padding-bottom: 8px;
        }

        &.type03 {
            .tabs {
                margin-top: -8px;
                margin-bottom: -8px;
                border-bottom: 0;
            }

            .controls {
                .btn-link {
                    line-height: 16px;
                }

                .loader-box {
                    height: 16px;
                }
            }
        }

        &.type04 {
            padding-top: 12px;
            padding-bottom: 13px;
        }

        .breadcrumbs-list {
            position: absolute;
            top: 8px;
        }

        h1 {
            margin: 0 24px 0 0;

            .status {
                margin-left: 24px;
                display: inline-flex;
                line-height: 32px;
                vertical-align: top;
            }

            .title-placeholder {
                color: $placeholder-color;
            }
        }

        .name-tag {
            margin-left: -16px;
        }

        .content-edit {
            margin-left: -8px;

            .btn-link {
                margin-right: 16px;
            }
        }

        > .status-message {
            margin-left: 16px;
        }
        /* individual bwic styles start */
        .bwic-status {
            position: relative;
            z-index: 1;
            padding-left: 0;
            padding-right: 0;
            line-height: 1;

            &-label {
                padding-right: 0;
            }
        }

        .bwic-due {
            margin: 0 24px;
            white-space: nowrap;

            .icon-svg-same-day {
                margin-right: 8px;
                vertical-align: top;
            }

            .same-day-wrapper {
                line-height: 16px;
            }
        }

        .header-bwic-rules {
            margin-right: 24px;
            white-space: nowrap;
            display: flex;

            &.header-bwic-bloomberg {
                .icon-svg-bloomberg {
                    margin-right: 4px;
                }
            }
        }

        .created-by {
            white-space: nowrap;
            line-height: 1;

            .pseudo-link {
                width: auto;
            }
        }

        .invitation-panel {
            flex-grow: 1;
            margin: -5px 24px -5px 0;

            .invitation {
                font-size: 12px;
                width: 100%;
                height: 26px;
                padding: 5px 4px;
                background-color: $yellow-4;

                .icon {
                    margin-left: 5px;
                    margin-right: 5px;
                    vertical-align: top;
                }
            }
        }
        /* individual bwic styles end */

        .controls {
            line-height: 1;

            .btn-next {
                .icon-svg {
                    margin-right: 0;
                    margin-left: 4px;
                }
            }

            & > .loader-box {
                display: inline-flex;
                height: 24px;
                margin-left: 24px;
                vertical-align: middle;
            }

            .btn,
            .btn-link,
            & > .limit-blocker,
            & > .status-message,
            .export-portfolio,
            .ends-in,
            .checkbox {
                margin-left: 24px;
            }

            & > .btn-link {
                line-height: 24px;

                &-active {
                    color: $link-focus;
                }

                &.show-agent-commission {
                    margin-right: 8px;
                    line-height: 1;
                }
            }

            .btn-link-group {
                display: inline-block;

                &:not(:last-child) {
                    margin-right: -8px;
                }

                .limit-blocker {
                    display: inline-block;
                    margin-left: 24px;

                    .btn-link,
                    .export-portfolio {
                        margin-left: 0;
                    }
                }
            }

            & > .allocation-overlay-btn,
            & > .toggle,
            & > .position-relative,
            & > .tooltip-wrapper {
                margin-left: 24px;

                .btn,
                .btn-link,
                .checkbox,
                .limit-blocker {
                    margin-left: 0;
                }
            }

            & > .limit-blocker {
                display: inline-flex;
            }

            .vertical-divider + .btn {
                margin-left: 0;
            }

            .btn-link,
            .tooltip-wrapper  {
                & + .btn:not(.btn-link) {
                    margin-left: 16px;
                }
            }

            .btn + .btn:not(.btn-link) {
                margin-left: 8px;
            }

            /* individual bwic styles start */
            .ends-in {
                display: flex;
                align-items: center;
                font-size: 12px;
                white-space: nowrap;

                .bwic-ends-time {
                    display: flex;
                    align-items: center;

                    span {
                        margin-left: 4px;

                        &:not(.tooltip-wrapper) {
                            line-height: 1.1;
                        }

                        .time-passed {
                            color: $green-9;
                        }

                        .less-then-five-mins {
                            color: $secondary-1;
                        }
                    }
                }

                .bidding-timer-value {
                    font-size: 18px;
                }

                & + .tooltip-wrapper {
                    margin-left: 8px;
                }
            }

            .bwic-completed {
                &.finished {
                    margin-left: 40px;
                    white-space: nowrap;
                }
            }

            .bob-saving-label {
                color: $grey-9;
                font-size: 12px;
                margin-left: 24px;
                display: flex;
                align-items: center;
                white-space: nowrap;

                .icon-svg {
                    flex: none;
                    margin-right: 8px;
                    vertical-align: top;
                }

                .icon-svg-saved {
                    color: $green-2;
                }

                .icon-svg-saving {
                    color: $yellow-1;
                }
            }
            /* individual bwic styles end */
            /*currency converter*/
            .currency-converter {
                .current-currency {
                    padding-left: 8px;
                    border-left: 1px solid $grey-2;
                }

                .tooltip-wrapper {
                    display: block;
                }
            }

            .currency-conversion {
                display: flex;
                align-items: center;

                .btn-link {
                    margin-left: 16px;

                    .icon {
                        margin-right: 0;
                    }
                }

                &-form {
                    position: relative;

                    .usd {
                        font-size: 14px;
                        color: $grey-8;
                        position: absolute;
                        bottom: 9px;
                        right: 6px;
                    }
                }
            }

            .buttons-with-tooltips {
                .btn {
                    margin-left: 16px;
                }

                .limit-blocker {
                    margin-left: 16px;

                    .btn {
                        margin-left: 0;
                    }
                }

                .icon-svg-info {
                    margin: 0 0 0 8px;
                }
            }
        }

        .tooltip-title {
            overflow: hidden;
            white-space: nowrap;

            h1 {
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }

        .controls-bottom-row {
            line-height: 19px;
            padding-top: 12px;
        }

        .alert-menu {
            .checkbox {
                margin-right: 0;
            }
        }
    }
}
