.right-panel {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    flex: 0 0 425px;
    align-self: stretch;
    max-width: 425px;
    position: relative;
    background-color: $white-1;

    &-header {
        @extend .flex-row;
        flex: none;
        padding: 12px 16px;
        height: 48px;
        align-items: flex-start;
        border-left: 1px solid $border-color;
        border-bottom: 1px solid $border-color;

        .btn {
            margin-left: 16px;
        }
    }

    &-title {
        h3 {
            line-height: 24px;
            margin: 0;
            display: flex;
            align-items: center;
        }

        .options-list {
            margin-left: 16px;
        }

        .btn-options {
            vertical-align: top;
        }
    }

    &-close {
        margin-left: auto;

        .btn-close {
            margin-top: -4px;
            margin-right: -8px;
        }
    }

    &-scroll {
        overflow-x: hidden;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        min-height: 1px;
        border-left: 1px solid $border-color;
    }

    &-content {
        position: relative;
        padding: 8px 16px 30px;

        .container-loader {
            background: none;
        }

        .tabs {
            padding-left: 0;
            padding-right: 0;

            & + .empty-placeholder {
                margin: 16px;
                width: calc(100% - 32px);
            }
        }

        .rules {
            &.flex-row {
                display: block;
            }
        }

        .empty-placeholder {
            margin: 0;
            padding: 32px 16px;
            border: 0;
            background: none;
        }
    }

    &-rules {
        .right-panel-content {
            flex: none;
        }
    }

    &-security-information {
        .right-panel-content {
            display: flex;
            flex-grow: 1;
        }
    }

    &-history,
    &-blast-message {
        .right-panel-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            min-height: 1px;
            padding: 0;
        }

        .right-panel-scroll {
            overflow: hidden;
        }
    }

    &-bids {
        .bid-list-content,
        .bid-list-empty {
            display: flex;
            flex-direction: column;
            min-height: 320px;
        }
    }

    &-bwic-details {
        .right-panel-content {
            padding-top: 0;
        }
    }

    &-company-members,
    &-templates,
    &-tabs {
        .right-panel-scroll {
            overflow: hidden;
        }

        .right-panel-content {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            min-height: 1px;
            padding: 0;
        }
    }

    &-tabs {
        .rules,
        .security-information {
            overflow-x: hidden;
            overflow-y: auto;
            padding: 16px 16px 22px;
        }

        .view-bidding {
            padding: 0 16px 16px;

            .limit-blocker {
                display: inline-block;
            }
        }
    }
}
