﻿.filters-area {
    .filter-section {
        margin-right: 16px;
        position: relative;

        .custom-drop-down-ghost {
            line-height: 24px;
        }

        .form-label {
            line-height: 14px;
            margin-bottom: 5px;
        }

        &.help {
            .icon-svg-help {
                margin: 4px 0;
                cursor: pointer;
            }
        }

        &.time-zone {
            width: 160px;
        }

        .separator {
            display: inline-block;
            width: 11px;
            margin: 0 6px;
            vertical-align: bottom;
            line-height: 24px;
        }

        .vertical-divider {
            display: inline-block;
        }

        &.filter-size {
            .form-control-wrapper {
                display: inline-block;
            }

            .form-control {
                width: 62px;
            }
        }

        &.filter-section-divider {
            margin-left: -8px;
            margin-right: 8px;
        }
    }

    .filter-group {
        margin-right: 8px;

        &-item {
            font: 12px/24px $font-roboto-medium;
            text-align: center;
            margin-right: 1px;
            padding: 0 8px;
            background: $grey-3;
            cursor: pointer;
            display: inline-flex;
            align-items: center;

            .icon-svg {
                margin-right: 4px;
            }

            &:first-child {
                border-top-left-radius: $app-brd-radius;
                border-bottom-left-radius: $app-brd-radius;
            }

            &:last-child {
                border-top-right-radius: $app-brd-radius;
                border-bottom-right-radius: $app-brd-radius;
                margin-right: 0;
            }

            &:hover {
                color: $primary-2;
                background: $primary-14;
            }

            &.selected {
                background: $primary-8;

                &:not(.disabled) {
                    &:hover {
                        color: $primary-2;
                    }
                }
            }

            &.disabled {
                color: $grey-1;
                background: $grey-7;
                cursor: default;
            }
        }

        &.filter-group-type01 {
            .filter-group-item {
                background: none;
                border-radius: $app-brd-radius;

                &:hover {
                    background: none;
                }

                &.selected {
                    background: $grey-3;

                    &:not(.disabled) {
                        &:hover {
                            color: inherit;
                        }
                    }
                }
            }
        }
    }

    .btn-clear {
        line-height: 24px;
    }

    .btn-main + .btn-clear {
        margin-left: 8px;
    }
}

.saved-filters-options {
    display: flex;
    flex-wrap: wrap;
    padding: 0 24px 8px 48px;
    border-bottom: 1px solid $border-color;
    background-color: $white-1;
}

.saved-filter-options {
    margin: 16px 24px 8px 0;

    &-list {
        .name-tag {
            margin: 4px 8px 0 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }
}