﻿.clients-area {
    .control-group-slide {
        padding: 0;
        width: 160px;
    }

    .clients-companies {
        position: relative;

        .data-list {
            &-content {
                &:after {
                    min-height: 44px;
                }
            }

            &-container {
                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 17px;
                    height: 24px;
                    background: $table-header-bg;
                    z-index: 20;
                }
            }

            &-header {
                overflow-y: scroll;
            }

            .data-client-company {
                .position-relative {
                    max-width: 100%;
                }

                .pseudo-link {
                    width: auto;
                    max-width: 100%;
                    white-space: nowrap;
                }
            }

            .data-settl-accs {
                padding-left: 0;
            }

            .data-agreement-action {
                display: flex;
                justify-content: flex-end;

                .btn-link {
                    margin-left: 16px;
                }

                .btn-edit {
                    width: auto;

                    .icon-svg {
                        margin-right: 4px;
                    }
                }

                .flex-row {
                    justify-content: flex-end;
                }
            }
        }
    }

    .edit-companies-limits {
        .limits-form {
            padding: 16px;
            overflow: auto;

            &-info {
                height: 100px;
                margin-top: -8px;
                line-height: 20px;

                p {
                    margin-bottom: 8px;
                    line-height: 20px;
                }

                .list-bullet {
                    li {
                        margin: 0;
                        line-height: 20px;
                        padding-left: 22px;

                        &:before {
                            content: "•";
                            width: 22px;
                            height: 20px;
                            top: auto;
                            line-height: 20px;
                            background: none;
                            text-align: center;
                        }
                    }
                }
            }

            &-header {
                margin-bottom: 16px;
            }

            &-footer {
                justify-content: flex-end;

                .btn-sm {
                    margin-left: 8px;
                }
            }

            &-all {
                margin-bottom: 16px;

                .checkbox {
                    margin-bottom: 8px;
                }

                .form-control-wrapper {
                    margin-left: 20px;
                    position: relative;

                    &:after {
                        position: absolute;
                        top: 5px;
                        left: 103px;
                        content: "USD";
                        color: $empty-placeholder-color;
                    }

                    &.form-control-error {
                        &:after {
                            display: none;
                        }
                    }
                }
            }

            &-by-rating {
                margin-bottom: 16px;

                .checkbox {
                    margin-bottom: 8px;
                }

                .data-list {
                    width: auto;
                    margin-right: -16px;
                    margin-left: -16px;
                }

                .data-list-container {
                    min-width: auto !important;
                }

                .data-list-content {
                    overflow: visible;

                    &:after {
                        display: none;
                    }
                }

                .data-list-header,
                .data-list-row {
                    padding-right: 16px;
                    padding-left: 16px;
                }

                .data-list-rating {
                    width: 80px;
                }

                .data-daily-trading-limit {
                    min-width: 150px;

                    .btn-link {
                        width: 16px;
                        margin-right: -20px;
                        margin-left: 4px;

                        .icon-svg {
                            margin-right: 0;
                        }
                    }

                    .form-label {
                        margin-bottom: 0;
                    }

                    .form-error {
                        width: 190px;
                        height: auto;
                        white-space: normal;
                    }
                }

                .data-list-row:last-child {
                    .form-error {
                        @extend .form-error-top;
                    }
                }
            }

            &-field {
                display: inline-block;
                width: 134px;

                &.usd-label {
                    width: 135px;
                    padding-right: 35px
                }
            }

            &-notify {
                margin-left: 20px;
                margin-bottom: 8px;
                line-height: 1.2;
            }
        }
    }

    .companies-contacts {
        position: relative;
        overflow: auto;
        height: 100%;

        .contacts-cards {
            margin-top: -1px;
        }
    }

    //bids tab
    .clients-bids {
        position: relative;

        .data-list {
            &-container {
                min-width: 1500px;
            }

            .data-time {
                width: 132px;
                padding-right: 0;
            }

            .data-view-bwic {
                width: 31px;
                padding-left: 0;
            }

            .data-client {
                min-width: 100px;
                width: 10%;

                .position-relative {
                    max-width: 100%;
                }

                .pseudo-link {
                    width: auto;
                    max-width: 100%;
                    @extend .text-ellipsis;
                }
            }

            .data-bwic-status {
                width: 90px;
            }

            .data-list-ticker {
                min-width: 166px;
            }

            .data-list-size {
                width: 70px;
            }

            .data-bid {
                position: relative;
                justify-content: flex-end;
                width: 130px;
                padding-right: 71px;
                line-height: 16px;

                .bid-level-delta {
                    display: inline-flex;
                    margin-right: -70px;
                    align-self: center;
                }
            }

            .data-commission {
                width: 80px;
            }

            .data-limit {
                width: 120px;
            }

            .data-status {
                width: 145px;

                &.flex-item-right {
                    text-align: left;
                }
            }
        }
    }
}
