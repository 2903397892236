﻿/* Statuses with bullet */

.status {
    position: relative;
    display: flex;
    min-height: 8px;
    padding-left: 14px;
    line-height: 1.2;
    font-size: 12px;

    &:first-letter {
        text-transform: uppercase;
    }

    &:after {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: currentColor;
    }

    &-color {
        padding-left: 0;

        &:after {
            display: none;
        }
    }

    &-no-label {
        width: 8px;
        margin-right: 4px;
        padding-left: 0;
    }

    .affirmed &,
    &.active,
    &.status-active,
    &.status-confirmed,
    &.status-autoconfirmed,
    &.status-settled {
        color: $green-2;
    }

    .rejected &,
    &.blocked,
    &.status-blocked,
    &.status-rejected,
    &.status-unsettled {
        color: $warning-3;
        user-select: none;

        &:hover {
            color: $warning-2;
        }

        &:focus,
        &:active {
            color: $warning-1;
        }
    }

    &.status-revoked,
    .cell-token-status &.status-expired {
        color: $warning-3;
    }

    .pending &,
    &.invited,
    &.status-invited,
    &.status-pending,
    &.status-pending-allocation,
    &.status-pending-approval,
    &.status-pending-proceeds,
    &.status-pending-settlement {
        color: $yellow-1;
    }

    &.canceled,
    &.status-canceled {
        color: $grey-4;
    }

    &.off-platform,
    &.not-invited,
    &.notinvited,
    &.status-off-platform,
    &.status-not-invited,
    &.status-notinvited,
    &.status-expired,
    &.status-unrequested {
        color: $grey-8;
    }

    .pseudo-link {
        color: inherit;
        border-bottom-color: inherit;
    }
}

/* end Statuses with bullet */


/* Text Statuses */

.status-affirmed {
    color: $green-2;
}

.status-rejected {
    color: $secondary-1;

    .popover {
        color: $grey-4;
    }

    &.pseudo-link {
        border-bottom: 1px dashed $secondary-1;

        &:hover {
            color: $link-danger-hover;
            border-bottom-color: $link-danger-hover;
        }
    }
}

.bwic-status {
    position: relative;

    &:not(.flex-row) {
        display: inline-block;
    }
}

.bwic-status-label {
    display: inline-block;
    padding: 0 8px;
    font: 10px/1.2 $font-roboto-medium;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;
}

.bwic-state-my {
    color: $blue-1;
    font-size: 10px;
    font-family: $font-roboto-bold;
    text-transform: uppercase;
    width: 15px;
    margin-right: 5px;
}

.state-new,
.state-updated,
.bwic-state-ktx,
.bwic-state-live {
    font: 10px/10px $font-roboto-bold;
    text-transform: uppercase;
}

.state-new,
.bwic-state-ktx,
.bwic-state-live {
    color: $secondary-1;
}

.state-updated {
    color: $yellow-1;
}

/* end Text Statuses */


/* Label Statuses */

.label-status {
    display: inline-block;
    padding: 6px 8px;
    font: 10px/12px $font-roboto-bold;
    border-radius: $app-brd-radius;
    text-transform: uppercase;
    vertical-align: middle;

    &-text {
        text-transform: uppercase;
    }

    &.label-status-iois {
        color: $grey-4;
        background-color: $yellow-3;
        font-family: $font-roboto-medium;
        text-transform: none;
        padding: 2px 4px;
        border: none;
        cursor: pointer;
        @extend .transition;

        &:hover {
            background-color: $yellow-2;
        }

        &:focus {
            background-color: $orange-2;
        }
    }
}

.label-status-timer {
    .time-counter {
        @extend .label-status;
        background-color: $secondary-2;

        &.time-passed {
            color: $green-2;
            background-color: $grey-3;
        }

        &.less-then-five-mins {
            color: $secondary-1;
            animation: warning-animation 1s steps(2, end) infinite;
        }
    }
}

//Without bg, just -
.label-status-no-amr {
    color: $grey-4;
    background: none;
}

//Bright Red
.label-status-active,
.label-status-bidding {
    color: $secondary-1;
    background-color: $secondary-2;
}

//BlueGreen
.label-status-scheduled {
    color: $primary-3;
    background-color: $primary-5;
}

//Green
.label-status-finished,
.label-status-settlement,
.label-status-result,
.label-status-success,
.label-status-submitted,
.label-status-closed,
.fullallocation {
    color: $green-2;
    background-color: $green-3;
}

//Grey
.label-status-not-scheduled {
    color: rgba($grey-4, .5);
    background-color: $grey-5;
}

//Dark grey
.label-status-failed,
.label-status-not-refinanced,
.label-status-canceled,
.label-status-not-invited,
.label-status-on-hold,
.label-status-onhold {
    color: $grey-4;
    background-color: $grey-5;
}

//Violet
.label-status-priced,
.label-status-allocation,
.label-status-allocation-correction,
.label-status-allocationcorrection,
.label-status-new {
    color: $purple-2;
    background-color: $purple-4;
}

// Gold
.label-status-draft,
.label-status-pending,
.label-status-pending-result,
.label-status-invited,
.pendingallocation,
.partialallocation {
    color: $brown-2;
    background-color: $yellow-3;
}


/* label status Type Transactions */

.label-status-type-amr,
.label-status-type-newissue,
.label-status-type-new-issue,
.label-status-type-new,
.label-status-type-refi,
.label-status-type-refinancing,
.label-status-type-reset {
    color: $grey-4;
    background-color: transparent;
    height: auto;
    padding: 0;
}

/* end label status Type Transactions */

/* end Label Statuses */

.new-label {
    font: 10px/1 $font-roboto-medium;
    color: $secondary-1;
    display: inline-block;
    padding: 2px 4px;
    text-transform: uppercase;
    border: 1px solid;
    border-radius: $app-brd-radius;
}

// News category label

.label-status-news-category {
    background-color: $grey-6;
    text-transform: capitalize;
    font: 12px/12px $font-roboto-medium;
}

.label-status-primary {
    border-left: 4px solid $primary-9;
}

.label-status-secondary {
    border-left: 4px solid $green-2;
}

.label-status-research {
    border-left: 4px solid $yellow-1;
}

.label-status-events {
    border-left: 4px solid $warning-3;
}

.label-status-my-portfolio {
    border-left: 4px solid $purple-2;
}