﻿.options-list {
    position: relative;

    &.disabled,
    .context-menu-content.disabled {
        pointer-events: none;
        cursor: default;

        .btn-link {
            color: $grey-1;
        }
    }

    div {
        cursor: pointer;

        .more {
            display: inline-block;
            border-radius: $app-brd-radius;
            line-height: 24px;
            width: 24px;
            text-align: center;

            &:hover {
                color: $primary-3;
            }
        }

        &.show {
            .more {
                color: $primary-1;
            }
        }
    }

    ul {
        box-shadow: $app-shadow;
        border: solid 1px $border-color;
        background: $white-1;
        position: absolute;
        top: 100%;
        bottom: auto;
        right: 0;
        z-index: 5;

        &.show-above {
            top: auto;
            bottom: 100%;
        }

        li {
            padding: 9px 12px;
            font: 12px/14px $font-roboto-regular;
            text-align: left;
            min-width: 145px;
            white-space: nowrap;

            a,
            .tooltip-wrapper {
                display: block;
                padding: 8px 12px;
                margin: -8px -12px;
            }

            .btn-link {
                font-family: $font-roboto-regular;
            }

            &.section:not(:first-child) {
                border-top: 1px solid $border-color;
            }

            &:hover {
                background-color: $primary-6;
                cursor: pointer;
            }

            &.disabled {
                color: $button-disabled;
                background: $control-disabled-bg;
                cursor: default;

                div {
                    cursor: default;
                }
            }

            &:not(.disabled).warning {
                color: $link-danger-color;
            }

            .loader-box {
                justify-content: flex-start;

                .text-loader {
                    font-family: $font-roboto-regular;
                }
            }
        }
    }

    &.options-list-bloomberg {
        ul {
            position: static;

            li {
                &:hover {
                    .icon-svg {
                        opacity: 1;
                    }
                }
            }
        }

        .icon-svg {
            margin-left: 8px;
            opacity: 0;
        }
    }
}
