﻿// Colors
$primary-1: #056c7a;
$primary-2: #02899b;
$primary-3: #0096aa; //default - teal-blue
$primary-4: rgba($primary-3, .5);
$primary-5: #cceaee;
$primary-6: #f3fbfd;
$primary-7: #c0dde0;
$primary-8: #d9f3f7;
$primary-9: #33d9ef;
$primary-10: #5bbed8;
$primary-11: #678ca1;
$primary-12: #75afbd;
$primary-13: #afe0eb;
$primary-14: #eef9fb;
$primary-15: #e7f5f8;
$primary-16: #f8fdff;

$grey-1: #bdbdbd;
$grey-2: #cecece;
$grey-3: #f5f5f5;
$grey-4: #4a4a4a; //default - $greyish-brown
$grey-5: #e4e4e4;
$grey-6: #ebebeb;
$grey-7: #f9f9f9;
$grey-8: #9b9b9b;
$grey-9: #7f7f7f;
$grey-10: #262626;
$grey-11: #FBFBFB;
$white-1: #ffffff;

$warning-1: #dd4212;
$warning-2: #f75d4a;
$warning-3: #ff614d;
$warning-4: rgba($warning-3, .5);
$warning-5: #fff3f2;

$secondary-1: #f0592a;
$secondary-2: #fcded4;
$secondary-3: #ffcdbc;

$green-1: #389358;
$green-2: #24b36b;
$green-3: #e3f3e8;
$green-4: rgba($green-2, .5);
$green-5: #b2edc7;
$green-6: #74b58f;
$green-7: #37a84a;
$green-8: #f1ffe1;
$green-9: #71c18d;
$green-10: rgba($green-3, .8);

$red-1: #a52d2a;
$red-2: #db4546;
$red-3: #b4131d;

$yellow-1: #ffb400;
$yellow-2: #ffe899;
$yellow-3: #ffe8b6;
$yellow-4: #fff5de;
$yellow-5: #fff9eb;
$yellow-6: #fff2d3;

$tertiary-1: #4d6b83;
$tertiary-2: #4f7b9c;
$tertiary-3: #d6eeff;
$tertiary-4: rgba($tertiary-2, .2);

$blue-1: #15a2f0;
$blue-2: #dcf2fe;
$blue-3: #13396e;
$blue-4: #235692;
$blue-5: #487eb2;
$blue-6: #9cc4e3;
$blue-7: #d2eaf8;
$blue-8: #ddf9ff;
$blue-9: #d6ebff;
$blue-10: #5b74a5;
$blue-11: #669fc3;
$blue-12: #b3c4d0;
$blue-13: #205eff;

$brown-1: #c57f5f;
$brown-2: #ac6f02;
$brown-3: rgba($brown-2, .5);

$purple-1: #5c4f84;
$purple-2: #8d40be;
$purple-3: rgba($purple-2, .5);
$purple-4: #f2deff;
$purple-5: #8D40BE;

$pink-1: #f26da1;

$orange-1: #ff8027;
$orange-2: #ffcb44;
$orange-3: #ff6e62;
$orange-4: #d57242;
$orange-5: #ea895b;
$orange-6: #ff6d45;

// Body
$body-bg: $white-1;
$app-brd-radius: 3px;
$app-shadow: 0 12px 16px 0 rgba($grey-4, .1);
$border-color: $grey-5;

$text-color: $grey-4;

$color-focus: $blue-1;
$color-error: $secondary-1;
$color-selected-border: $secondary-1;

$bg-error: $warning-5;

$tree-item-active-bg: $primary-6;



// Links
$link-color: $primary-3;
$link-hover: $primary-2;
$link-focus: $primary-1;
$link-disabled: $grey-1;

$link-danger-color: $warning-3;
$link-danger-hover: $warning-2;
$link-danger-focus: $warning-1;


//Buttons
$button-disabled: $grey-1;
$button-disabled-bg: $grey-2;

$button-bg: $primary-3;
$button-hover: $primary-2;
$button-focus: $primary-1;

$button-ghost-hover-color: $primary-2;
$button-ghost-hover: $primary-6;
$button-ghost-focus: $primary-1;
$button-ghost-focus-bg: $primary-6;

$btn-height: 32px;
$btn-line-height: 30px;
$btn-height-sm: 24px;
$btn-line-height-sm: 22px;

$btn-padding-x: 16px;
$btn-sm-padding-x: 12px;


// Inputs
$control-bg: $white-1;
$control-border: $grey-2;
$control-hover: $primary-2;
$control-hover-bg: $primary-6;
$control-hover-brd: $grey-8;
$control-focus: $color-focus;
$control-disabled-bg: $grey-3;
$control-disabled: $grey-2;
$control-error-brd: $warning-3;

$placeholder-color: $grey-1;
$empty-placeholder-color: $grey-8;

$control-height: 32px;
$control-line-height: 32px;
$control-sm-height: 24px;
$control-sm-line-height: 22px;
$control-padding-x: 8px;

$form-label: $grey-4;


// Checkbox, radio
$control-checkbox-radio-border: $grey-2;
$control-checkbox-radio-bg: $white-1;
$control-checkbox-radio-border-hover: $primary-2;
$control-checkbox-radio-bg-hover: $primary-6;
$control-checkbox-radio-border-focus: $primary-1;
$control-checkbox-radio-bg-focus: $primary-6;

$control-checkbox-radio-border-checked: $primary-3;
$control-checkbox-radio-bg-checked: $primary-3;
$control-checkbox-radio-border-checked-hover: $primary-2;
$control-checkbox-radio-bg-checked-hover: $primary-2;
$control-checkbox-radio-border-checked-focus: $primary-1;
$control-checkbox-radio-bg-checked-focus: $primary-1;

$control-checkbox-radio-border-disabled: $grey-2;
$control-checkbox-radio-bg-disabled: $grey-3;
$control-checkbox-radio-text-disabled: $grey-1;

$control-checkbox-radio-border-checked-disabled: $grey-2;
$control-checkbox-radio-bg-checked-disabled: $grey-2;

$control-radio-check-focus: $primary-1; //using for square radio btns and radio-checkbox components


// Tables, Data list
$table-header-bg: $grey-3;
$row-divider-color: $border-color;
$row-hover: $yellow-5;
$row-active: $primary-6;
$row-highlighted: $yellow-4;
$row-pending: $yellow-6;


// Grid
$grid-bg: $white-1;
$grid-header-bg: $grey-3;
$grid-footer-bg: $grey-3;
$grid-borders: $border-color;
$grid-borders-focus: $color-focus;
$grid-cell-inactive: $grey-7;
$grid-action: $primary-3;


//Popover
$popover-bg-color: $white-1;
$popover-shadow: 0 0 20px 0 rgba($grey-4, 0.2);

// History status color
$added: $green-5;
$updated: $yellow-2;
$removed: $secondary-2;


// Fonts
$font-roboto-regular: 'Roboto', Arial, Helvetica, sans-serif;
$font-roboto-light: 'Roboto Light', Arial, Helvetica, sans-serif;
$font-roboto-medium: 'Roboto Medium', Arial, Helvetica, sans-serif;
$font-roboto-bold: 'Roboto Bold', Arial, Helvetica, sans-serif;
$font-roboto-black: 'Roboto Black', Arial, Helvetica, sans-serif;   // not use now

$font-family-base: $font-roboto-regular;


// Screens size
$screen-mx-min: 0;
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 992px;
$screen-xl-min: 1200px;
