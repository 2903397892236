﻿.data-list {
    &-cell {
        flex: none;
        padding-left: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        min-width: 1px;
        min-height: 1px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            position: relative;
            margin-right: 0;
            padding-right: 0;
        }

        &:not(.cell-input):not(:last-child).text-right {
            padding-right: 8px;
            padding-left: 0;
        }

        &:not(:last-child).cell-input {
            padding-right: 8px;
        }

        input.form-control:not(.form-control-sm),
        .custom-drop-down-small {
            display: inline-block;
            width: 100%;
            height: 24px;
            font-size: inherit;
            text-align: inherit;
            vertical-align: middle;
        }

        .pseudo-link {
            width: auto;
            max-width: 100%;
        }

        .action-button-wrap {
            display: flex;
            justify-content: flex-end;
            width: 32px;

            .icon-svg {
                margin-right: 0;
            }

            &.cancel-action {
                &:empty {
                    &:not(:last-child) {
                        display: none;
                    }
                }

                &:not(:empty) {
                    & + .action-button-wrap {
                        display: none;
                    }
                }
            }
        }

        .form-control,
        .form-control-sm {
            text-align: inherit;
        }

        .custom-drop-down {
            .drop-down-list-item {
                font-size: inherit;
            }
        }

        .input-placeholder-value {
            display: inline-block;
            overflow: hidden;
            text-align: inherit;
            border-radius: 2px;
            vertical-align: middle;
            line-height: 25px;
        }

        .btn-link-active {
            color: $link-focus;
        }

        .btn-ghost:not([disabled]) {
            border-color: currentColor;
        }

        .container-loader {
            position: static;
            display: inline-flex;
            flex-direction: row;
            vertical-align: top;
            background: none;

            .text-loader {
                margin-left: 8px;
            }
        }

        .status-message {
            white-space: normal;
        }

        .rdp-input,
        .rdp-input input {
            width: 100%;
        }

        .less-then-five-mins {
            color: $secondary-1;
        }

        .animated-value-update-content {
            font-size: 12px;
            line-height: 24px;
        }

        .bidding-info-content {
            padding: 2px 8px 2px 0;

            .tooltip-wrapper:last-child:not(:first-child) {
                margin-left: 8px;
            }
        }

        .info-tooltip {
            margin-left: 4px;

            .icon-svg {
                margin-left: 0;
            }
        }

        // Global unusual cells
        &.cell-bid-value {
            position: relative;
            overflow: visible;
            margin-right: 24px;

            .btn-link:not(.btn-timeline) {
                width: 16px;
                margin-right: -24px;
                margin-left: 8px;
                position: relative;
                z-index: 5;

                .icon-svg {
                    margin-right: 0;
                }
            }

            .seller-buy-side-bid {
                display: flex;
                
                .commision {
                    width: 114px;
                }
                
                .axed-final-icons {
                    justify-content: flex-end;
                    min-width: 56px;
                    text-align: left;

                    .axed-icon-wrap:last-child {
                        margin-right: 24px;
                    }
                }
            }

            .new-bid-value {
                animation-name: newvalueanimation;
                animation-duration: 6s;
            }

            .bid-current-status {
                position: relative;
                display: inline-block;
                margin-right: -32px;
                padding: 8px;
                vertical-align: middle;
                line-height: 1;

                &.bid-current-status-tied {
                    color: $warning-1;
                }

                &.bid-current-status-best {
                    color: $green-9;
                }
            }

            .input-placeholder-value {
                min-width: 94px;
            }
        }

        &.cell-bid-level {
            display: flex;
            justify-content: flex-end;
            min-width: 140px;
            padding-right: 70px;
            white-space: nowrap;
            text-align: right;

            .bid-level-delta {
                display: inline-flex;
                margin-right: -70px;
                align-self: center;
                line-height: 14px;
            }
        }

        &.cell-submit-status {
            min-width: 239px;

            .flex-row {
                flex-grow: 1;
                min-width: 0;
            }

            .tooltip-wrapper {
                &:first-child {
                    min-width: 0;

                    .status {
                        display: block;
                    }
                }

                &:last-child {
                    margin-left: auto;
                }
            }
        }

        &.cell-flexible-text {
            position: relative;
            display: flex;
            align-items: center;
            min-height: 32px;
            line-height: 32px;

            & > .text-ellipsis {
                position: absolute;
                top: 0;
                left: 0;
                padding-left: 8px;

                .icon {
                    margin-right: 8px;
                    color: $tertiary-1;
                }
            }

            & > .animated-value-update {
                position: absolute;
                top: 4px;
                left: 16px;
                max-width: 100%;

                .animated-value-update-content {
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }

            .tooltip-wrapper + .text-ellipsis {
                margin-left: 24px;
            }

            & > .status {
                flex: none;
                max-width: calc(100% - 15px);
                margin-right: 8px;

                span {
                    width: auto;
                    overflow: hidden;
                    max-width: 100%;
                    text-overflow: ellipsis;
                }
            }

            .direct-bid-feedback {
                position: absolute;
                top: 0;
                right: 0;
                left: 0;
                padding-left: 8px;

                .status {
                    margin-left: 8px;
                }
            }
        }

        &.cell-bid-actions {
            position: relative;
            display: flex;
            justify-content: flex-end;
            padding-right: 4px;

            .btn-sm {
                & + .btn-sm,
                & + .tooltip-wrapper {
                    margin-left: 8px;
                }
            }

            .display-inline:not(:first-child) {
                margin-left: 11px;
            }

            & + .cell-button-history {
                margin-left: inherit;
            }
        }

        &.cell-color-value {
            & + .cell-bid-actions {
                margin-left: auto;
            }
        }

        &.cell-button-history {
            margin-left: auto;
        }

        &.cell-affirm-trade-btns {
            .limit-blocker {
                width: 20px;
                display: inline-block;
            }
        }

        &.cell-bob {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: 32px;
            border-right: 1px solid $row-divider-color;

            .icon-wrap {
                width: 16px;

                .icon {
                    vertical-align: top;
                }

                .icon-svg-final {
                    margin-top: -1px;
                }
            }

            .company-bid-value {
                display: flex;
                align-items: center;

                .quick-feedback-menu {
                    margin-left: auto;
                    padding: 4px;

                    .context-menu-dropdown {
                        margin-top: -4px;
                    }
                }

                &-content {
                    display: flex;
                    align-items: center;

                    .input-placeholder-value {
                        width: 55px;
                        margin-right: 4px;
                        padding: 1px 0 0;
                        line-height: 24px;
                    }
                }
            }

            .company-bid-empty {
                line-height: 24px;
            }

            .company-bid-pass {
                width: 55px;
                margin-right: 4px;
                line-height: 25px;
            }

            .bid-on-behalf-form {
                display: flex;

                &-value {
                    flex: none;
                    display: flex;
                    align-items: center;
                    width: 90px;
                    margin-right: 4px;
                    text-align: right;

                    .form-control {
                        width: 75px;
                        padding-top: 1px;
                    }

                    .highlight .form-control {
                        animation-name: input-border-animation;
                        animation-duration: 6s;
                    }

                    .btn-link {
                        margin-left: 5px;
                        width: 16px;
                    }
                }

                &-icons {
                    display: flex;
                    align-self: center;
                }

                &-actions {
                    display: flex;
                    align-items: center;
                    margin-left: auto;
                    margin-right: 4px;

                    .context-menu-dropdown {
                        left: 1px;
                    }

                    .bid-on-behalf-context-menu {
                        .context-menu {
                            .context-menu-content {
                                padding: 0;

                                button {
                                    color: inherit;
                                    width: 24px;
                                    height: 24px;
                                    padding: 4px;
                                    border: 0;
                                    background: none;
                                    cursor: pointer;
                                }

                                &:not(.disabled):hover,
                                &.show {
                                    color: $link-hover;

                                    button {
                                        color: currentColor;
                                    }
                                }

                                &:not(.disabled):focus,
                                &:not(.disabled):active {
                                    color: $link-focus;

                                    button {
                                        color: currentColor;
                                    }
                                }
                            }

                            .context-menu-dropdown {
                                padding-top: 3px;
                                padding-bottom: 3px;

                                li {
                                    font-size: 12px;
                                    min-width: 69px;
                                    padding: 8px 11px;
                                    line-height: inherit;

                                    .icon {
                                        margin-right: 4px;
                                    }

                                    label {
                                        cursor: inherit;
                                    }

                                    &.disabled {
                                        color: $grey-1;
                                        background: none;
                                        cursor: default;
                                    }

                                    .limit-blocker {
                                        color: $grey-1;
                                    }
                                }

                                &.show-above {
                                    margin-bottom: -2px;
                                }
                            }
                        }
                    }

                    .bid-on-behalf-feedback {
                        margin-left: 2px;
                    }
                }
            }
        }

        &.cell-trade-action {
            .btn {
                width: 59px;
                padding: 0;
            }

            .status {
                display: inline-block;
                text-align: left;
            }

            &.flex-item-right + .data-list-history-action,
            &.flex-item-right + .data-list-bidlist-action {
                margin-left: 0;
            }
        }

        &.cell-bd-trade-action {
            .icon-svg,
            .seller-rejected {
                margin-right: 0;
            }

            .loader-box {
                justify-content: flex-end;
            }
        }

        &.cell-bid-best,
        &.cell-bid-cover,
        &.cell-bid-on-top {
            font-size: 12px;
            border-right: 1px solid $row-divider-color;

            &:empty {
                min-height: 32px;
            }

            .flex-row {
                width: 100%;
                min-height: 32px;
            }

            .feedback-menu {
                flex: none;
                margin-left: auto;
                padding: 4px;

                .popover {
                    white-space: nowrap;
                }

                .context-menu-data {
                    position: relative;
                }
            }

            .company-bid-value-empty {
                display: flex;
                justify-content: flex-end;
                padding: 8px;
                color: $grey-1;
            }

            .bid-level-dash {
                display: block;
                padding-left: 8px;
                line-height: 32px;
            }
        }

        &.cell-bid-best {
            border-left: 1px solid $row-divider-color;

            .tooltip-wrapper {
                margin-left: auto;

                & + .feedback-menu {
                    min-width: 32px;
                    margin-left: 8px;
                }
            }
        }

        &.cell-bidlist-action {
            margin-left: auto;
            padding-right: 8px;

            .btn {
                width: 100%;
            }

            .btn:not(.btn-bidlist) {
                padding: 0 0 0 12px;
                text-align: left;
                justify-content: flex-start;
            }
        }

        &.cell-open-bidding-feedback,
        &.cell-feedback .live-bidding-feedback {
            .animated-value-update {
                display: block;
                margin: 0 0 0 4px;
                background-color: $green-5;

                .animated-value-update-content {
                    font-family: $font-roboto-medium;
                }

                .animated-value-update-bg {
                    background-color: $green-1;
                }
            }
        }

        &.cell-feedback {
            min-width: 220px;
            padding-left: 16px;

            & > .text-ellipsis {
                padding-left: 16px;
            }

            .animated-value-update {
                margin-right: 0;
                margin-left: -8px;
            }

            .animated-value-update-content {
                font-family: $font-roboto-regular;
            }
        }

        &.cell-submit-button {
            display: flex;
            justify-content: flex-end;

            .btn {
                width: 64px;
            }
        }

        &.cell-comment {
            flex: 1 1 auto;
            display: flex;

            textarea.form-control {
                font-size: inherit;
                padding-top: 5px;
                padding-bottom: 4px;
                min-height: 24px;
                flex: none;
                line-height: 1.1;
                resize: none;
            }

            .comment {
                width: 100%;
            }
        }

        &.cell-intex-button {
            .data-list-cell-icon-collapsed + & {
                margin-left: auto;
            }
        }
        
        &.cell-px-talk {
            padding-left: 0;
            letter-spacing: -0.3px;
        }
        
        &.cell-external-links {
            display: flex;
            justify-content: flex-end;
            min-width: 96px;

            .limit-blocker,
            .tooltip-wrapper,
            .bloomberg-wrapper {
                &:not(:last-child) {
                    margin-right: 16px;
                }
            }
        }

        &.cell-actions-placeholder {
            .right-sticky-collapsed .data-list-header & {
                opacity: 0;
            }
        }

        &.cell-input-commision {
            @include flex-basis(90px);
            padding-right: 8px;
            text-align: right;
        }
    }

    &-controls {
        display: flex;
        flex-direction: row;
        flex: 1 0 auto;
        align-items: center;
        padding-left: 8px;

        &.data-list-controls-transparent {
            background: transparent;
        }

        &.flex-none {
            flex-grow: 0;
        }

        .btn-link {
            svg.icon {
                margin-right: 0;
            }
        }

        .data-list-cell {
            &:not(:last-child) {
                padding-right: 5px;
            }
        }
    }

    .level-bids {
        position: relative;
        width: 100%;
        max-width: 160px;

        &-data {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border-radius: 2px;

            .icon-svg {
                position: absolute;
                top: 3px;
            }

            .icon-svg-axed {
                right: 18px;
            }

            .icon-svg-final {
                right: 2px;
            }

            &.no-feedback-bid {
                background-color: $yellow-4;
            }

            &.traded {
                color: $white-1;
                background-color: $green-9;
            }

            &:not(.traded):not(.rejected).finished {
                color: inherit;
                background-color: transparent;
            }

            span, b {
                width: auto;
                @extend .text-ellipsis;
            }

            span {
                display: inline-block;
                padding-left: 4px;
                vertical-align: top;
                font-family: $font-roboto-regular;
            }

            b {
                max-width: 60%;
                min-width: 50%;
                margin-left: auto;
                padding-left: 2px;
                text-align: right;
                font-family: $font-roboto-medium;
            }
        }

        .new-bid {
            background-color: $yellow-2;
        }

        .animated-value-update-content {
            display: flex;
            width: 100%;
            padding: 0 36px 0 4px;
        }
    }
    // Dealer stats columns
    &-ds-dealer {
        width: 62px;
        padding-left: 0;
    }

    &-ds-live-bidding {
        width: 99px;
    }

    &-ds-bwic {
        width: 74px;
    }

    &-ds-invited {
        width: 74px;
    }

    &-ds-rate {
        width: 121px;
        border-right: 1px solid $row-divider-color;
    }

    &-ds-pxtalk {
        width: 81px;
    }

    &-ds-pxtalk-deviation {
        width: 100px;
    }

    &-ds-bids {
        width: 73px;
    }

    &-ds-impv {
        width: 78px;
    }

    &-ds-total-trades {
        width: 77px;
    }

    &-ds-win-rate {
        width: 80px;
    }

    &-ds-bonds {
        width: 90px;
        border-left: 1px solid $row-divider-color;
    }

    &-ds-aaa {
        width: 62px;
    }

    &-ds-aa {
        width: 54px;
    }

    &-ds-a {
        width: 50px;
    }

    &-ds-bbb {
        width: 60px;
    }

    &-ds-bb {
        width: 52px;
    }

    &-ds-b {
        width: 50px;
    }

    &-ds-other {
        width: 70px;
    }

    &-ds-nr {
        width: 100%;
        max-width: 54px;
    }

    &.data-list-freeze {
        .data-column-sticky-left {
            & + .data-column-sticky-middle {
                .data-list-cell:first-child {
                    padding-left: 8px;
                }
            }
        }
    }
}
