﻿.sidebar-nav {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 1px;

    &.collapsed {
        flex-basis: 40px;
        max-width: 40px;

        .sidebar-nav-header {
            border-bottom: none;
            flex-wrap: wrap;
        }

        .btn-sidebar-expand {
            .icon-svg-collapse-left {
                transform: rotate(180deg);
            }
        }

        .form-control-search,
        .control-search-wrapper {
            display: none;
        }

        .sidebar-nav-list {
            width: 0;
            overflow: hidden;
        }

        .tabs {
            display: none;
        }
    }

    .loader-box {
        figure {
            .preloader {
                width: 48px;
                height: 48px;
                flex: none;
            }
        }
    }

    .invisible-data-placeholder {
        padding: 16px 24px;
        font-family: $font-roboto-regular;
        font-size: 14px;
    }
}


/* Sidebar navigation Header with search and Button expand */

.sidebar-nav-header {
    flex: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-basis: 50px;
    min-height: 50px;
    max-height: 50px;
    border-bottom: 1px solid $border-color;

    .tooltip-wrapper {
        display: block;
        height: 100%;
    }

    .btn-sidebar-expand {
        height: 48px;
        padding-left: 11px;
        padding-right: 11px;

        .icon-svg-collapse-left {
            margin-right: 0;
        }
    }

    .form-control-search {
        flex: auto;
        border: none;
        padding-left: 64px;
        background-position: 32px 6px;

        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    .btn-back + .control-search-wrapper .form-control-search {
        padding-left: 52px;
        background-position: 20px 6px;
    }

    .control-search-wrapper {
        border: none;
        border-radius: 0;
        flex: auto;
        height: 100%;
        align-items: center;
        border-left: 1px solid $border-color;
        border-right: 1px solid $border-color;

        &:not(.disabled):hover {
            border-color: $border-color;
        }

        .form-control-search-btn {
            order: 1;
            margin-left: 4px;
        }

        .form-control-search {
            order: 2;
            padding-left: 0;
        }

        .btn-close {
            order: 3;
        }
    }
}

/* end Sidebar navigation Header with search and Button expand  */


/* Sidebar navigation ordinary list menu */

.sidebar-nav-list {
    color: $text-color;
    font-size: 15px;
    font-family: $font-roboto-medium;
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;
    overflow: auto;
    width: 100%;
    position: relative;

    .empty-placeholder {
        margin: 0;
        font-size: 14px;
        font-family: $font-roboto-regular;
        min-width: 100%;
        max-width: 100%;
    }
}

.sidebar-nav-list-item {
    border-bottom: 1px solid $border-color;
    cursor: pointer;
    line-height: 2.7;
    padding-left: 24px;
    flex: none;
    display: flex;
    flex-direction: row;

    &:hover,
    &.active {
        background-color: $tree-item-active-bg;
    }

    &.active {
        color: $primary-3;
    }

    &.expanded,
    &.active.expanded {
        .icon-expand {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='10' height='7' viewBox='0 0 10 7'%3E%3Cpath fill='%230096AA'  d='M8.825 0L5 3.973 1.175 0 0 1.223 5 6.43l5-5.206z'/%3E%3C/svg%3E");
        }
    }
    &.expanded,
    &.active.expanded {
        .icon-svg-icon-expand {
            transform: rotate(90deg);
        }
    }

    &.sidebar-nav-list-item-extended {
        color: $text-color;
        line-height: 1.4;
        padding-right: 24px;

        .sidebar-nav-list-item-content {
            text-decoration: none;
            padding-top: 14px;
            padding-bottom: 15px;
            flex: auto;
            position: relative;

            .state-new {
                flex: none;
                display: inline-block;
            }

            .label-status-iois {
                flex: none;
                margin-left: 12px;
            }
        }

        .icon-expand {
            margin-right: 9px;
            vertical-align: top;
            margin-top: 8px;
            width: 16px;
            height: 28px;
            flex: none;
            background-size: auto;
            background-position-y: 9px;
        }

        .icon-svg-icon-expand {
            margin-right: 9px;
            vertical-align: top;
            margin-top: 8px;
            flex: none;
        }

        .title {
            color: $text-color;
            font-size: 14px;
            font-family: $font-roboto-medium;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 180px;
        }

        .text {
            color: $text-color;
        }

        .flex-row:first-child {
            margin-bottom: 8px;
        }

        &.expanded,
        &.active.expanded {
            border-bottom-color: transparent;

            .icon-expand {
                margin-top: 10px;
            }

            & + .component-subtree {
                flex: none;
                border-bottom: 1px solid $border-color;
            }
        }
    }

    .item-info-tooltip {
        margin-right: 10px;

        &:last-child {
            margin-right: 0;
            flex: 0 0 100px;
            margin-left: auto;
        }
    }

    .sidebar-controls {
        position: relative;
        height: 20px;

        &-item {
            width: 32px;
            line-height: 1;
        }

        &-item-label {
            width: 34px;
        }

        &-item-divider {
            padding-left: 8px;
            display: flex;
            align-items: center;
            width: 9px;
        }
    }
}

.sidebar-nav-list-mixed {
    .sidebar-nav-list-item {
        padding-left: 28px;

        &.no-expanded {
            padding-left: 52px;
        }
    }
}


/* end Sidebar navigation ordinary list menu */
