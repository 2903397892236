﻿@media all and (-ms-high-contrast:none) {
    .data-list {
        overflow-x: scroll;

        &:not(.data-list-freeze) {
            overflow-y: hidden;
        }

        &.data-list-freeze {
            overflow-x: visible;

            .data-list-content {
                .data-column-sticky-middle {
                    display: flex;
                    overflow-x: scroll;
                }
            }

            &.seller-buyside-content {
                .data-list-columns {
                    .data-column-sticky-middle {
                        overflow-x: auto;
                    }
                }
            }
        }

        &.data-list-bd-bidding {
            .data-list-container {
                min-width: 1350px;
            }
        }

        &.data-list-bd-finished {
            .data-list-container {
                min-width: 1350px;
            }
        }
    }

    .all-tab {
        .data-list.data-list-freeze {
            .middle-sticky-content {
                min-width: 1333px;
            }
        }

        &.all-admin-tab,
        &.all-viewer-tab {
            .data-list.data-list-freeze {
                .middle-sticky-content {
                    min-width: 1139px;
                }
            }
        }
    }

    .sell-tab {
        .data-list.data-list-freeze {
            .middle-sticky-content {
                min-width: 1400px;
            }
        }
    }

    .container-bwics:not(.all-tab):not(.sell-tab) {
        .data-list.data-list-freeze {
            .middle-sticky-content {
                min-width: 1350px;
            }

            &.seller-buyside-content {
                .middle-sticky-content {
                    min-width: 1398px;
                }
            }
        }
    }

    .inventory-cnt {
        .data-list.data-list-freeze {
            .middle-sticky-content {
                min-width: 1310px;
            }
        }
    }

    .data-list-striped {
        &.pipeline-list-deals {
            .data-column-sticky-middle {
                .header-sticky-content,
                .middle-sticky-content {
                    min-width: 3538px;
                }
            }
        }

        &.pipeline-list-class {
            .data-list-container {
                .data-column-sticky-middle {
                    .middle-sticky-content,
                    .header-sticky-content {
                        min-width: 5826px;
                    }
                }
            }
        }

        &.data-list-arranger-pipeline {
            .data-list-container {
                min-width: 2064px;
            }
        }

        &.clo-manager-investment-team-table {
            .data-list-container {
                /*AMR-8924 CLO Managers:: CLO Team:: Hide the 'Yrs at firm' and 'Yrs experience' fields in the table*/
                min-width: 1036px;
            }
        }

        &.transaction-view-history-table {
            .data-list-container {
                min-width: 1094px;
            }
        }
    }

    .data-list-striped-cap-structure {
        .middle-sticky-content {
            min-width: 2100px;
        }

        &.data-item-capital-structure {
            .middle-sticky-content {
                min-width: 3445px;
            }
        }
    }

    .component-tabs-target-portfolio {
        .data-list {
            &:not(.data-list-freeze) {
                overflow-x: scroll;
            }
        }
    }

    .container-trans-history {
        .data-list-striped-cap-structure {
            .middle-sticky-content {
                min-width: 1620px;
            }

            &.data-item-capital-structure {
                .middle-sticky-content {
                    min-width: 2120px;
                }
            }
        }
    }

    .clients-area {
        .clients-companies {
            .data-list-container {
                min-width: 1130px;
            }
        }
    }
    
}
