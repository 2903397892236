﻿.form-control-upload {
    background-color: $control-bg;
    border: solid 1px $control-border;
    border-radius: $app-brd-radius;
    padding: 3px;
    display: block;
    width: 100%;
    height: 32px;
    font-size: 0px;

    &.is-invalid,
    &.invalidated:invalid {
        border-color: $control-error-brd;

        &:before {
            width: 16px;
            height: 16px;
            content: "";
            position: absolute;
            top: 8px;
            right: 33px;
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.80021 8.7998H7.2002V3.99981H8.80021V8.7998Z' fill='%23FF614D'/%3E%3Cpath d='M7.2002 12H8.80021V10.4H7.2002V12Z' fill='%23FF614D'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0ZM8 1.3C11.7003 1.3 14.7 4.29969 14.7 8C14.7 11.7003 11.7003 14.7 8 14.7C4.29969 14.7 1.3 11.7003 1.3 8C1.3 4.29969 4.29969 1.3 8 1.3Z' fill='%23FF614D'/%3E%3C/svg%3E%0A");
            background-position: 0 0;
            background-repeat: no-repeat;
        }
    }

    & + .form-error {
        height: auto;
        padding-top: 1px;
        padding-bottom: 1px;
        white-space: normal;
    }
}

.form-control {
    &.form-control-uploadF {
        color: $placeholder-color;
        font-size: 14px;
        white-space: nowrap;
        text-overflow: ellipsis;
        background-position: 98% 7px;
        background-repeat: no-repeat;
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.24 2.16667L7.19429 4.15583L7.52571 4.5H14.8571V13.8333H1.14286V2.16667H5.24ZM5.24 1H1.14286C0.511675 1 0 1.52233 0 2.16667V13.8333C0 14.4777 0.511675 15 1.14286 15H14.8571C15.4883 15 16 14.4777 16 13.8333V4.5C16 3.85567 15.4883 3.33333 14.8571 3.33333H8L6.05143 1.34417C5.83669 1.12364 5.5446 0.999754 5.24 1Z' fill='%234A4A4A'/%3E%3C/svg%3E%0A");
        padding-right: 34px;
        overflow: hidden;
        height: 30px;
        line-height: 30px;
        width: calc(100% - 2px);
        border: none;
        position: absolute;
        top: 1px;
        left: 1px;

        &.active-color {
            color: $grey-4;
        }
    }
}

.control-search-wrapper {
    &.securities-search {
        width: 400px;

        .single-search-value {
            white-space: nowrap;
            padding: 0 0 0 16px;
            line-height: 32px;
        }
    }
}

.select-date-range {
    position: relative;
    width: 180px;

    &.select-focused {
        .form-select {
            border-color: $control-focus;
        }
    }

    &.select-expanded {
        .form-select {
            background-image:url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiIHN0YW5kYWxvbmU9Im5vIj8+PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj48c3ZnIHdpZHRoPSIxMHB4IiBoZWlnaHQ9IjZweCIgdmlld0JveD0iMCAwIDEwIDYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSIgeG1sbnM6c2VyaWY9Imh0dHA6Ly93d3cuc2VyaWYuY29tLyIgc3R5bGU9ImZpbGwtcnVsZTpldmVub2RkO2NsaXAtcnVsZTpldmVub2RkO3N0cm9rZS1saW5lam9pbjpyb3VuZDtzdHJva2UtbWl0ZXJsaW1pdDoyOyI+PHBhdGggZD0iTTEuNzk2LDMuMDc5Yy0wLjkyMSwwLjg4NyAtMS42NTcsMS41ODUgLTEuNzk2LDEuNjk1bDEuMDY2LDEuMjI2YzAuMjE2LC0wLjE3MyAxLjAzMywtMC45NTIgMS45MSwtMS43OTZjMC42NjUsLTAuNjQgMS4zOTYsLTEuMzQ5IDIuMDAxLC0xLjkzOGwzLjg0NiwzLjY4NWwxLjE3NywtMS4xMjhsLTUuMDMzLC00LjgyM2wtMC41ODksMC41NzRjLTAuNjY1LDAuNjUgLTEuNjg1LDEuNjQxIC0yLjU4MiwyLjUwNVoiIHN0eWxlPSJmaWxsOiM0YTRhNGE7Ii8+PC9zdmc+');
        }
    }

    .select-lookup {
        width: 100%;
        background-color: $white-1;
        box-shadow: $app-shadow;
        border: solid 1px $border-color;
        padding: 14px 0;
        position: absolute;
        top: 100%;
        left: 0;
        z-index: 2;
    }
}

.select-lookup-items {
    li {
        font-size: 14px;
        height: 32px;
        line-height: 32px;
        padding: 0 10px;
        cursor: pointer;

        &:hover {
            background-color: $row-active;
        }

        &.selected {
            font-family: $font-roboto-medium;
            background-color: $row-active;
        }
    }
}

.custom-drop-down {
    text-align: left;
    cursor: pointer;

    .loader-box {
        height: 100%;
        justify-content: flex-start;
        .text-loader {
            color: $grey-8;
        }
    }

    &-sm {
        &.form-control {
            font-size: 12px;
            height: 24px;
            line-height: 24px;
        }
    }

    & > span,
    .drop-down-btn > span {
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-right: 25px;
    }

    &.select-focused {
        border-color: $control-focus;
    }

    &.is-open {
        border-color: $control-focus;
        z-index: 5;
    }

    .icon-svg-icon-expand {
        position: absolute;
        top: 50%;
        right: 8px;
        transform: translateY(-50%) rotate(90deg);
        z-index: 0;

        &.is-open {
            margin-top: 0;
            transform: translateY(-50%) rotate(-90deg);
        }
    }

    &.is-invalid:not(textarea) {
        background-position: right 27px top 50%;
    }

    .drop-down-btn {
        span {
            display: block;
            position: relative;
            z-index: 1;

            &.btn-placeholder {
                color: $placeholder-color;
            }
        }
    }

    .drop-down-list {
        position: absolute;
        top: calc(100% + 1px);
        left: 0;
        z-index: 100;
        width: 230px;
        box-shadow: $app-shadow;
        border-radius: $app-brd-radius;
        background: $white-1;

        .section {
            &:nth-child(2) {
                overflow: auto;
                height: 400px;
                border-top: 1px solid $border-color;
            }
        }

        &-item {
            padding: 8px 12px;
            overflow: hidden;
            font: 14px/16px $font-roboto-regular;
            cursor: pointer;

            &:hover {
                background: $primary-6;
            }

            .item-wrapper {
                overflow: hidden;
            }

            .item-value-left {
                display: inline-block;
                width: 55px;
                color: $grey-8;
            }

            .item-value-right {
                display: inline-block;
                width: calc(100% - 55px);
                vertical-align: top;
                color: $grey-4;
            }
        }
    }

    &.form-control-sm {
        .drop-down-list {
            width: 130px;
        }

        .icon-arrow-teal {
            width: 12px;
            height: 8px;
            filter: grayscale(100%);
        }

        .drop-down-list {
            box-shadow: $app-shadow;
            border: 1px solid $grey-5;
            border-radius: 0;
        }

        .section {
            overflow: auto;
            max-height: 240px;
        }

        .drop-down-list-item {
            font-size: inherit;
            padding: 4px 12px;

            &:hover {
                background-color: $primary-6;
            }

            &.selected {
                font-family: $font-roboto-medium;
            }

            span {
                &:first-child {
                    color: inherit;
                }
            }
        }
    }

    &-ghost {
        border: none;
        border-radius: 0;
        height: auto;
        line-height: inherit;
        padding: 0;
        width: auto;
        margin-left: auto;
        display: inline-flex;

        & > span {
            margin-right: 0;
        }

        &.disabled {
            color: $grey-1;
            background-color: transparent;

            .drop-down-btn {
                &:hover,
                &:focus {
                    color: inherit;
                }
            }
        }

        .icon-svg {
            position: static;
            transform: none;
        }

        &.is-open {
            box-shadow: none;

            .drop-down-btn {
                .icon-svg {
                    transform: translateY(0) rotate(-90deg);
                }
            }
        }

        .drop-down-btn {
            display: flex;
            align-items: center;
            font-size: 12px;

            &:hover {
                color: $primary-2;
            }

            &:focus {
                color: $primary-1;
            }

            .icon-svg {
                margin-left: 4px;
                position: static;
                transform: translateY(0) rotate(90deg);
                overflow: visible;
            }
        }

        .drop-down-list {
            top: calc(100% + 5px);
            right: 0;
            left: auto;
            width: 100px;
            min-width: 100px;
            border: 1px solid $grey-5;
            box-shadow: 0px 12px 16px $grey-5;

            &-item {
                font-size: 12px;

                &.selected {
                    font-family: $font-roboto-medium;
                    background-color: $primary-6;
                }
            }
        }
    }

    &.select-search-field {
        .drop-down-list {
            width: auto;
            left: -1px;
            right: -1px;
            margin-top: 4px;

            .empty-placeholder {
                background-color: inherit;
                border: none;
                color: $placeholder-color;
            }
        }

        .control-search-wrapper {
            margin: 12px;
        }

        .section:nth-child(2) {
            height: auto;
            max-height: 154px;
            border-top: 0;
            border-bottom: 1px solid $border-color;
        }
    }
}

/* year selector */

.year-selector {
    position: relative;
    display: inline-flex;

    .btn-link {
        font-family: $font-roboto-regular;

        .icon-svg {
            margin-right: 0;
            margin-left: 4px;
            transform: rotate(90deg);
        }
    }

    &.show {
        .icon-svg {
            transform: rotate(-90deg);
        }
    }
}

.year-dropdown {
    position: absolute;
    z-index: 2;
    top: calc(100% + 5px);
    right: 0;
    width: 248px;
    border: 1px solid $grey-5;
    box-shadow: 0px 12px 16px $grey-5;
    background-color: $white-1;
    padding: 8px;
    display: flex;
    flex-wrap: wrap;
}

.year-item {
    width: 25%;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    font-size: 12px;

    &:hover {
        color: $primary-3;
    }

    &.selected {
        background-color: $primary-6;
        color: $primary-3;
        border-radius: $app-brd-radius;
    }
}

/* end year selector */
