.document-group-item {
    flex: none;
    padding-bottom: 32px;
}

.document-group-click-area {
    display: flex;
    align-items: center;
    flex: auto;
}

.document-group-item-title {
    margin-bottom: 20px;
    border-bottom: 1px solid $border-color;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 8px;
    cursor: pointer;

    .icon-svg-corner-arrow {
        margin-right: 8px;
        transform: rotate(-90deg);
        transform-origin: center;

        &.is-open {
            transform: rotate(0deg);
        }
    }

    h2 {
        margin-bottom: 0;
        font-size: 14px;
    }

    .header-title-count {
        color: $grey-8;
        font-family: $font-family-base;
        margin-left: 8px;
    }
}

.link-download-docs {
    display: flex;
    
    &:hover {
        text-decoration: none;
    }
}
