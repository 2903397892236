﻿.footer {
    color: $white-1;
    font-size: 10px;
    height: 32px;
    width: 100%;
    background-color: $grey-4;
    flex: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 24px;

    &.default-footer {
        min-width: 1280px;
    }
}

.footer-copyright,
.footer-nav {
    flex: 0 0 auto;
}

.footer-nav {
    margin-left: auto;

    a {
        color: $white-1;
        margin-left: 20px;

        &:hover {
            color: $grey-3;
        }

        &:active {
            color: $grey-5;
        }
    }
}

@media only screen and (max-width: 700px) {
    .footer:not(.default-footer) {
        font-size: 10px;
        height: 62px;
        flex-direction: column;
        padding: 0 20px;
    

        .footer-copyright {
            font-size: 10px;
            order: 1;
        }

        .footer-nav {
            text-align: center;
            order: 0;
            margin: 12px 0 10px;

            a {
                margin: 0 6px;
                position: relative;
                display: inline-block;
                vertical-align: middle;

                &:after {
                    color: inherit;
                    font-size: 10px;
                    content: "|";
                    pointer-events: none;
                    position: absolute;
                    top: 0;
                    right: -7px;
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;

                    &:after {
                        content: none;
                    }
                }
            }
        }
    }
}
