﻿.skeleton {
    display: flex;
    flex-direction: column;
    height: 327px;
    overflow: hidden;
    background: $white-1;

    &-element {
        border-radius: 4px;
        background-color: $grey-6;
        position: relative;

        &:after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            content: '';
            background-image: linear-gradient( 90deg, rgba($white-1, 0) 0, rgba($white-1, 0.2) 20%, rgba($white-1, 0.5) 60%, rgba($white-1, 0) );
            animation: shimmer 2s infinite;
        }
    }

    @keyframes shimmer {
        100% {
            transform: translateX(100%);
        }
    }

    &-heading {
        height: 24px;
        display: flex;
        flex-direction: row;
        margin: 0 0 12px;
    }

    &-title {
        width: 50%;
        height: 100%;
    }

    &-title-element {
        width: 24px;
        display: inline-block;
        height: 100%;
        margin-left: 12px;
    }

    &-cnt {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        min-height: 1px;
    }
}
